import React, { SyntheticEvent } from 'react'
import { CatalogFiltersForm } from './CatalogFiltersForm'
import { CatalogList } from './CatalogList'
import className from './index.module.scss'
import { ICatalogCard } from '../../../entities/catalog/model/types'
import { UseFormReturn } from 'react-hook-form'

type PropsType = {
    items: ICatalogCard[]
    filterForm: UseFormReturn
    isFetching: boolean
    handleSetFilter: () => void
    isFilterPopupOpen: boolean
    resetFilters: (e: SyntheticEvent) => void
    isOpenMobileFilter: () => void
    showMore: () => void
    page: number
    total: number
    limit: number
}

export const CatalogListWithFilters: React.FC<PropsType> = ({
    items,
    filterForm,
    isFetching,
    handleSetFilter,
    isFilterPopupOpen,
    resetFilters,
    isOpenMobileFilter,
    showMore,
    page,
    total,
    limit,
}) => {
    return (
        <div className={className.Container}>
            <CatalogFiltersForm
                handleSetFilter={handleSetFilter}
                filterForm={filterForm}
                isFilterPopupOpen={isFilterPopupOpen}
                resetFilters={resetFilters}
                isOpenMobileFilter={isOpenMobileFilter}
            />
            {!isFilterPopupOpen && (
                <CatalogList
                    items={items}
                    isFetching={isFetching}
                    showMore={showMore}
                    page={page}
                    total={total}
                    limit={limit}
                />
            )}
        </div>
    )
}
