import React from 'react'
import ReactSelect, {
    ClearIndicatorProps,
    DropdownIndicatorProps,
    GroupBase,
    MultiValueRemoveProps,
    components,
} from 'react-select'
import './styles.css'
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager'
import { Icons } from '../../icons'
import { Controller, useFormContext } from 'react-hook-form'
import { ReactElement, RefAttributes } from 'react'
import type SelectType from 'react-select/dist/declarations/src/Select.js'
import { css } from '@emotion/react'
import { theme } from '../../styles/theme'

export const MultiValueRemove = (props: MultiValueRemoveProps) => {
    return (
        <div>
            <components.MultiValueRemove {...props}>
                <Icons.M.Close />
            </components.MultiValueRemove>
        </div>
    )
}

export const DropdownIndicator = (props: DropdownIndicatorProps<any, any>) => {
    return (
        <div>
            <components.DropdownIndicator {...props}>
                <Icons.M.ChevronUp
                    css={css`
                        transition: 0.3s;
                        transform: ${props.isFocused
                            ? 'rotateZ(0deg)'
                            : 'rotateZ(180deg)'};

                        & > path {
                            fill: ${props.isFocused
                                ? theme.color.ozonBlue[500]
                                : theme.color.darkSpace[300]};
                        }
                    `}
                />
            </components.DropdownIndicator>
        </div>
    )
}

export const ClearIndicator = (props: ClearIndicatorProps<any, any, any>) => {
    return (
        <components.ClearIndicator
            {...props}
            className="none"
            css={css`
                cursor: pointer;
                position: absolute;
                top: calc(100% + 12px);
                right: 0;
                font-size: 12px;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                color: ${theme.color.darkSpace[700]};
                gap: 5px;
                height: 19px;
                padding: 0;

                &:hover {
                    color: ${theme.color.darkSpace[700]};
                }
            `}
        >
            <Icons.M.Close
                css={css`
                    width: 16px;
                    height: 16px;
                    & path {
                        fill: ${theme.color.darkBlue};
                    }
                `}
            />{' '}
            <span
                css={css`
                    height: 13px;
                `}
            >
                Удалить все теги
            </span>
        </components.ClearIndicator>
    )
}

declare type StateManagedSelectCustom = <
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
>(
    props: StateManagerProps<Option, IsMulti, Group> &
        RefAttributes<SelectType<Option, IsMulti, Group>> & {
            label?: string
        }
) => ReactElement

export const AppSelect: StateManagedSelectCustom = ({
    className,
    ...props
}) => {
    return (
        <div
            css={css`
                padding-bottom: 21px;
            `}
            className={className}
        >
            {props.label && (
                <label
                    css={css`
                        color: ${theme.color.darkSpace['700']};
                        margin-bottom: 8px;
                        display: block;
                    `}
                >
                    {props.label}
                </label>
            )}
            <ReactSelect
                {...props}
                components={{
                    MultiValueRemove,
                    DropdownIndicator,
                    ClearIndicator,
                }}
                blurInputOnSelect
                classNamePrefix="react-select"
            />
        </div>
    )
}

export const SmartSelect: StateManagedSelectCustom = (props) => {
    const form = useFormContext()

    return (
        <Controller
            render={({ field }) => {
                // const onChangeProxy: ChangeEventHandler<any> = (e) => {
                //     form.clearErrors(props.name!)
                //     field.onChange(e)
                // }

                return (
                    <AppSelect
                        {...props}
                        value={field.value}
                        classNamePrefix="react-select"
                        onChange={(value, actionMeta) => {
                            props.onChange && props.onChange(value, actionMeta)

                            form.setValue(props.name!, value)
                        }}
                    />
                )
            }}
            name={props.name!}
            control={form.control}
        />
    )
}
