import { Layout } from '../../shared/ui/Layout'
import { Container } from '../../shared/ui/Container'
import React, { useEffect } from 'react'
import { Advantages } from './advantages/advantages'
import { PromoExpertplace } from './promoExpertplace/PromoExpertplace'
import { Reviews } from './reviews/reviews'
import { Possibilities } from './possibilities/possibilities'
import { Banner } from './banner/banner'
import { CTA } from './cta/cta'
import { Faq } from '../../widgets/faq'

//TODO: нужно отрефакторить
export const LandingMain = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Layout>
            <Container>
                <Banner />
            </Container>
            <Advantages />
            <Container>
                <Possibilities />
            </Container>
            <PromoExpertplace />
            <Reviews />
            <CTA />
            <Container>
                <Faq type="mainPage" />
            </Container>
        </Layout>
    )
}
