import { useQuery } from '@tanstack/react-query'
import { applicationApi } from './index'
import { programApplicationsType, SubmitApplicationType } from '../types'

export const useGetListGroup = (id: number) => {
    return useQuery({
        queryKey: ['programGroup'],
        queryFn: async () => {
            const response = await applicationApi.getListGroup(id)
            return response.data.data
        },
    })
}

export const useGetProgramApplicationById = () => {
    return useQuery({
        queryKey: ['programApplication'],
        queryFn: async () => {
            const response = await applicationApi.getApplications('programs')
            return response.data.data
        },
    })
}

export const useSubmitApplication = (data: SubmitApplicationType) => {
    return useQuery({
        queryKey: ['submitApplication'],
        queryFn: async () => {
            const response = await applicationApi.postSubmitApplication(data)
            return response.data
        },
    })
}
