import { SmartCheckbox } from '../Checkbox'
import React from 'react'
import { SmartRadio } from '../Radio'
import s from './index.module.scss'

type PropsType = {
    items: { label: string; value: string; text?: string }[]
    name: string
} & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
>
export const Droplist: React.FC<PropsType> = ({ items, name, ...rest }) => {
    if (name === 'format' || name === 'city') {
        return (
            <div {...rest}>
                {items.length > 0 &&
                    items.map((item, index) => (
                        <SmartRadio
                            key={index}
                            name={name}
                            label={item.label}
                            value={item.value}
                        ></SmartRadio>
                    ))}
            </div>
        )
    }
    return (
        <div {...rest}>
            {items.length > 0 &&
                items.map((item, index) => (
                    <div className={s.wrapper} key={index}>
                        <SmartCheckbox
                            key={index}
                            name={name}
                            label={item.label}
                            value={item.value}
                        ></SmartCheckbox>

                        {item.text && <p className={s.text}>{item.text}</p>}
                    </div>
                ))}
        </div>
    )
}
