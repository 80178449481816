import React, {SyntheticEvent} from 'react';
import {SmartSelect} from "../../../shared/ui/Select";
import {AppButton} from "../../../shared/ui/Button";
import s from './Pagination.module.scss'
import {clsx} from "clsx";

interface IOption { value: number, label: number }

interface IPagination {
    page: number,
    total: number
    limit: number,
    options?: IOption[] | any,
    handleChangeLimit: (value: any) => void,
    onPrev: (evt: SyntheticEvent) => void,
    onNext: (evt: SyntheticEvent) => void,
    setPage: (page: number) => void,
    className?: string,
}

export const Pagination: React.FC<IPagination> = (props) => {
    const {limit, handleChangeLimit, options, page, total, onPrev, onNext, setPage, className} = props
    const defaultOptions = [
        {value:10, label:10},
        {value:20, label:20},
        {value:50, label:50},
    ]
    const totalPage = Math.ceil(total/limit)
    return (
        <div className={clsx(s.pagination, className)}>
            <p>На{'\u00A0'}странице</p>
            <SmartSelect
                className={s.select}
                name='limit'
                placeholder={'Страница'}
                onChange={handleChangeLimit}
                options={options ?? defaultOptions}
                value={limit}
                menuPlacement={'top'}
            />
            <AppButton
                variant='white'
                size='sm'
                disabled={page <= 1}
                onClick={() => setPage(1)}
            >
                {'<<'}
            </AppButton>
            <AppButton
                variant='white'
                size='sm'
                disabled={page <= 1}
                onClick={onPrev}
            >
                {'<'}
            </AppButton>
            {page}{'\u00A0'}из{'\u00A0'}{totalPage}
            <AppButton
                variant='white'
                size='sm'
                disabled={page === totalPage}
                onClick={onNext}>
                {'>'}
            </AppButton>
            <AppButton
                variant='white'
                size='sm'
                disabled={page === totalPage}
                onClick={() => setPage(totalPage)}>
                {'>>'}
            </AppButton>
        </div>
    )
}
