import { useState } from 'react'

export const useModalWindow = () => {
    const [isOpened, setIsOpened] = useState(false)

    const toggleOpen = () => setIsOpened(!isOpened)

    return {
        isOpened,
        setIsOpened,
        toggleOpen,
    }
}
