import { apiInstance } from '../../../shared/api'
import { SubmitApplicationType } from '../types'

export const applicationApi = {
    getApplications: (
        typeApplication: string,
        params: { sort: string } = { sort: 'recent' }
    ) => apiInstance.get(`/expert/applications/${typeApplication}`, { params }),
    getListProgramApplications: (id: number, params?: { sort: string }) =>
        apiInstance.get(`/expert/applications/programs/${id}`, { params }),
    putChangeApplicationState: (
        id: number,
        data: { reason: string; state: string; isSeries?: boolean }
    ) => apiInstance.put(`/expert/applications/${id}/state`, data),
    getListGroup: (id: number) =>
        apiInstance.get(`/expert/applications/programs/${id}/groups`),
    postCreateGroup: (id: number, data: { applicationIds: number[] }) =>
        apiInstance.post(`/expert/applications/programs/${id}/groups`, data),
    getGroup: (id: number, groupIndex: number) =>
        apiInstance.get(
            `/expert/applications/programs/${id}/groups/${groupIndex}`
        ),
    deleteGroup: (id: number, groupIndex: number) =>
        apiInstance.delete(
            `/expert/applications/programs/${id}/groups/${groupIndex}`
        ),
    changeGroupState: (
        id: number,
        groupIndex: number,
        completedIds: number[],
        state: 'completed' | 'failed'
    ) =>
        apiInstance.put(
            `/expert/applications/programs/${id}/groups/${groupIndex}/state`,
            { state, completedIds }
        ),
    postSubmitApplication: (data: SubmitApplicationType) =>
        apiInstance.post('/trainings/application', data),
}
