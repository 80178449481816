import { authApi } from '../../../features/auth/api'
import { setToken } from '../../../features/auth/lib/setToken'
import {
    ACCESS_TOKEN_LIFETIME,
    LOCAL_STORAGE_TOKEN_DATA_KEY,
    SSO_URL,
} from '../../../features/auth/model/constants'

let refreshTimeoutId: number | undefined

export const handleRefreshUserAccessToken = async (refresh: string) => {
    try {
        const { data: tokenData } = await authApi.getToken({ refresh })
        setToken(tokenData)

        window.clearTimeout(refreshTimeoutId)
        refreshTimeoutId = window.setTimeout(() => {
            handleRefreshUserAccessToken(tokenData.refreshToken)
        }, ACCESS_TOKEN_LIFETIME)
        return tokenData
    } catch (e: any) {
        handleRefreshUserRefreshToken()
    }
}

export const handleRefreshUserRefreshToken = () => {
    localStorage.removeItem(LOCAL_STORAGE_TOKEN_DATA_KEY)
    window.location.href = `${SSO_URL}?redirectUrl=${window.location.pathname}`
}
