import { IExpertProfile, MentorType } from '../../types'
import { Switcher } from '../../../../shared/ui/Switcher'
import React, { useEffect, useState } from 'react'
import { Tag } from '../../../../shared/ui/Tag'
import { Avatar } from '../../../../shared/ui/Avatar'
import s from './index.module.scss'
import { Icons } from '../../../../shared/icons'
import { ProfileTab } from './profileTab/profileTab'

type PropsType = {
    mentor: MentorType
    expertProfile: IExpertProfile
}

type roleOptions = {
    [key: string]: boolean
}

const options = [
    {
        value: '1',
        text: 'Направления экспертизы',
    },
    {
        value: '2',
        text: 'Опыт работы',
    },
]

export const MentorProfile: React.FC<PropsType> = ({ expertProfile }) => {
    const [tabIndex, setTabIndex] = useState<string>('1')
    // MentorProfile {props.mentor.id}

    const expertRoles: roleOptions = expertProfile.types
    const role = () => {
        const array: string[] = []

        for (const key in expertRoles) {
            if (expertRoles[key]) {
                switch (key) {
                    case 'coach':
                        array.push('Тренер')
                        break
                    case 'consultant':
                        array.push('Консультант')
                        break
                    case 'mentor':
                        array.push('Ментор')
                        break
                    default:
                        break
                }
            }
        }
        return array
    }
    const roles = role()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={s.Container}>
            <div className={s.Expert}>
                <div className={s.ExpertWrapper}>
                    <div className={s.ExpertAvatarBlock}>
                        <Avatar
                            url={expertProfile.photo}
                            className={s.ExpertAvatarBlock__avatar}
                        />
                        <div className={s.ExpertAvatarBlock__info}>
                            <h4 className={s.TitleH4}>
                                {expertProfile.fullName}
                            </h4>
                            <div className={s.ExpertAvatarBlock__roles}>
                                {roles.length > 0 &&
                                    roles.map((role, index) => (
                                        <span key={index}>{role}</span>
                                    ))}
                            </div>
                            <span className={s.ExpertAvatarBlock__position}>
                                {expertProfile.position}
                            </span>
                        </div>
                    </div>
                    <div className={s.ExpertSocials}>
                        {expertProfile.telegram && (
                            <a
                                href={expertProfile.telegram}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={s.ExpertSocials__link}
                            >
                                <Icons.social.Telegram />
                            </a>
                        )}
                        {expertProfile.staff && (
                            <a
                                href={expertProfile.staff}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={`${s.ExpertSocials__link} ${s.ExpertSocials__staff}`}
                            >
                                Staff
                            </a>
                        )}
                    </div>
                </div>
                <div className={s.ExpertTags}>
                    {expertProfile.tags.length > 0 &&
                        expertProfile.tags.map((tag, index) => {
                            return (
                                <Tag key={index} className={s.ExpertTags__tag}>
                                    {tag}
                                </Tag>
                            )
                        })}
                </div>
            </div>
            <div className={s.AboutExpert}>
                <div>
                    <p className={s.TitleP1}>О себе:</p>
                    <span>{expertProfile.about}</span>
                </div>
                <div className={s.AboutExpert__interaction}>
                    <div>
                        <p className={s.TitleP1}>Формат взаимодействия:</p>
                        <span>
                            {expertProfile.interactionFormat === 'online' &&
                                'Онлайн '}
                            {expertProfile.interactionFormat === 'offline' &&
                                'Офлайн '}
                            {expertProfile.interactionFormat === 'any' &&
                                'Онлайн, Офлайн'}
                        </span>
                    </div>
                    <div>
                        <p className={s.TitleP1}>Город:</p>
                        <span>{expertProfile.city}</span>
                    </div>
                </div>
            </div>
            <div className={s.ExpertExperience}>
                <Switcher
                    options={options}
                    setValue={setTabIndex}
                    value={tabIndex}
                />
                <ProfileTab
                    tabIndex={tabIndex}
                    expertise={expertProfile.expertise}
                    experience={expertProfile.experience}
                />
            </div>
        </div>
    )
}
