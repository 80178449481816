import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data: {},
}

export const applicationsSlice = createSlice({
    name: 'applications',
    initialState,
    reducers: {},
})

export const { actions: applicationActions, reducer: applicationReducer } =
    applicationsSlice
