import React, { FC } from 'react'
import s from './reviewItem.module.scss'
import { UserInfo } from '../../user/ui/userInfo'
import { LinkPresets } from '../../../shared/ui/Link'
import { ReviewType } from '../model/types'

export const ReviewItem: FC<
    ReviewType & { onClick: () => void; variant?: 'profile' }
> = ({ fullName, position, type, text, onClick, variant }) => {
    const variantClass = variant
        ? s.reviewsItem + ' ' + s.reviewsItem_profile
        : s.reviewsItem
    return (
        <div className={variantClass}>
            <div className={s.reviewPersonalInfo}>
                <UserInfo
                    user={{
                        expertState: '',
                        id: 1,
                        isAdmin: false,
                        username: fullName,
                        post: position,
                    }}
                />
            </div>
            <p className={s.reviewCategory}>
                {type === 'mentor' && 'Менторство'}
                {type === 'consultant' && 'Консультация'}
                {type === 'program' && 'Программы'}
            </p>
            <p className={s.reviewText}>{text}</p>
            {text.length > 150 && variant === 'profile' ? (
                <LinkPresets.LearnMore to="#" onClick={onClick}>
                    Посмотреть полностью
                </LinkPresets.LearnMore>
            ) : (
                <p className={s.unwrap} onClick={onClick}>
                    Развернуть
                </p>
            )}
        </div>
    )
}
