import { ReactNode } from 'react'

import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { ThemeType } from '../../styles/theme'
import { Icons } from '../../icons'
import React from 'react'

export type PropsType = {
    children: ReactNode
    variant?:
        | 'filled_dark'
        | 'filled'
        | 'ghost'
        | 'white'
        | 'outlined'
        | 'filled_circle'
    size?: 'lg' | 'md' | 'sm'
    icon?: keyof typeof Icons.circleIcons
} & React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
>

const variantStyles = (
    theme: ThemeType,
    variant: PropsType['variant'] = 'filled'
) => {
    return {
        filled_dark: css`
            color: ${theme.color.white};
            background: ${theme.color.ozonBlue['800']};
            &:hover {
                background: #00215c;
            }
            &:active {
                background: ${theme.color.darkSpace['900']};
            }
        `,
        filled: css`
            color: ${theme.color.white};
            background: ${theme.color.ozonMain};
            &:hover {
                background: #0c5ced;
            }
            &:active {
                background: #1158d7;
            }
        `,
        ghost: css`
            color: ${theme.color.ozonMain};
            background: ${theme.color.lightBlue};
            &:hover {
                background: #dae9ff;
            }
            &:active {
                background: #d0e2ff;
            }
        `,
        white: css`
            color: ${theme.color.ozonMain};
            background: ${theme.color.white};
            &:hover {
                background: ${theme.color.cloud};
            }
            &:active {
                background: ${theme.color.lightBlue};
            }
        `,
        outlined: css`
            color: ${theme.color.ozonMain};
            border: 1px solid ${theme.color.ozonMain};
            background: ${theme.color.white};
            &:hover {
                color: ${theme.color.ozonBlue['600']};
                border-color: ${theme.color.ozonBlue['600']};
            }
            &:active {
                color: ${theme.color.ozonBlue['700']};
                border-color: ${theme.color.ozonBlue['700']};
            }
        `,
        filled_circle: css`
            color: ${theme.color.white};
            background: ${theme.color.ozonMain};
            padding-right: calc(var(--button-size) + var(--button-size) / 3);
            display: flex;
            align-items: center;
            &:hover {
                background: #0c5ced;
            }
            &:active {
                background: #1158d7;
            }
        `,
    }[variant]
}

const sizesStyles = (
    theme: ThemeType,
    size: NonNullable<PropsType['size']>
) => {
    return {
        lg: css`
            --button-size: 56px;
        `,
        md: css`
            --button-size: 48px;
        `,
        sm: css`
            --button-size: 40px;
        `,
    }[size]
}

const Circle = styled.span`
    position: absolute;
    top: 0;
    right: 0px;
    height: var(--button-size);
    width: var(--button-size);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${(props) => {
        return css`
            background: ${props.theme.color.ozonBlue[400]};
        `
    }}
`

const ButtonStyled = styled.button<{
    variant: PropsType['variant']
    size: NonNullable<PropsType['size']>
    disabled?: boolean
}>(
    {
        userSelect: 'none',
        height: 'var(--button-size)',
        borderRadius: '30px',
        cursor: 'pointer',
        transition: '.3s',
        fontSize: '16px',
        padding: '0 28px',
        position: 'relative',
        lineHeight: '20.8px',
    },
    (props) => variantStyles(props.theme, props.variant),
    (props) => sizesStyles(props.theme, props.size),
    (props) => {
        return props.disabled
            ? css`
                  color: ${props.theme.color.darkSpace[200]};
                  pointer-events: none;
                  background: ${props.variant === 'outlined'
                      ? props.theme.color.white
                      : props.theme.color.darkSpace['50']};
                  border-color: ${props.theme.color.darkSpace['50']};

                  & .circle {
                      background-color: ${props.theme.color.darkSpace['150']};
                  }
              `
            : {}
    }
)

export const AppButton: React.FC<PropsType> = ({
    children,
    variant = 'filled',
    size = 'md',
    icon,
    ...htmlButtonProps
}) => {
    return (
        <ButtonStyled variant={variant} size={size} {...htmlButtonProps}>
            <span>{children}</span>
            {icon && (
                <Circle className="circle">
                    {React.createElement(Icons.circleIcons[icon])}
                </Circle>
            )}
        </ButtonStyled>
    )
}
