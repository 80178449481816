import { useModalWindow } from '../../../../shared/hooks/useModalWindow'
import { AppButton } from '../../../../shared/ui/Button'
import { ReviewHasBeenSentModal } from '../modals/reviewHasBeenSentModal'
import { SmartForm } from '../../../../services/formProcessing/ui/SmartForm'
import { TextField } from '../../../../shared/ui/TextField'
import React, { useState } from 'react'
import { useSmartForm } from '../../../../services/formProcessing'
import s from './index.module.scss'
import { reviewsApi } from '../../../../entities/review/api'
import { z } from 'zod'
import { useHistory, useLocation } from 'react-router-dom'
import { Rating } from 'react-simple-star-rating'
import { IReviewProfile } from '../../../../entities/review/model/types'

const formSchema = z.object({
    fullName: z.string().min(2, 'Имя пользователя слишком короткое'),
    position: z.string().min(2, 'Введите должность'),
    text: z.string().min(1, 'Оставьте ваш отзыв'),
})

type PropsType = {
    reviewProfile: IReviewProfile
}

export const CreateReviewForm: React.FC<PropsType> = ({ reviewProfile }) => {
    const { push } = useHistory()
    const location = useLocation()
    const key = location.pathname.replace('/feedback/', '')
    const reviewHasBeenSentModal = useModalWindow()
    const [ratingValue, setRatingValue] = useState(0)

    const form = useSmartForm(
        {
            defaultValues: {
                fullName: '',
                position: '',
                text: '',
                rating: 0,
            },
        },
        {
            schema: formSchema,
        }
    )

    const handleRating = (rate: number) => {
        setRatingValue(rate)
    }

    const handleSubmit = async (data: any) => {
        try {
            await reviewsApi.postReview(key, { ...data, rating: ratingValue })
            reviewHasBeenSentModal.toggleOpen()
            push('/')
        } catch (e) {
            console.log(e)
            alert('Something went wrong')
        }
    }

    return (
        <div className={s.Wrapper}>
            <h4 className={s.TitleH4}>
                {reviewProfile.expert
                    ? 'Отзыв о взаимодействии'
                    : 'Отзыв о программе'}
            </h4>
            <SmartForm
                id="reviewForm"
                form={form}
                submit={handleSubmit}
                className={s.Form}
            >
                <TextField
                    label="Ф.И.О."
                    placeholder="Фамилия Имя Отчество"
                    name="fullName"
                    className={s.Form__input}
                />
                <TextField
                    label="Должность"
                    placeholder="Введите должность"
                    name="position"
                    className={s.Form__input}
                />
                <Rating className={s.Form__input} onClick={handleRating} />
                <TextField
                    as="textarea"
                    name="text"
                    label="Отзыв"
                    placeholder="Оставьте ваш отзыв"
                    className={s.Form__textarea}
                />
                <ReviewHasBeenSentModal {...reviewHasBeenSentModal} />
                <AppButton style={{ width: '100%' }}>Отправить отзыв</AppButton>
            </SmartForm>
        </div>
    )
}
