import React from 'react'
import styled from '@emotion/styled'
import { theme } from '../../styles/theme'
import { css } from '@emotion/react'

type SwitcherOptionType = {
    text: string
    value: string
}

type PropsType = {
    defaultValue?: SwitcherOptionType
    options: SwitcherOptionType[]
    setValue: (v: string) => void
    value: string
    className?: string
}

const OuterContainerStyled = styled.div`
    container-type: normal;
`

const InnerContainerStyled = styled.div<{ length: number }>`
    padding: 4px;
    display: grid;
    gap: 4px;
    background-color: ${theme.color.lightBlue};
    border-radius: 100px;
    font-size: 16px;
    height: 50px;

    @container (max-width: 799px) {
        height: 100px;
        border-radius: 24px;
        grid-template-columns: repeat(2, 1fr);
    }

    ${({ length }) => css`
        grid-template-columns: repeat(${length}, 1fr);
    `}
`

const ButtonStyled = styled.button<{ isActive: boolean }>`
    cursor: pointer;
    border-radius: 24px;
    transition: 0.3s;
    font-size: inherit;
    background-color: ${theme.color.lightBlue};
    color: ${theme.color.ozonMain};

    ${({ isActive }) =>
        isActive
            ? css`
                  background-color: ${theme.color.white};
              `
            : ''}

    :hover {
        background-color: ${({ isActive }) =>
            isActive ? theme.color.white : 'rgba(255, 255, 255, 0.32)'};
    }
`

export const Switcher: React.FC<PropsType> = (props) => {
    const onClickHandler = (value: string) => {
        props.setValue(value)
    }

    return (
        <OuterContainerStyled className={props?.className}>
            <InnerContainerStyled length={props.options.length}>
                {props.options.map((item) => (
                    <ButtonStyled
                        key={item.value}
                        isActive={props.value === item.value}
                        onClick={() => onClickHandler(item.value)}
                    >
                        {item.text}
                    </ButtonStyled>
                ))}
            </InnerContainerStyled>
        </OuterContainerStyled>
    )
}
