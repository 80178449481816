import React from 'react'
import { css } from '@emotion/react'
import emptyAvatarIcon from './icons/emptyAvatar.svg'

type PropsType = {
    url?: string
} & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
>

export const Avatar: React.FC<PropsType> = ({ url, ...divProps }) => {
    return (
        <div
            css={css`
                height: 96px;
                width: 96px;
            `}
            {...divProps}
        >
            <img
                css={css`
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                `}
                src={url || emptyAvatarIcon}
                alt="avatar"
            />
        </div>
    )
}
