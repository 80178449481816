import React from 'react'
import { ApplicationType } from '../../types'
import { AppButton } from '../../../../shared/ui/Button'

import s from './applicationList.module.scss'
import { ApplicationList } from '../applicationCard'
import { UseFormRegister } from 'react-hook-form'

type ApplicationListProps = {
    applications: ApplicationType[]
    total: number
    isFormingNewGroup?: boolean
    showMore?: () => void
    page: number
    refetch: () => void
    hookFormRegister?: UseFormRegister<any>
    updateItem: (id: string | number, data: any) => void
}

export const ApplicationListWithInfoRow = ({
    applications,
    total,
    isFormingNewGroup,
    page,
    showMore,
    refetch,
    hookFormRegister,
    updateItem,
}: ApplicationListProps) => {
    return (
        <>
            <div className={s.applicationList}>
                <div className={s.columnName}>
                    <p>ФИО и почта</p>
                    <p>Дата заявки</p>
                    <p>Описание запроса</p>
                    <p>Статус</p>
                </div>

                <ApplicationList
                    updateItem={updateItem}
                    applications={applications}
                    isFormingNewGroup={isFormingNewGroup}
                    refetch={refetch}
                    hookFormRegister={hookFormRegister}
                />

                {page * 6 < total && (
                    <AppButton
                        size="lg"
                        variant="ghost"
                        onClick={showMore}
                        className={s.moreButton}
                    >
                        Показать ещё
                    </AppButton>
                )}
            </div>
        </>
    )
}
