import React, {SyntheticEvent} from 'react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { theme } from '../../styles/theme'
import { Icons } from '../../icons'
import { css } from '@emotion/react'

type PropsType = {
    to: string
    onClick?: (evt: SyntheticEvent) => void
    endAdornment?: ReactNode
    startAdornment?: ReactNode
    children?: ReactNode
    color?: string
    hoverColor?: string
    size?: 'sm' | 'md'
}

const LinkStyled = styled(Link)<{
    color: string
    hoverColor: string
    size: PropsType['size']
}>`
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: ${(props) => (props.size == 'md' ? '14px' : '12px')};

    color: ${(props) => props.color};

    & svg {
        width: ${(props) => (props.size == 'md' ? '20px' : '16px')};
        height: ${(props) => (props.size == 'md' ? '20px' : '16px')};
    }

    & path {
        fill: ${(props) => props.color};
    }

    &:hover {
        color: ${(props) => props.hoverColor};

        & path {
            fill: ${(props) => props.hoverColor};
        }
    }
`

export const AppLink: React.FC<PropsType> = ({
    color = theme.color.darkBlue,
    hoverColor = theme.color.ozonBlue[700],
    size = 'md',
    endAdornment = null,
    startAdornment = null,
    ...props
}) => {
    return (
        <LinkStyled
            size={size}
            hoverColor={hoverColor}
            color={color}
            {...props}
        >
            {startAdornment}
            <span>{props.children}</span>
            {endAdornment}
        </LinkStyled>
    )
}

export const LinkPresets = {
    LearnMore: (props: PropsType) => {
        return (
            <AppLink
                endAdornment={<Icons.M.Arrow />}
                {...props}
                color={theme.color.ozonMain}
                hoverColor={theme.color.ozonBlue[700]}
            >
                {props.children ? props.children : "Узнать больше"}
            </AppLink>
        )
    },
    Hide: (props: PropsType) => {
        return (
            <AppLink
                endAdornment={<Icons.M.Close />}
                {...props}
                color={theme.color.darkSpace[300]}
                hoverColor={theme.color.darkSpace[400]}
            >
                Скрыть
            </AppLink>
        )
    },
    AddBlock: (props: PropsType) => {
        return (
            <AppLink
                startAdornment={<Icons.M.Plus />}
                {...props}
                color={theme.color.ozonMain}
                hoverColor={theme.color.ozonBlue[600]}
            >
                Добавить блок
            </AppLink>
        )
    },
    BackBlue: (props: PropsType) => {
        return (
            <AppLink
                startAdornment={
                    <Icons.M.ChevronUp
                        css={css`
                            transform: rotate(-90deg);
                        `}
                    />
                }
                {...props}
                color={theme.color.ozonBlue[300]}
                hoverColor={theme.color.ozonBlue[700]}
            >
                Вернуться назад
            </AppLink>
        )
    },
    BackGray: (props: PropsType) => {
        return (
            <AppLink
                startAdornment={
                    <Icons.M.ChevronUp
                        css={css`
                            transform: rotate(-90deg);
                        `}
                    />
                }
                {...props}
                color={theme.color.darkSpace[300]}
                hoverColor={theme.color.darkSpace[400]}
                size="sm"
            >
                Вернуться назад
            </AppLink>
        )
    },
    Remove: (props: PropsType) => {
        return (
            <AppLink
                startAdornment={<Icons.M.Close />}
                {...props}
                color={theme.color.darkBlue}
                hoverColor={theme.color.ozonBlue[700]}
                size="sm"
            >
                Удалить
            </AppLink>
        )
    },
}
