import React from 'react'
import s from './training.module.scss'
import { Dropdown } from '../../../../../shared/ui/Dropdown'
import bg_1 from '../img/bg_1.png'
import bg_2 from '../img/bg_2.png'
import bg_3 from '../img/bg_3.png'
import svg_1 from '../img/image_1.svg'
import svg_2 from '../img/image_2.svg'
import svg_3 from '../img/image_3.svg'
import { Card } from './card/card'

export const Training = () => {
    return (
        <div className={s.training}>
            <h3 className={s.training__title}>Обучение для экспертов</h3>
            <div className={s.info}>
                <div className={s.info__wrapper}>
                    <p className={s.info__description}>
                        Команда Ozon Универа подготовила для тебя программы
                        обучения, памятки и инструкции для правильного и
                        эффективного выполнения своей роли
                    </p>
                    <div className={s.section}>
                        <div className={s.section__header}>
                            <span className={s.section__number}>01</span>
                            <span className={s.section__title}>
                                Для менторов
                            </span>
                        </div>
                        <div className={s.list}>
                            <Card
                                item={{
                                    title: 'Онлайн-курс “Ментор в Ozon”',
                                    description:
                                        'Полностью дистанционная программа с памятками, чек-листами и шаблонами встреч. Изучишь все - от первой встречи до подведения итогов менторинга.',
                                    photo: bg_1,
                                    logo: svg_1,
                                    link: 'https://olearning.o3.ru/program/1003262-mentor-v-ozon-expertplace',
                                    titleLink: 'Изучить',
                                    type: { mentor: true },
                                }}
                            />
                            <Card
                                item={{
                                    title: 'Гайд для менти',
                                    description:
                                        'Направь его своему менти ДО старта взаимодействия. В гайде менти найдет ответы на самые частые вопросы, а также сможет качественно подготовиться ко встрече.',
                                    photo: bg_2,
                                    logo: svg_2,
                                    link: 'https://cdn.ozone.ru/s3/ozon-disk-api/o-learning/Training_team/guide_menti_1714308751.pdf',
                                    titleLink: 'Скачать',
                                    type: { mentor: true },
                                }}
                            />
                            <Card
                                item={{
                                    title: 'Онлайн-курс по активному слушанию',
                                    description:
                                        'Краткий курс о развитии навыка, необходимого любому эксперту.',
                                    photo: bg_3,
                                    logo: svg_3,
                                    link: 'https://olearning.o3.ru/program/1000836-aktivnoe-slushanie',
                                    titleLink: 'Изучить',
                                }}
                            />
                        </div>
                    </div>
                    <div className={s.section}>
                        <div className={s.section__header}>
                            <span className={s.section__number}>02</span>
                            <span className={s.section__title}>
                                Для консультантов
                            </span>
                        </div>
                        <div className={s.list}>
                            <Card
                                item={{
                                    title: 'Онлайн-курс “Консультант в Ozon”',
                                    description:
                                        'Полностью дистанционная программа с памятками, чек-листами и шаблонами встреч. Изучишь все - от первой встречи с заказчиком до подведения итогов консультаций.',
                                    photo: bg_1,
                                    logo: svg_1,
                                    link: 'https://olearning.o3.ru/program/1003263-konsultant-v-ozon-expertplace',
                                    titleLink: 'Изучить',
                                    type: { consultant: true },
                                }}
                            />
                            <Card
                                item={{
                                    title: 'Гайд для заказчиков',
                                    description:
                                        'Направь его своему заказчику до старта взаимодействия. В гайде коллега найдет ответы на самые частые вопросы о процессе, а также сможет качественно подготовиться ко встрече.',
                                    photo: bg_2,
                                    logo: svg_2,
                                    link: 'https://cdn.ozone.ru/s3/ozon-disk-api/o-learning/Training_team/guide_zakaz_1714308753.pdf',
                                    titleLink: 'Скачать',
                                    type: { consultant: true },
                                }}
                            />
                            <Card
                                item={{
                                    title: 'Онлайн-курс по аргументации',
                                    description:
                                        'За полчаса ты узнаешь о приёмах, которые позволили тысячам людей «прокачать» навыки убеждения.',
                                    photo: bg_3,
                                    logo: svg_3,
                                    link: 'https://olearning.o3.ru/program/1000867-naviki-ubezhdeniya-',
                                    titleLink: 'Изучить',
                                }}
                            />
                        </div>
                    </div>
                    <div className={s.section}>
                        <div className={s.section__header}>
                            <span className={s.section__number}>03</span>
                            <span className={s.section__title}>
                                Для тренеров
                            </span>
                        </div>
                        <div className={s.list}>
                            <Card
                                item={{
                                    title: 'Школа внутреннего тренера',
                                    description:
                                        'Комплексная программа, на которой ты узнаешь все о том, как разрабатывать и проводить обучение. Прохождение обязательно для роли тренера.',
                                    photo: bg_1,
                                    logo: svg_1,
                                    link: 'https://forms.ozon.ru/form/145baacd88f646e9e52f4d70e9fe91c97b',
                                    titleLink:
                                        'Оставить заявку на ближайший поток',
                                    type: { program: true },
                                }}
                            />
                            <Card
                                item={{
                                    title: 'Видео-курс “Мастерство публичных выступлений”',
                                    description:
                                        'Проходи курс, выполняй задания, прокачивай на практике мастерство публичных выступлений пока ждешь поток Школы тренера.',
                                    photo: bg_2,
                                    logo: svg_2,
                                    link: 'https://olearning.o3.ru/program/1001034-masterstvo-publichnih-vistuplenii',
                                    titleLink: 'Изучить',
                                    type: { program: true },
                                }}
                            />
                            <Card
                                item={{
                                    title: 'Онлайн-курс по дизайну презентаций',
                                    description:
                                        'Пять записанных вебинаров о том, как создавать презентации на уровне эксперта. ',
                                    photo: bg_3,
                                    logo: svg_3,
                                    link: 'https://olearning.o3.ru/program/1001055-kak-delat-klassnie-prezentatsii',
                                    titleLink: 'Изучить',
                                    type: { program: true },
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Dropdown
                className={s.dropdown}
                title="Что делать, если в программе я не нашел ответа на свой вопрос?"
            >
                <p>
                    Обращайся со своим запросом на почту{' '}
                    <a href="mailto:univer@ozon.ru">univer@ozon.ru</a>
                </p>
            </Dropdown>
            <Dropdown
                className={s.dropdown}
                title="Как еще я могу прокачать свои навыки?"
            >
                <p>
                    Совсем скоро для активных экспертов будет создано
                    сообщество. В нем ты сможешь обменивать опытом,
                    познакомишься с другими крутыми экспертами Ozon,
                    поучаствуешь в обучающих активностях внутри и вне компании и
                    даже повеселишься на выездных мероприятиях.
                    <br />
                    Анонс запуска - октябрь 2024.
                </p>
            </Dropdown>
        </div>
    )
}
