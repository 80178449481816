import React from 'react';
import s from './AdminPanelMenu.module.scss'
import {adminPanelPaths} from "../../../../pages/adminPanel/routes";
import {AdminPanelMenuItem} from "../item/AdminPanelMenuItem";


export const AdminPanelMenu: React.FC = () => {
    return (
        <div className={s.wrapper}>
            <div className={s.list}>
                {
                    adminPanelPaths.map((el) => (
                        <AdminPanelMenuItem key={el.name} {...el}/>
                    ))
                }
            </div>
        </div>
    )
}
