import { IAuthBody } from '../model'
import { authAdminApiInstance } from '../../../shared/api'
import { GetTokenResponseType } from '../../auth/model/types'

export const adminAuthApi = {
    adminLogin: (body: IAuthBody) => {
        return authAdminApiInstance.post<GetTokenResponseType>('/login', body)
    },
    getTokens: (body: IAuthBody) => {
        return authAdminApiInstance.post<GetTokenResponseType>('/tokens', body)
    },
}
