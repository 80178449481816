import { adminApiInstance } from '../../../../../shared/api'
import { IProgramPatch, IProgramsParams, IProgramsPost } from '../model/types'

export const programsApi = {
    getPrograms: (params: IProgramsParams) =>
        adminApiInstance.get(`/programs`, { params }),
    postCreateProgram: (params: IProgramsPost) =>
        adminApiInstance.post(`/programs`, params),
    deletePrograms: (data: { ids: number[] }) =>
        adminApiInstance.delete(`/programs`, { data }),
    getProgramById: (id: number) => adminApiInstance.get(`/programs/${id}`),
    patchProgramById: (id: number, data: IProgramPatch) =>
        adminApiInstance.patch(`/programs/${id}`, data),
    postUploadProgramPhoto: (photo: FormData) =>
        adminApiInstance.post('program-images/photo', photo),
    postAddProgramPhoto: (image: string) =>
        adminApiInstance.post('program-images', { image }),
    getProgramImages: () => adminApiInstance.get(`/program-images`),
}
