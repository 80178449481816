import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data: {},
}

export const reviewsSlice = createSlice({
    name: 'reviews',
    initialState,
    reducers: {},
})

export const { actions: reviewsActions, reducer: reviewsReducer } = reviewsSlice
