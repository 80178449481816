import { TextField } from '../../../shared/ui/TextField'
import { IForm } from '../model'
import React from 'react'
import { SmartCheckbox } from '../../../shared/ui/Checkbox'

import s from './slides.module.scss'

const FirstField = (
    <fieldset>
        <legend>Шаг 1. Основная информация</legend>
        <TextField label="Ф.И.О." name="fullName" />
        <TextField label="Корпоративный e-mail" name="email" />
        <TextField label="Должность" name="position" />
        <TextField
            as="textarea"
            name="reason"
            label="Почему ты хочешь делиться экспертизой в Ozon?"
        />
    </fieldset>
)

const ThirdField = (
    <fieldset>
        <legend>Шаг 3. Выбери роль</legend>
        <div className="form-experts-slider_radio-wrapper">
            <div className={s.role}>
                <SmartCheckbox value="coach" name="role" label="Тренер" />
                <p className={s.roleDescr}>
                    Выбрав роль внутреннего тренера ты сможешь проводить
                    обучение для коллег по самостоятельно созданной программе
                    (предварительно пройдя школу тренера).
                </p>
            </div>
            <div className={s.role}>
                <SmartCheckbox
                    value="consultant"
                    name="role"
                    label="Консультант"
                />
                <p className={s.roleDescr}>
                    Выбрав роль консультанта ты сможешь помочь коллегам с
                    определенной задачей или поучаствовать в проекте.
                </p>
            </div>
            <div className={s.role}>
                <SmartCheckbox value="mentor" name="role" label="Ментор" />
                <p className={s.roleDescr}>
                    Выбрав роль ментора, ты будешь в формате индивидуальных
                    развивающих встреч помогать коллегам стать лучше в теме
                    твоей экспертизы.
                </p>
            </div>
        </div>
    </fieldset>
)

const LastField = (
    <fieldset>
        <TextField
            label="Корпоративная почта руководителя"
            placeholder="ivanov@ozon.ru"
            name="supervisorEmail"
        />
    </fieldset>
)

export const slides: IForm[] = [
    {
        id: 0,
        title: 'Готов присоединиться?',
        desc: 'Заполни простую форму за 3 шага, на это уйдёт 5-10 минут. Анкета поможет нам лучше познакомиться с твоим опытом, экспертизой и мотивацией.',
        component: FirstField,
    },
    {
        id: 1,
        title: 'Добавь область своей экспертизы',
        desc: 'Позже ты сможешь изменить или дополнить список тегов, ведь именно по ним пользователи платформы смогут тебя найти',
        component: null,
    },
    {
        id: 2,
        title: 'Выбери свою роль',
        desc: 'На данном этапе ты можешь выбрать одну или несколько ролей. Позже ты сможешь изменить свой выбор, а также выступать в роли ученика и тоже обучаться на платформе. Подробнее о каждой из ролей ты можешь узнать в блоке выше',
        component: ThirdField,
    },
    {
        id: 3,
        title: 'Оставь контакт своего руководителя для связи',
        desc: 'Мы свяжемся с ним в ближайшее время, чтобы получить отзыв на твою работу и подтвердить профиль',
        component: LastField,
    },
]
