import React, { useState } from 'react'
import clsx from 'clsx'
import { Icons } from '../../../../shared/icons'
import { useModalWindow } from '../../../../shared/hooks/useModalWindow'

import s from './programGroup.module.scss'
import { StudentList } from '../../../../widgets/programApplicationsWrapper/ui/modals/studentList'
import { ApplicationType, GroupType } from '../../types'
import {
    FailedStudendsModal,
    StatusChangedModal,
    SuccessStudentsModal,
} from '../../../../widgets/programApplicationsWrapper'
import { useGetListGroup } from '../../api/queries'
import { useParams } from 'react-router-dom'
import { applicationApi } from '../../api'
import { useMutation } from '@tanstack/react-query'

type PropsType = {
    reset: () => void
}

export const ProgramGroup: React.FC<PropsType> = ({ reset }) => {
    const successStudentsModal = useModalWindow()
    const failedStudentsModal = useModalWindow()
    const statusChangedModal = useModalWindow()
    const studentList = useModalWindow()
    const [currentGroup, setCurrentGroup] = useState<GroupType | null>(null)
    const [currentStudentList, setCurrentStudentList] = useState<string[]>([])

    // Стейт для сохранения айди оставшихся судентов после выбора прошедших студентов
    const [notSucceedStudents, setNotSucceedStudents] = useState<
        ApplicationType[]
    >([])

    const { id: programId } = useParams<{ id: string }>()
    const { data, isPending, refetch } = useGetListGroup(Number(programId))
    const groups = data as GroupType[]

    const [activeDropdownIndex, setActiveDropdownIndex] = useState(-1)

    const { mutate: finishGroup } = useMutation({
        mutationFn: async (completedIds: number[]) => {
            if (!currentGroup) return

            await applicationApi.changeGroupState(
                Number(programId),
                currentGroup?.groupIndex,
                completedIds,
                'completed'
            )
            await refetch()
            reset()
            statusChangedModal.setIsOpened(true)
        },
    })

    const removeApplication = async (groupId: number) => {
        try {
            await applicationApi.deleteGroup(Number(programId), groupId)
            await refetch()
        } catch (e) {
            console.log(e)
            alert('Something went wrong')
        }
    }

    const toggleDropdown = (index: number) => {
        setActiveDropdownIndex((prevIndex) =>
            prevIndex === index ? -1 : index
        )
    }

    const onChooseSucceedStudents = (ids: number[]) => {
        refetch()

        const notSucceedStudentsTemp = currentGroup?.applications.filter(
            (application) => !ids.includes(application.id)
        ) as ApplicationType[]

        setNotSucceedStudents(notSucceedStudentsTemp)

        if (notSucceedStudentsTemp.length === 0) {
            finishGroup(ids)
            statusChangedModal.setIsOpened(true)
        } else {
            failedStudentsModal.setIsOpened(true)
        }
    }

    const onChooseFailedStudents = (ids: number[]) => {
        const succeedStudents = currentGroup?.applications
            .filter((app) => !ids.includes(app.id))
            .map((app) => app.id)

        finishGroup(succeedStudents || [])
    }

    if (isPending) {
        return <div>Loading...</div>
    }

    return (
        <div className={s.yourGroupWrapper}>
            {groups === undefined || groups.length === 0 ? (
                <div className={s.yourGroup}>
                    <span className={s.emptyTitleGroup}>
                        У тебя пока нет групп на обучении. Выбери учеников с
                        принятыми в работу заявками и сформируй <br /> из них
                        первую группу
                    </span>
                </div>
            ) : (
                groups.map((group, index) => (
                    <div key={group.groupIndex}>
                        <div className={s.yourGroup} key={index}>
                            <div className={s.group}>
                                <span>{`Поток ${group.groupIndex}`}</span>
                                <div className={s.groupList}>
                                    <div>
                                        {group.applications
                                            .slice(0, 3)
                                            .map(({ fullName }, index) => (
                                                <p key={index}>{fullName}</p>
                                            ))}
                                    </div>
                                    {group.applications.length > 3 && (
                                        <p
                                            className={s.other}
                                            onClick={() => {
                                                setCurrentStudentList(
                                                    group.applications.map(
                                                        ({ fullName }) =>
                                                            fullName
                                                    )
                                                )
                                                studentList.toggleOpen()
                                            }}
                                        >
                                            и другие
                                        </p>
                                    )}
                                </div>

                                <StudentList
                                    {...studentList}
                                    studentList={currentStudentList}
                                />

                                <div className={s.leftSide}>
                                    <div className={s.applicationStatus}>
                                        <div
                                            className={s.dropdown}
                                            onClick={() =>
                                                toggleDropdown(group.groupIndex)
                                            }
                                        >
                                            <div className={s.statusInfo}>
                                                <div
                                                    className={clsx([
                                                        s.statusCircle,
                                                        {
                                                            [s.yellow]:
                                                                group.state ===
                                                                'accepted',
                                                        },
                                                        {
                                                            [s.green]:
                                                                group.state ===
                                                                'completed',
                                                        },
                                                    ])}
                                                />
                                                <p className={s.status}>
                                                    {group.state ===
                                                        'accepted' &&
                                                        'Идет обучение'}
                                                    {group.state ===
                                                        'completed' &&
                                                        'Завершено'}
                                                </p>
                                            </div>
                                            <div className={s.chevron}>
                                                <Icons.M.ChevronUp />
                                            </div>
                                            {activeDropdownIndex ===
                                                group.groupIndex &&
                                            group.state != 'completed' ? (
                                                <div
                                                    className={
                                                        s.dropdownContent
                                                    }
                                                >
                                                    <label
                                                        className={
                                                            s.dropdownItem
                                                        }
                                                        onClick={() => {
                                                            setCurrentGroup(
                                                                group
                                                            )
                                                            successStudentsModal.setIsOpened(
                                                                true
                                                            )
                                                        }}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            className={
                                                                s.checkbox
                                                            }
                                                        />
                                                        Завершить
                                                    </label>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div
                                        className={s.deleteGroup}
                                        onClick={() =>
                                            removeApplication(group.groupIndex)
                                        }
                                    >
                                        <Icons.S.Close />
                                        Удалить группу
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            )}
            {currentGroup && (
                <SuccessStudentsModal
                    {...successStudentsModal}
                    onSuccess={onChooseSucceedStudents}
                    group={currentGroup}
                />
            )}
            {currentGroup && notSucceedStudents.length !== 0 && (
                <FailedStudendsModal
                    {...failedStudentsModal}
                    notSucceedStudents={notSucceedStudents}
                    onSuccess={onChooseFailedStudents}
                />
            )}
            <StatusChangedModal {...statusChangedModal} />
        </div>
    )
}
