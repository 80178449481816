import React, { useEffect, useState } from 'react'
import { Layout } from '../../shared/ui/Layout'
import { Container } from '../../shared/ui/Container'
import { Faq } from '../../widgets/faq'
import { Cover } from './cover/cover'
import { Promo } from './promo/promo'
import { CatalogBlock } from './catalogBlock/catalogBlock'
import { css } from '@emotion/react'
import { theme } from '../../shared/styles/theme'
import { useWindowDimensions } from '../../shared/hooks/useWindowDimensions'

export const Catalog = () => {
    const [isFilterPopupOpen, setIsFilterPopupOpen] = useState<boolean>(false)
    const { width } = useWindowDimensions()

    useEffect(() => {
        width > 1023 && setIsFilterPopupOpen(false)
    }, [width])

    const isOpenMobileFilter = () => {
        setIsFilterPopupOpen(!isFilterPopupOpen)
    }

    return (
        <Layout
            css={css`
                background-color: ${theme.color.white};
            `}
        >
            {!isFilterPopupOpen && <Cover />}
            <Container>
                <CatalogBlock
                    isOpenMobileFilter={isOpenMobileFilter}
                    isFilterPopupOpen={isFilterPopupOpen}
                />
            </Container>
            {!isFilterPopupOpen && (
                <>
                    <Container>
                        <Promo />
                    </Container>
                    <Container>
                        <Faq type="student" />
                    </Container>
                </>
            )}
        </Layout>
    )
}
