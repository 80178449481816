import { apiInstance } from '../../../shared/api'

export const mentorApi = {
    getExpertProfile: (id: string) => {
        return apiInstance.get(`/trainings/expert/${id}`)
    },
    uploadPhoto: (form: FormData) => {
        return apiInstance.post('/expert/photo', form)
    },
    patchUploadPhoto: (url: { photo: string }) => {
        return apiInstance.patch('/expert', url)
    },
}
