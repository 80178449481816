import { useMutation, useQuery } from '@tanstack/react-query'
import { faqApi } from './index'
import {
    FaqType,
    IFaqGet,
    IFaqPost,
    IFaqResponse,
    IFaqByPageResponse,
} from '../model/types'

const key = 'faq'

export const useGetFaqByPage = (type: FaqType) => {
    return useQuery<IFaqByPageResponse>({
        queryKey: [key],
        queryFn: async () => {
            const response = await faqApi.getFaqByPage(type)
            return response.data
        },
    })
}
export const useGetFaqs = (params: IFaqGet, type: FaqType) => {
    return useQuery<IFaqResponse>({
        queryKey: [key, type],
        queryFn: async () => {
            const response = await faqApi.getFaqs(params, type)
            return response.data
        },
    })
}

export const useGetFaqById = (id: number) => {
    return useQuery<IFaqPost>({
        queryKey: [key],
        queryFn: async () => {
            const response = await faqApi.getFaq(id)
            return response.data
        },
    })
}

export const usePostFaq = (reset?: () => void) => {
    return useMutation({
        mutationKey: [key],
        mutationFn: async (data: IFaqPost) => {
            const response = await faqApi.postFaq(data)
            return response.data
        },
        onSuccess: async () => {
            reset?.()
        },
        onError: (error: any) => {
            alert(error?.response?.data?.error)
        },
    })
}

export const usePatchFaq = (reset?: () => void) => {
    return useMutation({
        mutationKey: [key],
        mutationFn: async (data: Partial<IFaqPost>) => {
            const response = await faqApi.patchFaq(data)
            return response.data
        },
        onSuccess: () => {
            reset?.()
        },
    })
}

export const useDeleteFaqs = (reset?: () => void) => {
    return useMutation({
        mutationKey: [key],
        mutationFn: async (ids: number[]) => {
            return faqApi.deleteFaqs(ids)
        },
        onSuccess: () => {
            reset?.()
        },
    })
}
