import React from 'react'
import { AdminPageLayout } from '../../../../ui/adminPageLayout/layout'
import { ApplicationsType, IApplicationsGet } from '../../model/types'
import { ApplicationsList } from '../applicationsList'
import { useList } from '../../../../../../shared/hooks/useList/useList'
import { applicationsApi } from '../../api'

type PropsType = {
    title: string
    type?: ApplicationsType
}

export const ApplicationsLayout: React.FC<PropsType> = ({ type, title }) => {
    const query = useList<IApplicationsGet>({
        key: ['applications', type || ''],
        apiMethod: (params) =>
            applicationsApi.getApplications({ ...params, type }),
    })
    return (
        <AdminPageLayout title={title}>
            <ApplicationsList type={type} query={query} />
        </AdminPageLayout>
    )
}
