import { ReactComponent as CircleIconArrow } from './circleIcons/circleIconArrow.svg'
import { ReactComponent as CircleIconArrowDark } from './circleIcons/circleIconArrowDark.svg'
import { ReactComponent as CircleIconClose } from './circleIcons/circleIconClose.svg'
import { ReactComponent as CircleIconSave } from './circleIcons/circleIconSave.svg'
import { ReactComponent as PlayIcon } from './circleIcons/play.svg'

import { ReactComponent as HeaderLogo } from './logos/header.svg'
import { ReactComponent as Star } from './logos/star.svg'
import { ReactComponent as FooterIcon1 } from './logos/footer_1.svg'
import { ReactComponent as FooterIcon2 } from './logos/footer_2.svg'
import { ReactComponent as FooterIconPic } from './logos/footer_subtract.svg'
import { ReactComponent as FooterIconPicBig } from './logos/footer_subtractBig.svg'
import { ReactComponent as Avatar } from './logos/avatar.svg'

import { ReactComponent as ChevronUpM } from './medium/chevronUp.svg'
import { ReactComponent as ChevronLeftM } from './medium/chevronLeft.svg'
import { ReactComponent as CloseM } from './medium/close.svg'
import { ReactComponent as ArrowM } from './medium/arrow.svg'
import { ReactComponent as PlusM } from './medium/plus.svg'
import { ReactComponent as Sound } from './medium/sound.svg'
import { ReactComponent as Soundwave } from './medium/soundwave.svg'
import { ReactComponent as Hamburger } from './medium/hamburger.svg'
import { ReactComponent as Search } from './medium/search.svg'
import { ReactComponent as ArrowToRight } from './medium/arrowToRight.svg'
import { ReactComponent as MediumInfo } from './medium/info.svg'
import { ReactComponent as Cancelled } from './medium/cancelled.svg'
import { ReactComponent as Edit } from './medium/edit.svg'
import { ReactComponent as Download} from "./medium/Download.svg";

import { ReactComponent as CheckS } from './small/check.svg'
import { ReactComponent as GreenCheck } from './small/greenCheck.svg'
import { ReactComponent as ChevronLeftS } from './small/chevronLeft.svg'
import { ReactComponent as ChevronRightS } from './small/chevronRight.svg'
import { ReactComponent as Cross } from './small/cross.svg'
import { ReactComponent as SmallInfo } from './small/info.svg'
import { ReactComponent as Account } from './small/account.svg'
import { ReactComponent as Applications } from './small/applications.svg'
import { ReactComponent as Reviews } from './small/reviews.svg'
import { ReactComponent as Programs } from './small/programs.svg'
import { ReactComponent as Shop } from './small/shop.svg'
import { ReactComponent as Close } from './small/close.svg'
import { ReactComponent as FullStar } from './small/star.svg'
import { ReactComponent as Trainings } from './small/trainings.svg'

import { ReactComponent as Bulb } from './advantages/bulb.svg'
import { ReactComponent as World } from './advantages/world.svg'
import { ReactComponent as Rocket } from './advantages/rocket.svg'
import { ReactComponent as AdvantagesStar } from './advantages/star.svg'
import { ReactComponent as Desktop } from './advantages/desktop.svg'
import { ReactComponent as Together } from './advantages/together.svg'

import { ReactComponent as BulbActive } from './advantages/active/bulb.svg'
import { ReactComponent as WorldActive } from './advantages/active/world.svg'
import { ReactComponent as RocketActive } from './advantages/active/rocket.svg'
import { ReactComponent as StarActive } from './advantages/active/star.svg'
import { ReactComponent as DesktopActive } from './advantages/active/desktop.svg'
import { ReactComponent as TogetherActive } from './advantages/active/together.svg'

import { ReactComponent as BackgroundStar } from './reviews/backgroundStar.svg'
import { ReactComponent as BackgroundCardStar } from './reviews/backgroundCardStar.svg'

import { ReactComponent as CtaBackgroundStar } from './cta-block/backgroundStar.svg'
import { ReactComponent as CtaBackgroundStarSmall } from './cta-block/backgroundStarSmall.svg'

import { ReactComponent as LeftStarBg } from './catalogPage/coverBlock/leftStarBg.svg'
import { ReactComponent as RightStarBg } from './catalogPage/coverBlock/rightStarBg.svg'
import { ReactComponent as PromoStarBg } from './catalogPage/promoBlock/starBg.svg'

import { ReactComponent as Vkontakte } from './social/vkontakte.svg'
import { ReactComponent as Telegram } from './social/telegram.svg'

import { ReactComponent as workspace } from './adminPanel/ic_m_desktop.svg'
import { ReactComponent as areasAndExpertise } from './adminPanel/ic_m_similar_var.svg'
import { ReactComponent as users } from './adminPanel/ic_m_anonim.svg'
import { ReactComponent as programs } from './adminPanel/ic_m_book_printed.svg'
import { ReactComponent as applications } from './adminPanel/ic_m_Book_and_star.svg'
import { ReactComponent as reviews } from './adminPanel/ic_m_Commemorative_coin.svg'
import { ReactComponent as shop } from './adminPanel/ic_m_gift.svg'
import { ReactComponent as faq } from './adminPanel/ic_m_questions&answers.svg'
import { ReactComponent as uploadImage } from './adminPanel/uploadImage.svg'
import { ReactComponent as trash } from './adminPanel/trash.svg'
import { ReactComponent as search } from './adminPanel/search.svg'
import { ReactComponent as chevronDown } from './adminPanel/chevron-down.svg'

export const Icons = {
    circleIcons: {
        CircleIconArrow,
        CircleIconArrowDark,
        CircleIconClose,
        CircleIconSave,
        PlayIcon,
    },
    logos: {
        HeaderLogo,
        Star,
        FooterIcon1,
        FooterIcon2,
        FooterIconPic,
        FooterIconPicBig,
        Avatar,
    },
    M: {
        ChevronUp: ChevronUpM,
        ChevronLeft: ChevronLeftM,
        Close: CloseM,
        Arrow: ArrowM,
        Plus: PlusM,
        Sound,
        Soundwave,
        Hamburger,
        Search,
        ArrowToRight,
        MediumInfo,
        Edit,
        Cancelled,
        Download,
    },
    S: {
        Check: CheckS,
        ChevronLeft: ChevronLeftS,
        ChevronRight: ChevronRightS,
        Cross,
        SmallInfo,
        Account,
        Applications,
        Reviews,
        Programs,
        Shop,
        GreenCheck,
        Close,
        FullStar,
        Trainings
    },
    reviews: {
        BackgroundStar,
        BackgroundCardStar,
    },
    advantages: {
        Bulb,
        World,
        Rocket,
        AdvantagesStar,
        Desktop,
        Together,
    },
    advantagesLight: {
        BulbActive,
        WorldActive,
        RocketActive,
        StarActive,
        DesktopActive,
        TogetherActive,
    },
    cta: {
        CtaBackgroundStar,
        CtaBackgroundStarSmall,
    },
    catalogPage: {
        LeftStarBg,
        RightStarBg,
        PromoStarBg,
    },
    social: {
        Vkontakte,
        Telegram,
    },
    admin: {
        workspace,
        areasAndExpertise,
        users,
        programs,
        applications,
        reviews,
        shop,
        faq,
        uploadImage,
        trash,
        search,
        chevronDown,
    },
}
