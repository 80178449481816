import { useQuery } from '@tanstack/react-query'
import { reviewsApi } from './index'
import { IReviewProfile } from '../model/types'

export const useGetReviewProfile = (key: string) => {
    return useQuery<IReviewProfile>({
        queryKey: ['reviewProfile'],
        queryFn: async () => {
            const response = await reviewsApi.getReviewProfile(key)
            return response.data
        },
    })
}
