import React from 'react'
import { AppButton } from '../../../shared/ui/Button'
import s from './CoverForExperts.module.scss'
import clsx from 'clsx'
import expert from '../../../shared/assets/woman-expert.png'
import badge_photo from '../../../shared/assets/badge_photo.png'
import { useFetchUser } from '../../../entities/profile/api/queries'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../config/routes'

const buttons = [
    'Дизайн',
    'Аналитика',
    'Маркетинг',
    'HR',
    'IT',
    'Управление',
    'Продажи',
    'Программы и ПО',
    'Финансы',
]

export const CoverForExperts = () => {
    const { data: user } = useFetchUser()
    const scrollToElem = () => {
        const element = document.getElementById('registration')
        element?.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }
    return (
        <div className={s.wrapper}>
            <p className={s.title}>
                Стань экспертом <br />
                Expertplace
            </p>
            <p className={s.desc}>
                Получи новый опыт - начни делиться своими знаниями с коллегами
            </p>
            {user?.expertState === 'active' ? (
                <Link
                    to={ROUTES.personalCabinet.me.root}
                    onClick={() => {
                        window.scrollTo({
                            top: 0,
                            behavior: 'auto',
                        })
                    }}
                    className={s.button}
                >
                    <AppButton>Перейти в личный кабинет</AppButton>
                </Link>
            ) : (
                <AppButton
                    className={s.button}
                    variant="filled_circle"
                    icon="CircleIconArrow"
                    onClick={scrollToElem}
                >
                    Присоединиться
                </AppButton>
            )}
            <div className={s.info}>
                <div className={clsx([s.info_item, s.features])}>
                    <div className={s.features_content}>
                        <p className={s.features_title}>
                            {
                                'Поделись экспертизой в\u00A0одной из наиболее востребованных тем'
                            }
                        </p>
                        <div className={s.features_buttons}>
                            {buttons.map((btn) => (
                                <AppButton key={btn} size="sm" variant="white">
                                    {btn}
                                </AppButton>
                            ))}
                            <AppButton
                                className={s.features_button_outline}
                                variant="outlined"
                                size="sm"
                            >
                                50+ актуальных тем
                            </AppButton>
                        </div>
                    </div>
                    <div className={s.features_bg_image_wrapper}>
                        <svg
                            preserveAspectRatio="xMidYMax slice"
                            height="100%"
                            width="100%"
                            viewBox="0 0 809 401"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M581.143 721.268L-17.3091 756.318C-122.244 762.463 -186.75 643.448 -124.318 558.882L218.213 94.9083C280.644 10.3422 413.378 36.94 438.406 139.032L581.143 721.268Z"
                                fill="#99BDFF"
                            />
                            <path
                                d="M1185.06 265.399L820.308 741.139C756.351 824.557 624.122 795.553 600.953 693.024L473.832 130.494C450.663 27.9649 557.571 -55.0796 651.183 -7.26903L1185.06 265.399Z"
                                fill="#99BDFF"
                            />
                        </svg>
                    </div>
                </div>
                <div className={clsx([s.info_item, s.expert])}>
                    <div className={clsx(s.badge, s.expert_card)}>
                        <img className={s.badge_photo} src={badge_photo} />
                        <div className={s.badge_text}>
                            <p className={s.badge_text_title}>Ирина Куликова</p>
                            <p className={s.badge_text_desc}>Эксперт в Excel</p>
                        </div>
                    </div>
                    <img className={s.expert_img} src={expert} />
                </div>
            </div>
        </div>
    )
}
