import { css } from '@emotion/react'
import { useSmartForm } from '../../../../services/formProcessing'
import {
    ModalWindow,
    ModalWindowPropsType,
} from '../../../../shared/ui/ModalWindow'
import { SmartForm } from '../../../../services/formProcessing/ui/SmartForm'
import { TextField } from '../../../../shared/ui/TextField'
import { theme } from '../../../../shared/styles/theme'
import { AppButton } from '../../../../shared/ui/Button'
import { useParams } from 'react-router-dom'
import { z } from 'zod'
import { applicationApi } from '../../../../entities/application'
import { Icons } from '../../../../shared/icons'

type FormType = {
    fullName: string
    email: string
    description: string
    staff: string
}

type PropsType = ModalWindowPropsType & {
    roleType: string
    onSuccess: () => void
}

const FORM_ID = 'consultation-application-modal'

const formSchema = z.object({
    fullName: z.string().min(2, 'Имя пользователя слишком короткое'),
    email: z.string().email('Некорректный email'),
    staff: z
        .string()
        .refine(
            (value) => value.includes('staff.o3.ru'),
            'Некорректная ссылка на Staff'
        ),
    description: z.string().min(2, 'Min 2').optional(),
})

export const CreateConsultationApplicationModal = ({
    onSuccess,
    roleType,
    ...props
}: PropsType) => {
    const { id } = useParams<{ id: string }>()
    const form = useSmartForm<FormType>(
        {
            defaultValues: {
                fullName: '',
                email: '',
                staff: '',
            },
        },
        {
            schema: formSchema,
        }
    )

    const handleSubmit = async (formValues: FormType) => {
        try {
            await applicationApi.postSubmitApplication({
                description: formValues.description,
                email: formValues.email,
                staff: formValues.staff,
                fullName: formValues.fullName,
                trainingId: Number(id),
                type: roleType,
            })
            props.setIsOpened(false)
            onSuccess()
        } catch (e) {
            console.log(e)
            alert('Something went wrong')
        }
    }

    const title =
        roleType === 'mentor' ? 'Заявка на менторинг' : 'Заявка на консультацию'
    const text =
        roleType === 'mentor'
            ? 'Как можно подробнее опиши свой запрос к ментору. Узнать о том, как сформулировать запрос и правильно подобрать ментора ты можешь в гайде. Он пригодится тебе на всех этапах взаимодействия с ментором.'
            : 'Как можно подробнее опиши свой запрос к консультанту. Узнать о том, как сформулировать запрос и правильно подобрать консультанта ты можешь в гайде. Он пригодится тебе на всех этапах взаимодействия.'
    const href =
        roleType === 'mentor'
            ? 'https://cdn.ozone.ru/s3/ozon-disk-api/o-learning/Training_team/guide_menti_1714308751.pdf'
            : 'https://cdn.ozone.ru/s3/ozon-disk-api/o-learning/Training_team/guide_zakaz_1714308753.pdf'
    const link =
        roleType === 'mentor'
            ? 'Скачать гайд для менти'
            : 'Скачать гайд для заказчика'
    const linkDescription =
        roleType === 'mentor'
            ? 'Узнай как грамотно выбрать эксперта и подготовиться к менторингу'
            : 'Узнай как грамотно выбрать эксперта и подготовиться к консультации'
    return (
        <ModalWindow {...props}>
            <div
                css={css`
                    width: 100%;
                `}
            >
                <h1
                    css={css`
                        margin-bottom: 26px;
                        font-size: var(--font-size-h1);
                        @media (min-width: 425px) and (max-width: 600px) {
                            font-size: 38px;
                        }
                    `}
                >
                    {title}
                </h1>
                <a
                    css={{
                        color: '#005bff',
                        width: 'fit-content',
                        fontSize: '20px',
                        margin: '0 0 6px',
                        display: 'flex',
                        gap: '6px'
                    }}
                    href={href}
                    rel="noreferrer"
                >
                    <span>{link}</span>
                    <Icons.circleIcons.CircleIconSave
                        css={{ path: { fill: '#005bff' } }}
                    />
                </a>
                <p css={{
                        color: '#5a6c7d',
                        fontSize: '14px',
                        margin: '0 0 26px'
                    }}>{linkDescription}</p>
                <SmartForm
                    css={css`
                        display: flex;
                        flex-direction: column;
                        gap: 24px;
                        @media screen and (max-width: ${theme.media.tablet}) {
                            gap: 16px;
                        }
                    `}
                    id={FORM_ID}
                    form={form}
                    submit={handleSubmit}
                >
                    <TextField
                        label="ФИО*"
                        placeholder="Фамилия Имя Отчество"
                        name="fullName"
                    />
                    <TextField
                        label="E-mail*"
                        placeholder="Введи Свой e-mail"
                        name="email"
                    />
                    <TextField
                        label="Ссылка на staff*"
                        placeholder="Ссылка на staff"
                        name="staff"
                    />
                    <TextField
                        label="Описание запроса"
                        placeholder={text}
                        as="textarea"
                        name="description"
                        css={{ paddingBottom: 0 }}
                    />
                </SmartForm>
                <AppButton
                    css={css`
                        margin-top: 20px;
                    `}
                    form={FORM_ID}
                >
                    Отправить заявку
                </AppButton>
            </div>
        </ModalWindow>
    )
}
