import React from 'react'
import { Layout } from '../../shared/ui/Layout'
import { Container } from '../../shared/ui/Container'
import s from './agreement.module.scss'

export const Agreement: React.FC = () => {
    return (
        <Layout>
            <Container>
                <div className={s.agreement}>
                    <h1>
                        Соглашение об использовании материалов и сервисов
                        интернет-сайта (пользовательское соглашение)
                    </h1>
                    <p>
                        Настоящее Соглашение является публичной офертой и
                        определяет условия использования материалов и сервисов,
                        размещенных на сайте в сети Интернет по адресу:{' '}
                        <a
                            href="https://expertplace.o3t.ru/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            expertplace.o3t.ru
                        </a>
                        , посетителями и пользователями данного интернет-сайта
                        (далее - Сайт).
                    </p>
                    <h2>1. Общие условия</h2>
                    <p>1.1. Сайт создан в целях обучения.</p>
                    <p>
                        1.2. Сайт разработан и поддерживается ООО «Си Четыре
                        Групп Криэйт».
                    </p>
                    <p>1.3. Сайт содержит материалы и сервисы.</p>
                    <p>
                        1.4. Использование материалов и сервисов Сайта
                        регулируется нормами действующего законодательства
                        Российской Федерации.
                    </p>
                    <p>
                        1.5. Для получения доступа к материалам Сайта
                        Пользователю необходимо выполнить следующие действия: -
                        заполнить регистрационную форму.
                    </p>
                    <p>
                        1.6. Получая доступ к материалам Сайта, Пользователь
                        считается присоединившимся к настоящему Соглашению.
                    </p>
                    <p>
                        1.7. Пользователь может использовать материалы Сайта и
                        предоставляемые на Сайте сервисы следующим образом:
                        прослушивание и просматривание, без права копирования.
                    </p>
                    <h2>2. Обязательства Пользователя</h2>
                    <p>
                        2.1. Пользователь соглашается не предпринимать действий
                        и не оставлять комментарии и записи, которые могут
                        рассматриваться как нарушающие российское
                        законодательство или нормы международного права, в том
                        числе в сфере интеллектуальной собственности, авторских
                        и/или смежных прав, общепринятые нормы морали и
                        нравственности, а также любых действий, которые приводят
                        или могут привести к нарушению нормальной работы Сайта и
                        сервисов Сайта.
                    </p>
                    <p>
                        2.2. Использование материалов Сайта без согласия
                        правообладателей не допускается.
                    </p>
                    <p>
                        2.3. При цитировании материалов Сайта, включая
                        охраняемые авторские произведения, ссылка на Сайт
                        обязательна.
                    </p>
                    <p>
                        2.4. Администрация Сайта не несет ответственности за
                        посещение и использование им внешних ресурсов, ссылки на
                        которые могут содержаться на Сайте.
                    </p>
                    <p>
                        2.5. Администрация Сайта не несет ответственности и не
                        имеет прямых или косвенных обязательств перед
                        Пользователем в связи с любыми возможными или возникшими
                        потерями или убытками, связанными с любым содержанием
                        Сайта, регистрацией авторских прав и сведениями о такой
                        регистрации, товарами или услугами, доступными на или
                        полученными через внешние сайты или ресурсы либо иные
                        контакты Пользователя, в которые он вступил, используя
                        размещенную на Сайте информацию или ссылки на внешние
                        ресурсы.
                    </p>
                    <p>
                        2.6. Пользователь согласен с тем, что Администрация
                        Сайта не несет какой-либо ответственности и не имеет
                        каких-либо обязательств в связи с рекламой, которая
                        может быть размещена на Сайте.
                    </p>
                    <h2>3. Прочие условия</h2>
                    <p>
                        3.1. Все возможные споры, вытекающие из настоящего
                        Соглашения или связанные с ним, подлежат разрешению в
                        соответствии с действующим законодательством Российской
                        Федерации.
                    </p>
                    <p>
                        3.2. Признание судом какого-либо положения Соглашения
                        недействительным или не подлежащим принудительному
                        исполнению не влечет недействительности иных положений
                        Соглашения.
                    </p>
                    <p>
                        3.3. Бездействие со стороны Администрации Сайта в случае
                        нарушения кем-либо из Пользователей положений Соглашения
                        не лишает Администрацию Сайта права предпринять позднее
                        соответствующие действия в защиту своих интересов и
                        защиту авторских прав на охраняемые в соответствии с
                        законодательством материалы Сайта.
                    </p>
                    <p>
                        3.4. Администрация Сайта вправе в любое время в
                        одностороннем порядке изменять условия настоящего
                        Соглашения. Такие изменения вступают в силу по истечении
                        1 (одного) дня с момента размещения новой версии
                        Соглашения на сайте. При несогласии Пользователя с
                        внесенными изменениями он обязан отказаться от доступа к
                        Сайту, прекратить использование материалов и сервисов
                        Сайта.
                    </p>
                    <p>
                        3.5. Переходя по ссылке{' '}
                        <a
                            href="https://expertplace.o3t.ru/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            expertplace.o3t.ru
                        </a>
                        , Пользователь подтверждает, что принимает условия
                        настоящего Соглашения, а также Политики
                        конфиденциальности Сайта, являющейся неотъемлемой частью
                        настоящего Соглашения и размещенной на странице по
                        адресу:{' '}
                        <a
                            href="https://expertplace.o3t.ru/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            expertplace.o3t.ru/privacy
                        </a>
                        .
                    </p>
                </div>
            </Container>
        </Layout>
    )
}
