import React, { SyntheticEvent, useEffect, useState } from 'react'
import { useDeleteExpert, useGetExpert } from '../../api/queries'
import {
    IAddExpertsForm,
    IEditExpertsForm,
    IExpert,
    IExpertPatch,
    IExpertsForm,
    TRolesPatch,
    TRoleValue,
} from '../../model'
import { useSmartForm } from '../../../../../../services/formProcessing'
import s from './index.module.scss'
import { SmartForm } from '../../../../../../services/formProcessing/ui/SmartForm'
import { SmartCheckbox } from '../../../../../../shared/ui/Checkbox'
import { TextField } from '../../../../../../shared/ui/TextField'
import { AppButton } from '../../../../../../shared/ui/Button'
import { AppLink, LinkPresets } from '../../../../../../shared/ui/Link'
import { SmartSelect } from '../../../../../../shared/ui/Select'
import { SmartRadio } from '../../../../../../shared/ui/Radio'
import moment from 'moment/moment'
import { useList } from '../../../../../../shared/hooks/useList/useList'
import { useFieldArray } from 'react-hook-form'
import { ModalWindow } from '../../../../../../shared/ui/ModalWindow'
import { expertsApi } from '../../api'
import user from '../../../../../../shared/assets/adminPanel/user.png'

import {
    transformCityForFetch,
    transformExperienceForFetch,
    transformTagsForFetch,
} from '../../../../../../widgets/fillingProfile/ui/helpers'
import { Icons } from '../../../../../../shared/icons'
import { SmartCreatableSelect } from '../../../../../../shared/ui/CreatableSelect'
moment.locale('ru')

const months = moment.months().map((month, i) => ({ value: i, label: month }))
const roleOptions = [
    { label: '🟡 Одобрено', value: 'active' },
    { label: '🔴 Отклонено', value: 'inactive' },
    { label: '🔵 Ожидает', value: 'onModeration' },
]
const roleStatus = {
    active: '🟡 Одобрено',
    inactive: '🔴 Отклонено',
    onModeration: '🔵 Ожидает',
}

export const ExpertsForm: React.FC<IExpertsForm> = (props) => {
    if (props.status === 'add') return <AddExpertsForm {...props} />
    if (props.status === 'edit' && props.id)
        return <EditExpertsForm id={props.id} {...props} />
    return null
}

const AddExpertsForm: React.FC<IAddExpertsForm> = (props) => {
    return <Form {...props} />
}

const EditExpertsForm: React.FC<IEditExpertsForm> = (props) => {
    const { data, isFetching } = useGetExpert(props.id)
    if (isFetching) return null
    return <Form {...props} data={data} />
}

const Form: React.FC<IExpertsForm> = ({
    id,
    status,
    setStatus,
    reset,
    role,
    data,
}) => {
    // FORM
    const form = useSmartForm<IExpert | any>({
        defaultValues: {
            ...data,
            code: data?.code,
            enabledUntil: moment(data?.enabledUntil).format('YYYY-MM-DD'),
            enabledSince: moment(data?.enabledSince).format('YYYY-MM-DD'),
            coach: {
                label: roleStatus[data?.roles.coach!],
                value: data?.roles.coach,
            },
            mentor: {
                label: roleStatus[data?.roles.mentor!],
                value: data?.roles.mentor,
            },
            consultant: {
                label: roleStatus[data?.roles.consultant!],
                value: data?.roles.consultant,
            },
            tags: data?.tags.map((label, i) => ({ value: i, label })),
            city: { value: 0, label: data?.city },
            periodicity: data?.periodicity,
            expertise: data?.expertise?.map((el) => ({
                ...el,
                specialisations: el.specialisations.join('\n'),
            })),
            experience: data?.experience?.map((el) => ({
                isOngoing: el.isOngoing,
                organization: el.organization,
                position: el.position,
                results: el?.results,
                sinceMonth: {
                    value: moment(el.since).month(),
                    label: moment(el.since).format('MMMM'),
                },
                sinceYear: moment(el.since).year(),
                untilMonth: {
                    value: moment(el.until).month(),
                    label:
                        moment(el.until).format('MMMM') !== 'Invalid date'
                            ? moment(el.until).format('MMMM')
                            : 'Месяц',
                },
                untilYear: moment(el.until).year(),
            })),
        },
    })
    const onSubmit = async (data: any) => {
        const formData: Partial<IExpertPatch> = {
            isEnabled: data.isEnabled,
            displayOnMainPage: data.displayOnMainPage,
            priority: Number(data.priority),
            enabledUntil: moment(data.enabledUntil).toISOString(),
            points: Number(data.points),
            photo: data.photo,
            fullName: data.fullName,
            position: data.position,
            about: data.about,
            socialStaff: data.socialStaff,
            socialTelegram: data.socialTelegram,
            socialVkontakte: data.socialVkontakte,
            expertise: data.expertise?.map((el: any) => ({
                ...el,
                specialisations: el.specialisations.split('\n'),
            })),
            tags: transformTagsForFetch(data.tags),
            interactionFormat: data.interactionFormat,
            city: transformCityForFetch(data.city),
            periodicity: data.periodicity,
            experience: transformExperienceForFetch(data.experience),
            clearEnabledUntil:
                data.enabledUntil === '' ||
                data.enabledUntil === 'Invalid date',
        }
        if (id) {
            if (data.isDelete) {
                await del(id)
                setStatus(null)
            } else {
                try {
                    await expertsApi.patchExpert(id, formData)
                    setStatus(null)
                } catch (e: any) {
                    alert(e.response.data.error)
                }
            }
        }
    }

    const {
        fields: fieldsExpertise,
        append: appendExpertise,
        remove: removeExpertise,
    } = useFieldArray({
        name: 'expertise',
        control: form.control,
    })
    const {
        fields: fieldsExperience,
        append: appendExperience,
        remove: removeExperience,
    } = useFieldArray({
        name: 'experience',
        control: form.control,
    })
    //FORM

    // Expertise
    const handleClickAddExpertise = (evt: SyntheticEvent) => {
        evt.preventDefault()
        appendExpertise({})
    }
    const handleClickRemoveExpertise = (evt: SyntheticEvent, index: number) => {
        evt.preventDefault()
        removeExpertise(index)
    }

    // Experience
    const handleClickAddExperience = (evt: SyntheticEvent) => {
        evt.preventDefault()
        appendExperience({})
    }
    const handleClickRemoveExperience = (
        evt: SyntheticEvent,
        index: number
    ) => {
        evt.preventDefault()
        removeExperience(index)
    }

    //MODAL
    const [isOpened1, setIsOpened1] = useState(false)
    const [isOpened2, setIsOpened2] = useState(false)
    const [isOpened3, setIsOpened3] = useState(false)
    const [selectRole, setSelectRole] = useState<'' | TRoleValue>('')
    const onChangeRoleStatus = async ({ value }: any, role: TRoleValue) => {
        if ((value === 'active' || value === 'onModeration') && id) {
            const roles: Partial<TRolesPatch> = { [`${role}`]: value }
            try {
                await expertsApi.patchExpert(id, { roles })
                value === 'active' && setIsOpened1(true)
            } catch (e: any) {
                alert(e.response.data.error)
            }
        }
        if (value === 'inactive') {
            setSelectRole(role)
            setIsOpened2(true)
        }
    }
    const handleClickModalSuccess = (evt: SyntheticEvent) => {
        evt.preventDefault()
        setIsOpened1(false)
    }
    const handleClickModalReject = async (evt: SyntheticEvent) => {
        evt.preventDefault()
        const roles: Partial<TRolesPatch> = {
            [`${selectRole}RejectReason`]: form.getValues().rejectReason,
            [`${selectRole}`]: 'inactive',
        }
        if (id) {
            try {
                await expertsApi.patchExpert(id, { roles })
                setIsOpened2(false)
                setIsOpened3(true)
            } catch (e: any) {
                alert(e.response.data.error)
            }
        }
    }

    //FOOTER
    const handleClickCancel = (evt: SyntheticEvent) => {
        evt.preventDefault()
        setStatus(null)
    }

    // Photo
    const uploadPhoto = (evt: SyntheticEvent) => {
        evt.preventDefault()
        try {
            const fileInput = document.createElement('input')
            fileInput.type = 'file'
            fileInput.accept = 'image/*'
            fileInput.onchange = async (event) => {
                const file = (event.target as HTMLInputElement).files?.[0]
                if (!file) return

                const formData = new FormData()
                formData.append('photo', file)

                const response = await expertsApi.uploadPhoto(formData)

                const imageUrl = response.data.url

                form.setValue('photo', imageUrl)
                form.watch()
            }
            fileInput.click()
        } catch (e) {
            console.log(e)
            alert('Something went wrong')
        }
        form.watch()
    }

    // FETCH
    const { mutate: del } = useDeleteExpert(reset)
    const { items: fetchTags } = useList<string[]>({
        key: 'tags',
        apiMethod: expertsApi.getTags,
        defaultLimit: 100,
    })
    const { items: fetchCities } = useList<string[]>({
        key: 'cities',
        apiMethod: (params) => expertsApi.getCities(params),
        defaultLimit: 100,
    })

    return (
        <div className={s.wrapper}>
            <SmartForm form={form} submit={form.handleSubmit(onSubmit)}>
                <div className={s.wrapper_form}>
                    {/*ID*/}
                    <div>
                        {id && <p className={s.h4}>ID: {id}</p>}
                        <div className={s.checkbox_wrapper}>
                            <div className={s.checkbox}>
                                <SmartCheckbox
                                    name="isEnabled"
                                    label={'Активность'}
                                />
                            </div>
                            <div className={s.checkbox}>
                                <SmartCheckbox
                                    name="displayOnMainPage"
                                    label={'Выводить на главной:'}
                                />
                            </div>
                        </div>
                        <TextField
                            label="Сортировка"
                            placeholder="500"
                            name="priority"
                            type="number"
                        />
                        <div className={s.date_wrapper}>
                            <TextField
                                className={s.date}
                                label="Начало активности"
                                placeholder="500"
                                name="enabledSince"
                                type="date"
                            />
                            <TextField
                                className={s.date}
                                label="Окончание активности"
                                placeholder="500"
                                name="enabledUntil"
                                type="date"
                            />
                        </div>
                        <TextField
                            label="Символьный код"
                            placeholder="Символьный код"
                            name="code"
                            disabled
                        />
                    </div>
                    {/*Роли эксперта*/}
                    <div>
                        <p className={s.h4}>Роли эксперта</p>
                        <div className={s.role_wrapper}>
                            <div className={s.role}>
                                <div className={s.role_title}>Ментор</div>
                                <SmartSelect
                                    onChange={(value) =>
                                        onChangeRoleStatus(value, 'mentor')
                                    }
                                    className={s.role_select}
                                    name={'mentor'}
                                    options={roleOptions}
                                />
                            </div>
                            <div className={s.role}>
                                <div className={s.role_title}>Консультант</div>
                                <SmartSelect
                                    onChange={(value) =>
                                        onChangeRoleStatus(value, 'consultant')
                                    }
                                    className={s.role_select}
                                    name={'consultant'}
                                    options={roleOptions}
                                />
                            </div>
                            <div className={s.role}>
                                <div className={s.role_title}>
                                    Внутренний тренер
                                </div>
                                <SmartSelect
                                    onChange={(value) =>
                                        onChangeRoleStatus(value, 'coach')
                                    }
                                    className={s.role_select}
                                    name={'coach'}
                                    options={roleOptions}
                                />
                            </div>
                        </div>
                    </div>
                    {/*Баланс*/}
                    <div>
                        <p className={s.h4}>Баланс</p>
                        <TextField
                            placeholder="500 баллов"
                            name="points"
                            type="number"
                        />
                    </div>
                    {/*Информация об эксперте*/}
                    <div>
                        <p className={s.h4}>Информация об эксперте</p>
                        <div className={s.avatar_wrapper}>
                            {form.getValues('photo') ? (
                                <img
                                    src={form.getValues('photo')}
                                    alt="placeholder"
                                    className={s.image}
                                />
                            ) : (
                                <img src={user} alt="placeholder" />
                            )}
                            <AppButton
                                onClick={uploadPhoto}
                                variant={'ghost'}
                                className={s.avatar_button}
                            >
                                Загрузить аватар <Icons.M.Download />
                            </AppButton>
                        </div>
                        <TextField
                            label="Ф.И.О."
                            placeholder="Свиридова Анна Александровна"
                            name="fullName"
                        />
                        <TextField
                            label="Должность"
                            placeholder="Дизайнер"
                            name="position"
                        />
                        {data?.reason && <TextField
                            label="Почему ты хочешь делиться экспертизой в Ozon?"
                            name="reason"
                            as="textarea"
                            disabled
                        />}
                        <TextField
                            label="Описание"
                            placeholder="Продуктовый дизайнер, коуч и ментор — вы можете обратиться ко мне за помощью, если вас интересует карьера в продукте / диджитал, хочется вырасти по карьерной лестнице или есть запрос на исследование себя и своих качеств, на самоопределение. "
                            name="about"
                            as="textarea"
                        />
                        <TextField
                            label="Ссылка на Staff"
                            placeholder="Staff"
                            name="socialStaff"
                        />
                        <div className={s.date_wrapper}>
                            <TextField
                                className={s.date}
                                label="Ссылка на Telegram"
                                placeholder="Telegram"
                                name="socialTelegram"
                            />
                            {/*<TextField*/}
                            {/*    className={s.date}*/}
                            {/*    label="Ссылка на Vkontakte"*/}
                            {/*    placeholder="Vkontakte"*/}
                            {/*    name="socialVkontakte"*/}
                            {/*/>*/}
                        </div>
                    </div>
                    {/*Experience*/}
                    <div>
                        <p className={s.h4}>Чем ты можешь быть полезен?</p>
                        <div className={s.wrapper_form}>
                            {fieldsExpertise.map((field, index) => (
                                <div key={field.id}>
                                    <TextField
                                        label="Заголовок"
                                        placeholder="Карьерно вырасти"
                                        name={`expertise.${index}.description`}
                                    />
                                    <TextField
                                        as="textarea"
                                        label="Описание"
                                        placeholder="Построить карьерный трек, получить повышение, перейти на другой грейд"
                                        name={`expertise.${index}.specialisations`}
                                    />
                                    <LinkPresets.Remove
                                        onClick={(evt) =>
                                            handleClickRemoveExpertise(
                                                evt,
                                                index
                                            )
                                        }
                                        to=""
                                    />
                                </div>
                            ))}
                            <LinkPresets.AddBlock
                                onClick={handleClickAddExpertise}
                                to=""
                            />
                        </div>
                    </div>
                    {/*Опыт работы*/}
                    <div>
                        <p className={s.h4}>Области экспертизы</p>
                        <SmartSelect
                            placeholder="Выберите области экспертизы"
                            name="tags"
                            isMulti
                            options={fetchTags.map((el: any) => ({
                                value: el.id,
                                label: el.tag,
                            }))}
                        />
                    </div>
                    {/*Формат взаимодействия*/}
                    <div>
                        <p className={s.h4}>Формат взаимодействия</p>
                        <div className={s.checkbox_wrapper}>
                            <SmartRadio
                                value="online"
                                name="interactionFormat"
                                label="онлайн"
                            />
                            <SmartRadio
                                value="offline"
                                name="interactionFormat"
                                label="офлайн"
                            />
                            <SmartRadio
                                value="any"
                                name="interactionFormat"
                                label="не важно"
                            />
                        </div>
                    </div>
                    {/*Город*/}
                    <div>
                        <p className={s.h4}>Город</p>
                        <SmartCreatableSelect
                            name="city"
                            options={fetchCities.map((label, i) => ({
                                value: i + 1,
                                label,
                            }))}
                        />
                    </div>
                    {/*Регулярность*/}
                    <div>
                        <p className={s.h4}>Регулярность</p>
                        <div className={s.checkbox_wrapper}>
                            <SmartRadio
                                value="oneTime"
                                name="periodicity"
                                label="Разовая консультация"
                            />
                            <SmartRadio
                                value="projectParticipation"
                                name="periodicity"
                                label="Участие в проекте"
                            />
                        </div>
                    </div>
                    {/*Опыт работы*/}
                    <div>
                        <p className={s.h4}>Опыт работы</p>
                        <div className={s.wrapper_form}>
                            {fieldsExperience.map((field, index) => (
                                <div key={field.id}>
                                    <TextField
                                        label="Место работы"
                                        name={`experience.${index}.organization`}
                                    />
                                    <TextField
                                        label="Должность"
                                        name={`experience.${index}.position`}
                                    />
                                    <>
                                        <p>Начало работы</p>
                                        <div className={s.date_inputs}>
                                            <SmartSelect
                                                className={s.date}
                                                name={`experience.${index}.sinceMonth`}
                                                options={months}
                                            />
                                            <TextField
                                                name={`experience.${index}.sinceYear`}
                                                type="number"
                                            />
                                        </div>
                                    </>
                                    <>
                                        <p>Окончание работы</p>
                                        <div className={s.date_inputs}>
                                            <SmartSelect
                                                className={s.date}
                                                name={`experience.${index}.untilMonth`}
                                                options={months}
                                            />
                                            <TextField
                                                name={`experience.${index}.untilYear`}
                                                type="number"
                                            />
                                            <SmartCheckbox
                                                name={`experience.${index}.isOngoing`}
                                                label="По настоящее время"
                                            />
                                        </div>
                                    </>
                                    <TextField
                                        as="textarea"
                                        name={`experience.${index}.results`}
                                    />
                                    <LinkPresets.Remove
                                        onClick={(evt: SyntheticEvent) =>
                                            handleClickRemoveExperience(
                                                evt,
                                                index
                                            )
                                        }
                                        to=""
                                    />{' '}
                                </div>
                            ))}
                            <LinkPresets.AddBlock
                                onClick={handleClickAddExperience}
                                to=""
                            />
                        </div>
                    </div>
                    {/*Удалить*/}
                    {status === 'edit' && (
                        <div className={s.checkbox}>
                            <SmartCheckbox
                                name="isDelete"
                                label={
                                    'Удалить профиль без возможности восстановления'
                                }
                            />
                        </div>
                    )}
                </div>

                <div className={s.buttons}>
                    <AppButton>
                        {status === 'edit' ? 'Сохранить изменения' : 'Создать'}
                    </AppButton>
                    <AppLink onClick={handleClickCancel} to={''}>
                        Отменить
                    </AppLink>
                </div>

                <div>
                    {/*<UploadAvatarModal {...uploadAvatarModal} />*/}
                    <ModalWindow
                        isOpened={isOpened1}
                        setIsOpened={setIsOpened1}
                    >
                        <div className={s.modal_inner}>
                            <p className={s.h4}>Роль одобрена</p>
                            <p className={s.p3}>Эксперт получит уведомление</p>
                            <AppButton onClick={handleClickModalSuccess}>
                                Хорошо
                            </AppButton>
                        </div>
                    </ModalWindow>
                    <ModalWindow
                        isOpened={isOpened2}
                        setIsOpened={setIsOpened2}
                    >
                        <div className={s.modal_inner}>
                            <p className={s.h4}>
                                Укажи причину отклонения заявки
                            </p>
                            <p className={s.p3}>Эксперт получит уведомление</p>
                            <TextField
                                placeholder="Введите причину отклонения заявки"
                                name="rejectReason"
                            />
                            <AppButton onClick={handleClickModalReject}>
                                Хорошо
                            </AppButton>
                        </div>
                    </ModalWindow>
                    <ModalWindow
                        isOpened={isOpened3}
                        setIsOpened={setIsOpened3}
                    >
                        <div className={s.modal_inner}>
                            <p className={s.h4}>Заявка отклонена</p>
                            <p className={s.p3}>
                                Форма успешно отправлена, заявка отклонена
                            </p>
                        </div>
                    </ModalWindow>
                </div>
            </SmartForm>
        </div>
    )
}
