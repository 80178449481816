import React, { useState } from 'react'
import { ReviewModal } from '../../../../../entities/review/modals/reviewModal'
import { useModalWindow } from '../../../../../shared/hooks/useModalWindow'

import s from './reviews.module.scss'
import { Switcher } from '../../../../../shared/ui/Switcher'
import { AppButton } from '../../../../../shared/ui/Button'
import { useList } from '../../../../../shared/hooks/useList/useList'
import { reviewsApi } from '../../../../../entities/review/api'
import { useQueryClient } from '@tanstack/react-query'
import { IProfileExpert } from '../../../../../entities/profile'
import { ReviewType } from '../../../../../entities/review/model/types'
import { ReviewItem } from '../../../../../entities/review'

export const Reviews = () => {
    const queryClient = useQueryClient()
    const profile = queryClient.getQueryData<IProfileExpert>(['profile'])
    const reviewsModalController = useModalWindow()

    const [switcherValue, setSwitcherValue] = useState<string>('')
    const [selectedReview, setSelectedReview] = useState<ReviewType>()

    const {
        items: reviewsList,
        showMore,
        reset,
        page,
        total,
    } = useList<ReviewType>({
        key: ['reviewsList', switcherValue],
        apiMethod: () =>
            reviewsApi.getReviewsList({
                expertId: profile?.id!,
                type: switcherValue,
            }),
        defaultLimit: 6,
    })

    const getActiveRoles = () => {
        const activeRoles = []
        if (profile?.roles) {
            if (profile.roles['coach'] === 'active') {
                activeRoles.push('program')
            }
            if (profile.roles['mentor'] === 'active') {
                activeRoles.push('mentor')
            }
            if (profile.roles['consultant'] === 'active') {
                activeRoles.push('consultant')
            }
        }
        return activeRoles
    }

    const generateSwitcherOptions = () => {
        const activeRoles = getActiveRoles()
        let options = activeRoles.map((role) => {
            let roleText = ''
            switch (role) {
                case 'program':
                    roleText = 'Программы'
                    break
                case 'mentor':
                    roleText = 'Менторство'
                    break
                case 'consultant':
                    roleText = 'Консультации'
                    break
                default:
                    roleText = role
            }
            return {
                value: role,
                text: roleText,
            }
        })

        if (activeRoles.length >= 2) {
            options.unshift({
                value: '',
                text: 'Все отзывы',
            })
        }

        if (activeRoles.length === 1) {
            options = []
        }
        return options
    }

    const optionsForSwitcher = generateSwitcherOptions()

    const onSwitchListType = (value: string) => {
        setSwitcherValue(value)
        reset()
    }

    const openReviewModal = (review: ReviewType) => {
        setSelectedReview(review)
        reviewsModalController.toggleOpen()
    }

    return (
        <div className={s.reviewsBlock}>
            <h3>Обратная связь</h3>
            <p className={s.reviewsDescription}>
                В этом разделе ты видишь отзывы от пользователей сервиса Ozon
                Expertplace
            </p>

            {optionsForSwitcher.length >= 2 && (
                <div className={s.switcherWrapper}>
                    <Switcher
                        options={optionsForSwitcher}
                        setValue={onSwitchListType}
                        value={switcherValue}
                        className={s.switcher}
                    />
                </div>
            )}

            <div className={s.reviewsWrapper}>
                {reviewsList.map(({ fullName, position, type, text }) => (
                    <>
                        <ReviewItem
                            fullName={fullName}
                            position={position}
                            type={type}
                            text={text}
                            onClick={() =>
                                openReviewModal({
                                    fullName,
                                    text,
                                    position,
                                    type,
                                } as ReviewType)
                            }
                        />
                        <ReviewModal
                            selectedReview={selectedReview}
                            {...reviewsModalController}
                        />
                    </>
                ))}
            </div>

            {page * 6 < total && (
                <AppButton
                    size="lg"
                    variant="ghost"
                    onClick={showMore}
                    className={s.moreButton}
                >
                    Показать ещё
                </AppButton>
            )}
        </div>
    )
}
