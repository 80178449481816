import React from 'react'
import { Typography } from '../../../../shared/ui/Typography'
import { AppButton } from '../../../../shared/ui/Button'
import {
    ModalWindow,
    ModalWindowPropsType,
} from '../../../../shared/ui/ModalWindow'
import { ApplicationType } from '../../../../entities/application/types'
import { css } from '@emotion/react'
import { ApplicationCardForUnify } from '../../../../entities/application/ui/applicationCardForUnfiy'
import { SmartForm } from '../../../../services/formProcessing/ui/SmartForm'
import { useForm } from 'react-hook-form'

type PropsType = {
    selectApplications?: (id: number) => void
    onSuccess: (choosenIds: number[]) => void
    notSucceedStudents: ApplicationType[]
}

type FormType = {
    applicationsToSuccess: string[]
}

export const FailedStudendsModal = ({
    notSucceedStudents,
    onSuccess,
    ...props
}: ModalWindowPropsType & PropsType) => {
    const form = useForm<FormType>({
        defaultValues: {
            applicationsToSuccess: [],
        },
    })

    const submitHandler = () => {
        const { applicationsToSuccess } = form.getValues()

        const completedIds = (
            typeof applicationsToSuccess === 'string'
                ? [applicationsToSuccess]
                : applicationsToSuccess
        ).map((s) => Number(s))

        onSuccess(completedIds)
        props.setIsOpened(false)
    }

    return (
        <ModalWindow {...props}>
            <div
                css={css`
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 20px;
                `}
            >
                <Typography center as="h4">
                    Не прошли обучение
                </Typography>
                <Typography center as="t1">
                    Сотрудники, которые не прошли обучение, получат уведомление
                    на почту и не смогут оставить отзыв на программу
                </Typography>
                <SmartForm
                    css={css`
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 12px;
                    `}
                    submit={() => {}}
                    form={form}
                >
                    {notSucceedStudents.map((item) => (
                        <ApplicationCardForUnify
                            name="applicationsToSuccess"
                            key={item.id}
                            application={item}
                        />
                    ))}
                </SmartForm>
                <AppButton onClick={submitHandler}>Отправить</AppButton>
            </div>
        </ModalWindow>
    )
}
