import { CreateReviewForm } from '../../widgets/createReviewForm'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { LinkPresets } from '../../shared/ui/Link'
import React from 'react'
import { Layout } from '../../shared/ui/Layout'
import { css } from '@emotion/react'
import { theme } from '../../shared/styles/theme'
import { Container } from '../../shared/ui/Container'
import s from './index.module.scss'
import { useGetReviewProfile } from '../../entities/review/api/queries'
import { ReviewProfile } from '../../entities/review/ui/reviewProfile/reviewProfile'

export const CreateReviewPage = () => {
    const { id } = useParams<{ id: string }>()
    const history = useHistory()
    const location = useLocation()
    const key = location.pathname.replace('/feedback/', '')
    const { data: reviewProfile, isFetching } = useGetReviewProfile(key)

    return (
        <Layout
            css={css`
                background-color: ${theme.color.white};
            `}
        >
            <Container>
                {isFetching ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '600px',
                        }}
                    >
                        Loading...
                    </div>
                ) : (
                    <div className={s.ProgramContainer}>
                        <div className={s.GoBackLink}>
                            <LinkPresets.BackBlue
                                to={'#'}
                                onClick={() => history.goBack()}
                            />
                        </div>
                        <div className={s.ProgramWrapper}>
                            {reviewProfile && (
                                <>
                                    <ReviewProfile
                                        reviewProfile={reviewProfile}
                                    />
                                    <CreateReviewForm
                                        reviewProfile={reviewProfile}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                )}
            </Container>
        </Layout>
    )
}
