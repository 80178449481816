import { css } from '@emotion/react'
import {
    ModalWindow,
    ModalWindowPropsType,
} from '../../../../shared/ui/ModalWindow'
import { Typography } from '../../../../shared/ui/Typography'
import { AppButton } from '../../../../shared/ui/Button'
import { theme } from '../../../../shared/styles/theme'

type PropsType = ModalWindowPropsType

export const ReviewHasBeenSentModal = ({ ...props }: PropsType) => {
    return (
        <ModalWindow {...props}>
            <div
                css={css`
                    width: 100%;
                `}
            >
                <Typography
                    as="h4"
                    css={css`
                        margin-bottom: 16px;
                    `}
                >
                    Спасибо, твой отзыв отправлен!
                </Typography>
                <Typography
                    css={css`
                        margin-bottom: 32px;
                    `}
                    color={theme.color.darkSpace[400]}
                    as="t1"
                >
                    После модерации он появится на странице обучающей программы.
                </Typography>
                <AppButton onClick={() => props.setIsOpened(false)}>
                    Хорошо
                </AppButton>
            </div>
        </ModalWindow>
    )
}
