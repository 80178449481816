import React, { SyntheticEvent } from 'react'
import { StepLayout } from './StepLayout'
import s from './FillingProfile.module.scss'
import { useSmartForm } from '../../../services/formProcessing'
import clsx from 'clsx'
import { SmartForm } from '../../../services/formProcessing/ui/SmartForm'
import { AppButton } from '../../../shared/ui/Button'
import { LinkPresets } from '../../../shared/ui/Link'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../app/store'
import { profileActions } from '../../../entities/profile'
import { useFieldArray } from 'react-hook-form'
import moment from 'moment'
import 'moment/locale/ru'
import { TextField } from '../../../shared/ui/TextField'
import { SmartSelect } from '../../../shared/ui/Select'
import { SmartCheckbox } from '../../../shared/ui/Checkbox'
import { transformExperienceForFetch } from './helpers'
import {
    useFetchProfile,
    usePatchProfile,
} from '../../../entities/profile/api/queries'

moment.locale('ru')
const months = moment.months().map((month, i) => ({ value: i, label: month }))

interface IExperience {
    isOngoing?: boolean
    organization?: string
    position?: string
    results?: string
    sinceMonth?: { value: number; label: string }
    sinceYear?: number
    untilMonth?: { value: number; label: string }
    untilYear?: number
}

interface IForm {
    experience: IExperience[]
}

export const FillingProfileThirdStep = () => {
    const dispatch = useDispatch<AppDispatch>()
    const nextStep = () => dispatch(profileActions.setStep(null))
    const { data } = useFetchProfile()
    const { mutate, isPending } = usePatchProfile(nextStep)
    const form = useSmartForm<IForm>({
        defaultValues: {
            experience: data?.experience?.map((el) => ({
                isOngoing: el.isOngoing,
                organization: el.organization,
                position: el.position,
                results: el?.results,
                sinceMonth: {
                    value: moment(el.since).month(),
                    label: moment(el.since).format('MMMM'),
                },
                sinceYear: moment(el.since).year(),
                untilMonth: {
                    value: moment(el.until).month(),
                    label:
                        moment(el.until).format('MMMM') !== 'Invalid date'
                            ? moment(el.until).format('MMMM')
                            : 'Месяц',
                },
                untilYear: moment(el.until).year(),
            })),
        },
    })
    const { fields, append, remove } = useFieldArray({
        name: 'experience',
        control: form.control,
    })
    const handleClickAddExperience = (evt: SyntheticEvent) => {
        evt.preventDefault()
        append({})
    }
    const handleClickRemoveExperience = (
        evt: SyntheticEvent,
        index: number
    ) => {
        evt.preventDefault()
        remove(index)
    }
    const onSubmit = (formData: IForm) => {
        const fetchData = {
            experience: transformExperienceForFetch(formData.experience),
        }
        mutate(fetchData)
    }

    return (
        <StepLayout>
            <SmartForm
                className={s.step_1}
                form={form}
                submit={form.handleSubmit(onSubmit)}
            >
                {fields.map((field, index) => (
                    <div
                        key={field.id}
                        className={clsx(s.step, s.step_1, s.section_1)}
                    >
                        <div className={s.section_1}>
                            <div>
                                <p className={s.subtitle}>Опыт работы</p>
                                <p className={s.desc}>
                                    Подробное описание опыта поможет
                                    пользователям найти более подходящих
                                    экспертов под их запрос
                                </p>
                            </div>
                        </div>
                        <TextField
                            label="Место работы"
                            placeholder="Где ты работаешь или работал ранее?"
                            name={`experience.${index}.organization`}
                        />
                        <TextField
                            label="Должность"
                            placeholder="Занимаемая должность"
                            name={`experience.${index}.position`}
                        />
                        <div>
                            <p className={s.date_subtitle}>Начало работы</p>
                            <div className={s.date_inputs}>
                                <SmartSelect
                                    name={`experience.${index}.sinceMonth`}
                                    options={months}
                                    placeholder="Месяц"
                                />
                                <TextField
                                    name={`experience.${index}.sinceYear`}
                                    type="number"
                                    placeholder="Год"
                                />
                            </div>
                        </div>
                        <div>
                            <p className={s.date_subtitle}>Окончание работы</p>
                            <div className={s.date_inputs}>
                                <SmartSelect
                                    name={`experience.${index}.untilMonth`}
                                    options={months}
                                    placeholder="Месяц"
                                />
                                <TextField
                                    name={`experience.${index}.untilYear`}
                                    type="number"
                                    placeholder="Год"
                                />
                                <SmartCheckbox
                                    name={`experience.${index}.isOngoing`}
                                    label="По настоящее время"
                                />
                            </div>
                        </div>
                        <div>
                            <p
                                className={clsx(
                                    [s.subtitle],
                                    [s.subtitleResults]
                                )}
                            >
                                Результаты
                            </p>
                            <TextField
                                as="textarea"
                                name={`experience.${index}.results`}
                            />
                        </div>
                        <LinkPresets.Remove
                            onClick={(evt: SyntheticEvent) =>
                                handleClickRemoveExperience(evt, index)
                            }
                            to=""
                        />
                    </div>
                ))}
                <div className={clsx(s.step)}>
                    <p className={clsx([s.subtitle], [s.subtitleAddWork])}>
                        Добавь еще место работы
                    </p>
                    <LinkPresets.AddBlock
                        onClick={handleClickAddExperience}
                        to=""
                    />
                    <div className={clsx(s.submit)}>
                        <AppButton disabled={isPending}>
                            Сохранить изменения
                        </AppButton>
                        <p onClick={nextStep} className={s.skip}>
                            Далее
                        </p>
                    </div>
                </div>
            </SmartForm>
        </StepLayout>
    )
}
