import { AppButton } from '../../../../../../shared/ui/Button'
import React from 'react'
import { useModalWindow } from '../../../../../../shared/hooks/useModalWindow'
import { CreateOrEditProgramModal } from '../programsList/modals'

import s from './noProgramsPage.module.scss'

export const NoProgramsPage = () => {
    const formModalController = useModalWindow()

    return (
        <div className={s.noPrograms}>
            <h4 className={s.title}>Созданные программы</h4>
            <p className={s.description}>
                Теперь тебе предстоит обучиться в школе тренера, в которой ты научишься создавать и проводить обучение.
                Информация о старте потока Школы и регистрации придет тебе на почту в течение полугода.
                Вопросы по школе пиши на <a href="mailto:univer@ozon.ru">univer@ozon.ru</a>
            </p>
            <p className={s.description}>
                А если ты уже завершил обучение в Школе,
                воспользуйся формой ниже. чтобы создать программу.
                После успешной модерации она появится в каталоге Ozon Expertplace и ты сможешь начать обучать коллег
            </p>

            <AppButton
                onClick={formModalController.toggleOpen}
                className={s.createProgram}
            >
                Создать программу
            </AppButton>

            <CreateOrEditProgramModal {...formModalController} />
        </div>
    )
}
