import { Icons } from '../../../shared/icons'
import React, { useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import ISwiper from 'swiper'
import { Navigation } from 'swiper/modules'
import s from './ProfileReviews.module.scss'
import { useModalWindow } from '../../../shared/hooks/useModalWindow'
import { ReviewModal } from '../../../entities/review/modals/reviewModal'
import { ReviewType } from '../../../entities/review/model/types'
import { ReviewItem } from '../../../entities/review'
import { useParams } from 'react-router-dom'
import { useList } from '../../../shared/hooks/useList/useList'
import { reviewsApi } from '../../../entities/review/api'

type PropsType = {
    type?: string
}
export const ProfileReviews: React.FC<PropsType> = ({ type }) => {
    const { id } = useParams<{ id: string }>()
    const reviewsSwiperRef = useRef<ISwiper>()
    const reviewsModalController = useModalWindow()
    const [selectedReview, setSelectedReview] = useState<ReviewType>()
    const typeChoise = type === 'program' ? 'programId' : 'expertId'

    const { items: reviewsList, isFetching } = useList<ReviewType>({
        key: ['reviewsList'],
        apiMethod: () =>
            reviewsApi.getReviewsList({
                [typeChoise]: Number(id)
            }),
        defaultLimit: 12,
    })

    const openReviewModal = (review: ReviewType) => {
        setSelectedReview(review)
        reviewsModalController.toggleOpen()
    }

    const sliderSettings = {
        320: {
            slidesPerView: 1,
            spaceBetween: 16,
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 16,
        },
        1024: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        1440: {
            slidesPerView: 4,
            spaceBetween: 20,
        },
    }

    return (
        <div className={s.Container}>
            <div className={s.Wrapper}>
                <div className={s.TitleWrapper}>
                    <h3 className={s.Title}>
                        {type === 'program'
                            ? 'Что говорят о программе?'
                            : 'Что говорят об эксперте?'}
                    </h3>
                    <div className={s.ReviewsSliderButtonsWrapper}>
                        <button
                            className={`${s.ReviewsSliderButtons} ReviewsSliderButtons-prev`}
                            aria-label="Prev slide"
                        >
                            <Icons.S.ChevronLeft></Icons.S.ChevronLeft>
                        </button>
                        <button
                            className={`${s.ReviewsSliderButtons} ReviewsSliderButtons-next`}
                            aria-label="Next slide"
                        >
                            <Icons.S.ChevronRight></Icons.S.ChevronRight>
                        </button>
                    </div>
                </div>
            </div>
            {isFetching ? (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '600px',
                    }}
                >
                    Loading...
                </div>
            ) : (
                <Swiper
                    className={s.ReviewsSlider}
                    breakpoints={sliderSettings}
                    modules={[Navigation]}
                    navigation={{
                        nextEl: '.ReviewsSliderButtons-next',
                        prevEl: '.ReviewsSliderButtons-prev',
                        disabledClass: `${s.ReviewsSliderButtons_disabled}`,
                    }}
                    onSwiper={(swiper: ISwiper) => {
                        reviewsSwiperRef.current = swiper
                    }}
                >
                    {reviewsList.length === 0 && <div>Отзывов нет</div>}
                    {reviewsList.length > 0 &&
                        reviewsList.map((review, index) => (
                            <SwiperSlide key={index}>
                                <ReviewItem
                                    fullName={review.fullName}
                                    position={review.position}
                                    type={review.type}
                                    text={review.text}
                                    onClick={() => openReviewModal(review)}
                                    variant={'profile'}
                                />
                            </SwiperSlide>
                        ))}
                    <ReviewModal
                        selectedReview={selectedReview}
                        {...reviewsModalController}
                    />
                </Swiper>
            )}
        </div>
    )
}
