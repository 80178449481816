import './FormExperts.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { SmartForm } from '../../../services/formProcessing/ui/SmartForm'
import { useSmartForm } from '../../../services/formProcessing'
import { AppButton } from '../../../shared/ui/Button'
import {
    IFormExpertResponse,
    IFormExpertValues,
    IFormExpertValuesTag,
} from '../model'
import ISwiper from 'swiper'
import { Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { slides } from './slides'
import { useList } from '../../../shared/hooks/useList/useList'
import { tagsApi } from '../../../entities/tags/api'
import { ModalWindow } from '../../../shared/ui/ModalWindow'
import { apiInstance } from '../../../shared/api'
import { useFetchUser } from '../../../entities/profile/api/queries'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../app/store'
import { profileActions } from '../../../entities/profile'
import { SmartCreatableSelect } from '../../../shared/ui/CreatableSelect'

const transformRolesForFetch = (selectedRoles: string[]) => {
    const roles: any = {
        coach: false,
        consultant: false,
        mentor: false,
    }
    selectedRoles.forEach((role) => {
        roles[role] = true
    })
    return roles
}

export const FormExperts = () => {
    const [isOpened, setIsOpened] = useState(false)
    const [modalText, setModalText] = useState('')
    const [activeIndex, setActiveIndex] = useState<number>(0)
    const [disabled, setDisabled] = useState<boolean>(false)
    const { data: user } = useFetchUser()
    const dispatch = useDispatch<AppDispatch>()

    const { items: tags } = useList<string[]>({
        key: 'tags',
        apiMethod: tagsApi.getTags,
        defaultLimit: 100,
    })
    const form = useSmartForm<IFormExpertValues>({
        defaultValues: {},
    })
    const formValues = form.watch()
    const swiperRef = useRef<ISwiper>()
    const handleSubmit = async (values: IFormExpertValues) => {
        try {
            await apiInstance.post<IFormExpertResponse>(`/expert/application`, {
                tags: values.tags.map((el: IFormExpertValuesTag) => el.label),
                position: values.position,
                supervisorEmail: values.supervisorEmail,
                fullName: values.fullName,
                email: values.email,
                reason: values.reason,
                roles: transformRolesForFetch(values.role),
            })
            setModalText('Заявка успешно отправлена')
            dispatch(profileActions.setStep(1))
            window.location.href = '/personal-cabinet/me'
        } catch (e: any) {
            setModalText(e.response.data.error)
        } finally {
            setIsOpened(true)
            swiperRef.current?.slideTo(0)
            form.reset()
        }
    }
    const next = (evt: SyntheticEvent) => {
        if (activeIndex !== slides.at(-1)?.id) {
            evt.preventDefault()
            swiperRef.current?.slideNext()
        }
    }
    const prev = (evt: SyntheticEvent) => swiperRef.current?.slidePrev()
    const closeModal = () => {
        setModalText('')
        setIsOpened(false)
    }

    useEffect(() => {
        const { email, supervisorEmail, fullName, position, role, tags, reason } =
            formValues
        switch (activeIndex) {
            case 0:
                return setDisabled(!fullName || !email || !position || !reason)
            case 1:
                return setDisabled(!Array.isArray(tags) || !tags.length)
            case 2:
                return setDisabled(!role)
            case 3:
                return setDisabled(!supervisorEmail)
        }
    }, [formValues])

    return user?.expertState !== 'onModeration' &&
        user?.expertState !== 'active' ? (
        <div className={'form-experts-slider_wrapper'} id={'registration'}>
            <div className={'form-experts-slider_text'}>
                <p className={'form-experts-slider_title'}>
                    {slides[activeIndex].title}
                </p>
                <p className={'form-experts-slider_desc'}>
                    {slides[activeIndex].desc}
                </p>
            </div>
            <SmartForm
                form={form}
                className={'form-experts-slider_form'}
                submit={handleSubmit}
            >
                <Swiper
                    modules={[Pagination]}
                    effect="fade"
                    slidesPerView={1}
                    allowTouchMove={false}
                    autoHeight={true}
                    pagination={{ clickable: false, type: 'bullets' }}
                    onSwiper={(swiper: ISwiper) => (swiperRef.current = swiper)}
                    onSlideChange={(swiper: ISwiper) =>
                        setActiveIndex(swiper.activeIndex)
                    }
                    className={
                        activeIndex === 1
                            ? `${'form-experts-slider'} ${'step2'}`
                            : 'form-experts-slider'
                    }
                >
                    {slides.map(
                        (slide, index) =>
                            activeIndex !== 1 && (
                                <SwiperSlide key={index}>
                                    {slide.component}
                                    <div className="form-experts-slider_button-wrapper">
                                        <AppButton
                                            disabled={disabled}
                                            onClick={next}
                                        >
                                            Далее
                                        </AppButton>
                                        {Boolean(activeIndex) && (
                                            <p onClick={prev}>
                                                Вернуться назад
                                            </p>
                                        )}
                                    </div>
                                </SwiperSlide>
                            )
                    )}
                    {activeIndex === 1 && (
                        <div
                            className={`${'swiper-slide'} ${'form-experts-slider_step2'}`}
                        >
                            <fieldset>
                                <legend>Шаг 2. Область знаний</legend>
                                <SmartCreatableSelect
                                    label="Добавь теги - области своих знаний, по которым тебя смогут найти коллеги"
                                    placeholder="Добавь теги..."
                                    name="tags"
                                    isMulti
                                    options={tags.map((el: any, i: number) => ({
                                        value: i,
                                        label: el,
                                    }))}
                                />
                            </fieldset>
                            <div className="form-experts-slider_button-wrapper">
                                <AppButton disabled={disabled} onClick={next}>
                                    Далее
                                </AppButton>
                                <p onClick={prev}>Вернуться назад</p>
                            </div>
                        </div>
                    )}
                </Swiper>
            </SmartForm>
            <ModalWindow isOpened={isOpened} setIsOpened={setIsOpened}>
                <div>{modalText}</div>
                <AppButton onClick={closeModal}>Ок</AppButton>
            </ModalWindow>
        </div>
    ) : null
}
