import React, { SyntheticEvent, useEffect, useState } from 'react'
import { AdminPageLayout } from '../../../../ui/adminPageLayout/layout'
import { IAdminPanelPropsType } from '../../../../model/types'
import { useSmartForm } from '../../../../../../services/formProcessing'
import { useList } from '../../../../../../shared/hooks/useList/useList'
import { IMerchandiseCatalogGet } from '../../model/types'
import { shopApi } from '../../api'
import { SmartForm } from '../../../../../../services/formProcessing/ui/SmartForm'
import { TextField } from '../../../../../../shared/ui/TextField'
import { AppButton } from '../../../../../../shared/ui/Button'

import s from './shopCatalog.module.scss'
import { Pagination } from '../../../../../../widgets/pagination/ui/Pagination'
import clsx from 'clsx'
import { Icons } from '../../../../../../shared/icons'
import { MerchandiseForm } from '../../ui/shopForm'
import { ModalWindow } from '../../../../../../shared/ui/ModalWindow'
import { StatusType } from '../../../faq/model/types'
import { useMerchandiseById } from '../../api/queries'

interface IColumn {
    label: string
    order: 'asc' | 'desc' | 'default'
    sort: keyof IMerchandiseCatalogGet
}

interface IRow extends IMerchandiseCatalogGet {
    checked: boolean
}

const COLUMNS: IColumn[] = [
    { label: 'ID', order: 'default', sort: 'id' },
    { label: 'Активность', order: 'default', sort: 'isEnabled' },
    { label: 'Сортировка', order: 'default', sort: 'priority' },
    { label: 'Название', order: 'default', sort: 'name' },
    { label: 'Размер', order: 'default', sort: 'variants' },
    { label: 'Стоимость', order: 'default', sort: 'cost' },
]

export const ShopCatalog: React.FC<IAdminPanelPropsType> = ({ title }) => {
    const [limit, setLimit] = useState(10)
    const [isCreateMerchandise, setIsCreateMerchandise] = useState(false)
    const [selectedId, setSelectedId] = useState<{ id: number }[]>([])
    const [columns, setColumns] = useState<IColumn[]>(COLUMNS)
    const [rows, setRows] = useState<IRow[]>([])
    const isRowActive = (id: number) => selectedId.find((el) => el.id === id)
    const [isOpened, setIsOpened] = useState(false)
    const [status, setStatus] = useState<StatusType>('add')
    const form = useSmartForm({
        defaultValues: {
            q: '',
            limit: { value: limit, label: limit },
        },
    })
    const {
        items: merchandiseCatalog,
        setFilters,
        nextPage,
        prevPage,
        total,
        page,
        setPage,
        reset,
    } = useList<IMerchandiseCatalogGet>({
        key: 'merchandiseRequests',
        apiMethod: (params) => shopApi.getMerchandise(params),
        defaultLimit: limit,
    })

    const onPrev = (evt: SyntheticEvent) => {
        evt.preventDefault()
        prevPage()
    }
    const onNext = (evt: SyntheticEvent) => {
        evt.preventDefault()
        nextPage()
    }
    const handleChangeLimit = ({ value }: any) => {
        setLimit(value)
        setFilters({ limit: value })
        form.setValue('q', '')
    }

    const removeMerchandise = async () => {
        const ids = selectedId.map((el) => el.id)
        try {
            await shopApi.deleteMerchandise({ ids })
            setIsOpened(false)
            setSelectedId([])
            reset()
        } catch (e) {
            console.log(e)
            alert('Something went wrong')
        }
    }

    const toggleCheckbox = (value: IRow) => {
        setRows((rows) =>
            rows.map((el) =>
                el.id === value.id ? { ...el, checked: !el.checked } : el
            )
        )
        if (selectedId.find((el) => el.id === value.id)) {
            setSelectedId((item) => item.filter(({ id }) => id !== value.id))
        } else {
            setSelectedId([...selectedId, { id: value.id }])
        }
    }

    const selectAllCheckbox = () => {
        if (selectedId.length === rows.length) {
            setRows((rows) => rows.map((el) => ({ ...el, checked: false })))
            setSelectedId([])
        } else {
            setRows((rows) => rows.map((el) => ({ ...el, checked: true })))
            setSelectedId(rows.map((el) => ({ id: el.id })))
        }
    }

    const sortTable = (column: IColumn) => {
        setFilters({
            ...form.getValues(),
            limit,
            sort: column.sort,
            order: column.order === 'asc' ? 'desc' : 'asc',
        })
        setColumns((columns) =>
            columns.map((el) =>
                el.sort === column.sort
                    ? { ...el, order: el.order === 'asc' ? 'desc' : 'asc' }
                    : { ...el, order: 'default' }
            )
        )
    }

    const handleClickAdd = () => {
        setIsCreateMerchandise(true)
        setStatus('add')
    }

    const handleClickEdit = () => {
        setStatus('edit')
        setIsCreateMerchandise(true)
    }

    const onFiltersApply = (data: any) => {
        setFilters({ ...data, limit, q: data.q })
    }

    useEffect(() => {
        setRows(merchandiseCatalog.map((el) => ({ ...el, checked: false })))
    }, [merchandiseCatalog, isCreateMerchandise])

    const MerchandiseChangeForm = () => {
        const { data, isFetching } = useMerchandiseById(selectedId[0]?.id)
        if (isFetching) return null
        return (
            <MerchandiseForm
                status={status}
                merchandise={data!}
                setIsCreateMerchandise={setIsCreateMerchandise}
                setSelectedId={setSelectedId}
                reset={reset}
            />
        )
    }

    const MerchandiseCreateForm = () => {
        return (
            <MerchandiseForm
                status={status}
                setIsCreateMerchandise={setIsCreateMerchandise}
                setSelectedId={setSelectedId}
                reset={reset}
            />
        )
    }

    if (isCreateMerchandise && status === 'edit')
        return <MerchandiseChangeForm />

    if (isCreateMerchandise && status === 'add')
        return <MerchandiseCreateForm />

    return (
        <AdminPageLayout title={title}>
            <SmartForm form={form} submit={onFiltersApply}>
                <div className={s.shopRequestWrapper}>
                    <div className={s.controls}>
                        <TextField
                            className={s.search_field}
                            name="q"
                            placeholder="Поиск"
                        ></TextField>
                        <AppButton variant="ghost" className={s.search_button}>
                            Найти
                        </AppButton>
                        <AppButton
                            className={s.add_button}
                            onClick={handleClickAdd}
                        >
                            Добавить{'\u00A0'}элемент
                        </AppButton>
                    </div>
                    <div className={s.tableWrapper}>
                        <table className={s.table}>
                            <thead>
                                <tr>
                                    <th>
                                        <input
                                            type="checkbox"
                                            onChange={selectAllCheckbox}
                                        />
                                    </th>
                                    {columns.map((el) => (
                                        <th key={el.label}>
                                            <span
                                                onClick={() =>
                                                    el.label !== 'Размер' &&
                                                    sortTable(el)
                                                }
                                            >
                                                {el.label}
                                                {el.label !== 'Размер' && (
                                                    <Icons.M.ChevronUp
                                                        className={clsx(
                                                            s.sort,
                                                            {
                                                                [s.sort_asc]:
                                                                    el.order ===
                                                                    'asc',
                                                            }
                                                        )}
                                                    />
                                                )}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map((el, index) => (
                                    <tr
                                        key={index}
                                        className={clsx(s.row, {
                                            [s.row_active]: isRowActive(el.id),
                                        })}
                                    >
                                        <td>
                                            <input
                                                type="checkbox"
                                                onChange={() =>
                                                    toggleCheckbox(el)
                                                }
                                                checked={el.checked}
                                            />
                                        </td>
                                        <td>{el.id}</td>
                                        <td>
                                            {el.isEnabled && 'Да'}
                                            {!el.isEnabled && 'Нет'}
                                        </td>
                                        <td>{el.priority}</td>
                                        <td>{el.name}</td>
                                        <td>
                                            {el.variants
                                                ? el.variants.join(', ')
                                                : ''}
                                        </td>
                                        <td>{el.cost} баллов</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className={s.footer}>
                    <div className={s.footer_buttons}>
                        {selectedId.length === 1 && (
                            <div
                                className={clsx(s.footer_buttons_icon, s.edit)}
                                onClick={handleClickEdit}
                            >
                                <Icons.M.Edit />
                                Редактировать
                            </div>
                        )}
                        {selectedId.length >= 1 && (
                            <div
                                className={clsx(
                                    s.footer_buttons_icon,
                                    s.remove
                                )}
                                onClick={() => {
                                    setIsOpened(true)
                                }}
                            >
                                <Icons.M.Cancelled />
                                Удалить
                            </div>
                        )}
                    </div>
                    <Pagination
                        page={page}
                        total={total}
                        limit={limit}
                        handleChangeLimit={handleChangeLimit}
                        onNext={onNext}
                        onPrev={onPrev}
                        setPage={setPage}
                    />
                </div>
            </SmartForm>

            <ModalWindow isOpened={isOpened} setIsOpened={setIsOpened}>
                <div className={s.modal}>
                    <p className={s.modal_title}>
                        Ты уверен, что хочешь удалить выбранные элементы?
                    </p>
                    <div className={s.modal_controls}>
                        <AppButton onClick={removeMerchandise}>
                            Да, удалить
                        </AppButton>
                        <AppButton
                            onClick={() => setIsOpened(false)}
                            variant="ghost"
                        >
                            Нет, оставить
                        </AppButton>
                    </div>
                </div>
            </ModalWindow>
        </AdminPageLayout>
    )
}
