import {IGetTag, IGetTags, IPatchTag, IPostTag, ISearchParams} from "../model";
import {adminApiInstance} from "../../../../../shared/api";


export const tagApi = {
    getTags: (params: ISearchParams) => adminApiInstance.get<IGetTags>(`/tags`, {params}),
    getTag: (id: number) => adminApiInstance.get<IGetTag>(`/tags/${id}`),
    deleteTags: (ids: number[]) => adminApiInstance.delete(`/tags`, {data: {ids}}),
    deleteTag: (id: number) => adminApiInstance.delete(`/tags/${id}`),
    postTag: (body: IPostTag) => adminApiInstance.post(`/tags`, body),
    patchTag: (body: IPatchTag, id: number) => adminApiInstance.patch(`/tags/${id}`, body)
}
