import React, { useState } from 'react'
import clsx from 'clsx'
import { Icons } from '../../../../shared/icons'
import { useModalWindow } from '../../../../shared/hooks/useModalWindow'
import { ApplicationApprovedModal } from '../modals/applicationApproved'
import { ReasonForRejectionModal } from '../modals/reasonForRejection'
import { applicationApi } from '../../api'
import { ApplicationType, StatusOption } from '../../types'
import { UseFormRegister } from 'react-hook-form'

import s from './applicationCard.module.scss'
import { ApplicationCompleted } from '../modals/applicationCompleted'

type ApplicationCardProps = {
    applications: ApplicationType[]
    isFormingNewGroup?: boolean
    refetch?: () => void
    hookFormRegister?: UseFormRegister<any>
    updateItem?: (id: string | number, data: any) => void
    hookformPrefix?: string
}

const statusOptionsForPending: StatusOption[] = [
    {
        label: 'Принять заявку',
        value: 'accepted',
    },
    {
        label: 'Отклонить',
        value: 'rejected' || 'failed',
    },
]

const statusOptionsForAccepted: StatusOption[] = [
    {
        label: 'Завершить',
        value: 'completed',
    },
    {
        label: 'Отклонить',
        value: 'rejected' || 'failed',
    },
]

export const ApplicationList = ({
    applications,
    isFormingNewGroup,
    hookFormRegister,
    updateItem,
    hookformPrefix = '',
}: ApplicationCardProps) => {
    const applicationApprovedModal = useModalWindow()
    const reasonForRejectionModal = useModalWindow()
    const applicationCompleteModal = useModalWindow()

    const [chosenApplication, setChosenApplication] = useState<number | null>(
        null
    )
    const [type, setType] = useState<string | null>()

    const [activeDropdown, setActiveDropdown] = useState(-1)

    const changeApplicationStatus = async (newValue: string, id: number) => {
        switch (newValue) {
            case 'accepted':
                try {
                    const { data } =
                        await applicationApi.putChangeApplicationState(id, {
                            reason: '',
                            state: 'accepted',
                        })

                    const chosenApp = applications.find(
                        (item) => item.id === id
                    )
                    setType(chosenApp!.type)

                    if (updateItem) {
                        updateItem(data.application.id, data.application)
                    }
                    applicationApprovedModal.toggleOpen()
                } catch (e) {
                    console.log(e)
                    alert('Something went wrong')
                }
                break
            case 'completed':
                setChosenApplication(id)
                applicationCompleteModal.toggleOpen()
                break
            case 'rejected':
                setChosenApplication(id)
                reasonForRejectionModal.toggleOpen()
                break
        }
    }

    const toggleDropdown = (index: number) => {
        setActiveDropdown((prevIndex) => (prevIndex === index ? -1 : index))
    }

    return (
        <>
            {applications.map((application) => {
                const {
                    fullName,
                    id,
                    state,
                    email,
                    staff,
                    description,
                    createdAt,
                } = application
                const created = new Date(createdAt).toLocaleDateString(
                    'ru-RU',
                    {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    }
                )

                return (
                    <label
                        className={s.applicationItem}
                        key={id}
                        htmlFor={`${hookformPrefix}_application${id}`}
                    >
                        <div
                            className={clsx(s.applicationInfo, {
                                [s.reverse]:
                                    isFormingNewGroup && state === 'accepted',
                            })}
                        >
                            {hookFormRegister &&
                                isFormingNewGroup &&
                                state === 'accepted' && (
                                    <div className={s.applicationCheckbox}>
                                        <input
                                            type="checkbox"
                                            value={id}
                                            id={`${hookformPrefix}_application${id}`}
                                            {...hookFormRegister(
                                                'selectedApplications'
                                            )}
                                        />
                                    </div>
                                )}
                            <div>
                                <p className={s.name}>{fullName}</p>
                                <p className={s.email}>{email}</p>
                                {staff && (
                                    <a
                                        target="blank"
                                        href={staff}
                                        className={s.staff}
                                    >
                                        Staff
                                    </a>
                                )}
                            </div>
                        </div>

                        <p className={s.date}>{created}</p>

                        <div className={s.applicationDescription}>
                            <p>{description}</p>
                        </div>

                        <div className={s.applicationStatus}>
                            <div
                                className={s.dropdown}
                                onClick={() => toggleDropdown(id)}
                            >
                                <div className={s.statusInfo}>
                                    <div
                                        className={clsx([
                                            s.statusCircle,
                                            {
                                                [s.yellow]:
                                                    state === 'accepted',
                                                [s.red]:
                                                    state === 'rejected' ||
                                                    state === 'failed',
                                                [s.green]:
                                                    state === 'completed',
                                            },
                                        ])}
                                    />
                                    <p className={s.status}>
                                        {state === 'accepted' && 'Принята'}
                                        {state === 'completed' && 'Завершено'}
                                        {state === 'pending' &&
                                            'Ожидает ответа'}
                                        {(state === 'rejected' ||
                                            state === 'failed') &&
                                            'Отклонена'}
                                    </p>
                                </div>
                                <div className={s.chevron}>
                                    <Icons.M.ChevronUp />
                                </div>

                                {activeDropdown === id &&
                                    state !== 'completed' &&
                                    state !== 'failed' &&
                                    state !== 'rejected' && (
                                        <div className={s.dropdownContent}>
                                            {(state == 'pending'
                                                ? statusOptionsForPending
                                                : statusOptionsForAccepted
                                            ).map(({ label, value }, i) => (
                                                <label
                                                    className={s.dropdownItem}
                                                    key={i}
                                                    onClick={() => {
                                                        changeApplicationStatus(
                                                            value,
                                                            id
                                                        )
                                                    }}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        className={s.checkbox}
                                                        checked={
                                                            state === value
                                                        }
                                                    />
                                                    {label}
                                                </label>
                                            ))}
                                        </div>
                                    )}
                            </div>
                        </div>
                    </label>
                )
            })}

            <ApplicationApprovedModal
                props={applicationApprovedModal}
                type={type!}
            />

            <ApplicationCompleted
                props={applicationCompleteModal}
                updateItem={updateItem!}
                id={chosenApplication!}
            />
            {chosenApplication && updateItem && (
                <ReasonForRejectionModal
                    updateItem={updateItem}
                    props={reasonForRejectionModal}
                    id={chosenApplication}
                />
            )}
        </>
    )
}
