import { configureStore } from '@reduxjs/toolkit'
import userSlice from '../../entities/user/user/slice'
import { errorsSlice } from '../../services/formProcessing'
import { profileReducer } from '../../entities/profile'
import { promoCatalogReducer } from '../../entities/promoCatalog'
import { applicationReducer } from '../../entities/application/model/slice'
import { programReducer } from '../../entities/programm/model/slice'
import { reviewsReducer } from '../../entities/review/model/slice'
import { marketReducer } from '../../entities/market/model/slice'

export const store = configureStore({
    reducer: {
        userSlice,
        errorsSlice: errorsSlice.reducer,
        profile: profileReducer,
        promoCatalog: promoCatalogReducer,
        applications: applicationReducer,
        programs: programReducer,
        reviews: reviewsReducer,
        expertMarket: marketReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
