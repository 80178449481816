import React, {SyntheticEvent} from 'react';
import {IAddTagForm, IEditTagForm, IGetTag, ITagForm} from "../../model";
import {useDeleteTag, useGetTag, usePatchTag, usePostTag} from "../../api/queries";
import s from '../../../faq/ui/faqForm/index.module.scss'
import {useSmartForm} from "../../../../../../services/formProcessing";
import {SmartForm} from "../../../../../../services/formProcessing/ui/SmartForm";
import {SmartCheckbox} from "../../../../../../shared/ui/Checkbox";
import {TextField} from "../../../../../../shared/ui/TextField";
import {AppButton} from "../../../../../../shared/ui/Button";
import {AppLink} from "../../../../../../shared/ui/Link";
import moment from "moment";
import {getISOString} from "../../../../../../shared/helpers/getISOString";

export const TagForm: React.FC<ITagForm> = ({id, status, setStatus, data, reset}) => {
    if (status === 'add') return <AddTagForm status={status} setStatus={setStatus} reset={reset}/>
    if (status === 'edit' && id) return <EditTagForm id={id} status={status} setStatus={setStatus} reset={reset}/>
    return null
}

const AddTagForm: React.FC<IAddTagForm> = ({status, setStatus, reset}) => {
    return (
        <Form
            status={status}
            setStatus={setStatus}
            reset={reset}
        />
    )
}

const EditTagForm: React.FC<IEditTagForm> = ({id, status, setStatus, reset}) => {
    const {data, isFetching} = useGetTag(id)
    if (isFetching) return null
    return (
        <Form
            id={id}
            data={data}
            status={status}
            setStatus={setStatus}
            reset={reset}
        />
    )
}

const Form: React.FC<ITagForm> = ({id, status, setStatus, data, reset}) => {
    const {mutate: del} = useDeleteTag(reset)
    const {mutate: post} = usePostTag(reset)
    const {mutate: patch} = usePatchTag(reset)

    const form = useSmartForm<IGetTag & {isDelete: boolean}>({
        defaultValues: {
            ...data,
            enabledUntil: moment(data?.enabledUntil).format('YYYY-MM-DD'),
            enabledSince: moment(data?.enabledSince).format('YYYY-MM-DD'),
        }
    })
    const handleClickCancel = (evt: SyntheticEvent) => {
        evt.preventDefault()
        setStatus(null)
    }
    const onSubmit = (data: IGetTag & {isDelete: boolean}) => {
        const value = {
            ...data,
            priority: Number(data.priority),
            enabledSince: getISOString(data.enabledSince),
            enabledUntil: getISOString(data.enabledUntil),
        }
        if (status === 'add') post(value)
        if (status === 'edit') data.isDelete
            ? del(value.id)
            : patch({data: {...value, clearEnabledUntil: false}, id: value.id})
        setStatus(null)
    }


    return (
        <div className={s.wrapper}>
            <SmartForm form={form} submit={form.handleSubmit(onSubmit)}>
                {id && <p className={s.h4}>ID: {id}</p>}
                <div className={s.checkbox}>
                    <SmartCheckbox name='isEnabled' label={'Активность'}/>
                </div>
                <TextField
                    label="Сортировка"
                    placeholder="500"
                    name="priority"
                    type="number"
                />
                <div className={s.date_wrapper}>
                    <TextField
                        className={s.date}
                        label="Начало активности"
                        placeholder="500"
                        name="enabledSince"
                        type="date"
                    />
                    <TextField
                        className={s.date}
                        label="Окончание активности"
                        placeholder="500"
                        name="enabledUntil"
                        type="date"
                    />
                </div>
                <TextField
                        label="Название"
                        placeholder="Область экспертизы"
                        name="tag"
                    />
                {
                    status === 'edit' &&
                        <div className={s.checkbox}>
                        <SmartCheckbox
                            name='isDelete'
                            label={'Удалить вопрос без возможности восстановления'}
                        />
                        </div>
                }
                <div className={s.buttons}>
                    <AppButton>{status === 'edit' ? 'Сохранить изменения' : 'Создать'}</AppButton>
                    <AppLink onClick={handleClickCancel} to={''}>Отменить</AppLink>
                </div>
            </SmartForm>
        </div>
    )
}
