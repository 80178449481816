import React from 'react'
import {
    FillingProfileFirstStep,
    FillingProfileSecondStep,
    FillingProfileThirdStep,
    ProfileUnderReview,
} from '../../../widgets/fillingProfile'
import { useSelector } from 'react-redux'
import { profileSelectors } from '../model/selectors'
import { ExpertProfile } from './expertProfile/ExpertProfile'
import { useFetchProfile, useFetchUser } from '../api/queries'
import { ProfileDisabled } from '../../../widgets/fillingProfile/ui/profileDisabled'

export const MyProfile = () => {
    const { data: user } = useFetchUser()
    const { isPending, data: profile } = useFetchProfile()
    const step = useSelector(profileSelectors.step)

    if (isPending) {
        return <div>Loading...</div>
    }

    if (step) {
        switch (step) {
            case 1:
                return <FillingProfileFirstStep />
            case 2:
                return <FillingProfileSecondStep />
            case 3:
                return <FillingProfileThirdStep />
        }
    }
    if (!profile?.isEnabled) return <ProfileDisabled />

    switch (user?.expertState) {
        case 'onModeration':
            return <ProfileUnderReview />
        case 'active':
            return <ExpertProfile />
        default:
            return null
    }
}
