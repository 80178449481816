import React, { useState } from 'react'
import { Switcher } from '../../../../shared/ui/Switcher'
import { SmartSelect } from '../../../../shared/ui/Select'
import { SmartForm } from '../../../../services/formProcessing/ui/SmartForm'
import { useSmartForm } from '../../../../services/formProcessing'
import {
    applicationApi,
    ApplicationListWithInfoRow,
    ProgramApplicationCard,
} from '../../../../entities/application'
import { useList } from '../../../../shared/hooks/useList/useList'

import s from './applicationListWrapper.module.scss'
import {
    ApplicationType,
    programApplicationsType,
    StatusOption,
} from '../../../../entities/application/types'
import { SingleValue } from 'react-select'
import { useQueryClient } from '@tanstack/react-query'
import { IProfileExpert } from '../../../../entities/profile'

const optionsForSmartSelect: StatusOption[] = [
    {
        value: 'pending',
        label: 'сначала необработанные',
    },
    {
        value: 'recent',
        label: 'по новизне',
    },
    {
        value: 'completed',
        label: 'сначала завершенные',
    },
]

export const ApplicationListWrapper = () => {
    const form = useSmartForm()
    const queryClient = useQueryClient()
    const profile = queryClient.getQueryData<IProfileExpert>(['profile'])

    const getActiveRoles = () => {
        const activeRoles = []
        if (profile?.roles) {
            if (profile.roles['coach'] === 'active') {
                activeRoles.push('programs')
            }
            if (profile.roles['mentor'] === 'active') {
                activeRoles.push('mentor')
            }
            if (profile.roles['consultant'] === 'active') {
                activeRoles.push('consultant')
            }
        }
        return activeRoles
    }

    const [switcherValue, setSwitcherValue] = useState<string>(getActiveRoles()[0])

    const {
        items: applications,
        total,
        page,
        showMore,
        setFilters,
        reset,
        updateItem,
    } = useList<ApplicationType & programApplicationsType>({
        key: ['applications', switcherValue],
        apiMethod: (params) =>
            applicationApi.getApplications(switcherValue, params),
        defaultLimit: 6,
    })

    const generateSwitcherOptions = () => {
        const activeRoles = getActiveRoles()
        let options = activeRoles.map((role) => {
            let roleText = ''
            switch (role) {
                case 'programs':
                    roleText = 'Программы'
                    break
                case 'mentor':
                    roleText = 'Менторство'
                    break
                case 'consultant':
                    roleText = 'Консультации'
                    break
                default:
                    roleText = role
            }
            return {
                value: role,
                text: roleText,
            }
        })

        if (activeRoles.length === 1) {
            options = []
        }
        return options
    }

    const optionsForSwitcher = generateSwitcherOptions()

    const onFiltersApply = (newValue: SingleValue<StatusOption>) => {
        setFilters({ sort: newValue!.value })
    }

    const onSwitchListType = (value: string) => {
        setSwitcherValue(value)
        reset()
    }

    return (
        <div className={s.applicationListWrapper}>
            {optionsForSwitcher.length >= 2 && (
                <div className={s.switcherWrapper}>
                    <Switcher
                        options={optionsForSwitcher}
                        setValue={onSwitchListType}
                        value={switcherValue}
                    />
                </div>
            )}

            {switcherValue === 'programs' ? (
                <ProgramApplicationCard
                    applications={applications}
                    showMore={showMore}
                    total={total}
                    page={page}
                />
            ) : (
                <>
                    <SmartForm
                        form={form}
                        submit={() => {}}
                        className={s.smartSelect}
                    >
                        <SmartSelect
                            placeholder="Сортировать"
                            name="sorting"
                            options={optionsForSmartSelect}
                            onChange={onFiltersApply}
                        />
                    </SmartForm>

                    <ApplicationListWithInfoRow
                        applications={applications}
                        total={total}
                        page={page}
                        showMore={showMore}
                        refetch={reset}
                        updateItem={updateItem}
                    />
                </>
            )}
        </div>
    )
}
