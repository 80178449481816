import { adminApiInstance } from '../../../../../../../shared/api'
import {
    IReviewPatch,
    IReviewsListGet,
    IReviewsParams,
    IReviewPost,
} from '../model/types'

export const reviewsApi = {
    getPromoReviews: (params: IReviewsParams) =>
        adminApiInstance.get<IReviewsListGet>(`promo-reviews`, { params }),
    postCreatePromoReview: (params: IReviewPost) =>
        adminApiInstance.post(`promo-reviews`, params),
    deletePromoReviews: (data: { ids: number[] }) =>
        adminApiInstance.delete(`promo-reviews`, { data }),
    uploadPreviewPhoto: (photo: FormData) =>
        adminApiInstance.post(`promo-reviews/preview`, photo),
    uploadVideoPreview: (video: FormData) =>
        adminApiInstance.post(`promo-reviews/video`, video),
    getPromoReviewById: (id: number) =>
        adminApiInstance.get<IReviewPatch>(`promo-reviews/${id}`),
    patchPromoReviews: (id: number, data: IReviewPatch) =>
        adminApiInstance.patch<IReviewPatch>(`promo-reviews/${id}`, data),
}
