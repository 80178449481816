import React from 'react'
import { Typography } from '../../../../shared/ui/Typography'
import { AppButton } from '../../../../shared/ui/Button'
import {
    ModalWindow,
    ModalWindowPropsType,
} from '../../../../shared/ui/ModalWindow'
import { GroupType } from '../../../../entities/application/types'
import { useMutation } from '@tanstack/react-query'
import { applicationApi } from '../../../../entities/application'
import { css } from '@emotion/react'
import { ApplicationCardForUnify } from '../../../../entities/application/ui/applicationCardForUnfiy'
import { SmartForm } from '../../../../services/formProcessing/ui/SmartForm'
import { useForm } from 'react-hook-form'

type PropsType = {
    group: GroupType
    selectApplications?: (id: number) => void
    onSuccess: (ids: number[]) => void
}

type FormType = {
    applicationsToSuccess: string[]
}

export const SuccessStudentsModal = ({
    group,
    onSuccess,
    ...props
}: ModalWindowPropsType & PropsType) => {
    const form = useForm<FormType>({
        defaultValues: {
            applicationsToSuccess: [],
        },
    })

    const { mutate: unifyApplications } = useMutation({
        mutationFn: async (applications: string | string[]) => {
            const completedIds = (
                typeof applications === 'string' ? [applications] : applications
            ).map((s) => Number(s))

            onSuccess(completedIds)
        },
    })

    const handleUnify = () => {
        const { applicationsToSuccess } = form.getValues()

        unifyApplications(applicationsToSuccess)
        props.setIsOpened(false)
    }

    return (
        <ModalWindow {...props}>
            <div
                css={css`
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 20px;
                `}
            >
                <Typography center as="h4">
                    Отметь учеников из группы, <br /> которые прошли обучение
                </Typography>
                <Typography center as="t1">
                    Каждый ученик, который завершил программу, получит
                    уведомление <br />и сможет оставить тебе отзыв
                </Typography>
                <SmartForm
                    css={css`
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 12px;
                    `}
                    submit={() => {}}
                    form={form}
                >
                    {group.applications.map((item) => (
                        <ApplicationCardForUnify
                            name="applicationsToSuccess"
                            key={item.id}
                            application={item}
                        />
                    ))}
                </SmartForm>
                <AppButton onClick={handleUnify}>Отправить</AppButton>
            </div>
        </ModalWindow>
    )
}
