import React from 'react'
import s from './card.module.scss'
import { IPromoCatalogItem } from '../../../../../../entities/promoCatalog/model/types'
import { css } from '@emotion/react'
import { Tag } from '../../../../../../shared/ui/Tag'

type PropsType = {
    item: IPromoCatalogItem
} & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
>

export const Card: React.FC<PropsType> = ({ item, ...htmlProps }) => {
    const { title, description, photo, link, titleLink, type, logo } = item
    const tagType = { ...type }
    const array: [string, boolean][] = Object.entries(tagType)
    const tags: string[] = []
    if (!array.length) tags.push('Для всех')
    array.forEach(([key, value]) => {
        if (value) {
            switch (key) {
                case 'consultant':
                    tags.push('Для консультантов')
                    break
                case 'mentor':
                    tags.push('Для менторов')
                    break
                case 'program':
                    tags.push('Для тренеров')
                    break
                default:
                    break
            }
        }
    })

    return (
        <article className={s.Container} {...htmlProps}>
            <div className={s.LabelsWrapper}>
                <div className={s.TagsWrapper}>
                    {tags.map((tag, index) => {
                        return (
                            <Tag
                                key={index}
                                css={css`
                                    color: #fff;
                                    background-color: #005bff;
                                `}
                            >
                                {tag}
                            </Tag>
                        )
                    })}
                </div>
            </div>
            {photo && <img className={s.Image} src={photo} alt={''} />}
            {logo && <img className={s.Logo} src={logo} alt="" />}
            <div className={s.ContentWrapper}>
                <div className={s.Content}>
                    <p className={s.Title}>{title}</p>

                    <p className={s.DescriptionWrapper}>{description}</p>
                </div>
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                        gap: 4px;
                    `}
                >
                    {link && titleLink && (
                        <a
                            href={link}
                            target="_blank"
                            rel="noreferrer"
                            className={s.Link}
                        >
                            <span>{titleLink}</span>
                            <svg
                                width="21"
                                height="20"
                                viewBox="0 0 21 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M11.9087 4.41107C12.2275 4.08563 12.7444 4.08563 13.0633 4.41107L18.5391 10.0003L13.0633 15.5896C12.7444 15.915 12.2275 15.915 11.9087 15.5896C11.5898 15.2641 11.5898 14.7365 11.9087 14.4111L15.4135 10.8337H2.68886C2.23796 10.8337 1.87244 10.4606 1.87244 10.0003C1.87244 9.54009 2.23796 9.16699 2.68886 9.16699H15.4135L11.9087 5.58958C11.5898 5.26414 11.5898 4.73651 11.9087 4.41107Z"
                                ></path>
                            </svg>
                        </a>
                    )}
                </div>
            </div>
        </article>
    )
}
