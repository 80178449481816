import { useQuery } from '@tanstack/react-query'
;('../model/types')
import { IMerchandisePatch } from '../model/types'
import { shopApi } from './index'

export const useMerchandiseById = (id: number) => {
    return useQuery<IMerchandisePatch>({
        queryKey: ['merchandise'],
        queryFn: async () => {
            const response = await shopApi.getMerchandiseById(id)
            return response.data
        },
    })
}
