import React, { useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import ISwiper from 'swiper'
import { Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import styled from '@emotion/styled'
import { Icons } from '../../../shared/icons'
import { ReviewCard } from '../../../features/reviewCard'
import { theme } from '../../../shared/styles/theme'
import './index.css'
import { useList } from '../../../shared/hooks/useList/useList'
import { IPromoReviewsGet } from '../../../entities/review/model/types'
import { reviewsApi } from '../../../entities/review/api'

const H2Styles = styled.h2`
    font-size: 36px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.01em;
    color: ${theme.color.darkSpace[700]};
    max-width: 400px;

    @media (min-width: 768px) {
        font-size: 52px;
    }

    @media (min-width: 1100px) {
        max-width: 100%;
    }

    @media (min-width: 1280px) {
        font-size: 60px;
        max-width: 100%;
    }
`

export const Reviews = () => {
    const reviewsSwiperRef = useRef<ISwiper>()
    const containerRef = useRef<HTMLDivElement | null>(null)
    const collectedCallbacksToRunOnObserve = useRef<Array<() => void>>([])
    const { items: reviews } = useList<IPromoReviewsGet>({
        apiMethod: reviewsApi.getPromoReviews,
        key: ['PromoReviews'],
    })
    const sliderSettings = {
        320: {
            slidesPerView: 1,
            spaceBetween: 16,
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 16,
        },
        1024: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        1440: {
            slidesPerView: 4,
            spaceBetween: 20,
        },
    }

    useEffect(() => {
        // We can do it when it is mounted because that effect will be called when all children are mounted
        const observer = new IntersectionObserver(
            (e: IntersectionObserverEntry[]) => {
                if (e?.[0].isIntersecting) {
                    collectedCallbacksToRunOnObserve.current.forEach((cb) =>
                        cb()
                    )
                }
            },
            {
                root: window.document,
                rootMargin: '0px',
                threshold: 1.0,
            }
        )
        observer.observe(containerRef.current as Element)

        return () => {
            observer.disconnect()
        }
    }, [])

    const addCallbackToObserver = (cb: () => void) => {
        collectedCallbacksToRunOnObserve.current.push(cb)
    }

    return (
        <div ref={containerRef} className="ReviewsBlock">
            <div className="ReviewsTitleSection">
                <div className="ReviewsTitleWrapper">
                    <H2Styles>Что коллеги говорят об Expertplace?</H2Styles>
                    <div className="ReviewsSliderButtonsWrapper">
                        {/* //TODO hover? animation? */}
                        <button
                            className="ReviewsSliderButtons ReviewsSliderButtons-prev"
                            aria-label="Prev slide"
                        >
                            <Icons.S.ChevronLeft></Icons.S.ChevronLeft>
                        </button>
                        <button
                            className="ReviewsSliderButtons ReviewsSliderButtons-next"
                            aria-label="Next slide"
                        >
                            <Icons.S.ChevronRight></Icons.S.ChevronRight>
                        </button>
                    </div>
                </div>
            </div>
            <Swiper
                breakpoints={sliderSettings}
                modules={[Navigation]}
                navigation={{
                    nextEl: '.ReviewsSliderButtons-next',
                    prevEl: '.ReviewsSliderButtons-prev',
                    disabledClass: 'ReviewsSliderButtons-disabled',
                }}
                className="ReviewsSlider"
                onSwiper={(swiper: ISwiper) => {
                    reviewsSwiperRef.current = swiper
                }}
            >
                {reviews.map((item) => (
                    <SwiperSlide key={item.id}>
                        <ReviewCard
                            addCallbackToObserver={addCallbackToObserver}
                            item={item}
                        ></ReviewCard>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}
