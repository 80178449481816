import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

export type UserSliceType = {
    user?: {
        name: number
    }
}

const initialState: UserSliceType = {}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, { payload }: PayloadAction<UserSliceType['user']>) => {
            state.user = payload
        },
    },
})

export const userSliceActions = userSlice.actions
export const userSliceSelectors = {
    user: (store: RootState) => store.userSlice.user,
}

export default userSlice.reducer
