import { Header } from '../Header'
import React, { PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import { theme } from '../../styles/theme'
import { Footer } from '../Footer'

const LayoutStyles = styled.div`
    background-color: ${theme.color.cloud};
    overflow: hidden;
`

export const Layout = ({
    children,
    ...htmlProps
}: PropsWithChildren &
    React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    >) => {
    return (
        <LayoutStyles {...htmlProps}>
            <Header />
            {children}
            <Footer />
        </LayoutStyles>
    )
}
