import { Avatar } from '../../../shared/ui/Avatar'
import { UserType } from '../types'

import s from './userInfo.module.scss'

type PropsType = {
    user: UserType
}

export const UserInfo = ({ user }: PropsType) => {
    return (
        <div className={s.personalInfo}>
            <Avatar className={s.personalInfo__avatar} />
            <div className={s.personalInfo__info}>
                <p className={s.personalInfo__name}>{user.username}</p>
                <p className={s.personalInfo__post}>{user.post}</p>
            </div>
        </div>
    )
}
