import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {profileApi} from "./index";
import {IProfileExpert, IUser} from "../model/types";

const key = 'profile'

export const useFetchProfile = () => {
    return useQuery<IProfileExpert>({
        queryKey: [key],
        queryFn: async () => {
            const response = await profileApi.getExpertProfile()
            return response.data
        },
    })
}

export const usePatchProfile = (successFn?: () => void, errorFn?: () => void) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [key],
        mutationFn: async (data: IProfileExpert) => {
            return profileApi.patchExpertProfile(data)
        },
        onSuccess: (data) => {
            queryClient.setQueryData([key], {...data.data})
            successFn?.()
        },
        onError: (data: any) => {
            const error  = data.response.data.error
            alert(error)
            errorFn?.()
        }
    })
}

export const useFetchUser = () => {
    return useQuery<IUser>({
        queryKey: ['user'],
        queryFn: async () => {
            const response = await profileApi.getSelf()
            return response.data
        }
    })
}
