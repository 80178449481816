import React, { SyntheticEvent, useState } from 'react'
import moment from 'moment/moment'
import { IProgramGet, IProgramImages, IProgramsPost } from '../model/types'
import { StatusType } from '../../faq/model/types'
import { useSmartForm } from '../../../../../services/formProcessing'
import { SmartForm } from '../../../../../services/formProcessing/ui/SmartForm'
import { SmartCheckbox } from '../../../../../shared/ui/Checkbox'
import { TextField } from '../../../../../shared/ui/TextField'
import { AppButton } from '../../../../../shared/ui/Button'
import { SmartRadio } from '../../../../../shared/ui/Radio'
import { useList } from '../../../../../shared/hooks/useList/useList'
import { Icons } from '../../../../../shared/icons'
import s from './programForm.module.scss'
import { programsApi } from '../api'
import { getISOString } from '../../../../../shared/helpers/getISOString'
import { LinkPresets } from '../../../../../shared/ui/Link'
import { useFieldArray } from 'react-hook-form'
import { clsx } from 'clsx'
import { statusOptions } from '../programList'
import { tagApi } from '../../expertise/api'
import { SmartCreatableSelect } from '../../../../../shared/ui/CreatableSelect'

type ProgramType = {
    program?: IProgramGet
    status: StatusType
    setStatus: (value: StatusType | null) => void
    reset?: () => void
}

const defaultValues: IProgramGet = {
    city: '',
    authorId: 0,
    authorPosition: '',
    enabledSince: '',
    authorName: '',
    enabledUntil: '',
    isEnabled: true,
    priority: 0,
    displayOnMainPage: true,
    duration: '',
    expertise: [
        {
            description:
                'Сможете составить план развития и разбить его на разные области (учебные планы, карьерные, и т.д). Найдете свои таланты и ты поймете, в какой сфере дизайна сможете реализоваться на 100%',
            specialisations: ['Начинающие дизайнеры'],
        },
    ],
    format: '',
    fullDescription: '',
    image: '',
    name: '',
    shortDescription: '',
    state: '',
    tags: [],
}

export const ProgramForm: React.FC<ProgramType> = ({
    program,
    status,
    setStatus,
}) => {
    const [selectedCover, setSelectedCover] = useState<string | null>(
        program?.image || null
    )
    const [activeDropdown, setActiveDropdown] = useState(-1)

    const { items: programImages, isFetching } = useList<IProgramImages>({
        key: ['programImages'],
        apiMethod: programsApi.getProgramImages,
    })

    const form = useSmartForm<
        IProgramGet & IProgramsPost & { isDelete: boolean }
    >({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        defaultValues: program
            ? {
                  ...defaultValues,
                  enabledUntil: moment(program.enabledUntil).format(
                      'YYYY-MM-DD'
                  ),
                  enabledSince: moment(program.enabledSince).format(
                      'YYYY-MM-DD'
                  ),
                  isEnabled: program.isEnabled,
                  priority: program.priority,
                  displayOnMainPage: program.displayOnMainPage,
                  duration: program.duration,
                  expertise: program?.expertise?.map((el) => ({
                      ...el,
                      specialisations: el.specialisations.join('\n'),
                  })),
                  format: program.format,
                  fullDescription: program.fullDescription,
                  image: program.image,
                  name: program.name,
                  shortDescription: program.shortDescription,
                  state: program.state,
                  tags: program.tags.map((label, i) => ({
                      value: i,
                      label,
                  })),
                  authorName: program.authorName,
                  authorId: program.authorId,
                  authorPosition: program.authorPosition,
                  city: program.city,
              }
            : {
                  isEnabled: true,
                  displayOnMainPage: true,
                  expertise: [
                      {
                          description: '',
                          specialisations: [''],
                      },
                  ],
              },
    })

    const { fields, append, remove } = useFieldArray({
        name: 'expertise',
        control: form.control,
    })

    const { items: fetchTags } = useList<string[]>({
        key: 'tags',
        apiMethod: tagApi.getTags,
        defaultLimit: 100,
    })
    const onSubmit = async () => {
        const {
            displayOnMainPage,
            enabledUntil,
            enabledSince,
            isEnabled,
            priority,
            duration,
            expertise,
            format,
            fullDescription,
            image,
            name,
            shortDescription,
            state,
            tags,
            authorName,
            city,
            authorId,
            authorPosition,
        } = form.getValues()
        const value: any = {
            displayOnMainPage,
            enabledUntil: getISOString(enabledUntil),
            enabledSince: getISOString(enabledSince),
            isEnabled,
            priority: Number(priority),
            duration,
            expertise: expertise.map((el: any) => ({
                ...el,
                specialisations: el.specialisations.split('\n'),
            })),
            format,
            fullDescription,
            image,
            name,
            shortDescription,
            state,
            tags: tags.map((el: any) => el.label),
            authorName,
            authorId: Number(authorId),
            city,
            authorPosition,
        }
        if (status === 'add') {
            try {
                await programsApi.postCreateProgram(value)
                setStatus(null)
            } catch (e) {
                console.log(e)
                alert('Something went wrong')
            }
        } else {
            if (form.getValues('isDelete')) {
                await programsApi.deletePrograms({ ids: [program!.id!] })
            } else {
                try {
                    await programsApi.patchProgramById(program!.id!, value)
                    setStatus(null)
                } catch (e) {
                    console.log(e)
                    alert('Something went wrong')
                }
            }
        }
    }

    const handleCoverChange = async (image: string) => {
        setSelectedCover(image === selectedCover ? null : image)
        form.setValue('image', image)
        form.watch()
    }

    const uploadImage = async () => {
        try {
            const fileInput = document.createElement('input')
            fileInput.type = 'file'
            fileInput.accept = 'image/*'
            fileInput.onchange = async (event) => {
                const file = (event.target as HTMLInputElement).files?.[0]
                if (!file) return

                const formData = new FormData()
                formData.append('photo', file)

                const response =
                    await programsApi.postUploadProgramPhoto(formData)

                const imageUrl = response.data.url

                form.setValue('image', imageUrl)
                setSelectedCover(imageUrl)
                form.watch()
            }
            fileInput.click()
        } catch (e) {
            console.log(e)
            alert('Something went wrong')
        }
        form.watch()
    }

    const handleClickAddExpertise = (evt: SyntheticEvent) => {
        evt.preventDefault()
        append({
            description: '',
            specialisations: [''],
        })
    }

    const handleClickRemoveExpertise = (evt: SyntheticEvent, index: number) => {
        evt.preventDefault()
        remove(index)
    }

    const handleClickCancel = () => {
        setStatus(null)
    }

    const toggleDropdown = (index: number) => {
        setActiveDropdown((prevIndex) => (prevIndex === index ? -1 : index))
    }

    const changeProgramStatus = async (newValue: string) => {
        form.setValue('state', newValue)
        form.watch()
    }

    return (
        <div className={s.wrapper}>
            <SmartForm form={form} submit={() => {}}>
                {program && <p className={s.id}>ID: {program?.id}</p>}
                <div className={s.checkbox}>
                    <SmartCheckbox name="isEnabled" label={'Активность:'} />
                    <SmartCheckbox
                        name="displayOnMainPage"
                        label={'Выводить на главной:'}
                    />
                </div>
                <TextField
                    label="Сортировка"
                    name="priority"
                    type="number"
                    placeholder="500"
                />
                <div className={s.date_wrapper}>
                    <TextField
                        className={s.date}
                        label="Начало активности"
                        name="enabledSince"
                        type="date"
                    />
                    <TextField
                        className={s.date}
                        label="Окончание активности"
                        name="enabledUntil"
                        type="date"
                    />
                </div>

                {program?.state && (
                    <div className={s.publicationStatus}>
                        <p className={s.title}>Статус публикации</p>

                        <div
                            className={clsx([
                                s.dropdown,
                                {
                                    [s.active]: activeDropdown === program.id,
                                },
                            ])}
                            onClick={() => toggleDropdown(program.id!)}
                        >
                            <div className={s.statusInfo}>
                                <div className={s.status}>
                                    <div
                                        className={clsx([
                                            s.statusCircle,
                                            {
                                                [s.red]:
                                                    form.getValues('state') ===
                                                    'inactive',
                                                [s.yellow]:
                                                    form.getValues('state') ===
                                                    'active',
                                            },
                                        ])}
                                    />
                                    <p className={s.status}>
                                        {form.getValues('state') ===
                                            'inactive' && 'Отклонено'}
                                        {form.getValues('state') === 'active' &&
                                            'Одобрено'}
                                        {form.getValues('state') ===
                                            'onModeration' && 'Ожидает'}
                                    </p>
                                </div>
                                <div className={s.chevron}>
                                    <Icons.admin.chevronDown />
                                </div>

                                {activeDropdown === program.id &&
                                    program.state !== 'inactive' && (
                                        <div className={s.dropdownContent}>
                                            {statusOptions.map(
                                                ({ label, value }, i) => (
                                                    <label
                                                        className={
                                                            s.dropdownItem
                                                        }
                                                        key={i}
                                                        onClick={() => {
                                                            changeProgramStatus(
                                                                value
                                                            )
                                                        }}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            className={
                                                                s.checkbox
                                                            }
                                                            checked={
                                                                form.getValues(
                                                                    'state'
                                                                ) === value
                                                            }
                                                        />
                                                        {label}
                                                    </label>
                                                )
                                            )}
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                )}

                <p className={s.title}>Информация о программе</p>

                <TextField
                    label="Название программы"
                    name="name"
                    placeholder="Продуктовые исследования"
                />

                <div className={s.coverWrapper}>
                    Выбери обложку
                    <div className={s.coverList}>
                        {form.getValues('image') && (
                            <div className={s.imageBlock}>
                                <img
                                    src={form.getValues('image')}
                                    alt="image"
                                    className={s.image}
                                />
                                <input
                                    type="checkbox"
                                    className={s.coverCheckbox}
                                    name="coverCheckbox"
                                    checked={
                                        selectedCover ===
                                        form.getValues('image')
                                    }
                                />
                            </div>
                        )}
                        {!isFetching &&
                            programImages?.map(({ id, image }) => (
                                <div className={s.imageBlock} key={id}>
                                    <img
                                        src={image}
                                        alt="image"
                                        className={s.image}
                                    />
                                    <input
                                        type="checkbox"
                                        className={s.coverCheckbox}
                                        name="coverCheckbox"
                                        checked={selectedCover === image}
                                        onChange={() =>
                                            handleCoverChange(image)
                                        }
                                    />
                                </div>
                            ))}
                    </div>
                    <p
                        className={s.coverWrapper_add}
                        onClick={() => uploadImage()}
                    >
                        <Icons.M.Plus />
                        Загрузить ещё
                    </p>
                </div>

                <TextField
                    label="Автор программы"
                    name="authorName"
                    placeholder="Свиридова Анна Александровна"
                />

                <TextField
                    label="ID эксперта"
                    name="authorId"
                    placeholder="1"
                />

                <TextField
                    label="Должность"
                    name="authorPosition"
                    placeholder="Дизайнер"
                />
                <TextField
                    label="Краткое описание программы"
                    name="shortDescription"
                    placeholder="О том, какие методики лучше применять в каких ситуациях и на что обращать внимание. Как придумывать свои инструменты для быстрой проверки гипотез. "
                    as={'textarea'}
                    className={s.textarea}
                    maxLength={200}
                />
                <TextField
                    label="Подробное описание программы"
                    name="fullDescription"
                    placeholder="О том, какие методики лучше применять в каких ситуациях и на что обращать внимание. Как придумывать свои инструменты для быстрой проверки гипотез. О том, какие методики лучше применять в каких ситуациях и на что обращать внимание. Как придумывать свои инструменты для быстрой проверки гипотез. О том, какие методики лучше применять в каких ситуациях и на что обращать внимание. "
                    as={'textarea'}
                />
                <TextField
                    label="Продолжительность"
                    name="duration"
                    placeholder="Например, 3 часа"
                />

                {fields.map((field, index) => (
                    <div key={field.id} className={s.expertise}>
                        <TextField
                            label="Для кого"
                            placeholder="Например, начинающим дизайнерам"
                            name={`expertise.${index}.specialisations`}
                            required={true}
                        />
                        <TextField
                            as="textarea"
                            label="Чему научатся"
                            placeholder="Сможете составить план развития и разбить его на разные области (учебные планы, карьерные, и т.д). Найдете свои таланты и ты поймете, в какой сфере дизайна сможете реализоваться на 100%"
                            name={`expertise.${index}.description`}
                            required={true}
                        />
                        {fields.length > 1 && (
                            <LinkPresets.Remove
                                onClick={(evt) =>
                                    handleClickRemoveExpertise(evt, index)
                                }
                                to=""
                            />
                        )}
                    </div>
                ))}
                <LinkPresets.AddBlock onClick={handleClickAddExpertise} to="" />

                <p className={s.title}>Формат взаимодействия</p>
                <div className={s.checkboxes}>
                    <SmartRadio value="online" name="format" label="Онлайн" />
                    <SmartRadio value="offline" name="format" label="Офлайн" />
                    <SmartRadio
                        value="any"
                        name="format"
                        label="Не имеет значения"
                    />
                </div>

                <TextField
                    label="Город"
                    name="city"
                    className={s.city}
                    placeholder="Город"
                />

                <SmartCreatableSelect
                    label="Теги"
                    name="tags"
                    placeholder="Теги"
                    isMulti
                    options={fetchTags.map((el: any) => ({
                        value: el.id,
                        label: el.tag,
                    }))}
                    className={s.tegs}
                />

                {status === 'edit' && (
                    <div className={s.checkbox}>
                        <SmartCheckbox
                            name="isDelete"
                            label={
                                'Удалить вопрос без возможности восстановления'
                            }
                        />
                    </div>
                )}

                <div className={s.buttons}>
                    <AppButton onClick={form.handleSubmit(onSubmit)}>
                        {status === 'edit' ? 'Сохранить изменения' : 'Создать'}
                    </AppButton>
                    <p onClick={handleClickCancel} className={s.cancel}>
                        Отменить
                    </p>
                </div>
            </SmartForm>
        </div>
    )
}
