import React from 'react'
import { useModalWindow } from '../../../shared/hooks/useModalWindow'
import { AppButton } from '../../../shared/ui/Button'
import { CreateProgrammApplicationModal } from './modals/createProgrammApplicationModal'
import { CreateProgrammApplicationSuccessModal } from './modals/createProgrammApplicationSuccessModal'

export const CreateProgrammApplicationButton = () => {
    const createApplicationFormModal = useModalWindow()
    const createApplicationSuccessModal = useModalWindow()

    return (
        <>
            <AppButton onClick={createApplicationFormModal.toggleOpen}>
                Оставить заявку на программу
            </AppButton>
            <CreateProgrammApplicationModal
                onSuccess={createApplicationSuccessModal.toggleOpen}
                {...createApplicationFormModal}
            />
            <CreateProgrammApplicationSuccessModal
                {...createApplicationSuccessModal}
            />
        </>
    )
}
