import { adminApiInstance } from '../../../../../shared/api'
import { ICreateReviews, IReviewsPatch } from '../model/types'

export const adminApiReviews = {
    getReviewsById: (id: number) => adminApiInstance.get(`reviews/${id}`),
    patchReviewsById: (id: number, data: IReviewsPatch) =>
        adminApiInstance.patch(`reviews/${id}`, data),
    deleteReviewsById: (id: number) => adminApiInstance.delete(`reviews/${id}`),
    createReviews: (data: ICreateReviews) =>
        adminApiInstance.post(`reviews`, data),
    deleteReviews: (data: { ids: number[] }) =>
        adminApiInstance.delete(`reviews`, { data }),
}
