import className from './index.module.scss'
import { Dropdown } from '../../../shared/ui/Dropdown'
import { Droplist } from '../../../shared/ui/Droplist'
import { SmartForm } from '../../../services/formProcessing/ui/SmartForm'
import { useList } from '../../../shared/hooks/useList/useList'
import { AppButton } from '../../../shared/ui/Button'
import { Icons } from '../../../shared/icons'
import React, { SyntheticEvent } from 'react'
import { catalogApi } from '../../../entities/catalog'
import { UseFormReturn } from 'react-hook-form'
import { useWindowDimensions } from '../../../shared/hooks/useWindowDimensions'
import { filtersDataList } from './constants'

type PropsType = {
    filterForm: UseFormReturn
    handleSetFilter: () => void
    isFilterPopupOpen: boolean
    resetFilters: (e: SyntheticEvent) => void
    isOpenMobileFilter: () => void
}

export const CatalogFiltersForm: React.FC<PropsType> = ({
    filterForm,
    handleSetFilter,
    isFilterPopupOpen,
    resetFilters,
    isOpenMobileFilter,
}) => {
    const { width } = useWindowDimensions()

    const { items: fetchCities } = useList<string>({
        key: 'city',
        apiMethod: (params) => catalogApi.getCities(params),
        defaultLimit: 100,
    })

    const cities = [...fetchCities]
    const filtersCities: {
        name: string
        label: string
        items: { label: string; value: string }[]
    } = {
        name: 'city',
        label: 'Город',
        items: [],
    }

    if (cities.length > 0) {
        cities.forEach((city) => {
            filtersCities.items.push({
                label: city,
                value: city,
            })
        })
    }

    const { items: fetchTag } = useList<string>({
        key: 'tags',
        apiMethod: catalogApi.getTags,
    })

    const tags = [...fetchTag]
    const filtersTags: {
        name: string
        label: string
        items: { label: string; value: string }[]
    } = {
        name: 'tags',
        label: 'Область экспертизы',
        items: [],
    }

    if (tags.length > 0) {
        tags.forEach((tag) => {
            filtersTags.items.push({
                label: tag,
                value: tag,
            })
        })
    }

    const onFiltersApply = () => {
        handleSetFilter()
        isFilterPopupOpen && isOpenMobileFilter()
    }

    const cls = isFilterPopupOpen
        ? className.CatalogFiltersForm +
          ' ' +
          className.CatalogFiltersForm__open
        : className.CatalogFiltersForm

    return (
        <div className={cls}>
            <SmartForm
                form={filterForm}
                submit={filterForm.handleSubmit(onFiltersApply)}
                onChange={() => {
                    width > 1023 && handleSetFilter()
                }}
                className={className.Form}
            >
                {filtersDataList.length > 0 &&
                    filtersDataList.map((filter, index) => (
                        <fieldset key={index}>
                            <Dropdown
                                title={filter.label}
                                className={
                                    filter.items.length === 0
                                        ? `${className.Dropdown} ${className.Dropdown__disabled}`
                                        : `${className.Dropdown}`
                                }
                            >
                                {filter.items && (
                                    <Droplist
                                        items={filter.items}
                                        name={filter.name}
                                        className={className.Droplist}
                                    />
                                )}
                            </Dropdown>
                        </fieldset>
                    ))}
                <fieldset>
                    <Dropdown
                        title={filtersTags.label}
                        className={
                            filtersTags.items.length === 0
                                ? `${className.Dropdown} ${className.Dropdown__disabled}`
                                : `${className.Dropdown}`
                        }
                    >
                        {filtersTags.items && (
                            <Droplist
                                items={filtersTags.items}
                                name={filtersTags.name}
                                className={className.Droplist}
                            />
                        )}
                    </Dropdown>
                </fieldset>
                <fieldset>
                    <Dropdown
                        title={filtersCities.label}
                        className={
                            filtersCities.items.length === 0
                                ? `${className.Dropdown} ${className.Dropdown__disabled}`
                                : `${className.Dropdown}`
                        }
                    >
                        {filtersCities.items && (
                            <Droplist
                                items={filtersCities.items}
                                name={filtersCities.name}
                                className={className.Droplist}
                            />
                        )}
                    </Dropdown>
                </fieldset>
                <AppButton
                    variant="ghost"
                    className={className.ClearButton}
                    onClick={resetFilters}
                >
                    Очистить все фильтры
                    <Icons.M.Close
                        className={className.ClearButton__icon}
                    ></Icons.M.Close>
                </AppButton>
                {isFilterPopupOpen && <AppButton>Найти</AppButton>}
            </SmartForm>
        </div>
    )
}
