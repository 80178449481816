import React, { useState } from 'react'
import { AdminPageLayout } from '../../../../ui/adminPageLayout/layout'
import { IAdminPanelPropsType } from '../../../../model/types'
import { ExpertsReviews } from './ui/expertsReviews/expertsReviews'
import { StatusType } from '../../../faq/model/types'
import { useGetReviewsById } from '../../api/queries'
import { NewForm } from './ui/expertsReviewsForm/newForm/newForm'
import { EditForm } from './ui/expertsReviewsForm/editForm/editForm'

export const ReviewsExpert = ({ title }: IAdminPanelPropsType) => {
    const [status, setStatus] = useState<StatusType | null>(null)
    const [reviewId, setReviewId] = useState<number>()

    const ChangeForm = () => {
        const { data: review, isFetching } = useGetReviewsById(reviewId!)

        if (isFetching) return null

        return (
            <EditForm status={status!} setStatus={setStatus} review={review} />
        )
    }

    if (status === 'edit') {
        return <ChangeForm />
    }

    if (status === 'add') {
        return <NewForm status={status!} setStatus={setStatus} />
    }

    return (
        <AdminPageLayout title="Отзывы об экспертах">
            <ExpertsReviews
                setStatus={setStatus}
                status={status}
                setReviewId={setReviewId}
            />
        </AdminPageLayout>
    )
}
