import clsx from 'clsx';
import s from './Accordion.module.scss';
import React, {useRef} from "react";
import {Icons} from '../../../icons'
import {css} from "@emotion/react";
import {theme} from "../../../styles/theme";
import {IFaqItem} from "../../../../pages/adminPanel/subpages/faq/model/types";

interface AccordionItemProps {
    className?: string,
    value: IFaqItem
    onClick: () => void,
    isOpen: boolean
}

export const AccordionItem = (props: AccordionItemProps) => {
    const {className, value, onClick, isOpen} = props;
    const ref = useRef<HTMLDivElement>(null)


    return (
        <li className={clsx([s.accordion_item, className])} onClick={onClick}>
            <p className={s.question} >
                {value.question}
                <Icons.M.ChevronUp
                    css={css`
                        fill: ${theme.color.darkSpace["700"]};
                        transition: transform .3s;
                    `}
                    className={clsx({[s.chevron_up]: isOpen})}
                />
            </p>
            <div
                className={s.answer}
                style={{height: isOpen ? ref.current?.scrollHeight : 0}}
                ref={ref}
            >
                {typeof value.answer === 'string' ? (
                    <p>{value.answer}</p>
                ): (
                    <value.answer/>
                )}
            </div>
        </li>
    );
}
