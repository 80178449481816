import React from 'react'
import { MentorType } from '../../../../entities/mentor'
import { ProgrammType } from '../../../../entities/programm/types'
import { Icons } from '../../../../shared/icons'
import { css } from '@emotion/react'
import { Tag } from '../../../../shared/ui/Tag'
import { LinkPresets } from '../../../../shared/ui/Link'
import { IPromoCatalogItem } from '../../../../entities/promoCatalog/model/types'
import s from './CatalogCard.module.scss'

type PropsType = {
    source?: ProgrammType | MentorType
    item: IPromoCatalogItem
} & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
>

export const CatalogCard: React.FC<PropsType> = ({ item, ...htmlProps }) => {
    const { type, title, subtitle, description, photo, link, rating } = item
    const [name, position] = subtitle!.split('\n')
    const tagType = { ...type }
    const array: [string, boolean][] = Object.entries(tagType)
    const tags: string[] = []
    array.forEach(([key, value]) => {
        if (value) {
            switch (key) {
                case 'consultant':
                    tags.push('Консультант')
                    break
                case 'mentor':
                    tags.push('Ментор')
                    break
                case 'program':
                    tags.push('Программа')
                    break
                default:
                    break
            }
        }
    })
    return (
        <article className={s.Container} {...htmlProps}>
            <div className={s.LabelsWrapper}>
                <div className={s.TagsWrapper}>
                    {tags.map((tag, index) => {
                        return <Tag key={index}>{tag}</Tag>
                    })}
                </div>
                {!isNaN(Number(rating)) && (
                    <div className={s.Rating}>
                        <Icons.logos.Star></Icons.logos.Star>
                        <span>{rating}</span>
                    </div>
                )}
            </div>
            {photo?.includes('emptyAvatar') ? (
                <div className={s.EmptyAvatarContainer}>
                    <img className={s.EmptyAvatarImage} src={photo} alt={''} />
                </div>
            ) : (
                <img className={s.Image} src={photo} alt={''} />
            )}
            <div className={s.ContentWrapper}>
                <div className={s.Content}>
                    <p className={s.Title}>{title}</p>
                    <div>
                        <p>{name}</p>
                        <p>{position}</p>
                    </div>
                    <p className={s.DescriptionWrapper}>{description}</p>
                </div>
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                        gap: 4px;
                    `}
                >
                    {/*TODO fix link string*/}
                    {link && (
                        <LinkPresets.LearnMore
                            to={link.replace('https://expertdev.ru', '')}
                        />
                    )}
                </div>
            </div>
        </article>
    )
}
