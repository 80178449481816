import { apiInstance } from '../../../shared/api'
import { ProgramCardType } from '../types'

export const programApi = {
    getProgram: (id: number) => apiInstance.get(`/expert/programs/${id}`),
    getProgramsList: (params: { limit: number }) =>
        apiInstance.get(`/expert/programs`, { params }),
    postCreateProgram: (data: ProgramCardType) =>
        apiInstance.post('expert/programs', data),
    patchProgramById: (id: number, data: ProgramCardType) =>
        apiInstance.patch(`/expert/programs/${id}`, data),
    getProgramProfile: (id: number) =>
        apiInstance.get(`/trainings/program/${id}`),
}
