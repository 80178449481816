import className from './index.module.scss'
import { AppButton } from '../../../../shared/ui/Button'
import { css } from '@emotion/react'
import { Icons } from '../../../../shared/icons'
import { useHistory } from 'react-router-dom'
import React from 'react'
import photo from './masha.jpg'

type PropsType = {
    title: string
    description: string
    buttonName: string
    navigation: string
    variant: 'v1' | 'v2'
    expert?: {
        name?: string
        role?: string
        description?: string
        photo?: string
    }
}

export const PromoCard: React.FC<PropsType> = ({
    title,
    description,
    buttonName,
    variant,
    expert,
    navigation,
}) => {
    const history = useHistory()
    return (
        <article className={className.Card}>
            {variant === 'v1' && (
                <Icons.catalogPage.PromoStarBg
                    css={css`
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        bottom: 0;
                        right: -12%;
                        z-index: -1;

                        @media (max-width: 1023px) {
                            right: 0;
                        }

                        @media (max-width: 767px) {
                            right: -45%;
                            width: auto;
                        }
                    `}
                />
            )}
            <div className={className.ContentWrapper}>
                <h3 className={className.Title}>{title}</h3>
                <p>{description}</p>
            </div>
            {variant === 'v2' && (
                <div className={className.Expert}>
                    <div className={className.Expert__wrapperImage}>
                        <img
                            className={className.Expert__image}
                            src={photo}
                            alt={expert?.name}
                        />
                    </div>
                    <div className={className.Expert__info}>
                        <div className={className.Expert__nameWrapper}>
                            <span className={className.Expert__name}>
                                {expert?.name}
                            </span>
                            <span>{expert?.role}</span>
                        </div>
                        <span>{expert?.description}</span>
                    </div>
                </div>
            )}
            <AppButton
                variant="filled_dark"
                css={css`
                    width: fit-content;
                `}
                onClick={
                    variant === 'v1'
                        ? () => history.push(navigation)
                        : () => (window.location.href = navigation)
                }
            >
                {buttonName}
            </AppButton>
        </article>
    )
}
