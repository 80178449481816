import { Container } from '../Container'
import { Icons } from '../../icons'
import { css } from '@emotion/react'

import s from './footer.module.scss'
import { ROUTES } from '../../../config/routes'
import { Link, useHistory, useLocation } from 'react-router-dom'
import React from 'react'

export const Footer = () => {
    const location = useLocation()
    const { push } = useHistory()
    return (
        <div className={s.footer}>
            {window.innerWidth < 768 ? (
                <Icons.logos.FooterIconPicBig className={s.footerIcon} />
            ) : (
                <Icons.logos.FooterIconPic className={s.footerIcon} />
            )}
            <Container>
                <div className={s.container}>
                    <div className={s.leftSide}>
                        <div className={s.iconWrapper}>
                            <Link to={ROUTES.root}>
                                <Icons.logos.FooterIcon1 />
                            </Link>
                            <a
                                href="https://univer.o3.ru/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Icons.logos.FooterIcon2 />
                            </a>
                        </div>
                        <div>
                            <Link
                                className={s.privacyPolicyLink}
                                to={ROUTES.privacyPolicy}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Политика конфиденциальности
                            </Link>
                            <Link
                                className={s.privacyPolicyLink}
                                to={ROUTES.agreement}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Пользовательское соглашение
                            </Link>
                        </div>
                    </div>
                    <div
                        css={css`
                            display: grid;
                            grid-column-start: 3;
                            grid-template-rows: 2fr;
                        `}
                    >
                        <div className={s.listWrapper}>
                            <div className={s.wrapper}>
                                <h4>Обучение</h4>
                                <ul className={s.list}>
                                    <li>
                                        <Link
                                            to={`${ROUTES.catalog}?types=programs`}
                                        >
                                            Найти обучающую программу
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={`${ROUTES.catalog}?types=mentors`}
                                        >
                                            Найти ментора
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={`${ROUTES.catalog}?types=consultants`}
                                        >
                                            Получить консультацию
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className={s.wrapper}>
                                <h4>Экспертность</h4>
                                <ul className={s.list}>
                                    <li>
                                        <Link
                                            to={ROUTES.experts}
                                            onClick={() => {
                                                window.scrollTo({
                                                    top: 0,
                                                    behavior: 'smooth',
                                                })
                                            }}
                                        >
                                            Стать внутренним тренером
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={ROUTES.experts}
                                            onClick={() => {
                                                window.scrollTo({
                                                    top: 0,
                                                    behavior: 'smooth',
                                                })
                                            }}
                                        >
                                            Стать ментором
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={ROUTES.experts}
                                            onClick={() => {
                                                window.scrollTo({
                                                    top: 0,
                                                    behavior: 'smooth',
                                                })
                                            }}
                                        >
                                            Стать консультантом
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className={s.wrapper}>
                                <h4>О платформе</h4>
                                <ul className={s.list}>
                                    <li>
                                        <Link
                                            to={`#`}
                                            onClick={() => {
                                                if (location.pathname === '/') {
                                                    window.scrollTo({
                                                        top: 0,
                                                        behavior: 'smooth',
                                                    })
                                                } else {
                                                    push('/')
                                                }
                                            }}
                                        >
                                            О нас
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={`#`}
                                            onClick={() => {
                                                const element =
                                                    document.getElementById(
                                                        'faq'
                                                    )
                                                element?.scrollIntoView({
                                                    block: 'center',
                                                    behavior: 'smooth',
                                                })
                                            }}
                                        >
                                            Вопросы и ответы
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={`#`}
                                            onClick={() => {
                                                const element =
                                                    document.getElementById(
                                                        'contacts'
                                                    )
                                                element?.scrollIntoView({
                                                    block: 'center',
                                                    behavior: 'smooth',
                                                })
                                            }}
                                        >
                                            Контакты
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={s.politic}>
                            <span>
                                © 2024 ООО «Си Четыре Групп Криэйт»
                                Все&nbsp;права&nbsp;защищены.
                            </span>
                            <div>
                                <Link
                                    to={ROUTES.privacyPolicy}
                                    className={s.privacyPolicy}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Политика конфиденциальности
                                </Link>
                                <Link
                                    to={ROUTES.agreement}
                                    className={s.privacyPolicy}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Пользовательское соглашение
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}
