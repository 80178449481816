import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ChangeEventHandler } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { theme } from '../../styles/theme'

const CircleStyled = styled.div`
    height: var(--switch-circle-dimensions);
    width: var(--switch-circle-dimensions);
    position: absolute;
    border-radius: 50%;
    top: 2px;
    left: 0;
    transition: 0.3s;
`

const SwitchStyled = styled.label<
    Required<Pick<PropsType, 'variant' | 'color'>> & {
        value: boolean
    }
>`
    ${(props) => css`
        --switch-width: ${props.variant == 'lg' ? '64px' : '36px'};
        --switch-height: ${props.variant == 'lg' ? '32px' : '20px'};
        border-radius: ${props.variant == 'lg' ? '20px' : '10px'};
    `}

    ${(props) => css`
        background: ${{
            white: {
                true: props.theme.color.white,
                false: theme.color.darkSpace[300],
            },
            blue: {
                true: props.theme.color.ozonBlue[200],
                false: theme.color.darkSpace[300],
            },
        }[props.color][String(props.value || false) as 'true' | 'false']};
    `}

    --switch-circle-dimensions: calc(var(--switch-height) - 4px);

    width: var(--switch-width);
    height: var(--switch-height);
    position: relative;
    cursor: pointer;
    display: block;

    ${CircleStyled} {
        transform: translateX(
            ${(props) =>
                props.value
                    ? 'calc(var(--switch-width) - var(--switch-circle-dimensions) - 2px)'
                    : '2px'}
        );
        background: ${(props) =>
            props.value
                ? props.theme.color.ozonBlue[500]
                : props.theme.color.white};
    }
`

type PropsType = {
    variant?: 'md' | 'lg'
    name: string
    color?: 'blue' | 'white'
    value: boolean
    onChange: ChangeEventHandler
}

export const Switch: React.FC<PropsType> = ({
    variant = 'md',
    name,
    value,
    onChange,
    color = 'blue',
}) => {
    return (
        <div>
            <input
                type="checkbox"
                id={name}
                name={name}
                checked={value}
                onChange={onChange}
                css={css`
                    display: none;
                `}
            />
            <SwitchStyled
                color={color}
                value={value}
                variant={variant}
                htmlFor={name}
            >
                <CircleStyled />
            </SwitchStyled>
        </div>
    )
}

export const SmartSwitch: React.FC<Omit<PropsType, 'value' | 'onChange'>> = (
    props
) => {
    const form = useFormContext()

    return (
        <Controller
            name={props.name}
            control={form.control}
            render={({ field }) => {
                return (
                    <Switch
                        {...props}
                        onChange={field.onChange}
                        value={field.value}
                    />
                )
            }}
        />
    )
}
