import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { ReactNode, useState } from 'react'
import { Icons } from '../../icons'
import { css } from '@emotion/react'

type PropsType = {
    title: string
    text?: ReactNode
    children?: ReactNode
    isFilterPopupOpen?: boolean
} & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
>

const ContainerStyled = styled.div`
    background-color: ${(props) => props.theme.color.white};
    > p {
        max-width: 650px;
        padding-bottom: 24px;
        font-size: 16px;
    }
`

const ButtonStyled = styled.div`
    cursor: pointer;
    font: var(--font-t1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    padding: 14px 20px;
    border-radius: 6px;
`

const Icon = styled(Icons.M.ChevronUp)<{ isOpened: boolean }>(
    {
        transition: '0.3s',
    },
    (props) => css`
        transform: ${props.isOpened ? 'rotate(0deg)' : 'rotate(180deg)'};
    `
)

export const Dropdown: React.FC<PropsType> = ({
    text,
    title,
    children,
    isFilterPopupOpen,
    ...rest
}) => {
    const [isOpened, setIsOpened] = useState(false)

    const handleOpen = () => {
        setIsOpened(!isOpened)
    }
    useEffect(() => {
        if (!isFilterPopupOpen) setIsOpened(false)
    }, [isFilterPopupOpen])

    return (
        <ContainerStyled {...rest}>
            <ButtonStyled onClick={handleOpen} role="button">
                <span>{title}</span>
                <Icon isOpened={isOpened} />
            </ButtonStyled>
            {text && isOpened && <p>{text}</p>}
            {children && isOpened && <div>{children}</div>}
        </ContainerStyled>
    )
}
