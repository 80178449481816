import React from 'react'
import { store } from './store'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { theme } from '../shared/styles/theme'
import { ThemeProvider } from '@emotion/react'
import { DevNavigator } from '../shared/devTools/navigator'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { generateCssColorsFromTheme } from '../shared/helpers/generateCssVarsFromTheme'
import { ROUTES } from '../config/routes'
import '../shared/styles/index.css'
import { RootRouter } from '../pages'
import { AuthAppWrapper } from '../features/auth/ui/authAppWrapper'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: { refetchOnWindowFocus: false },
    },
})

const history = createBrowserHistory()

generateCssColorsFromTheme(theme)

function App() {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <Router history={history}>
                        <AuthAppWrapper>
                            <RootRouter />
                            <DevNavigator routes={ROUTES} />
                        </AuthAppWrapper>
                    </Router>
                </QueryClientProvider>
            </ThemeProvider>
        </Provider>
    )
}

export default App
