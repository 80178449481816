import React, { useState } from 'react'
import { z } from 'zod'
import s from '../programsReviewsForm.module.scss'
import { useSmartForm } from '../../../../../../../../../services/formProcessing'
import { adminApiReviews } from '../../../../../api'
import { getISOString } from '../../../../../../../../../shared/helpers/getISOString'
import { SmartForm } from '../../../../../../../../../services/formProcessing/ui/SmartForm'
import { SmartCheckbox } from '../../../../../../../../../shared/ui/Checkbox'
import { TextField } from '../../../../../../../../../shared/ui/TextField'
import { SmartSelect } from '../../../../../../../../../shared/ui/Select'
import { AppButton } from '../../../../../../../../../shared/ui/Button'

type ProgramsReviewsType = {
    status: 'edit' | 'add'
    setStatus: (value: 'add' | 'edit' | null) => void
    reset?: () => void
}

const stateOptions: {
    value: string
    label: string
}[] = [
    {
        value: 'moderationPending',
        label: 'Ожидает',
    },
    {
        value: 'moderationAccepted',
        label: 'Одобрено',
    },
    {
        value: 'moderationRejected',
        label: 'Отклонено',
    },
]

const formSchema = z.object({
    isEnabled: z.boolean(),
    priority: z.preprocess(
        (a) => parseInt(String(a), 10),
        z
            .number({ invalid_type_error: 'Введите значение от 1 до 500' })
            .positive({ message: 'Значение должно быть больше нуля' })
            .min(1, { message: 'Значение должно быть больше нуля' })
            .max(500, { message: 'Значение должно быть не больше 500' })
    ),
    enabledUntil: z
        .string()
        .min(1, { message: 'Введите дату окончания активности' }),
    trainingId: z.preprocess(
        (a) => parseInt(String(a), 10),
        z
            .number({ invalid_type_error: 'Введите ID' })
            .positive({ message: 'Значение должно быть больше нуля' })
            .min(1, { message: 'Значение должно быть больше нуля' })
    ),
    rating: z.preprocess(
        (a) => parseInt(String(a), 10),
        z
            .number({ invalid_type_error: 'Поставьте оценку' })
            .positive({ message: 'Оценка должна быть больше нуля' })
            .min(1, { message: 'Оценка должна быть больше нуля' })
            .max(5, { message: 'Оценка должна быть не больше 5' })
    ),
    fullName: z.string().min(2, { message: 'Введите своё имя' }),
    position: z.string().min(2, { message: 'Введите свою должность' }),
    text: z.string().min(1, { message: 'Оставьте свой отзыв' }),
    state: z.object(
        { value: z.string(), label: z.string() },
        {
            required_error: 'Выберите статус публикации',
            invalid_type_error: 'Выберите статус публикации',
        }
    ),
})
export const NewForm: React.FC<ProgramsReviewsType> = ({
    status,
    setStatus,
}) => {
    const [state, setState] = useState('awaiting')

    const form = useSmartForm(
        {
            defaultValues: {
                isEnabled: true,
                priority: 500,
                fullName: '',
                position: '',
                trainingId: '',
                rating: 5,
                text: '',
                state: '',
                type: 'program',
                enabledUntil: '',
            },
        },
        {
            schema: formSchema,
        }
    )

    const onSubmit = async (data: any) => {
        if (data) {
            if (status === 'add') {
                try {
                    await adminApiReviews.createReviews({
                        enabledUntil: getISOString(data.enabledUntil),
                        fullName: data.fullName,
                        isEnabled: data.isEnabled,
                        position: data.position,
                        priority: Number(data.priority),
                        rating: Number(data.rating),
                        state: state,
                        text: data.text,
                        type: 'program',
                        trainingId: Number(data.trainingId),
                    })
                    setStatus(null)
                } catch (e) {
                    console.log(e)
                    alert('Something went wrong')
                }
            }
        }
    }

    const handleClickCancel = () => {
        setStatus(null)
    }

    const changeState = (data: any) => {
        setState(data.value)
    }

    return (
        <div className={s.wrapper}>
            <SmartForm form={form} submit={() => {}}>
                <div className={s.checkbox}>
                    <SmartCheckbox name="isEnabled" label={'Активность'} />
                </div>
                <TextField
                    label="Сортировка"
                    name="priority"
                    type="number"
                    placeholder="Введите значение от 1 до 500"
                />
                <div className={s.doubleFields}>
                    <TextField
                        className={s.doubleFields_wrapper}
                        label="Начало активности"
                        name="enabledSince"
                        type="date"
                        required={true}
                        disabled={status === 'edit'}
                    />
                    <TextField
                        className={s.doubleFields_wrapper}
                        label="Окончание активности"
                        name="enabledUntil"
                        type="date"
                    />
                </div>
                <TextField
                    label="ID Программы"
                    name="trainingId"
                    type="number"
                />
                <div className={s.doubleFields}>
                    <TextField
                        className={s.doubleFields_wrapper}
                        label="Автор отзыва"
                        name="fullName"
                        type="text"
                    />
                    <TextField
                        className={s.doubleFields_wrapper}
                        label="Должность"
                        name="position"
                        type="text"
                    />
                </div>
                <div className={s.doubleFields}>
                    <div className={s.selectContainer}>
                        <SmartSelect
                            className={
                                form.formState.errors.type
                                    ? `${s.doubleFields_wrapper} ${s.doubleFields_select} ${s.doubleFields_error}`
                                    : `${s.doubleFields_wrapper} ${s.doubleFields_select}`
                            }
                            label="Статус публикации"
                            placeholder={'Выберите статус публикации'}
                            name="state"
                            onChange={(data) => changeState(data)}
                            options={stateOptions}
                        />
                        {form.formState.errors.type && (
                            <div className={s.error}>
                                {form.formState.errors.type.message}
                            </div>
                        )}
                    </div>
                    <TextField
                        className={s.doubleFields_wrapper}
                        label="Рейтинг"
                        name="rating"
                        placeholder="Поставьте оценку от 1 до 5"
                        type="number"
                        min="1"
                        max="5"
                    />
                </div>
                <TextField label="Отзыв" name="text" as="textarea" />
                <div className={s.buttons}>
                    <AppButton onClick={form.handleSubmit(onSubmit)}>
                        Создать
                    </AppButton>
                    <p onClick={handleClickCancel} className={s.cancel}>
                        Отменить
                    </p>
                </div>
            </SmartForm>
        </div>
    )
}
