import React, { useState } from 'react'
import { ReviewsPromoList } from '../reviewsPromoList'
import { ReviewPromoForm } from '../reviewsPromoForm'
import { useGetReviewById } from '../../api/queries'
import { StatusType } from '../../../../../faq/model/types'
import { AdminPageLayout } from '../../../../../../ui/adminPageLayout/layout'

type PropsType = {
    title: string
}

export const ReviewsLayout: React.FC<PropsType> = ({ title }) => {
    const [status, setStatus] = useState<StatusType | null>(null)
    const [reviewId, setReviewId] = useState<number>()

    const ReviewPromoChangeForm = () => {
        const { data: review, isFetching } = useGetReviewById(reviewId!)
        if (isFetching) return null
        return (
            <ReviewPromoForm
                status={status!}
                setStatus={setStatus}
                review={review}
            />
        )
    }

    if (status === 'edit') {
        return <ReviewPromoChangeForm />
    }

    if (status === 'add') {
        return <ReviewPromoForm status={status} setStatus={setStatus} />
    }

    return (
        <AdminPageLayout title={title}>
            <ReviewsPromoList
                setStatus={setStatus}
                status={status}
                setReviewId={setReviewId}
            />
        </AdminPageLayout>
    )
}
