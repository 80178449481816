import className from './index.module.scss'
import React from 'react'
import { AppButton } from '../../../shared/ui/Button'
import { CatalogCard } from '../../../features/catalogCard'
import { ICatalogCard } from '../../../entities/catalog/model/types'
import { Icons } from '../../../shared/icons'

type PropsType = {
    items: ICatalogCard[]
    isFetching: boolean
    showMore: () => void
    page: number
    total: number
    limit: number
}

export const CatalogList: React.FC<PropsType> = ({
    items,
    isFetching,
    showMore,
    page,
    total,
    limit,
}) => {
    return (
        <div className={className.CatalogListWrapper}>
            {items.length > 0 ? (
                <>
                    <div className={className.CatalogList}>
                        {items.map((item, index) => (
                            <CatalogCard key={index} item={item}></CatalogCard>
                        ))}
                    </div>
                    {page * limit < total && (
                        <AppButton onClick={showMore} variant="ghost">
                            Показать еще
                        </AppButton>
                    )}
                </>
            ) : (
                !isFetching && (
                    <div className={className.ErrorSearch}>
                        <Icons.M.Cancelled />
                        <span>
                            К сожалению, по запросу ничего не найдено. Попробуй
                            изменить запрос или воспользоваться фильтрами.
                        </span>
                    </div>
                )
            )}
        </div>
    )
}
