import React from 'react'
import styled from '@emotion/styled'
import { theme } from '../../styles/theme'

type PropsType = {
    children: React.ReactNode
    size?: 'sm' | 'md'
} & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
>

const LabelRole = styled.span<{ size: 'sm' | 'md' }>`
    padding: ${({ size }) => (size === 'md' ? '9.5px 24px' : '7.5px 20.5px')};
    border-radius: 300px;
    background-color: ${theme.color.white};
    color: ${theme.color.ozonMain};
`

//TODO добавить стили по ролям
export const Tag: React.FC<PropsType> = ({
    children,
    size = 'sm',
    ...props
}) => {
    return (
        <LabelRole size={size} {...props}>
            {children}
        </LabelRole>
    )
}
