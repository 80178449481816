import React from 'react'
import { AppButton } from '../../../../shared/ui/Button'
import { Typography } from '../../../../shared/ui/Typography'
import {
    ModalWindow,
    ModalWindowPropsType,
} from '../../../../shared/ui/ModalWindow'
import { useSmartForm } from '../../../../services/formProcessing'
import { SmartForm } from '../../../../services/formProcessing/ui/SmartForm'
import { css } from '@emotion/react'
import { applicationApi } from '../../api'
import { SmartRadio } from '../../../../shared/ui/Radio'

const FORM_ID = 'ApplicationCompletedForm'

type ApplicationCompletedModalType = {
    id: number
    props: ModalWindowPropsType
    updateItem: (id: string | number, value: any) => void
}

type FormType = {
    isSeries: string
}

export const ApplicationCompleted = ({
    id,
    props,
    updateItem,
}: ApplicationCompletedModalType) => {
    const form = useSmartForm<FormType>({
        defaultValues: {
            isSeries: 'oneOrTwo',
        },
    })

    const handleSubmit = async (id: number) => {
        try {
            const { data } = await applicationApi.putChangeApplicationState(
                id,
                {
                    reason: '',
                    state: 'completed',
                    isSeries: form.getValues('isSeries') === 'cycle',
                }
            )
            updateItem(data.application.id, data.application)
            props.setIsOpened(false)
        } catch (e) {
            console.log(e)
            alert('Something went wrong')
        }
    }

    return (
        <>
            <ModalWindow {...props}>
                <Typography as="h4">Сколько встреч у тебя прошло?</Typography>
                <SmartForm
                    css={css`
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        margin: 20px 0;

                        span {
                            font-size: 18px;
                        }
                    `}
                    id={FORM_ID}
                    form={form}
                    submit={() => {}}
                >
                    <SmartRadio
                        value="oneOrTwo"
                        name="isSeries"
                        label="Я провел(а) 1-2 встречи"
                    />
                    <SmartRadio
                        value="cycle"
                        name="isSeries"
                        label="Я провел(а) 3 и более встреч (цикл)"
                    />
                </SmartForm>
                <AppButton
                    form={FORM_ID}
                    onClick={() => handleSubmit(id)}
                    variant="filled"
                    size="md"
                >
                    Отправить
                </AppButton>
            </ModalWindow>
        </>
    )
}
