import { useQuery } from '@tanstack/react-query'
import { IExpertProfile } from '../types'
import { mentorApi } from './index'

export const useFetchExpertProfile = (id: string) => {
    return useQuery<IExpertProfile>({
        queryKey: ['expert'],
        queryFn: async () => {
            const response = await mentorApi.getExpertProfile(id)
            return response.data
        },
    })
}
