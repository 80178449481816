import s from './StepCards.module.scss'
import clsx from 'clsx'
import card1 from '../../../shared/assets/stepCards/card-1.png'
import card2 from '../../../shared/assets/stepCards/card-2.png'
import card3 from '../../../shared/assets/stepCards/card-3.png'
import card4 from '../../../shared/assets/stepCards/card-4.png'
import card5 from '../../../shared/assets/stepCards/card-5.png'
import card6 from '../../../shared/assets/stepCards/card-6.png'

const cards = [
    { title: 'Определить области своей экспертизы', img: card1 },
    { title: 'Выбрать одну или несколько ролей', img: card2 },
    { title: 'Определить свои цели', img: card3 },
    { title: 'Создать и заполнить профиль эксперта', img: card4 },
    { title: 'Пройти обучение', img: card5 },
    { title: 'Начать делиться своей экспертизой', img: card6 },
]

export const StepCards = () => {
    return (
        <div className={s.wrapper}>
            <p className={s.title}>
                Чтобы стать экспертом, <br />
                тебе нужно:
            </p>
            <ul className={s.cards}>
                {cards?.map((el, index) => (
                    <div
                        key={index}
                        className={clsx(s.card, s[`card_${index + 1}`])}
                    >
                        <div className={s.card_title}>
                            <p className={s.card_title_index}>{index + 1}</p>
                            <span className={s.card_title_text}>
                                {el.title}
                            </span>
                        </div>
                        {index === 0 && (
                            <p className={s.card_title_desc}>
                                <a href="https://olearning.o3.ru/program/1003137-kak-poborot-sindrom-samozvantsa-i-nachat-tsenit-se">
                                    Здесь{' '}
                                </a>
                                узнай, как справиться с «синдромом самозванца»
                            </p>
                        )}
                        <img className={s.card_img} src={el.img} />
                    </div>
                ))}
            </ul>
        </div>
    )
}
