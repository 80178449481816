import { useRef, useEffect } from 'react'

export function useOutsideClick(
    ref: any,
    onOutsideClick: () => void,
    enabled: boolean
) {
    const cb = useRef<any>()
    useEffect(() => {
        cb.current = function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                onOutsideClick()
                document.removeEventListener('mousedown', handleClickOutside)
            }
        }

        if (enabled) {
            document.addEventListener('mousedown', cb.current)
            return () => {
                document.removeEventListener('mousedown', cb.current)
            }
        }
    }, [enabled])
}
