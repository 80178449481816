import { useHistory, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { authApi } from '../../api'
import { setToken } from '../../lib/setToken'

export const AuthFinishPage = () => {
    const { search } = useLocation()
    const { push } = useHistory()

    const handleGetToken = async () => {
        try {
            const searchParams = new URLSearchParams(search)
            const code = searchParams.get('code') as string
            const redirectUrl = searchParams.get('redirectUrl')

            const { data } = await authApi.getToken({ code })
            setToken(data)
            if (redirectUrl !== undefined && redirectUrl !== null) {
                push(redirectUrl)
            } else {
                push('/')
            }
        } catch (e) {
            console.log(e)
            alert('There was an error with authorization.')
        }
    }

    useEffect(() => {
        handleGetToken()
    }, [])

    return null
}
