import { ModalWindow } from '../../../shared/ui/ModalWindow'

import { useState } from 'react'

import s from "./VideoPlayer.module.scss"

type TProps = {
    isOpenVideo: boolean;
    setIsOpenVideo: React.Dispatch<React.SetStateAction<boolean>>;
    width?: string;
    height?: string;
    autoplay?: boolean;
    loop?: boolean;
    muted?: boolean;
}

export const VideoPlayer = ({
    isOpenVideo,
    setIsOpenVideo,
    width = "100%",
    height = "100%",
    autoplay = false,
    loop = false,
    muted = false,
}: TProps) => {
    const [error, setError] = useState<boolean>(false);

    const handleError = () => {
        setError(true);
    };
    return (
        <ModalWindow isOpened={isOpenVideo} setIsOpened={setIsOpenVideo}>
            {error ? (
                <div style={{ color: 'red', textAlign: 'center' }}>
                    Не удалось загрузить видео.
                </div>
            ) : (
                <video
                    width={width}
                    height={height}
                    className={s.video}
                    autoPlay={autoplay}
                    loop={loop}
                    muted={muted}
                    onError={handleError}
                    controls
                >
                    <source src="https://storage.yandexcloud.net/expert-storage/Expertplace_screencast.mp4"
                            type="video/mp4" />
                    Ваш браузер не поддерживает элемент video.
                </video>
            )}
        </ModalWindow>
    )
}