import React, { useEffect, useState } from 'react'
import s from './SliderOpportunities.module.scss'
import clsx from 'clsx'

import bg1 from '../assets/1.png'
import bg2 from '../assets/2.png'
import bg3 from '../assets/3.png'
import bg4 from '../assets/4.png'
import bg5 from '../assets/5.png'
import bg6 from '../assets/6.png'
import bg1s from '../assets/1s.png'
import bg2s from '../assets/2s.png'
import bg3s from '../assets/3s.png'
import bg4s from '../assets/4s.png'
import bg5s from '../assets/5s.png'
import bg6s from '../assets/6s.png'

import { Icons } from '../../../shared/icons'
import { useWindowDimensions } from '../../../shared/hooks/useWindowDimensions'
import text_avatar from '../assets/text_avatar.png'

interface SliderOpportunitiesProps {
    className?: string
}

const list = [
    {
        title: 'Возможность зарабатывать и тратить баллы в «Магазине эксперта»',
        bg: bg1,
        bgS: bg1s,
        first: true,
    },
    {
        title: 'Шанс создать собственный бренд эксперта внутри компании и за её пределами',
        bg: bg2,
        bgS: bg2s,
    },
    {
        title: 'Дополнительный проект, который можно указать в ревью',
        bg: bg3,
        bgS: bg3s,
    },
    {
        title: 'Новый опыт и профессиональное развитие',
        bg: bg4,
        bgS: bg4s,
    },
    {
        title: 'Возможность участия в эксклюзивных закрытых мероприятиях сообщества экспертов',
        bg: bg5,
        bgS: bg5s,
    },
    {
        title: 'Участие в конкурсе «Эксперт года» и ценные призы',
        bg: bg6,
        bgS: bg6s,
        last: true,
    },
]

export const SliderOpportunities = (props: SliderOpportunitiesProps) => {
    const { className } = props
    const { width } = useWindowDimensions()
    const [active, setActive] = useState<number>(0)
    const [render, setRender] = useState(
        new Array(3).fill(0).map((_, i) => ({ slide: i }))
    )

    useEffect(() => {
        width < 768 || width > 1440
            ? setRender(new Array(3).fill(0).map((_, i) => ({ slide: i })))
            : setRender(new Array(2).fill(0).map((_, i) => ({ slide: i })))
    }, [width])

    const prev = () => {
        if (active > 0) {
            setActive((active) => active - 1)
            if (render.length === 2) {
                if (
                    render.length - render[0].slide + render.length <
                    list.length - render.length
                ) {
                    setRender((render) =>
                        render.map((el) => ({ slide: el.slide - 1 }))
                    )
                }
            } else if (render.length === 3) {
                if (active - 1 < render.length) {
                    setRender((render) =>
                        render.map((el) => ({ slide: el.slide - 1 }))
                    )
                }
            }
        }
    }
    const next = () => {
        setActive(active < list.length - 1 ? active + 1 : active)
        if (render.length + render[0].slide < list.length) {
            setRender((render) => render.map((el) => ({ slide: el.slide + 1 })))
        }
    }

    const handleClick = (index: number) => {
        setActive(render[index].slide)
    }

    return (
        <div className={clsx([s.sliderOpportunities, className])}>
            <div className={s.header}>
                <p className={s.title}>Что получают эксперты?</p>
                <div className={s.controls}>
                    <button
                        onClick={prev}
                        className={clsx([
                            s.arrow,
                            s.prev,
                            { [s.disabled]: !active },
                        ])}
                    >
                        <Icons.M.ChevronUp />
                    </button>
                    <button
                        onClick={next}
                        className={clsx([
                            s.arrow,
                            s.next,
                            { [s.disabled]: active === list.length - 1 },
                        ])}
                    >
                        <Icons.M.ChevronUp />
                    </button>
                </div>
            </div>
            <div className={s.slider}>
                {render.map(({ slide }, index: number) => {
                    return slide === active ? (
                        <div
                            onClick={() => handleClick(index)}
                            className={clsx(s.slide, s.slide_active)}
                            key={slide}
                        >
                            <div className={s.slide_title}>
                                {list[slide]?.title}
                            </div>
                            {list[slide].first !== true && (
                                <div
                                    className={clsx([s.slide_text], {
                                        [s.slide_text__last]: list[slide]?.last,
                                    })}
                                >
                                    <img src={text_avatar} />
                                    <p>начислено 1000 баллов за консультацию</p>
                                </div>
                            )}
                            <div
                                className={clsx([s.slide_rating], {
                                    [s.slide_rating__last]: list[slide]?.last,
                                    [s.slide_rating__first]: list[slide]?.first,
                                })}
                            >
                                <p>
                                    {' '}
                                    <Icons.S.FullStar />
                                    {list[slide].first === true
                                        ? '21 000 баллов'
                                        : '450 баллов'}
                                </p>
                            </div>
                            <picture>
                                <>
                                    <source
                                        srcSet={list[slide].bgS}
                                        // media="(max-width: 767px)"
                                        media={
                                            list[slide].first
                                                ? '(max-width: 990px)'
                                                : '(max-width: 767px)'
                                        }
                                    />
                                    <img
                                        src={list[slide].bg}
                                        className={clsx([s.slide_img], {
                                            [s.slide_firstImage]:
                                                list[slide].first,
                                        })}
                                    />
                                </>
                            </picture>
                        </div>
                    ) : (
                        <div
                            onClick={() => handleClick(index)}
                            className={s.slide}
                            key={slide}
                        >
                            <div className={s.slide_title}>
                                {list[slide]?.title}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
