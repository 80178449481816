import React, { SyntheticEvent, useState } from 'react'
import { AdminPageLayout } from '../../../../ui/adminPageLayout/layout'
import { IAdminPanelPropsType } from '../../../../model/types'
import { TextField } from '../../../../../../shared/ui/TextField'
import { AppButton } from '../../../../../../shared/ui/Button'
import { SmartForm } from '../../../../../../services/formProcessing/ui/SmartForm'
import { useSmartForm } from '../../../../../../services/formProcessing'
import clsx from 'clsx'
import { useList } from '../../../../../../shared/hooks/useList/useList'
import { shopApi } from '../../api'
import { IMerchandiseRequestsGet } from '../../model/types'
import s from './shopRequest.module.scss'
import { StatusOption } from '../../../../../../entities/application/types'
import { Icons } from '../../../../../../shared/icons'
import { Pagination } from '../../../../../../widgets/pagination/ui/Pagination'
import moment from 'moment'

interface IColumn {
    label: string
    order: 'asc' | 'desc' | 'default'
    sort: keyof IMerchandiseRequestsGet
}

const statusOptions: StatusOption[] = [
    {
        label: 'Завершить',
        value: 'completed',
    },
    {
        label: 'Отклонить',
        value: 'rejected' || 'failed',
    },
]

const COLUMNS: IColumn[] = [
    { label: 'ID', order: 'default', sort: 'id' },
    { label: 'Дата создания', order: 'default', sort: 'createdAt' },
    { label: 'ID эксперта', order: 'default', sort: 'expertId' },
    { label: 'Email', order: 'default', sort: 'expertEmail' },
    { label: 'Товар', order: 'default', sort: 'merchandiseName' },
    { label: 'Размер', order: 'default', sort: 'size' },
    { label: 'Статус', order: 'default', sort: 'state' },
]

export const ShopRequest: React.FC<IAdminPanelPropsType> = ({ title }) => {
    const [limit, setLimit] = useState(10)
    const form = useSmartForm({
        defaultValues: {
            q: '',
            limit: { value: limit, label: limit },
        },
    })
    const [columns, setColumns] = useState<IColumn[]>(COLUMNS)
    const [activeDropdown, setActiveDropdown] = useState(-1)

    const {
        items: merchandiseRequests,
        setFilters,
        reset,
        nextPage,
        prevPage,
        total,
        page,
        setPage,
    } = useList<IMerchandiseRequestsGet>({
        key: 'shopRequests',
        apiMethod: (params) => shopApi.getMerchandiseRequests(params),
        defaultLimit: limit,
    })

    const onPrev = (evt: SyntheticEvent) => {
        evt.preventDefault()
        prevPage()
    }
    const onNext = (evt: SyntheticEvent) => {
        evt.preventDefault()
        nextPage()
    }
    const handleChangeLimit = ({ value }: any) => {
        setLimit(value)
        setFilters({ limit: value })
        form.setValue('q', '')
    }

    const changeApplicationStatus = async (newValue: string, id: number) => {
        switch (newValue) {
            case 'completed':
                try {
                    await shopApi.putMerchandiseRequestById(id, {
                        state: newValue,
                    })
                } catch (e) {
                    console.log(e)
                    alert('Something went wrong')
                }
                break
            case 'rejected':
                try {
                    await shopApi.putMerchandiseRequestById(id, {
                        state: newValue,
                    })
                } catch (e) {
                    console.log(e)
                    alert('Something went wrong')
                }
                break
        }
        reset()
    }

    const sortTable = (column: IColumn) => {
        setFilters({
            ...form.getValues(),
            limit,
            sort: column.sort,
            order: column.order === 'asc' ? 'desc' : 'asc',
        })
        setColumns((columns) =>
            columns.map((el) =>
                el.sort === column.sort
                    ? { ...el, order: el.order === 'asc' ? 'desc' : 'asc' }
                    : { ...el, order: 'default' }
            )
        )
    }

    const toggleDropdown = (index: number) => {
        setActiveDropdown((prevIndex) => (prevIndex === index ? -1 : index))
    }

    const onFiltersApply = (data: any) => {
        setFilters({ ...data, limit, q: data.q })
    }

    return (
        <AdminPageLayout title={title}>
            <SmartForm form={form} submit={onFiltersApply}>
                <div className={s.shopRequestWrapper}>
                    <div className={s.controls}>
                        <TextField
                            className={s.search_field}
                            name="q"
                            placeholder="Поиск"
                        />
                        <AppButton variant="ghost" className={s.search_button}>
                            Найти
                        </AppButton>
                    </div>

                    <div className={s.tableWrapper}>
                        <table className={s.table}>
                            <thead>
                                <tr>
                                    {columns.map((el) => (
                                        <th
                                            key={el.label}
                                            onClick={() =>
                                                el.label !== 'Размер' &&
                                                el.label !== 'Товар' &&
                                                sortTable(el)
                                            }
                                        >
                                            <span>
                                                {el.label}
                                                {el.label !== 'Размер' &&
                                                    el.label !== 'Товар' && (
                                                        <Icons.M.ChevronUp
                                                            className={clsx(
                                                                s.sort,
                                                                {
                                                                    [s.sort_asc]:
                                                                        el.order ===
                                                                        'asc',
                                                                }
                                                            )}
                                                        />
                                                    )}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {merchandiseRequests.map(
                                    (
                                        {
                                            id,
                                            email,
                                            expertFullName,
                                            expertId,
                                            state,
                                            createdAt,
                                            merchandiseName,
                                            size,
                                        },
                                        index
                                    ) => (
                                        <tr key={index}>
                                            <td>{id}</td>
                                            <td>
                                                {moment(createdAt).format(
                                                    'DD.MM.YYYY'
                                                )}
                                            </td>
                                            <td>
                                                ID:{expertId} {expertFullName}
                                            </td>
                                            <td>{email}</td>
                                            <td>{merchandiseName}</td>
                                            <td>{size}</td>
                                            <td>
                                                <div
                                                    className={clsx([
                                                        s.dropdown,
                                                        {
                                                            [s.active]:
                                                                activeDropdown ===
                                                                    id &&
                                                                state !==
                                                                    'completed' &&
                                                                state !==
                                                                    'rejected',
                                                        },
                                                    ])}
                                                    onClick={() =>
                                                        toggleDropdown(id)
                                                    }
                                                >
                                                    <div
                                                        className={s.statusInfo}
                                                    >
                                                        <div
                                                            className={clsx([
                                                                s.statusCircle,
                                                                {
                                                                    [s.red]:
                                                                        state ===
                                                                            'rejected' ||
                                                                        state ===
                                                                            'failed',
                                                                    [s.green]:
                                                                        state ===
                                                                        'completed',
                                                                },
                                                            ])}
                                                        />
                                                        <p className={s.status}>
                                                            {state ===
                                                                'completed' &&
                                                                'Завершена'}
                                                            {state ===
                                                                'pending' &&
                                                                'Ожидает'}
                                                            {(state ===
                                                                'rejected' ||
                                                                state ===
                                                                    'failed') &&
                                                                'Отклонена'}
                                                        </p>
                                                    </div>
                                                    <div className={s.chevron}>
                                                        <Icons.M.ChevronUp />
                                                    </div>

                                                    {activeDropdown === id &&
                                                        state !== 'completed' &&
                                                        state !==
                                                            'rejected' && (
                                                            <div
                                                                className={
                                                                    s.dropdownContent
                                                                }
                                                            >
                                                                {statusOptions.map(
                                                                    (
                                                                        {
                                                                            label,
                                                                            value,
                                                                        },
                                                                        i
                                                                    ) => (
                                                                        <label
                                                                            className={
                                                                                s.dropdownItem
                                                                            }
                                                                            key={
                                                                                i
                                                                            }
                                                                            onClick={() => {
                                                                                changeApplicationStatus(
                                                                                    value,
                                                                                    id
                                                                                )
                                                                            }}
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                className={
                                                                                    s.checkbox
                                                                                }
                                                                                checked={
                                                                                    state ===
                                                                                    value
                                                                                }
                                                                            />
                                                                            {
                                                                                label
                                                                            }
                                                                        </label>
                                                                    )
                                                                )}
                                                            </div>
                                                        )}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className={s.footer}>
                    <Pagination
                        page={page}
                        total={total}
                        limit={limit}
                        handleChangeLimit={handleChangeLimit}
                        onNext={onNext}
                        onPrev={onPrev}
                        setPage={setPage}
                    />
                </div>
            </SmartForm>
        </AdminPageLayout>
    )
}
