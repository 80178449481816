import React, { useEffect } from 'react'
import { AppButton } from '../../../../shared/ui/Button'
import {
    ModalWindow,
    ModalWindowPropsType,
} from '../../../../shared/ui/ModalWindow'
import { Typography } from '../../../../shared/ui/Typography'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { applicationApi } from '../../../../entities/application'
import { ApplicationType } from '../../../../entities/application/types'
import { ApplicationList } from '../../../../entities/application/ui/applicationCard'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { SmartForm } from '../../../../services/formProcessing/ui/SmartForm'
import { ApplicationCardForUnify } from '../../../../entities/application/ui/applicationCardForUnfiy'
import { css } from '@emotion/react'

type PropsType = {
    selectedApplications: ApplicationType[]
}

export const UnifyApplicationsModal = ({
    selectedApplications,
    ...props
}: ModalWindowPropsType & PropsType) => {
    const { id: programId } = useParams<{ id: string }>()
    const form = useForm<{
        applicationsToUnify: string[]
    }>({
        defaultValues: {
            applicationsToUnify: [],
        },
    })
    const queryClient = useQueryClient()

    const { mutate: unifyApplications } = useMutation({
        mutationFn: async (ids: string[]) => {
            await applicationApi.postCreateGroup(Number(programId), {
                applicationIds: ids.map((v) => Number(v)),
            })
            queryClient.invalidateQueries({
                queryKey: ['programGroup'],
                exact: false,
            })
        },
    })

    const handleUnify = () => {
        unifyApplications(form.getValues().applicationsToUnify)
        props.setIsOpened(false)
    }

    useEffect(() => {
        if (props.isOpened) {
            form.setValue(
                'applicationsToUnify',
                selectedApplications.map((v) => String(v.id))
            )
        }
    }, [props.isOpened])

    return (
        <ModalWindow {...props}>
            <div
                css={css`
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 20px;
                `}
            >
                <Typography as="h4" center>
                    Объединить заявки в группу?
                </Typography>
                <Typography as="t1" center>
                    Объединение заявок помогает формировать учебные группы
                </Typography>

                <SmartForm
                    css={css`
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 12px;
                    `}
                    submit={() => {}}
                    form={form}
                >
                    {selectedApplications.map((item) => (
                        <ApplicationCardForUnify
                            key={item.id}
                            name="applicationsToUnify"
                            application={item}
                        />
                    ))}
                </SmartForm>
                <AppButton
                    css={css`
                        width: fit-content;
                    `}
                    onClick={handleUnify}
                >
                    Создать группу
                </AppButton>
            </div>
        </ModalWindow>
    )
}
