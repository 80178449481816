import { css } from '@emotion/react'
import { tagsApi } from '../../../entities/tags/api'
import { AppButton } from '../../ui/Button'
import { Tag } from '../../ui/Tag'
import { useList } from './useList'
import { SmartForm } from '../../../services/formProcessing/ui/SmartForm'
import { useForm } from 'react-hook-form'
import { Typography } from '../../ui/Typography'
import { Box } from '../../ui/Box'
import { theme } from '../../styles/theme'
import { TextField } from '../../ui/TextField'
import { SmartSelect } from '../../ui/Select'
import { z } from 'zod'
import { useSmartForm } from '../../../services/formProcessing'

const zodSchema = z.object({
    q: z.string().optional(),
    sort: z
        .object({ value: z.string() })
        .transform((val: any) => val.value)
        .optional(),
})

export const UseListExample = () => {
    const form = useSmartForm(
        {},
        {
            schema: zodSchema,
        }
    )

    const {
        items,
        onPageClickHandler,
        showAll,
        showMore,
        total,
        nextPage,
        prevPage,
        page,
        setFilters,
    } = useList<string[]>({
        key: 'tags',
        apiMethod: tagsApi.getTags,
        defaultLimit: 2,
    })

    const onFiltersApply = (data: any) => {
        console.log(data, 'data')
        setFilters(data)
    }

    return (
        <div
            css={css`
                padding: 50px;
            `}
        >
            <Box
                color={theme.color.cloud}
                css={css`
                    margin-bottom: 30px;
                `}
            >
                <Typography
                    as="h2"
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    Filters
                </Typography>
                <SmartForm form={form} submit={onFiltersApply}>
                    <TextField name="q" placeholder="Search" />
                    <SmartSelect
                        label="Sort"
                        name="sort"
                        options={[
                            {
                                value: 'id',
                                label: 'Id',
                            },
                            {
                                value: 'priority',
                                label: 'Priority',
                            },
                        ]}
                    />
                    <AppButton>Apply</AppButton>
                </SmartForm>
            </Box>
            <div
                css={css`
                    display: flex;
                    gap: 10px;
                    flex-direction: column;
                `}
            >
                {items?.map((q: any, i) => (
                    <Tag key={i}>{q.id + ' ' + q.tag}</Tag>
                ))}
            </div>

            <div
                css={css`
                    display: flex;
                    gap: 20px;
                    margin-top: 30px;
                `}
            >
                <AppButton disabled={page == 1} onClick={() => prevPage()}>
                    Prev page
                </AppButton>
                <AppButton
                    disabled={page * 2 >= total}
                    onClick={() => nextPage()}
                >
                    Next page
                </AppButton>
                <AppButton onClick={() => showMore()}>Show more</AppButton>
                <AppButton onClick={() => showAll()}>Show all</AppButton>
            </div>
            <div
                css={css`
                    margin-top: 30px;
                    display: flex;
                    align-items: center;
                    gap: 30px;
                `}
            >
                Pages:
                <AppButton onClick={() => onPageClickHandler(1)}>1</AppButton>
                <AppButton onClick={() => onPageClickHandler(2)}>2</AppButton>
                <AppButton onClick={() => onPageClickHandler(3)}>3</AppButton>
            </div>
        </div>
    )
}
