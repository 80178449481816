import { ROUTES } from '../../../config/routes'
import { authApi } from '../../../features/auth/api'
import { setAdminToken } from '../../../features/auth/lib/setToken'
import {
    ACCESS_TOKEN_LIFETIME,
    LOCAL_STORAGE_ADMIN_TOKEN_DATA_KEY,
} from '../../../features/auth/model/constants'

let refreshTimeoutId: number | undefined

export const handleRefreshAdminAccessToken = async (refresh: string) => {
    try {
        const { data: tokenData } = await authApi.getToken({ refresh })
        setAdminToken(tokenData)

        window.clearTimeout(refreshTimeoutId)

        refreshTimeoutId = window.setTimeout(() => {
            handleRefreshAdminAccessToken(tokenData.refreshToken)
        }, ACCESS_TOKEN_LIFETIME)
        return tokenData
    } catch (e: any) {
        handleRefreshAdminRefreshToken()
    }
}

export const handleRefreshAdminRefreshToken = () => {
    localStorage.removeItem(LOCAL_STORAGE_ADMIN_TOKEN_DATA_KEY)
    window.location.href = ROUTES.adminPanel.auth
}
