import React from 'react'
import {
    ModalWindow,
    ModalWindowPropsType,
} from '../../../../shared/ui/ModalWindow'
import { Typography } from '../../../../shared/ui/Typography'
import { AppButton } from '../../../../shared/ui/Button'
import { theme } from '../../../../shared/styles/theme'

export const StatusChangedModal = (props: ModalWindowPropsType) => {
    return (
        <ModalWindow {...props}>
            <Typography as="h2">Статус успешно изменён</Typography>
            <Typography as="t1" center>
                Сотрудники, которые прошли обучение, получат уведомление и
                смогут оставить отзыв на программу
            </Typography>
            <Typography color={theme.color.ozonMain} as="p1">
                Ты получил 1000 баллов
            </Typography>
            <AppButton onClick={() => props.setIsOpened(false)}>
                Хорошо
            </AppButton>
        </ModalWindow>
    )
}
