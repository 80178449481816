import React from 'react'
import { IReviewProfile } from '../../model/types'
import s from './reviewProfile.module.scss'
import { Avatar } from '../../../../shared/ui/Avatar'
import { Icons } from '../../../../shared/icons'
import { Tag } from '../../../../shared/ui/Tag'

type PropsType = {
    reviewProfile: IReviewProfile
}
export const ReviewProfile: React.FC<PropsType> = ({ reviewProfile }) => {
    return (
        <div className={s.Container}>
            {reviewProfile.expert && (
                <>
                    <div className={s.Program}>
                        <div className={s.ProgramWrapper}>
                            <h4
                                className={`${s.TitleH4} ${s.ProgramMobileTitle}`}
                            >
                                {reviewProfile.expert.fullName}
                            </h4>
                            <div className={s.ProgramAvatarBlock}>
                                <Avatar
                                    url={reviewProfile.expert.photo}
                                    className={s.ProgramAvatarBlock__avatar}
                                />
                                <div className={s.ProgramAvatarBlock__info}>
                                    <h4
                                        className={`${s.TitleH4} ${s.ProgramAvatarBlock__title}`}
                                    >
                                        {reviewProfile.expert.fullName}
                                    </h4>
                                    <span
                                        className={
                                            s.ProgramAvatarBlock__position
                                        }
                                    >
                                        {reviewProfile.expert.position}
                                    </span>
                                </div>
                            </div>
                            <div className={s.ProgramSocials}>
                                {reviewProfile.expert.telegram && (
                                    <a
                                        href={reviewProfile.expert.telegram}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={s.ProgramSocials__link}
                                    >
                                        <Icons.social.Telegram />
                                    </a>
                                )}
                                {reviewProfile.expert.vkontakte && (
                                    <a
                                        href={reviewProfile.expert.vkontakte}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={s.ProgramSocials__link}
                                    >
                                        <Icons.social.Vkontakte />
                                    </a>
                                )}
                                {reviewProfile.expert.staff && (
                                    <a
                                        href={reviewProfile.expert.staff}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={`${s.ProgramSocials__link} ${s.ProgramSocials__staff}`}
                                    >
                                        Staff
                                    </a>
                                )}
                            </div>
                        </div>
                        <div className={s.ProgramTags}>
                            {reviewProfile.expert.tags.length > 0 &&
                                reviewProfile.expert.tags.map((tag, index) => {
                                    return (
                                        <Tag
                                            key={index}
                                            className={s.ProgramTags__tag}
                                        >
                                            {tag}
                                        </Tag>
                                    )
                                })}
                        </div>
                    </div>
                    <div className={s.AboutProgram}>
                        <div>
                            <p className={s.TitleP1}>
                                {reviewProfile.type === 'mentor'
                                    ? 'О менторе:'
                                    : 'О консультанте:'}
                            </p>
                            <span>{reviewProfile.expert.about}</span>
                        </div>
                    </div>
                </>
            )}
            {reviewProfile.program && (
                <>
                    <div className={s.Program}>
                        <div className={s.ProgramWrapper}>
                            <h4
                                className={`${s.TitleH4} ${s.ProgramMobileTitle}`}
                            >
                                {reviewProfile.program.name}
                            </h4>
                            <div className={s.ProgramAvatarBlock}>
                                <Avatar
                                    url={reviewProfile.program.author.photo}
                                    className={s.ProgramAvatarBlock__avatar}
                                />
                                <div className={s.ProgramAvatarBlock__info}>
                                    <h4
                                        className={`${s.TitleH4} ${s.ProgramAvatarBlock__title}`}
                                    >
                                        {reviewProfile.program.name}
                                    </h4>
                                    <span
                                        className={
                                            s.ProgramAvatarBlock__position
                                        }
                                    >
                                        {reviewProfile.program.author.fullName},
                                    </span>
                                    <span
                                        className={
                                            s.ProgramAvatarBlock__position
                                        }
                                    >
                                        {reviewProfile.program.author.position}
                                    </span>
                                </div>
                            </div>
                            <div className={s.ProgramSocials}>
                                {reviewProfile.program.author.telegram && (
                                    <a
                                        href={
                                            reviewProfile.program.author
                                                .telegram
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={s.ProgramSocials__link}
                                    >
                                        <Icons.social.Telegram />
                                    </a>
                                )}
                                {reviewProfile.program.author.vkontakte && (
                                    <a
                                        href={
                                            reviewProfile.program.author
                                                .vkontakte
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={s.ProgramSocials__link}
                                    >
                                        <Icons.social.Vkontakte />
                                    </a>
                                )}
                                {reviewProfile.program.author.staff && (
                                    <a
                                        href={
                                            reviewProfile.program.author.staff
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={`${s.ProgramSocials__link} ${s.ProgramSocials__staff}`}
                                    >
                                        Staff
                                    </a>
                                )}
                            </div>
                        </div>
                        <div className={s.ProgramTags}>
                            {reviewProfile.program.tags.length > 0 &&
                                reviewProfile.program.tags.map((tag, index) => {
                                    return (
                                        <Tag
                                            key={index}
                                            className={s.ProgramTags__tag}
                                        >
                                            {tag}
                                        </Tag>
                                    )
                                })}
                        </div>
                    </div>
                    <div className={s.AboutProgram}>
                        <div>
                            <p className={s.TitleP1}>О курсе:</p>
                            <span>{reviewProfile.program.about}</span>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}
