import React from 'react'
import { Route } from 'react-router-dom'
import { Icons } from '../../shared/icons'
import { Expertise } from './subpages/expertise/expertise'
import { Users, UserCoach, UserConsultant, UserMentor } from './subpages/users'
import { Programs } from './subpages/programs/programs'
import {
    Applications,
    ApplicationsMentor,
    ApplicationsCoach,
    ApplicationsConsultant,
} from './subpages/applications'
import {
    Reviews,
    ReviewsMainPage,
    ReviewsProgram,
    ReviewsExpert,
} from './subpages/reviews'
import { Shop, ShopCatalog, ShopRequest } from './subpages/shop'
import { Faq, FaqStudent, FaqExpert, FaqMainPage } from './subpages/faq'
import { IAdminPanelPath, IAdminPanelSubpage } from './model/types'
import s from './ui/adminPage/index.module.scss'
import { Workspace } from './subpages/workspace/workspace'
import { ROUTES } from '../../config/routes'

export const adminPanelPaths: IAdminPanelPath[] = [
    {
        name: 'workspace',
        title: 'Рабочий стол',
        path: ROUTES.adminPanel.workspace,
        component: Workspace,
        icon: <Icons.admin.workspace />,
    },
    {
        name: 'tags',
        title: 'Области Экспертизы',
        path: ROUTES.adminPanel.expertise,
        component: Expertise,
        icon: <Icons.admin.areasAndExpertise />,
    },
    {
        name: 'users',
        title: 'Пользователи',
        path: ROUTES.adminPanel.users.root,
        component: Users,
        icon: <Icons.admin.users />,
        subpages: [
            {
                name: 'coach',
                title: 'Тренеры',
                path: ROUTES.adminPanel.users.coach,
                component: UserCoach,
            },
            {
                name: 'consultant',
                title: 'Консультанты',
                path: ROUTES.adminPanel.users.consultant,
                component: UserConsultant,
            },
            {
                name: 'mentor',
                title: 'Менторы',
                path: ROUTES.adminPanel.users.mentor,
                component: UserMentor,
            },
        ],
    },
    {
        name: 'programs',
        title: 'Программы',
        path: ROUTES.adminPanel.programs,
        component: Programs,
        icon: <Icons.admin.programs />,
    },
    {
        name: 'requests',
        title: 'Заявки',
        path: ROUTES.adminPanel.applications.root,
        component: Applications,
        icon: <Icons.admin.applications />,
        subpages: [
            {
                name: 'coach',
                title: 'Тренеры',
                path: ROUTES.adminPanel.applications.coach,
                component: ApplicationsCoach,
            },
            {
                name: 'consultant',
                title: 'Консультанты',
                path: ROUTES.adminPanel.applications.consultant,
                component: ApplicationsConsultant,
            },
            {
                name: 'mentor',
                title: 'Менторы',
                path: ROUTES.adminPanel.applications.mentor,
                component: ApplicationsMentor,
            },
        ],
    },
    {
        name: 'reviews',
        title: 'Отзывы',
        path: ROUTES.adminPanel.reviews.root,
        component: Reviews,
        icon: <Icons.admin.reviews />,
        subpages: [
            {
                name: 'mainPage',
                title: 'Отзывы на главной',
                path: ROUTES.adminPanel.reviews.mainPage,
                component: ReviewsMainPage,
            },
            {
                name: 'expert',
                title: 'Об экспертах',
                path: ROUTES.adminPanel.reviews.expert,
                component: ReviewsExpert,
            },
            {
                name: 'program',
                title: 'О программах',
                path: ROUTES.adminPanel.reviews.program,
                component: ReviewsProgram,
            },
        ],
    },
    {
        name: 'shop',
        title: 'Магазин подарков',
        path: ROUTES.adminPanel.shop.root,
        component: Shop,
        icon: <Icons.admin.shop />,
        subpages: [
            {
                name: 'request',
                title: 'Заявки на подарок',
                path: ROUTES.adminPanel.shop.request,
                component: ShopRequest,
            },
            {
                name: 'catalog',
                title: 'Каталог подарков',
                path: ROUTES.adminPanel.shop.catalog,
                component: ShopCatalog,
            },
        ],
    },
    {
        name: 'faq',
        title: 'FAQ',
        path: ROUTES.adminPanel.faq.root,
        component: Faq,
        icon: <Icons.admin.faq />,
        subpages: [
            {
                name: 'mainPage',
                title: 'FAQ для главной',
                path: ROUTES.adminPanel.faq.mainPage,
                component: FaqMainPage,
            },
            {
                name: 'expert',
                title: 'FAQ для экспертов',
                path: ROUTES.adminPanel.faq.expert,
                component: FaqExpert,
            },
            {
                name: 'student',
                title: 'FAQ для учеников',
                path: ROUTES.adminPanel.faq.student,
                component: FaqStudent,
            },
        ],
    },
]

export const AdminPanelRoutes: React.FC = () => {
    return (
        <div className={s.page}>
            {adminPanelPaths.map((el) => (
                <Route path={el.path} key={el.name}>
                    <Route path={el.path} exact>
                        <el.component title={el.title} />
                    </Route>
                    {Array.isArray(el.subpages) &&
                        el.subpages?.map((el) => (
                            <Route path={el.path} key={el.name}>
                                <el.component title={el.title} />
                            </Route>
                        ))}
                </Route>
            ))}
        </div>
    )
}
