import React from 'react'
import styled from '@emotion/styled'
import { Icons } from '../../icons'
import { useFormContext } from 'react-hook-form'
import { ChangeEventHandler, useId } from 'react'
import { theme } from '../../styles/theme'
import { css } from '@emotion/react'

const CheckboxStyled = styled.div`
    border: 1px solid ${(props) => props.theme.color.darkSpace[200]};
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 4px;
    cursor: pointer;

    > svg {
        display: none;
    }
`

const LabelStyled = styled.span`
    margin-left: 8px;
    font-size: 14px;
    color: ${theme.color.darkSpace[450]};
    user-select: none;
`

const InputStyled = styled.input`
    display: none;

    &:checked + label > ${CheckboxStyled} {
        border: none;
        background: ${(props) => props.theme.color.ozonMain};
    }

    &:checked + label > ${CheckboxStyled} > svg {
        display: block;
    }
`

type PropsType = {
    label?: string
    inputProps: {
        onChange: ChangeEventHandler
        name: string
        value?: string
    }
}

export const Checkbox: React.FC<PropsType> = (props) => {
    const id = useId()

    return (
        <div>
            <InputStyled {...props.inputProps} id={id} type="checkbox" />
            <label
                css={css`
                    display: flex;
                    align-items: flex-end;
                    cursor: pointer;
                `}
                htmlFor={id}
            >
                <CheckboxStyled>
                    <Icons.S.Check />
                </CheckboxStyled>
                {props.label && <LabelStyled>{props.label}</LabelStyled>}
            </label>
        </div>
    )
}

export const SmartCheckbox: React.FC<{
    name: string
    value?: string
    label?: string
}> = (props) => {
    const form = useFormContext()

    return (
        <Checkbox
            inputProps={{
                ...form.register(props.name),
                value: props.value,
            }}
            label={props.label}
        />
    )
}
