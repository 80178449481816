import React, {ReactNode} from 'react';
import s from './index.module.scss'
import {AppButton} from "../../../../shared/ui/Button";
import {Link} from "react-router-dom";
import {usePutWorkspace} from "./api/queries";

type PropType = {
    title: string
    icon: ReactNode
    desktop?: boolean
    path: string
    name: string
    stats?: string
}

export const DesktopCard: React.FC<PropType> = (props) => {
    const {title, icon, path, name, stats, desktop = false} = props
    const {mutate} = usePutWorkspace()

    const handleClickAdd = () => {
        mutate(name)
    }

    return (
        <div
            className={s.card}
        >
            <div className={s.card_info}>
                {/*{desktop && <Icons.M.Close onClick={() => {}} className={s.close_icon}/>}*/}
                {icon}
                <p className={s.p1}>{title}</p>
                <p className={s.t1}>{stats}</p>
            </div>

            {
                desktop
                    ? (
                        <Link to={path ?? ''} className={s.link}>
                            <AppButton variant='ghost' >Перейти</AppButton>
                        </Link>
                    )
                    : (
                        <AppButton variant='ghost' onClick={handleClickAdd}>Добавить на рабочий стол</AppButton>
                    )
            }
        </div>
    )}
