import React from 'react'
import { Icons } from '../../../../shared/icons'

import s from './programmApplicationCard.module.scss'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../../config/routes'
import { AppButton } from '../../../../shared/ui/Button'
import { programApplicationsType } from '../../types'
import { useFetchProfile } from '../../../profile/api/queries'

type ProgramApplicationCardProps = {
    applications: programApplicationsType[]
    total: number
    page: number
    showMore?: () => void
}

export const ProgramApplicationCard = ({
    applications,
    total,
    page,
    showMore,
}: ProgramApplicationCardProps) => {
    const { data: profile } = useFetchProfile()

    return (
        <>
            <div className={s.programWrapper}>
                {applications.map(
                    (
                        {
                            programName,
                            shortDescription,
                            applicationsCount,
                            programId,
                        },
                        index
                    ) => (
                        <div className={s.programItem} key={index}>
                            <p className={s.programTitle}>{programName}</p>
                            <p className={s.programInfo}>
                                {profile?.fullName}, <br /> {profile?.position}
                            </p>
                            <p className={s.programDescription}>
                                {shortDescription}
                            </p>
                            <div className={s.programBottom}>
                                <Link
                                    className={s.toApplications}
                                    to={`${ROUTES.personalCabinet.applications.programm}/${programId}`}
                                >
                                    К заявкам
                                    <Icons.M.ArrowToRight />
                                </Link>
                                <p className={s.applicationsQuantity}>
                                    Количество заявок: {applicationsCount}
                                </p>
                            </div>
                        </div>
                    )
                )}
            </div>

            {page * 6 < total && (
                <AppButton
                    size="lg"
                    variant="ghost"
                    onClick={showMore}
                    className={s.moreButton}
                >
                    Показать ещё
                </AppButton>
            )}
        </>
    )
}
