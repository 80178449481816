import { adminAuthApi } from './index'
import { useMutation } from '@tanstack/react-query'
import { IAuthBody } from '../model'
import { GetTokenResponseType } from '../../auth/model/types'
import { AxiosResponse } from 'axios'
import { setAdminToken } from '../../auth/lib/setToken'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../../config/routes'

export const useAdminLogin = () => {
    const { push } = useHistory()

    return useMutation({
        mutationKey: ['adminLogin'],
        mutationFn: async (
            data: IAuthBody & {
                shouldRemember: boolean
            }
        ) => {
            return adminAuthApi.adminLogin({
                password: data.password,
                username: data.username,
            })
        },
        onSuccess: (data: AxiosResponse<GetTokenResponseType>, variables) => {
            setAdminToken(data.data, variables.shouldRemember)
            push(ROUTES.adminPanel.workspace)
        },
        onError: (e: any) => {
            alert(e?.response?.data?.error)
        },
    })
}
