import React from 'react'
import { useState } from 'react'
import { AppButton } from '../../shared/ui/Button'
import { ProgressBar } from '../../shared/ui/ProgressBar'
import { css } from '@emotion/react'

export const ProgressBarExample = () => {
    const [value, setValue] = useState(0)

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                width: 300px;
                gap: 30px;
            `}
        >
            <h2>Progress bar example</h2>
            <AppButton
                onClick={() => setValue((v) => (v == 100 ? 0 : v + 10))}
                variant="filled"
                size="md"
            >
                Increase by 10 percents
            </AppButton>
            <ProgressBar value={value} />
        </div>
    )
}
