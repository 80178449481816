import { Controller, useFormContext } from 'react-hook-form'
import { ChangeEventHandler, ReactNode } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

const TextFieldContainerStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 21px;
    position: relative;

    .TextField__label {
        font-size: 16px;
        color: ${(props) => props.theme.color.darkSpace['700']};
        margin-bottom: 6px;
        font-weight: 400;
        line-height: 20.8px;
    }

    .TextField__error {
        font-size: 12px;
        color: ${(props) => props.theme.color.ozonMagenta['500']};
        margin-top: 8px;
        line-height: 13.2px;
        position: absolute;
        bottom: 0;
    }
`

const generateStylesForInputField = (as: 'input' | 'textarea') =>
    styled(as)<{ error?: boolean; disabled?: boolean }>(
        (props) => css`
            height: ${as == 'input' ? '48px' : '113px'};
            transition: 0.3s;
            font-size: 14px;
            padding: ${as == 'input' ? '0 16px' : '16px'};
            border-radius: 8px;
            border: 1px solid ${props.theme.color.darkSpace['100']};
            outline: none;
            resize: none;
            padding-top: ${as == 'input' ? '3px' : '16px'};
            caret-color: ${props.theme.color.ozonBlue[500]};

            &::placeholder {
                transform: translateX(3px);
            }

            &:focus {
                border: 1px solid ${props.theme.color.ozonBlue[500]};
            }
        `,
        (props) => {
            if (props.error) {
                return css`
                    border-color: ${props.theme.color.ozonMagenta[500]};

                    &:focus {
                        border: 1px solid ${props.theme.color.ozonMagenta[500]};
                    }
                `
            }
            return null
        }
    )

const TextFieldStyled = generateStylesForInputField('input')
const TextAreaFieldStyled = generateStylesForInputField('textarea')

export const TextField: React.FC<
    {
        name: string
        label?: string
        endAdornment?: ReactNode
        as?: 'textarea' | 'input'
    } & React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    >
> = ({ name, label, className, as = 'input', ...rest }) => {
    const form = useFormContext()

    return (
        <Controller
            render={({ field, fieldState }) => {
                const onChangeProxy: ChangeEventHandler<
                    HTMLInputElement | HTMLTextAreaElement
                > = (e) => {
                    form.clearErrors(name)
                    field.onChange(e)
                }

                const hasError = Boolean(fieldState.error?.message)

                return (
                    <TextFieldContainerStyled className={className}>
                        {label && (
                            <label className="TextField__label">{label}</label>
                        )}
                        {as == 'input' ? (
                            <TextFieldStyled
                                {...rest}
                                {...field}
                                error={hasError}
                                onChange={onChangeProxy}
                            />
                        ) : (
                            <TextAreaFieldStyled
                                {...rest}
                                {...field}
                                error={hasError}
                                onChange={onChangeProxy}
                            />
                        )}

                        {hasError && (
                            <div className="TextField__error">
                                {fieldState.error?.message}
                            </div>
                        )}
                    </TextFieldContainerStyled>
                )
            }}
            name={name}
            control={form.control}
        />
    )
}
