import s from './SwitcherExperts.module.scss'
import React from 'react'
import { AppButton } from '../../../shared/ui/Button'
import { useFetchUser } from '../../../entities/profile/api/queries'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../config/routes'

interface TabProps {
    question: string
    steps: string[]
    img: string
    title: string
    desc: string
    cards: { src: string; text: string }[]
    value: string
}

export const Tab: React.FC<TabProps> = (props) => {
    const { question, steps, img, title, desc, cards, value } = props
    const { data: user } = useFetchUser()
    const scrollToElem = () => {
        const element = document.getElementById('registration')
        element?.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }
    return (
        <div className={s.tab}>
            <div className={s.section_top}>
                <p className={s.question}>{question}</p>
                {value === '1' && (
                    <p className={s.subtitle}>
                        Если хочешь узнать подробнее, как будет устроено
                        взаимодействие консультанта с коллегой, пройди
                        <a
                            href={
                                'https://olearning.o3.ru/program/1003263-konsultant-v-ozon-expertplace'
                            }
                            target="_blank"
                            rel="noreferrer"
                        >
                            {' '}
                            курс
                        </a>
                    </p>
                )}
                {value === '2' && (
                    <p className={s.subtitle}>
                        Если хочешь узнать подробнее, как будет устроено
                        взаимодействие ментора с менти, пройди
                        <a
                            href={
                                'https://olearning.o3.ru/program/1003262-mentor-v-ozon-expertplace'
                            }
                            target="_blank"
                            rel="noreferrer"
                        >
                            {' '}
                            курс
                        </a>
                    </p>
                )}
                <div className={s.grid_wrapper}>
                    <div className={s.grid}>
                        {steps?.map((step, index) => (
                            <div className={s.step} key={index}>
                                <span>
                                    {String(index + 1).padStart(2, '0')}
                                </span>
                                <p>{step}</p>
                            </div>
                        ))}
                        {user?.expertState === 'active' ? (
                            <Link
                                to={ROUTES.personalCabinet.me.root}
                                onClick={() => {
                                    window.scrollTo({
                                        top: 0,
                                        behavior: 'auto',
                                    })
                                }}
                                className={s.grid_button}
                            >
                                <AppButton>Перейти в личный кабинет</AppButton>
                            </Link>
                        ) : (
                            <AppButton
                                className={s.grid_button}
                                onClick={scrollToElem}
                            >
                                Хочу присоединиться
                            </AppButton>
                        )}
                    </div>
                </div>
                <img src={img} alt="" />
            </div>
            <div className={s.section_bot}>
                <p className={s.title}>{title}</p>
                <p className={s.desc}>{desc}</p>
                <div className={s.cards}>
                    {cards?.map((card, index) => (
                        <div
                            className={s.card}
                            key={index}
                            style={{ backgroundImage: `url(${card.src})` }}
                        >
                            <div className={s.card_text}>{card.text}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
