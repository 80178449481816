import { IExpert, IExpertList, IExpertListParams, IExpertPatch } from '../model'
import { adminApiInstance, apiInstance } from '../../../../../shared/api'

export const expertsApi = {
    getExperts: (params: IExpertListParams) =>
        adminApiInstance.get<IExpertList>(`/experts`, { params }),
    getExpert: (id: number) => adminApiInstance.get<IExpert>(`/experts/${id}`),
    patchExpert: (id: number, body: Partial<IExpertPatch>) =>
        adminApiInstance.patch<IExpert>(`/experts/${id}`, body),
    deleteExperts: (ids: number[]) =>
        adminApiInstance.delete(`/experts`, { data: { ids } }),
    deleteExpert: (id: number) => adminApiInstance.delete(`/experts/${id}`),
    getTags: (params: any) => adminApiInstance.get('/tags', { params }),
    getCities: (params: { limit: number }) =>
        apiInstance.get('/search/city', { params }),
    uploadPhoto: (photo: FormData) =>
        apiInstance.post("/expert/photo", photo),
}
