import React, { useEffect, useState } from 'react'
import {
    ModalWindow,
    ModalWindowPropsType,
} from '../../../../shared/ui/ModalWindow'
import { AppButton } from '../../../../shared/ui/Button'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper/modules'
import clsx from 'clsx'
import { useFetchProfile } from '../../../../entities/profile/api/queries'
import { marketItemType } from '../../../../entities/market/model/types'

import s from './marketItem.module.scss'
import { marketApi } from '../../../../entities/market/api'
import { css } from '@emotion/react'

interface MarketItemProps extends ModalWindowPropsType {
    selectedItem: marketItemType
    points: number | undefined
    onOrderPlaced: () => void
}

export const MarketItem: React.FC<MarketItemProps> = ({
    selectedItem,
    onOrderPlaced,
    points,
    ...props
}) => {
    const { refetch } = useFetchProfile()

    const { name, description, cost, variants, photos, id, preview } =
        selectedItem
    const [activeSize, setActiveSize] = useState<string | null>(null)
    const [isOutOfStoke, setIsOutOfStoke] = useState(false)

    useEffect(() => {
        setIsOutOfStoke(variants.every((variant) => variant.quantity === 0))
    }, [variants])

    const orderButtonClick = async () => {
        if (activeSize !== null && points! >= cost) {
            try {
                onOrderPlaced()
                await marketApi.postBuyMerchandise({ id: id, size: activeSize })
                await refetch()
                props.setIsOpened(false)
                setActiveSize(null)
            } catch (e) {
                console.log(e)
                alert('Something went wrong')
            }
        } else if (points! < cost) {
            alert('Недостаточно средств')
            props.setIsOpened(false)
        } else {
            alert('Пожалуйста, выбери размер перед заказом')
        }
    }

    return (
        <ModalWindow {...props} className={s.modalWindow}>
            <div className={s.marketItemWrapper}>
                <div className={s.leftSide}>
                    <Swiper
                        slidesPerView={1}
                        pagination={{ clickable: true }}
                        modules={[Pagination, Navigation]}
                        className={s.swiperContainer}
                        navigation={{ enabled: true }}
                    >
                        <SwiperSlide className={s.swiperSlide}>
                            <img
                                src={preview}
                                alt=""
                                className={s.leftSide__merchandiseImg}
                            />
                        </SwiperSlide>
                        {photos.map((img, index) => (
                            <SwiperSlide className={s.swiperSlide} key={index}>
                                <img
                                    src={img}
                                    alt="itemImage"
                                    className={s.leftSide__merchandiseImg}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <div className={s.rightSide}>
                    <p className={s.rightSide__price}>{cost} баллов</p>
                    <p className={s.rightSide__name}>{name}</p>
                    <p className={s.rightSide__description}>{description}</p>

                    <div className={s.rightSide__sizesWrapper}>
                        <p className={s.rightSide__sizesWrapper_text}>
                            Размеры:
                        </p>
                        <div className={s.rightSide__sizesWrapper_sizes}>
                            {variants.map(({ size, quantity }, index) =>
                                quantity >= 1 ? (
                                    <div
                                        className={clsx([s.size], {
                                            [s.active]: activeSize === size,
                                        })}
                                        key={index}
                                        onClick={() => setActiveSize(size)}
                                    >
                                        {size}
                                    </div>
                                ) : null
                            )}
                        </div>
                    </div>

                    {isOutOfStoke ? (
                        <AppButton
                            size="lg"
                            variant="ghost"
                            onClick={orderButtonClick}
                            className={s.rightSide__order}
                            disabled={true}
                            css={css`
                                color: #000;
                            `}
                        >
                            Нет в наличии
                        </AppButton>
                    ) : (
                        <AppButton
                            size="lg"
                            variant="ghost"
                            onClick={orderButtonClick}
                            className={s.rightSide__order}
                        >
                            Заказать
                        </AppButton>
                    )}
                </div>
            </div>
        </ModalWindow>
    )
}
