import { IReviewsGet } from '../../../../../model/types'
import React, { useState } from 'react'
import { useSmartForm } from '../../../../../../../../../services/formProcessing'
import moment from 'moment/moment'
import { adminApiReviews } from '../../../../../api'
import { getISOString } from '../../../../../../../../../shared/helpers/getISOString'
import s from '../programsReviewsForm.module.scss'
import { SmartForm } from '../../../../../../../../../services/formProcessing/ui/SmartForm'
import { SmartCheckbox } from '../../../../../../../../../shared/ui/Checkbox'
import { TextField } from '../../../../../../../../../shared/ui/TextField'
import { SmartSelect } from '../../../../../../../../../shared/ui/Select'
import { AppButton } from '../../../../../../../../../shared/ui/Button'

type ProgramsReviewsType = {
    review?: IReviewsGet
    status: 'edit' | 'add'
    setStatus: (value: 'add' | 'edit' | null) => void
    reset?: () => void
}

const defaultValues = {
    clearEnabledUntil: false,
    enabledSince: '',
    enabledUntil: '',
    fullName: '',
    isEnabled: true,
    position: '',
    priority: 0,
    rating: 0,
    state: {
        value: 'awaiting',
        label: 'На модерации',
    },
    text: '',
    expertInfo: '',
    deleteReview: false,
    type: {},
}

const stateOptions: {
    value: string
    label: string
}[] = [
    {
        value: 'moderationPending',
        label: 'Ожидает',
    },
    {
        value: 'moderationAccepted',
        label: 'Одобрено',
    },
    {
        value: 'moderationRejected',
        label: 'Отклонено',
    },
]

export const EditForm: React.FC<ProgramsReviewsType> = ({
    review,
    status,
    setStatus,
}) => {
    const initialState = review ? review.state : 'awaiting'

    const [state, setState] = useState(initialState)
    const form = useSmartForm({
        defaultValues: {
            ...defaultValues,
            priority: review?.priority,
            isEnabled: review?.isEnabled,
            enabledSince: moment(review?.enabledSince).format('YYYY-MM-DD'),
            enabledUntil: moment(review?.enabledUntil).format('YYYY-MM-DD'),
            rating: review?.rating,
            fullName: review?.fullName,
            position: review?.position,
            text: review?.text,
            state: findLabelByValue(stateOptions, review!.state),
            expertInfo: `ID: ${review?.program.id}, ${review?.program.name}`,
        },
    })

    function findLabelByValue(
        array: {
            value: string
            label: string
        }[],
        value: string
    ) {
        return array.filter((item) => item.value === value)
    }

    const onSubmit = async (data: any) => {
        if (data) {
            if (data.deleteReview) {
                try {
                    await adminApiReviews.deleteReviewsById(Number(review?.id))
                    setStatus(null)
                } catch (e) {
                    console.log(e)
                    alert('Something went wrong')
                }
            } else {
                try {
                    await adminApiReviews.patchReviewsById(Number(review?.id), {
                        isEnabled: data.isEnabled,
                        clearEnabledUntil: data.clearEnabledUntil,
                        enabledUntil: getISOString(data.enabledUntil),
                        fullName: data.fullName,
                        position: data.position,
                        priority: Number(data.priority),
                        rating: Number(data.rating),
                        state: state,
                        text: data.text,
                    })
                    setStatus(null)
                } catch (e) {
                    console.log(e)
                    alert('Something went wrong')
                }
            }
        }
    }

    const handleClickCancel = () => {
        setStatus(null)
    }

    const changeState = (data: any) => {
        setState(data.value)
    }

    return (
        <div className={s.wrapper}>
            <SmartForm form={form} submit={() => {}}>
                {review && <p className={s.id}>ID: {review?.id}</p>}
                <div className={s.checkbox}>
                    <SmartCheckbox name="isEnabled" label={'Активность'} />
                </div>
                <TextField
                    label="Сортировка"
                    name="priority"
                    type="number"
                    placeholder="Введите значение от 1 до 500"
                />
                <div className={s.doubleFields}>
                    <TextField
                        className={s.doubleFields_wrapper}
                        label="Начало активности"
                        name="enabledSince"
                        type="date"
                        required={true}
                        disabled={status === 'edit'}
                    />
                    <TextField
                        className={s.doubleFields_wrapper}
                        label="Окончание активности"
                        name="enabledUntil"
                        type="date"
                    />
                </div>
                {review?.expert && (
                    <TextField
                        label="Эксперт"
                        name="expertInfo"
                        type="text"
                        disabled={status === 'edit'}
                    />
                )}
                <div className={s.doubleFields}>
                    <TextField
                        className={s.doubleFields_wrapper}
                        label="Автор отзыва"
                        name="fullName"
                        type="text"
                    />
                    <TextField
                        className={s.doubleFields_wrapper}
                        label="Должность"
                        name="position"
                        type="text"
                    />
                </div>
                <div className={s.doubleFields}>
                    <SmartSelect
                        className={`${s.doubleFields_wrapper} ${s.doubleFields_select}`}
                        label="Статус публикации"
                        placeholder={'Выберите статус публикации'}
                        name="state"
                        onChange={(data) => changeState(data)}
                        options={stateOptions}
                    />
                    <TextField
                        className={s.doubleFields_wrapper}
                        label="Рейтинг"
                        name="rating"
                        placeholder="Поставьте оценку от 1 до 5"
                        type="number"
                        min="1"
                        max="5"
                    />
                </div>
                <TextField label="Отзыв" name="text" as="textarea" />
                <div className={s.deleteCheckbox}>
                    <SmartCheckbox
                        name="deleteReview"
                        label="Удалить отзыв без возможности восстановления"
                    />
                </div>
                <div className={s.buttons}>
                    <AppButton onClick={form.handleSubmit(onSubmit)}>
                        Сохранить изменения
                    </AppButton>
                    <p onClick={handleClickCancel} className={s.cancel}>
                        Отменить
                    </p>
                </div>
            </SmartForm>
        </div>
    )
}
