import { css } from '@emotion/react'
import { theme } from '../../../../shared/styles/theme'
import { AppButton } from '../../../../shared/ui/Button'
import {
    ModalWindow,
    ModalWindowPropsType,
} from '../../../../shared/ui/ModalWindow'
import { Typography } from '../../../../shared/ui/Typography'

type PropsType = ModalWindowPropsType & {
    roleType?: string
}

export const CreateConsultationApplicationSuccessModal = ({
    roleType,
    ...props
}: PropsType) => {
    return (
        <ModalWindow {...props}>
            <div>
                <Typography
                    as="h4"
                    css={css`
                        margin-bottom: 16px;
                    `}
                >
                    Спасибо, твоя заявка отправлена!
                </Typography>
                <Typography
                    css={css`
                        margin-bottom: 32px;
                    `}
                    color={theme.color.darkSpace[400]}
                    as="t1"
                >
                    Когда заявка будет принята,
                    {roleType === 'mentor' ? ' ментор ' : ' консультант '}
                    свяжется с тобой для обсуждения деталей.
                </Typography>
                <AppButton onClick={() => props.setIsOpened(false)}>
                    Понятно
                </AppButton>
            </div>
        </ModalWindow>
    )
}
