import { css } from '@emotion/react'
import { theme } from '../../../../shared/styles/theme'
import { AppButton } from '../../../../shared/ui/Button'
import {
    ModalWindow,
    ModalWindowPropsType,
} from '../../../../shared/ui/ModalWindow'
import { Typography } from '../../../../shared/ui/Typography'

type PropsType = ModalWindowPropsType

export const CreateProgrammApplicationSuccessModal = ({
    ...props
}: PropsType) => {
    return (
        <ModalWindow {...props}>
            <div>
                <Typography
                    as="h4"
                    css={css`
                        margin-bottom: 16px;
                    `}
                >
                    Спасибо, твоя заявка отправлена!
                </Typography>
                <Typography
                    css={css`
                        margin-bottom: 32px;
                    `}
                    color={theme.color.darkSpace[400]}
                    as="t1"
                >
                    Как только группа будет набрана, тренер свяжется с тобой и
                    сообщит все детали и даты.
                </Typography>
                <AppButton
                    css={css`
                        display: flex;
                        align-items: center;
                        margin: 0 auto;
                    `}
                    onClick={() => props.setIsOpened(false)}
                >
                    Понятно
                </AppButton>
            </div>
        </ModalWindow>
    )
}
