import React, {useState} from 'react';
import {IAdminPanelPropsType} from "../../../model/types";
import {AdminPageLayout} from "../../../ui/adminPageLayout/layout";
import {TagList} from "./list";
import {TagForm} from "./form";
import {ITagItem, TagStatusType} from "../model";
import {useList} from "../../../../../shared/hooks/useList/useList";
import {tagApi} from "../api";

export const Expertise: React.FC<IAdminPanelPropsType> = ({title}) => {
    const [status, setStatus] = useState<TagStatusType | null>(null)
    const [id, setId] = useState<number>()

    const query = useList<ITagItem>({
        key: ['adminTags'],
        apiMethod: tagApi.getTags,
    })

    if (status) return (
        <TagForm
            id={id}
            status={status}
            setStatus={setStatus}
            reset={query.reset}
        />
    )

    return (
        <AdminPageLayout title={title}>
            <TagList
                setId={setId}
                setStatus={setStatus}
                query={query}
            />
        </AdminPageLayout>
    )
}
