import { SmartForm } from '../../../../../../../../services/formProcessing/ui/SmartForm'
import { useSmartForm } from '../../../../../../../../services/formProcessing'
import { useList } from '../../../../../../../../shared/hooks/useList/useList'
import { programsReviewsApi } from '../../api'
import { IProgramReview } from '../../model/types'
import React, { SyntheticEvent, useEffect, useState } from 'react'
import s from './programsReviews.module.scss'
import { Icons } from '../../../../../../../../shared/icons'
import { TextField } from '../../../../../../../../shared/ui/TextField'
import { AppButton } from '../../../../../../../../shared/ui/Button'
import clsx from 'clsx'
import { Pagination } from '../../../../../../../../widgets/pagination/ui/Pagination'
import { ModalWindow } from '../../../../../../../../shared/ui/ModalWindow'
import { adminApiReviews } from '../../../../api'

interface IColumn {
    label: string
    order: 'asc' | 'desc' | 'default'
    sort: string
}

interface IRow extends IProgramReview {
    checked: boolean
}

const COLUMNS: IColumn[] = [
    { label: 'ID', order: 'default', sort: 'id' },
    { label: 'Активность', order: 'default', sort: 'isEnabled' },
    { label: 'Сортировка', order: 'default', sort: 'priority' },
    { label: 'Программа', order: 'default', sort: 'programName' },
    { label: 'Отзыв', order: 'default', sort: 'reviewText' },
    { label: 'Статус', order: 'default', sort: 'state' },
]

type PropsType = {
    setStatus: (value: 'add' | 'edit' | null) => void
    status: 'add' | 'edit' | null
    setReviewId: (id: number) => void
}

export const ProgramsReviews: React.FC<PropsType> = ({
    setStatus,
    status,
    setReviewId,
}) => {
    const [columns, setColumns] = useState<IColumn[]>(COLUMNS)
    const [rows, setRows] = useState<IRow[]>([])
    const [limit, setLimit] = useState(10)
    const [isOpened, setIsOpened] = useState(false)
    const [selectedId, setSelectedId] = useState<{ id: number }[]>([])

    const programsReviewsForm = useSmartForm({
        defaultValues: {
            q: '',
            limit: { value: limit, label: limit },
        },
    })

    const {
        items: programsReviewsList,
        setFilters,
        page,
        total,
        setPage,
        nextPage,
        prevPage,
        reset,
    } = useList<IProgramReview>({
        key: 'programsReviews',
        apiMethod: (params) => programsReviewsApi.getProgramsReviews(params),
        defaultLimit: limit,
    })

    const onFiltersApply = (data: any) => {
        console.log(data)
        setFilters({ ...data, limit, q: data.q })
    }

    const onPrev = (evt: SyntheticEvent) => {
        evt.preventDefault()
        prevPage()
    }

    const onNext = (evt: SyntheticEvent) => {
        evt.preventDefault()
        nextPage()
    }

    const handleChangeLimit = ({ value }: any) => {
        setLimit(value)
        setFilters({ limit: value })
        programsReviewsForm.setValue('q', '')
    }

    const handleClickEdit = () => {
        const [{ id }] = selectedId
        setStatus('edit')
        setReviewId(id)
    }

    const removeReviews = async () => {
        const ids = selectedId.map((el) => el.id)
        try {
            await adminApiReviews.deleteReviews({ ids })
            setIsOpened(false)
            setSelectedId([])
            reset()
        } catch (e) {
            console.log(e)
            alert('Something went wrong')
        }
    }

    const isRowActive = (id: number) => selectedId.find((el) => el.id === id)

    const toggleCheckbox = (value: IRow) => {
        setRows((rows) =>
            rows.map((el) =>
                el.id === value.id ? { ...el, checked: !el.checked } : el
            )
        )
        if (selectedId.find((el) => el.id === value.id)) {
            setSelectedId((item) => item.filter(({ id }) => id !== value.id))
        } else {
            setSelectedId([...selectedId, { id: value.id }])
        }
    }

    const selectAllCheckbox = () => {
        if (selectedId.length === rows.length) {
            setRows((rows) => rows.map((el) => ({ ...el, checked: false })))
            setSelectedId([])
        } else {
            setRows((rows) => rows.map((el) => ({ ...el, checked: true })))
            setSelectedId(rows.map((el) => ({ id: el.id })))
        }
    }

    const sortTable = (column: IColumn) => {
        setFilters({
            ...programsReviewsForm.getValues(),
            limit,
            sort: column.sort,
            order: column.order === 'asc' ? 'desc' : 'asc',
        })
        setColumns((columns) =>
            columns.map((el) => {
                return el.sort === column.sort &&
                    el.sort !== 'programName' &&
                    el.sort !== 'reviewText'
                    ? { ...el, order: el.order === 'asc' ? 'desc' : 'asc' }
                    : { ...el, order: 'default' }
            })
        )
    }

    useEffect(() => {
        setRows(programsReviewsList.map((el) => ({ ...el, checked: false })))
    }, [programsReviewsList, status])

    return (
        <>
            <SmartForm form={programsReviewsForm} submit={onFiltersApply}>
                <div className={s.FormWrapper}>
                    <div className={s.Search}>
                        <div className={s.Search__searchLine}>
                            <Icons.admin.search />
                            <TextField
                                className={s.Search__field}
                                name="q"
                                placeholder="Поиск"
                            />
                        </div>
                        <AppButton variant="ghost" className={s.Search__button}>
                            Найти
                        </AppButton>
                        <AppButton
                            onClick={(e: SyntheticEvent) => {
                                e.preventDefault()
                                setStatus('add')
                            }}
                        >
                            +{'\u00A0'}Добавить{'\u00A0'}элемент
                        </AppButton>
                    </div>
                    <div className={s.ReviewsList}>
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <input
                                            type="checkbox"
                                            onChange={selectAllCheckbox}
                                        />
                                    </th>
                                    {columns.map((el) => (
                                        <th
                                            key={el.label}
                                            onClick={() => sortTable(el)}
                                        >
                                            <span>
                                                {el.label}
                                                {el.label !== 'Программа' &&
                                                    el.label !== 'Отзыв' && (
                                                        <Icons.M.ChevronUp
                                                            className={clsx(
                                                                s.ReviewsList__sort,
                                                                {
                                                                    [s.ReviewsList__sortAsc]:
                                                                        el.order ===
                                                                        'asc',
                                                                }
                                                            )}
                                                        />
                                                    )}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map((el, index) => (
                                    <tr
                                        key={index}
                                        className={clsx(s.row, {
                                            [s.row_active]: isRowActive(el.id),
                                        })}
                                    >
                                        <td>
                                            <input
                                                type="checkbox"
                                                onChange={() =>
                                                    toggleCheckbox(el)
                                                }
                                                checked={el.checked}
                                            />
                                        </td>
                                        <td>{el.id}</td>
                                        <td>{el.isEnabled ? 'Да' : 'Нет'}</td>
                                        <td>{el.priority}</td>
                                        <td>{el.programName}</td>
                                        <td>{el.reviewText}</td>
                                        <td>
                                            <div className={s.StatusInfo}>
                                                <div
                                                    className={clsx([
                                                        s.StatusCircle,
                                                        {
                                                            [s.StatusCircle__red]:
                                                                el.state ===
                                                                'moderationRejected',
                                                            [s.StatusCircle__green]:
                                                                el.state ===
                                                                'moderationAccepted',
                                                            [s.StatusCircle__yellow]:
                                                                el.state ===
                                                                'moderationPending',
                                                        },
                                                    ])}
                                                />
                                                <p
                                                    className={
                                                        s.ReviewsList__status
                                                    }
                                                >
                                                    {el.state === 'awaiting' &&
                                                        'На модерации'}
                                                    {el.state ===
                                                        'moderationPending' &&
                                                        'Ожидает'}
                                                    {el.state ===
                                                        'moderationAccepted' &&
                                                        'Одобрено'}
                                                    {el.state ===
                                                        'moderationRejected' &&
                                                        'Отклонено'}
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className={s.footer}>
                    <div className={s.footer_buttons}>
                        {selectedId.length === 1 && (
                            <div
                                className={clsx(s.footer_buttons_icon, s.edit)}
                                onClick={handleClickEdit}
                            >
                                <Icons.M.Edit />
                                Редактировать
                            </div>
                        )}
                        {selectedId.length >= 1 && (
                            <div
                                className={clsx(
                                    s.footer_buttons_icon,
                                    s.remove
                                )}
                                onClick={() => {
                                    setIsOpened(true)
                                }}
                            >
                                <Icons.M.Cancelled />
                                Удалить
                            </div>
                        )}
                    </div>
                    <Pagination
                        page={page}
                        total={total}
                        limit={limit}
                        handleChangeLimit={handleChangeLimit}
                        onNext={onNext}
                        onPrev={onPrev}
                        setPage={setPage}
                    />
                </div>
            </SmartForm>

            <ModalWindow isOpened={isOpened} setIsOpened={setIsOpened}>
                <div className={s.modal}>
                    <p className={s.modal_title}>
                        Вы уверены, что хотите удалить выбранные элементы?
                    </p>
                    <div className={s.modal_controls}>
                        <AppButton onClick={removeReviews}>
                            Да, удалить
                        </AppButton>
                        <AppButton
                            onClick={() => {
                                setIsOpened(false)
                                setSelectedId([])
                                setRows(
                                    programsReviewsList.map((el) => ({
                                        ...el,
                                        checked: false,
                                    }))
                                )
                            }}
                            variant="ghost"
                        >
                            Нет, оставить
                        </AppButton>
                    </div>
                </div>
            </ModalWindow>
        </>
    )
}
