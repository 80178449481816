export const filtersDataList = [
    {
        name: 'types',
        label: 'Направления',
        items: [
            {
                label: 'Менторы',
                value: 'mentors',
                text: 'Ментор - это эксперт в определённой сфере, который готов в формате индивидуальных встреч поможет тебе стать лучше в теме своей экспертизы.',
            },
            {
                label: 'Консультанты',
                value: 'consultants',
                text: 'Консультант - эксперт в определённой сфере, который готов помочь тебе с конкретной задачей или поучаствовать в проекте.',
            },
            {
                label: 'Программы от тренеров',
                value: 'programs',
                text: 'Внутренний тренер - эксперт, который переложил свои знания и опыт в вебинар и готов провести его для тебя.',
            },
        ],
    },
    {
        name: 'format',
        label: 'Формат',
        items: [
            {
                label: 'Онлайн',
                value: 'online',
            },
            {
                label: 'Офлайн',
                value: 'offline',
            },
            {
                label: 'Не имеет значения',
                value: '',
            },
        ],
    },
    // {
    //     name: 'periodicity',
    //     label: 'Регулярность',
    //     items: [
    //         {
    //             label: 'Разовая консультация',
    //             value: 'oneTime',
    //         },
    //         {
    //             label: 'Участие в проекте',
    //             value: 'projectParticipation',
    //         },
    //     ],
    // },
]
