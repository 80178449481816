import className from './index.module.scss'
import { Tag } from '../../../shared/ui/Tag'
import { Icons } from '../../../shared/icons'
import { css } from '@emotion/react'

const tabs = [
    'Аналитика',
    'Маркетинг',
    'HR',
    'IT',
    'Дизайн',
    'Финансы',
    'Управление',
    'Продажи',
    'Программы и ПО',
]

export const Cover = () => {
    return (
        <div className={className.Container}>
            <div className={className.Background}>
                <Icons.catalogPage.LeftStarBg
                    css={css`
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        opacity: 80%;

                        @media (max-width: 1279px) {
                            top: -20%;
                            left: -20%;
                            width: 65%;
                        }

                        @media (max-width: 767px) {
                            top: -40%;
                            left: -28%;
                            width: 100%;
                            opacity: 32%;
                        }
                    `}
                />
                <Icons.catalogPage.RightStarBg
                    css={css`
                        display: block;
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        opacity: 80%;

                        @media (max-width: 1279px) {
                            right: -15%;
                            bottom: -20%;
                            width: 65%;
                        }

                        @media (max-width: 767px) {
                            right: -25%;
                            bottom: -37%;
                            width: 100%;
                            opacity: 32%;
                        }
                    `}
                />
                <div className={className.ContentWrapper}>
                    <h2 className={className.Title}>
                        Развивайся вместе с лучшими экспертами Ozon по темам
                    </h2>
                    <div className={className.TagsWrapper}>
                        {tabs.length > 0 &&
                            tabs.map((tab) => (
                                <Tag key={tab} size="md">
                                    {tab}
                                </Tag>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
