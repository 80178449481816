import React, { useEffect } from 'react'
import { Layout } from '../../shared/ui/Layout'
import { Container, ContainerVariants } from '../../shared/ui/Container'
import { CoverForExperts } from '../../widgets/coverForExperts'
import { Faq } from '../../widgets/faq'
import { SliderOpportunities } from '../../widgets/sliderOpportunities'
import { StepCards } from '../../widgets/stepCards'
import { AdvantagesForExperts } from './advantages/Advantages'
import { SwitcherExperts } from '../../widgets/switcherExperts'
import { FormExperts } from '../../widgets/formExperts'
import { Tags } from './tags/Tags'

export const LandingForExperts = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Layout>
            <Container>
                <CoverForExperts />
                <SliderOpportunities />
            </Container>
            <Container variant={ContainerVariants.Wide}>
                <StepCards />
            </Container>
            <AdvantagesForExperts />
            <Container variant={ContainerVariants.Wide}>
                <Tags />
                <SwitcherExperts />
            </Container>
            <Container>
                <FormExperts />
                <Faq type="expert" />
            </Container>
        </Layout>
    )
}
