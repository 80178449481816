import { useQuery } from '@tanstack/react-query'
import { adminApiReviews } from './index'
import { IReviewsGet } from '../model/types'

export const useGetReviewsById = (id: number) => {
    return useQuery<IReviewsGet>({
        queryKey: ['adminReviews'],
        queryFn: async () => {
            const response = await adminApiReviews.getReviewsById(id)
            return response.data
        },
    })
}
