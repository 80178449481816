import { apiInstance } from '../../../shared/api'

const seed = Math.floor(Math.random() * 1000)
export const catalogApi = {
    getCatalogCards: (params?: any) =>
        apiInstance.get(`/trainings?seed=${seed}`, {
            params,
        }),
    getCities: (params: { limit: number }) =>
        apiInstance.get('/search/city', { params }),
    getTags: () => apiInstance.get('/search/tag?limit=1000'),
}
