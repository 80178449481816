import React from 'react'
import s from './reviewsPromoForm.module.scss'
import { useSmartForm } from '../../../../../../../../services/formProcessing'
import { SmartForm } from '../../../../../../../../services/formProcessing/ui/SmartForm'
import { TextField } from '../../../../../../../../shared/ui/TextField'
import { SmartCheckbox } from '../../../../../../../../shared/ui/Checkbox'
import { AppButton } from '../../../../../../../../shared/ui/Button'
import { IReviewPatch } from '../../model/types'
import image from '../../../../../../../../shared/assets/adminPanel/image.png'
import videoImage from '../../../../../../../../shared/assets/adminPanel/video.png'
import { Icons } from '../../../../../../../../shared/icons'
import moment from 'moment/moment'
import { reviewsApi } from '../../api'
import { getISOString } from '../../../../../../../../shared/helpers/getISOString'
import { StatusType } from '../../../../../faq/model/types'

type ReviewsType = {
    review?: IReviewPatch
    status: 'edit' | 'add'
    setStatus: (value: StatusType | null) => void
    reset?: () => void
}

const defaultValues: IReviewPatch = {
    clearEnabledUntil: false,
    enabledSince: '',
    enabledUntil: '',
    fullName: '',
    isEnabled: true,
    position: '',
    priority: 0,
    previewVideo: '',
    previewPhoto: '',
}

export const ReviewPromoForm: React.FC<ReviewsType> = ({
    review,
    status,
    setStatus,
}) => {
    const form = useSmartForm<IReviewPatch>({
        defaultValues: review
            ? {
                  ...defaultValues,
                  enabledUntil: moment(review.enabledUntil).format(
                      'YYYY-MM-DD'
                  ),
                  enabledSince: moment(review.enabledSince).format(
                      'YYYY-MM-DD'
                  ),
                  id: review.id,
                  fullName: review.fullName,
                  isEnabled: review.isEnabled,
                  position: review.position,
                  priority: review.priority,
                  previewPhoto: review.previewPhoto,
                  previewVideo: review.previewVideo,
              }
            : {
                  isEnabled: true,
              },
    })
    const onSubmit = async (data: IReviewPatch) => {
        const value: IReviewPatch = {
            id: data.id,
            enabledUntil: getISOString(data.enabledUntil),
            fullName: data.fullName,
            isEnabled: data.isEnabled,
            position: data.position,
            priority: Number(data.priority),
            previewPhoto: data.previewPhoto,
            previewVideo: data.previewVideo,
        }

        if (status === 'add') {
            try {
                await reviewsApi.postCreatePromoReview(value)
                setStatus(null)
            } catch (e) {
                console.log(e)
                alert('Something went wrong')
            }
        } else {
            try {
                await reviewsApi.patchPromoReviews(data.id!, value)
                setStatus(null)
            } catch (e) {
                console.log(e)
                alert('Something went wrong')
            }
        }
    }
    const handleClickCancel = () => {
        setStatus(null)
    }

    const uploadPreview = async () => {
        try {
            const fileInput = document.createElement('input')
            fileInput.type = 'file'
            fileInput.accept = 'image/*'
            fileInput.onchange = async (event) => {
                const file = (event.target as HTMLInputElement).files?.[0]
                if (!file) return

                const formData = new FormData()
                formData.append('photo', file)

                const response = await reviewsApi.uploadPreviewPhoto(formData)

                const imageUrl = response.data.url

                form.setValue('previewPhoto', imageUrl)
                form.watch()
            }
            fileInput.click()
        } catch (e) {
            console.log(e)
            alert('Something went wrong')
        }
        form.watch()
    }

    const uploadPreviewVideo = async () => {
        try {
            const fileInput = document.createElement('input')
            fileInput.type = 'file'
            fileInput.accept = 'video/*'
            fileInput.onchange = async (event) => {
                const file = (event.target as HTMLInputElement).files?.[0]
                if (!file) return

                const formData = new FormData()
                formData.append('video', file)

                const response = await reviewsApi.uploadVideoPreview(formData)

                const videoUrl = response.data.url

                form.setValue('previewVideo', videoUrl)
                form.watch()
            }
            fileInput.click()
        } catch (e) {
            console.log(e)
            alert('Something went wrong')
        }
        form.watch()
    }

    return (
        <div className={s.wrapper}>
            <SmartForm form={form} submit={() => {}}>
                {review && <p className={s.id}>ID: {review?.id}</p>}
                <div className={s.checkbox}>
                    <SmartCheckbox name="isEnabled" label={'Активность'} />
                </div>
                <TextField
                    label="Сортировка"
                    name="priority"
                    type="number"
                    placeholder="500"
                />
                <div className={s.date_wrapper}>
                    <TextField
                        className={s.date}
                        label="Начало активности"
                        name="enabledSince"
                        type="date"
                        required={true}
                        disabled={status === 'edit'}
                    />
                    <TextField
                        className={s.date}
                        label="Окончание активности"
                        name="enabledUntil"
                        type="date"
                    />
                </div>

                <p className={s.photoTitle}>Фото превью</p>
                <div className={s.photoPreview}>
                    {(form.getValues('previewPhoto') && (
                        <img
                            src={form.getValues('previewPhoto')}
                            alt="image"
                            className={s.image}
                        />
                    )) || <img src={image} alt="image" />}
                    <button className={s.uploadImage} onClick={uploadPreview}>
                        Загрузить фото
                        <Icons.admin.uploadImage />
                    </button>
                </div>

                <p className={s.photoTitle}>Видео</p>
                <div className={s.photoPreview}>
                    {(form.getValues('previewVideo') && (
                        <video
                            src={form.getValues('previewVideo')}
                            className={s.video}
                        />
                    )) || <img src={videoImage} alt="image" />}
                    <button
                        className={s.uploadImage}
                        onClick={uploadPreviewVideo}
                    >
                        Загрузить видео
                        <Icons.admin.uploadImage></Icons.admin.uploadImage>
                    </button>
                </div>

                <div className={s.personalInfo}>
                    <TextField
                        className={s.personalInfo_field}
                        label="Автор отзыва"
                        name="fullName"
                        placeholder="Семёнов Антон"
                    />
                    <TextField
                        className={s.personalInfo_field}
                        label="Должность"
                        name="position"
                        placeholder="Менеджер по продажам"
                    />
                </div>

                <div className={s.buttons}>
                    <AppButton onClick={form.handleSubmit(onSubmit)}>
                        {status === 'edit' ? 'Сохранить изменения' : 'Создать'}
                    </AppButton>
                    <p onClick={handleClickCancel} className={s.cancel}>
                        Отменить
                    </p>
                </div>
            </SmartForm>
        </div>
    )
}
