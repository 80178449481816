import React, { useState } from 'react'
import { Container } from '../../../../shared/ui/Container'
import { AdminPanelRoutes } from '../../routes'
import s from './index.module.scss'
import { AdminPanelMenu } from '../../../../widgets/adminPanelMenu'
import { LinkPresets } from '../../../../shared/ui/Link'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { useAdminAutoAuth } from '../../../../features/auth/hooks/useAdminAutoAuth'
import { ROUTES } from '../../../../config/routes'
import { AdminAuth } from '../../../../features/adminAuth'
import { useAutoAuth } from "../../../../features/auth";

export const AdminPanel = () => {
    const history = useHistory()
    const { isReady: isAdminAuthReady } = useAdminAutoAuth()
    const { isReady: isAuthReady } = useAutoAuth()
    if (!isAdminAuthReady || !isAuthReady) return null

    return (
        <>
            <Switch>
                <Route exact path={ROUTES.adminPanel.auth}>
                    <AdminAuth />
                </Route>
                <Route>
                    <div className={s.header}></div>
                    <div className={s.layout}>
                        <Container>
                            <LinkPresets.BackBlue
                                to={'#'}
                                onClick={() => history.goBack()}
                            />
                            <div className={s.wrapper}>
                                <AdminPanelMenu />
                                <AdminPanelRoutes />
                            </div>
                        </Container>
                    </div>
                </Route>
            </Switch>
        </>
    )
}
