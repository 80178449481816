import { ApplicationListWrapper } from '../../../../widgets/applicationListWrapper'

import s from './applications.module.scss'
import { useFetchProfile } from '../../../../entities/profile/api/queries'
import React from 'react'

export const ApplicationsPage = () => {
    const { data } = useFetchProfile()

    return (
        <div className={s.applications}>
            <div className={s.title}>
                <h3 className={s.applicationTitle}>
                    Заявки
                    {data?.pendingApplications! >= 1 && (
                        <sup>{data?.pendingApplications}</sup>
                    )}
                </h3>
            </div>
            <p className={s.applicationDescription}>
                Сюда приходят все заявки на обучение. Ты можешь ознакомиться
                <br /> с анкетами учеников, принять или отклонить заявку
            </p>
            <ApplicationListWrapper />
        </div>
    )
}
