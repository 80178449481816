import {useMutation, useQuery} from "@tanstack/react-query";
import {expertsApi} from "./index";
import {IExpertPatch} from "../model";


const key = 'admin-experts'

export const useGetExpert = (id: number) => {
    return useQuery({
        queryKey: [key],
        queryFn: async () => {
            const res = await expertsApi.getExpert(id)
            return res.data
        }
    })
}

export const usePatchExpert = (reset?: () => void) => {
    return useMutation({
        mutationKey: [key],
        mutationFn: async ({id, data}: {id: number, data: Partial<IExpertPatch> }) => {
            return expertsApi.patchExpert(id, data)
        },
        onSuccess: () => {
            reset?.()
        },
        onError: (e: any) => alert(e.response.data.error)
    })
}

export const useDeleteExperts = (reset?: () => void) => {
    return useMutation({
        mutationKey: [key],
        mutationFn: async (ids: number[]) => {
            return expertsApi.deleteExperts(ids)
        },
        onSuccess: () => reset?.()
    })
}

export const useDeleteExpert = (reset?: () => void) => {
    return useMutation({
        mutationKey: [key],
        mutationFn: async (id: number) => {
            return expertsApi.deleteExpert(id)
        },
        onSuccess: () => reset?.()
    })
}
