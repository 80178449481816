import { useQuery } from '@tanstack/react-query'

import { IReviewPatch } from '../model/types'
import { reviewsApi } from './index'

export const useGetReviewById = (id: number) => {
    return useQuery<IReviewPatch>({
        queryKey: ['review'],
        queryFn: async () => {
            const response = await reviewsApi.getPromoReviewById(id)
            return response.data
        },
    })
}
