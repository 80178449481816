import React from 'react'
import { useState } from 'react'
import { AppButton } from '../../shared/ui/Button'
import { Dropdown } from '../../shared/ui/Dropdown'
import { FormExample } from './formExample'
import { ModalWindowExample } from './modalWindowExample'
import { css } from '@emotion/react'
import { LinkPresets } from '../../shared/ui/Link'
import { ProgressBarExample } from './progressBarExample'
import { SwitcherExample } from './switcherExample'
import { Avatar } from '../../shared/ui/Avatar'
import { ListExample } from './listExample'
import { CreateConsultationApplicationButton } from '../../features/createConsultationApplication'
import { ModalWindowsList } from './modalsExample'
import { Typography } from '../../shared/ui/Typography'
import { useAuth } from '../../features/auth'
import { UseListExample } from '../../shared/hooks/useList/useListExample'

export const Playground = () => {
    const [is, setIs] = useState(false)
    const { login } = useAuth()

    return <UseListExample />

    // return <FormExample />

    return (
        <div
            css={css`
                padding: 40px;
                background: #f4f8ff;
                display: flex;
                flex-direction: column;
                gap: 30px;
                padding-bottom: 500px;
            `}
        >
            <div>
                Buttons
                <div
                    style={{ padding: '20px 0', display: 'flex', gap: '20px' }}
                >
                    <AppButton
                        onClick={() => setIs(true)}
                        disabled={is}
                        size={'lg'}
                        variant="outlined"
                    >
                        Начать обучение
                    </AppButton>
                    <AppButton size="lg" variant="ghost">
                        Начать обучение
                    </AppButton>
                    <AppButton size="lg" variant="filled">
                        Начать обучение
                    </AppButton>
                    <AppButton size="lg" variant="outlined">
                        Начать обучение
                    </AppButton>
                    <AppButton
                        size="md"
                        disabled
                        variant="filled_circle"
                        icon="CircleIconSave"
                    >
                        Начать обучение
                    </AppButton>
                </div>
            </div>
            <div>
                <AppButton onClick={login}>Login</AppButton>
            </div>
            <div>
                <div style={{ marginBottom: 20 }}> Form Example</div>
                <FormExample />
            </div>
            <div>
                <div style={{ marginBottom: 20 }}>Dropdown</div>
                <Dropdown
                    title="Что от меня требуется для того, чтобы стать экспертом?"
                    text="Для того, чтобы поделиться своей экспертизой, вам потребуется предоставить описание программы обучения, фото тренера-эксперта, информацию о вашем опыте, структуру программы, а также выбрать категорию и указать ключевые слова. Это поможет пользователям легко найти вашу программу и ознакомиться с ней, а также создаст доверие и интерес."
                />
            </div>
            <div>
                <div style={{ marginBottom: 20 }}>Modal</div>
                <ModalWindowExample />
            </div>
            <div>
                <div
                    css={css`
                        margin-bottom: 20px;
                        font-size: 30px;
                    `}
                >
                    Link presets:
                </div>
                <div
                    css={css`
                        display: flex;
                        gap: 10px;
                        flex-direction: column;
                    `}
                >
                    <LinkPresets.AddBlock to="/" />
                    <LinkPresets.BackBlue to="/" />
                    <LinkPresets.LearnMore to="/" />
                    <LinkPresets.Hide to="/" />
                    <LinkPresets.BackGray to="/" />
                    <LinkPresets.Remove to="/" />
                </div>
            </div>
            <div>
                <ProgressBarExample />
            </div>
            <div>
                <h2>Swticher ( it uses container queries )</h2>
                <SwitcherExample />

                <div
                    css={css`
                        margin-top: 40px;
                        width: 320px;
                    `}
                >
                    <SwitcherExample />
                </div>
            </div>
            <div>
                <h2>Avatar</h2>
                <div
                    css={css`
                        display: flex;
                        gap: 30px;
                    `}
                >
                    <Avatar />
                    <Avatar url="https://img.freepik.com/free-vector/mysterious-mafia-man-smoking-cigarette_52683-34828.jpg?w=826&t=st=1708518582~exp=1708519182~hmac=dbc3115327a5098a04e2f52b5e7603c7bc392bdec0007d1d608a8d5ab1b7d01e" />
                </div>
            </div>
            <ListExample />
            <div>
                <h1>Application card</h1>
                {/*<ApplicationCard />*/}
            </div>
            <div>{/*<CreateConsultationApplicationButton />*/}</div>
            <div>
                <Typography
                    as="h2"
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    Modal window list
                </Typography>
                <ModalWindowsList />
            </div>
        </div>
    )
}
