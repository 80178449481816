import React from 'react'
import { Route } from 'react-router-dom'
import { ROUTES } from '../../config/routes'
import { AboutMarketPage } from './subpages/aboutMarket'

import { MyPrograms } from './subpages/myPrograms'
import { Reviews } from './subpages/reviews'
import { ExpertsMarket } from './subpages/expertsMarket'
import { ApplicationsPage } from './subpages/applications'
import { MyProfilePage } from './subpages/myProfile'
import { ProgramApplicationPage } from './subpages/applications/subpages/programApplications'
import { Training } from "./subpages/training";

export const PersonalAccountRouter = () => {
    return (
        <>
            <Route path={ROUTES.personalCabinet.aboutMarket}>
                <AboutMarketPage />
            </Route>

            <Route path={ROUTES.personalCabinet.me.root}>
                <Route path={ROUTES.personalCabinet.me.root} exact>
                    <MyProfilePage />
                </Route>
            </Route>

            <Route path={ROUTES.personalCabinet.applications.root}>
                <Route exact path={ROUTES.personalCabinet.applications.root}>
                    <ApplicationsPage />
                </Route>

                <Route
                    path={`${ROUTES.personalCabinet.applications.programm}/:id`}
                >
                    <ProgramApplicationPage />
                </Route>
            </Route>

            <Route path={ROUTES.personalCabinet.myPrograms}>
                <MyPrograms />
            </Route>

            <Route path={ROUTES.personalCabinet.reviews}>
                <Reviews />
            </Route>

            <Route path={ROUTES.personalCabinet.training}>
                <Training />
            </Route>

            <Route path={ROUTES.personalCabinet.market}>
                <ExpertsMarket />
            </Route>
        </>
    )
}
