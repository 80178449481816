import { useLayoutEffect, useState } from 'react'

type TScreenType = 'desktop' | 'tablet' | 'mobile' | null

export function useScreenType(): TScreenType {
    const [screenType, setScreenType] = useState<TScreenType>(null)

    useLayoutEffect(() => {
        const matchMobile = matchMedia('(max-width: 767px)')
        const matchTablet = matchMedia(
            '(max-width: 1279px) and (min-width: 768px)'
        )
        const matchDesktop = matchMedia('(min-width: 1280px)')

        if (matchDesktop.matches) setScreenType('desktop')
        if (matchTablet.matches) setScreenType('tablet')
        if (matchMobile.matches) setScreenType('mobile')

        const handleMobileChange = (e: MediaQueryListEvent) => {
            if (e.matches) setScreenType('mobile')
        }

        const handleTabletChange = (e: MediaQueryListEvent) => {
            if (e.matches) setScreenType('tablet')
        }

        const handleDesktopChange = (e: MediaQueryListEvent) => {
            if (e.matches) setScreenType('desktop')
        }

        matchMobile.addEventListener('change', handleMobileChange)
        matchTablet.addEventListener('change', handleTabletChange)
        matchDesktop.addEventListener('change', handleDesktopChange)

        return () => {
            matchMobile.removeEventListener('change', handleMobileChange)
            matchTablet.removeEventListener('change', handleTabletChange)
            matchDesktop.removeEventListener('change', handleDesktopChange)
        }
    }, [])

    return screenType
}