import { ProgramApplicationsWrapper } from '../../../../../../widgets/programApplicationsWrapper'

import s from './programApplications.module.scss'

export const ProgramApplicationPage = () => {
    return (
        <div className={s.programApplicationWrapper}>
            <div className={s.subTitle}>Заявки на программу</div>
            <ProgramApplicationsWrapper />
        </div>
    )
}
