import {
    ModalWindow,
    ModalWindowPropsType,
} from '../../../shared/ui/ModalWindow'
import { UserInfo } from '../../user/ui/userInfo'

import s from './reviewModal.module.scss'
import { ReviewType } from '../model/types'

type PropsType = ModalWindowPropsType & {
    selectedReview?: ReviewType
}

export const ReviewModal = ({ selectedReview, ...props }: PropsType) => {
    if (!selectedReview) return null

    const { fullName, text, position, type } = selectedReview

    return (
        <ModalWindow {...props}>
            <div className={s.reviewWrapper}>
                <UserInfo
                    user={{
                        expertState: 'asd',
                        id: 1,
                        isAdmin: false,
                        username: fullName,
                        post: position,
                    }}
                />
                <p className={s.reviewCategory}>
                    {type === 'mentor' && 'Менторство'}
                    {type === 'consultant' && 'Консультация'}
                    {type === 'program' && 'Программы'}
                </p>
                <p className={s.reviewText}>{text}</p>
            </div>
        </ModalWindow>
    )
}
