import React, { SyntheticEvent } from 'react'
import s from './index.module.scss'
import {
    IAddFaqForm,
    IEditFaqForm,
    IFaqForm,
    IFaqPost,
} from '../../model/types'
import { useSmartForm } from '../../../../../../services/formProcessing'
import { SmartForm } from '../../../../../../services/formProcessing/ui/SmartForm'
import { TextField } from '../../../../../../shared/ui/TextField'
import { SmartCheckbox } from '../../../../../../shared/ui/Checkbox'
import { AppButton } from '../../../../../../shared/ui/Button'
import { AppLink } from '../../../../../../shared/ui/Link'
import {
    useDeleteFaqs,
    useGetFaqById,
    usePatchFaq,
    usePostFaq,
} from '../../api/queries'
import { getISOString } from '../../../../../../shared/helpers/getISOString'
import moment from 'moment'
import { z } from 'zod'

export const FaqForm: React.FC<IFaqForm> = ({
    id,
    type,
    status,
    setStatus,
    reset,
}) => {
    if (status === 'add')
        return (
            <AddFaqForm
                status={status}
                type={type}
                setStatus={setStatus}
                reset={reset}
            />
        )
    if (status === 'edit' && id)
        return (
            <EditFaqForm
                id={id}
                status={status}
                type={type}
                setStatus={setStatus}
                reset={reset}
            />
        )
    return null
}

const AddFaqForm: React.FC<IAddFaqForm> = ({
    status,
    type,
    setStatus,
    reset,
}) => {
    return (
        <Form type={type} status={status} setStatus={setStatus} reset={reset} />
    )
}

const EditFaqForm: React.FC<IEditFaqForm> = ({
    id,
    status,
    type,
    setStatus,
    reset,
}) => {
    const { data, isFetching } = useGetFaqById(id)
    if (isFetching) return null
    return (
        <Form
            id={id}
            data={data}
            type={type}
            status={status}
            setStatus={setStatus}
            reset={reset}
        />
    )
}

const formSchema = z.object({
    isEnabled: z.boolean(),
    priority: z.preprocess(
        (a) => parseInt(String(a), 10),
        z
            .number({ invalid_type_error: 'Введите значение от 1 до 500' })
            .positive({ message: 'Значение должно быть больше нуля' })
            .min(1, { message: 'Значение должно быть больше нуля' })
            .max(500, { message: 'Значение должно быть не больше 500' })
    ),
    question: z.string().min(2, { message: 'Введите вопрос' }),
    answer: z.string().min(2, { message: 'Введите ответ' }),
    enabledSince: z
        .string()
        .min(1, { message: 'Введите дату окончания активности' }),
    enabledUntil: z
        .string()
        .min(1, { message: 'Введите дату окончания активности' }),
})

const Form: React.FC<IFaqForm> = ({
    id,
    status,
    type,
    setStatus,
    data,
    reset,
}) => {
    const form = useSmartForm<IFaqPost & { isDelete: boolean }>(
        {
            defaultValues: {
                id,
                isEnabled: status === 'edit' ? data?.isEnabled : true,
                priority: data?.priority,
                question: data?.question,
                answer: data?.answer,
                enabledSince: moment(data?.enabledSince).format('YYYY-MM-DD'),
                enabledUntil: moment(data?.enabledUntil).format('YYYY-MM-DD'),
                isDelete: false,
            },
        },
        {
            schema: formSchema,
        }
    )
    const handleClickCancel = (evt: SyntheticEvent) => {
        evt.preventDefault()
        setStatus(null)
    }
    const { mutate: post } = usePostFaq(reset)
    const { mutate: patch } = usePatchFaq(reset)
    const { mutate: del } = useDeleteFaqs(reset)
    const onSubmit = async (data: IFaqPost & { isDelete: boolean }) => {
        const value: IFaqPost = {
            id: id!,
            answer: data.answer,
            type: data.type,
            question: data.question,
            isEnabled: data.isEnabled,
            priority: Number(data.priority),
            enabledSince: getISOString(data.enabledSince),
            enabledUntil: getISOString(data.enabledUntil),
        }
        if (status === 'add') post({ ...value, type })
        if (status === 'edit') {
            data.isDelete ? del([data.id]) : patch(value)
        }
        setStatus(null)
    }

    return (
        <div className={s.wrapper}>
            <SmartForm form={form} submit={form.handleSubmit(onSubmit)}>
                {id && <p className={s.h4}>ID: {id}</p>}
                <div className={s.checkbox}>
                    <SmartCheckbox name="isEnabled" label={'Активность'} />
                </div>
                <TextField
                    label="Сортировка"
                    placeholder="500"
                    name="priority"
                    type="number"
                />
                <div className={s.date_wrapper}>
                    <TextField
                        className={s.date}
                        label="Начало активности"
                        placeholder="500"
                        name="enabledSince"
                        type="date"
                    />
                    <TextField
                        className={s.date}
                        label="Окончание активности"
                        placeholder="500"
                        name="enabledUntil"
                        type="date"
                    />
                </div>
                <TextField
                    label="Название"
                    placeholder="Что от меня требуется для того, чтобы стать экспертом?"
                    name="question"
                />
                <TextField
                    label="Описание"
                    placeholder="Для того, чтобы поделиться своей экспертизой, тебе потребуется предоставить
                        описание программы обучения, фото тренера-эксперта, информацию о твоём опыте, структуру
                        программы, а также выбрать категорию и указать ключевые слова. Это поможет
                        пользователям легко найти твою программу и ознакомиться с ней, а также создаст доверие
                        и интерес."
                    name="answer"
                    as="textarea"
                />
                {status === 'edit' && (
                    <div className={s.checkbox}>
                        <SmartCheckbox
                            name="isDelete"
                            label={
                                'Удалить вопрос без возможности восстановления'
                            }
                        />
                    </div>
                )}
                <div className={s.buttons}>
                    <AppButton>
                        {status === 'edit' ? 'Сохранить изменения' : 'Создать'}
                    </AppButton>
                    <AppLink onClick={handleClickCancel} to={''}>
                        Отменить
                    </AppLink>
                </div>
            </SmartForm>
        </div>
    )
}
