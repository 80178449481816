import { AppButton } from '../../../shared/ui/Button'
import React, { useState } from 'react'
import banner1920 from '../../../public/image/landingMain/banner/banner1920.png'
import banner1440 from '../../../public/image/landingMain/banner/banner1440.png'
import banner768 from '../../../public/image/landingMain/banner/banner768.png'
import banner360 from '../../../public/image/landingMain/banner/banner360.png'
import manAvatar from '../../../public/image/landingMain/banner/manAvatar.png'
import womanAvatar from '../../../public/image/landingMain/banner/womanAvatar.png'

import { Icons } from '../../../shared/icons'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../config/routes'
import { VideoPlayer } from './VideoPlayer'

import s from './banner.module.scss'

const data = [
    {
        class: s.hisCard,
        avatar: manAvatar,
        name: 'Сергей Антонов',
        about: 'Эксперт в области анализа данных',
    },
    {
        class: s.herCard,
        avatar: womanAvatar,
        name: 'Анна Степанова',
        about: 'Эксперт по маркетингу',
    },
]

export const Banner = () => {
    const [isOpenVideo, setIsOpenVideo] = useState(false)

    const handleOpenVideo = () => setIsOpenVideo(true)

    return (
        <>
            <VideoPlayer
                isOpenVideo={isOpenVideo}
                setIsOpenVideo={setIsOpenVideo}
            />
            <div className={s.topStyles}>
                <h1 className={s.title}>
                    Ozon Expertplace — <br />
                    место обмена экспертизой сотрудников Ozon
                </h1>
                <p className={s.description}>
                    Развивайся у лучших и сам становись экспертом
                </p>
                <div className={s.wrapper}>
                    <div className={s.buttonWrapper}>
                        <Link to={ROUTES.catalog}>
                            <AppButton
                                size="md"
                                variant="filled"
                                icon="CircleIconArrow"
                                className={s.button}
                            >
                                Начать обучение
                            </AppButton>
                        </Link>
                        <Link to={ROUTES.experts} className={s.expertLink}>
                            <AppButton
                                size="md"
                                variant="outlined"
                                className={s.button}
                            >
                                Поделиться экспертизой
                            </AppButton>
                        </Link>
                    </div>
                    <div className={s.bannerWrapper}>
                        <picture>
                            <source
                                srcSet={banner360}
                                media="(max-width: 767px)"
                            />
                            <source
                                srcSet={banner768}
                                media="(min-width: 768px) and (max-width: 1010px)"
                            />
                            <source
                                srcSet={banner1440}
                                media="(min-width: 1280px) and (max-width: 1699px)"
                            />
                            <img
                                src={banner1920}
                                alt="banner"
                                className={s.imgBanner}
                            />
                        </picture>

                        {data.map((item) => {
                            return (
                                <div className={item.class} key={item.name}>
                                    <img
                                        src={item.avatar}
                                        className={s.avatar}
                                        alt="avatar"
                                    />

                                    <div className={s.info}>
                                        <p className={s.name}>{item.name}</p>
                                        <p className={s.about}>{item.about}</p>
                                    </div>
                                </div>
                            )
                        })}

                        <button
                            className={s.watchVideo}
                            type="button"
                            onClick={handleOpenVideo}
                        >
                            <p className={s.textButton}>
                                Посмотреть видео о платформе
                            </p>
                            <Icons.circleIcons.PlayIcon className={s.play} />
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
