import React from 'react'
import s from './FillingProfile.module.scss'
import clsx from 'clsx'
import { AppButton } from '../../../shared/ui/Button'
import { StepLayout } from './StepLayout'
import { SmartRadio } from '../../../shared/ui/Radio'
import { useSmartForm } from '../../../services/formProcessing'
import { SmartForm } from '../../../services/formProcessing/ui/SmartForm'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../app/store'
import { profileActions } from '../../../entities/profile'
import { IProfileExpertSecondStep } from '../../../entities/profile/model/types'
import { SmartCheckbox } from '../../../shared/ui/Checkbox'
import { useList } from '../../../shared/hooks/useList/useList'
import { tagsApi } from '../../../entities/tags/api'
import { profileApi } from '../../../entities/profile/api'
import {
    transformCityForFetch,
    transformRolesForFetch,
    transformTagsForFetch,
} from './helpers'
import {
    useFetchProfile,
    usePatchProfile,
} from '../../../entities/profile/api/queries'
import { SmartCreatableSelect } from '../../../shared/ui/CreatableSelect'

interface IForm extends IProfileExpertSecondStep {
    tags: { value: number; label: string }[]
    city: { label: string }
    roles: string[]
}

export const FillingProfileSecondStep: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>()
    const nextStep = () => dispatch(profileActions.setStep(3))
    const { data } = useFetchProfile()
    const { mutate, isPending } = usePatchProfile(nextStep)
    const form = useSmartForm<IForm>({
        defaultValues: {
            tags: data?.tags?.map((el, i) => ({ value: i, label: el })),
            interactionFormat: data?.interactionFormat,
            city: { label: data?.city },
            roles: Object.keys(data?.roles).filter(
                (el) => data?.roles[el] === 'active'
            ),
        },
    })
    const { items: fetchTags } = useList<string[]>({
        key: 'tags',
        apiMethod: tagsApi.getTags,
        defaultLimit: 100,
    })
    const { items: fetchCities } = useList<string[]>({
        key: 'cities',
        apiMethod: (params) => profileApi.getCities(params),
        defaultLimit: 100,
    })

    const onSubmit = (formData: IForm) => {
        const fetchData = {
            interactionFormat: formData.interactionFormat,
            roles: transformRolesForFetch(formData?.roles, data?.roles),
            tags: transformTagsForFetch(formData?.tags),
            city: transformCityForFetch(formData?.city),
        }
        mutate(fetchData)
    }

    return (
        <StepLayout>
            <SmartForm
                className={clsx(s.step, s.step_1)}
                form={form}
                submit={form.handleSubmit(onSubmit)}
            >
                <div className={s.section_1}>
                    <p className={s.subtitle}>Выбранные роли эксперта</p>
                    <div className={s.checkboxes}>
                        <div className={s.role}>
                            <SmartCheckbox
                                value={'coach'}
                                name="roles"
                                label="тренер"
                            />
                            <p className={s.roleDescr}>
                                Выбрав роль внутреннего тренера ты сможешь
                                проводить обучение для коллег по самостоятельно
                                созданной программе (предварительно пройдя школу
                                тренера).
                            </p>
                        </div>
                        <div className={s.role}>
                            <SmartCheckbox
                                value={'consultant'}
                                name="roles"
                                label="консультант"
                            />
                            <p className={s.roleDescr}>
                                Выбрав роль консультанта ты сможешь помочь
                                коллегам с определенной задачей или
                                поучаствовать в проекте.
                            </p>
                        </div>
                        <div className={s.role}>
                            <SmartCheckbox
                                value={'mentor'}
                                name="roles"
                                label="ментор"
                            />
                            <p className={s.roleDescr}>
                                Выбрав роль ментора, ты будешь в формате
                                индивидуальных развивающих встреч помогать
                                коллегам стать лучше в теме твоей экспертизы.
                            </p>
                        </div>
                    </div>
                </div>

                <div className={s.section_1}>
                    <div>
                        <p className={clsx(s.subtitle)}>Области экспертизы</p>
                        <p className={clsx([s.desc], [s.descForExpertise])}>
                            По этим тегам пользователи платформы смогут тебя
                            найти. Ты сможешь изменить или дополнить список
                        </p>
                    </div>
                    <SmartCreatableSelect
                        placeholder="Выбери области экспертизы"
                        name="tags"
                        isMulti
                        options={fetchTags.map((el: any, i) => ({
                            value: i,
                            label: el,
                        }))}
                    />
                </div>

                <div className={s.section_1}>
                    <div>
                        <p className={clsx(s.subtitle)}>
                            Формат взаимодействия
                        </p>
                        <p className={s.desc}>
                            Выбери, в каком формате ты бы хотел
                            взаимодействовать с учениками
                        </p>
                    </div>
                    <div className={s.checkboxes}>
                        <SmartRadio
                            value="online"
                            name="interactionFormat"
                            label="онлайн"
                        />
                        <SmartRadio
                            value="offline"
                            name="interactionFormat"
                            label="офлайн"
                        />
                        <SmartRadio
                            value="any"
                            name="interactionFormat"
                            label="не важно"
                        />
                    </div>
                </div>

                <div className={s.section_1}>
                    <p className={clsx(s.subtitle)}>Город</p>
                    <SmartCreatableSelect
                        placeholder="Выбери город"
                        name="city"
                        options={fetchCities.map((el, i) => ({
                            value: i,
                            label: el,
                        }))}
                    />
                </div>

                <div className={clsx(s.submit)}>
                    <AppButton disabled={isPending}>
                        Сохранить изменения
                    </AppButton>
                    <p onClick={nextStep} className={s.skip}>
                        Далее
                    </p>
                </div>
            </SmartForm>
        </StepLayout>
    )
}
