import {useMutation, useQuery} from "@tanstack/react-query";
import {tagApi} from "./index";
import {IPatchTag, IPostTag, ISearchParams} from "../model";


const key = 'adminTags'

export const useGetTags = (params: ISearchParams) => {
    const query = useQuery({
        queryKey: [key],
        queryFn: async () => {
            const res = await tagApi.getTags(params)
            return res.data
        }
    })
    return query
}

export const useGetTag = (id: number) => {
    return useQuery({
        queryKey: ['adminTag'],
        queryFn: async () => {
            const res = await tagApi.getTag(id)
            return res.data
        }
    })
}

export const useDeleteTags = (reset?: () => void) => {
    return useMutation({
        mutationKey: [key],
        mutationFn: async (ids: number[]) => {
            await tagApi.deleteTags(ids)
        },
        onSuccess: () => reset?.(),
        onError: (e: any) => {
            alert(e?.response?.data?.error)
        }
    })
}

export const useDeleteTag = (reset?: () => void) => {
    return useMutation({
        mutationKey: [key],
        mutationFn: async (id: number) => {
            await tagApi.deleteTag(id)
        },
        onSuccess: () => reset?.(),
        onError: (e: any) => {
            alert(e?.response?.data?.error)
        }
    })
}

export const usePostTag = (reset?: () => void) => {
    return useMutation({
        mutationKey: [key],
        mutationFn: async (data: IPostTag) => {
            await tagApi.postTag(data)
        },
        onSuccess: () => reset?.(),
        onError: (e: any) => {
            alert(e?.response?.data?.error)
        }
    })
}

export const usePatchTag = (reset?: () => void) => {
    return useMutation({
        mutationKey: [key],
        mutationFn: async ({data, id}: {data: IPatchTag, id: number}) => {
            await tagApi.patchTag(data, id)
        },
        onSuccess: () => reset?.(),
        onError: (e: any) => {
            alert(e?.response?.data?.error)
        }
    })
}
