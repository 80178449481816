import styled from '@emotion/styled'
import { useFormContext } from 'react-hook-form'
import { ChangeEventHandler, useId } from 'react'
import { theme } from '../../styles/theme'
import { css } from '@emotion/react'

const RadioStyled = styled.div`
    border: 1px solid ${(props) => props.theme.color.darkSpace[200]};
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${theme.color.darkSpace[50]};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
        display: none;
    }
`

const LabelStyled = styled.span`
    margin-left: 8px;
    font-size: 14px;
    color: ${theme.color.darkSpace[450]};
    user-select: none;
    height: 15px;
`

const InputStyled = styled.input`
    display: none;

    &:checked + label > ${RadioStyled} {
        border: none;
        background: ${theme.color.ozonMain};

        &::after {
            content: '';
            width: 6px;
            height: 6px;
            background-color: ${theme.color.white};
            border-radius: 50%;
        }
    }
`

type PropsType = {
    label?: string
    inputProps: {
        onChange: ChangeEventHandler
        name: string
        value?: string
    }
}

export const Radio: React.FC<PropsType> = (props) => {
    const id = useId()

    return (
        <div>
            <InputStyled {...props.inputProps} id={id} type="radio" />
            <label
                css={css`
                    display: flex;
                    align-items: flex-end;
                    cursor: pointer;
                `}
                htmlFor={id}
            >
                <RadioStyled />
                {props.label && <LabelStyled>{props.label}</LabelStyled>}
            </label>
        </div>
    )
}

export const SmartRadio: React.FC<{
    name: string
    value?: string
    label?: string
}> = (props) => {
    const form = useFormContext()

    return (
        <Radio
            inputProps={{
                ...form.register(props.name),
                value: props.value,
            }}
            label={props.label}
        />
    )
}
