import styled from '@emotion/styled'
import { theme } from '../../styles/theme'
import { css } from '@emotion/react'

type PropsType = {
    value: number
}

const ProgressStyled = styled.div<Pick<PropsType, 'value'>>`
    background-color: ${theme.color.ozonMain};
    transition: 0.3s;
    height: 12px;
    border-radius: 20px;
    clip-path: ${(props) =>
        `polygon(0 0, ${props.value}% 0, ${props.value}% 100%, 0% 100%);`};
`

export const ProgressBar: React.FC<PropsType> = (props: PropsType) => {
    return (
        <div
            css={css`
                border-radius: 20px;
                height: 12px;
                background-color: ${theme.color.white};
            `}
        >
            <ProgressStyled value={props.value} />
        </div>
    )
}
