import { useQuery } from '@tanstack/react-query'
;('../model/types')
import { IProgramGet } from '../model/types'
import { programsApi } from './index'

export const useGetProgramById = (id: number) => {
    return useQuery<IProgramGet>({
        queryKey: ['program'],
        queryFn: async () => {
            const response = await programsApi.getProgramById(id)
            return response.data
        },
    })
}
