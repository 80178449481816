export type NumberItem = {
    id: number
    number: string
    title?: string
    description: string
}

export type NumberItems = NumberItem[]

export const numberData = [
    {
        id: 1,
        number: '500+',
        title: 'коллег',
        description:
            'будут пользоваться платформой Ozon Expertplace ежемесячно',
    },
    {
        id: 2,
        number: '200+',
        title: 'менторов',
        description: 'смогут делиться своей экспертизой',
    },
    {
        id: 3,
        number: '100+',
        title: 'консультантов',
        description: 'помогут в решении любых вопросов',
    },
    {
        id: 4,
        number: '80+',
        title: 'программ',
        description: 'от экспертов сможешь найти в Ozon Expertplace',
    },
]
