import React, { SyntheticEvent, useEffect, useState } from 'react'
import { SmartForm } from '../../../../../../services/formProcessing/ui/SmartForm'
import s from './applicationsList.module.scss'
import { TextField } from '../../../../../../shared/ui/TextField'
import { AppButton } from '../../../../../../shared/ui/Button'
import moment from 'moment/moment'
import { Pagination } from '../../../../../../widgets/pagination/ui/Pagination'
import { useSmartForm } from '../../../../../../services/formProcessing'
import { Icons } from '../../../../../../shared/icons'
import clsx from 'clsx'
import { ModalWindow } from '../../../../../../shared/ui/ModalWindow'
import { ApplicationsType, IApplicationsGet } from '../../model/types'
import { applicationsApi } from '../../api'
import {SmartSelect} from "../../../../../../shared/ui/Select";

type PropsType = {
    type?: ApplicationsType
    query: any
}

interface IColumn {
    label: string
    order: 'asc' | 'desc' | 'default'
    sort: keyof IApplicationsGet
}

interface IRow extends IApplicationsGet {
    checked: boolean
}

const COLUMNS: IColumn[] = [
    { label: 'ID', order: 'default', sort: 'id' },
    { label: 'Ф.И.О.', order: 'default', sort: 'fullName' },
    { label: 'Email', order: 'default', sort: 'email' },
    { label: 'Дата', order: 'default', sort: 'updatedAt' },
    { label: 'Эксперт', order: 'default', sort: 'expertFullName' },
    { label: 'Статус', order: 'default', sort: 'state' },
]

const stateMap: Record<string, string> = {
    Ожидает: 'pending',
    Завершена: 'completed',
    Одобрено: 'accepted',
    Отклонена: 'rejected',
    Провалена: 'failed',
}

const ParamsField = [
    {
        title: 'Ф.И.О.',
        placeholder: 'Свиридова Анна Александровна',
        name: 'fullName',
    },
    {
        title: 'Эксперт',
        placeholder: 'Свиридова Анна Александровна',
        name: 'expertFullName',
    },
]
const params = ['ID', 'email', 'Дата изменений', 'Статус']

export const ApplicationsList: React.FC<PropsType> = ({ type, query }) => {
    const {
        items,
        page,
        total,
        setFilters,
        nextPage,
        prevPage,
        setPage,
        reset,
    } = query
    const [isOpened, setIsOpened] = useState(false)
    const [limit, setLimit] = useState(10)
    const form = useSmartForm({
        defaultValues: {
            id: '',
            fullName: '',
            email: '',
            expertFullName: '',
            state: '',
            limit: { value: limit, label: limit },
        },
    })

    const [columns, setColumns] = useState<IColumn[]>(COLUMNS)
    const [rows, setRows] = useState<IRow[]>([])
    const [selectedId, setSelectedId] = useState<{ id: number }[]>([])
    const [activeParamsBlock, setActiveParamsBlock] = useState(false)
    const [paramsField, setParamsField] = useState(ParamsField)
    const [addParams, setAddParams] = useState(false)

    const isRowActive = (id: number) => selectedId.find((el) => el.id === id)
    const onSubmit = (data: any) => {
        if (data.state) {
            data.state = data.state.value || data.state
        }
        setFilters({ ...data, limit })
        setActiveParamsBlock(false)
        form.reset()
    }
    const onPrev = (evt: SyntheticEvent) => {
        evt.preventDefault()
        prevPage()
    }
    const onNext = (evt: SyntheticEvent) => {
        evt.preventDefault()
        nextPage()
    }
    const handleChangeLimit = ({ value }: any) => {
        setLimit(value)
        setFilters({ ...form.getValues(), limit: value })
    }
    const toggleCheckbox = (value: IRow) => {
        setRows((rows) =>
            rows.map((el) =>
                el.id === value.id ? { ...el, checked: !el.checked } : el
            )
        )
        if (selectedId.find((el) => el.id === value.id)) {
            setSelectedId((item) => item.filter(({ id }) => id !== value.id))
        } else {
            setSelectedId([...selectedId, { id: value.id }])
        }
    }
    const selectAllCheckbox = () => {
        if (selectedId.length === rows.length) {
            setRows((rows) => rows.map((el) => ({ ...el, checked: false })))
            setSelectedId([])
        } else {
            setRows((rows) => rows.map((el) => ({ ...el, checked: true })))
            setSelectedId(rows.map((el) => ({ id: el.id })))
        }
    }
    const handleClickDelete = async () => {
        const ids = selectedId.map((el) => el.id)
        try {
            await applicationsApi.deleteApplications({ ids })
            setIsOpened(false)
            setSelectedId([])
            reset()
        } catch (e) {
            console.log(e)
            alert('Something went wrong')
        }
    }

    const sortTable = (column: IColumn) => {
        setFilters({
            ...form.getValues(),
            limit,
            sort: column.sort,
            order: column.order === 'asc' ? 'desc' : 'asc',
        })
        setColumns((columns) =>
            columns.map((el) =>
                el.sort === column.sort
                    ? { ...el, order: el.order === 'asc' ? 'desc' : 'asc' }
                    : { ...el, order: 'default' }
            )
        )
    }

    const handleAddParam = (param: string) => {
        if (!paramsField.some((item) => item.title === param)) {
            setParamsField([
                ...paramsField,
                {
                    title: param,
                    placeholder: param,
                    name: param,
                },
            ])
        } else {
            setParamsField(paramsField.filter((item) => item.title !== param))
        }
    }

    useEffect(() => {
        setRows(items.map((el: any) => ({ ...el, checked: false })))
    }, [items])

    return (
        <>
            <SmartForm form={form} submit={form.handleSubmit(onSubmit)}>
                <div className={s.wrapper}>
                    <div className={s.controls}>
                        <div className={s.searchWrapper}>
                            <div
                                className={s.searchLine}
                                onClick={() =>
                                    setActiveParamsBlock(!activeParamsBlock)
                                }
                            >
                                <Icons.admin.search />
                                <TextField
                                    className={s.search_field}
                                    name="q"
                                    placeholder="Поиск"
                                />
                                <div className={s.chevron}>
                                    <Icons.M.ChevronUp />
                                </div>
                            </div>

                            <div
                                className={clsx(s.searchPanel, {
                                    [s.active]: activeParamsBlock,
                                })}
                            >
                                {paramsField.map(
                                    ({ title, placeholder, name }, index) => {
                                        if (name === 'Статус') {
                                            return (
                                                <SmartSelect
                                                    key={index}
                                                    className={s.searchPanel_field}
                                                    label="Статус"
                                                    placeholder={'Статус заявки'}
                                                    name="state"
                                                    options={Object.entries(stateMap).map(([key, value]) => ({value: value, label: key}))}
                                                    isClearable
                                                />
                                            )
                                        }
                                        return (
                                            <div
                                                className={s.searchPanel_block}
                                                key={index}
                                            >
                                                {title}
                                                <TextField
                                                    className={s.searchPanel_field}
                                                    name={
                                                        name === 'Дата изменений'
                                                            ? 'updatedAt'
                                                            : name === 'ID'
                                                                    ? 'id'
                                                                    : name
                                                    }
                                                    placeholder={
                                                        name === 'Дата изменений'
                                                            ? 'год-месяц-день'
                                                            : placeholder
                                                    }
                                                />
                                            </div>
                                        )
                                    }
                                )}

                                <p
                                    className={s.searchPanel_add}
                                    onClick={() => setAddParams(!addParams)}
                                >
                                    <Icons.M.Plus />
                                    Добавить ещё параметр
                                </p>

                                <div
                                    className={clsx(s.searchPanel_params, {
                                        [s.active]: addParams,
                                    })}
                                >
                                    {params.map((value, index) => (
                                        <div
                                            key={index}
                                            onChange={() =>
                                                handleAddParam(value)
                                            }
                                        >
                                            <input type="checkbox" />
                                            {value}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <AppButton variant="ghost" className={s.search_button}>
                            Найти
                        </AppButton>
                    </div>
                    <div className={s.tableWrapper}>
                        <table className={s.table}>
                            <thead>
                                <tr>
                                    <th>
                                        <input
                                            type="checkbox"
                                            onChange={selectAllCheckbox}
                                        />
                                    </th>
                                    {columns.map((el) => (
                                        <th key={el.label}>
                                            <span onClick={() => sortTable(el)}>
                                                {el.label}
                                                <Icons.M.ChevronUp
                                                    className={clsx(s.sort, {
                                                        [s.sort_asc]:
                                                            el.order === 'asc',
                                                    })}
                                                />
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map((el) => (
                                    <tr
                                        key={el.id}
                                        className={clsx(s.row, {
                                            [s.row_active]: isRowActive(el.id),
                                        })}
                                    >
                                        <td>
                                            <input
                                                checked={el.checked}
                                                onChange={() =>
                                                    toggleCheckbox(el)
                                                }
                                                type="checkbox"
                                            />
                                        </td>
                                        <td>{el.id}</td>
                                        <td>{el.fullName}</td>
                                        <td>{el.email}</td>
                                        <td>
                                            {moment(el.updatedAt).format(
                                                'DD.MM.YYYY'
                                            )}
                                        </td>
                                        <td>{el.expertFullName}</td>
                                        <td>
                                            <div className={s.statusInfo}>
                                                <div
                                                    className={clsx([
                                                        s.statusCircle,
                                                        {
                                                            [s.red]:
                                                                el.state ===
                                                                    'rejected' ||
                                                                el.state ===
                                                                    'failed',
                                                            [s.green]:
                                                                el.state ===
                                                                'completed',
                                                            [s.yellow]:
                                                                el.state ===
                                                                'accepted',
                                                        },
                                                    ])}
                                                />
                                                <p className={s.status}>
                                                    {el.state === 'completed' &&
                                                        'Завершена'}
                                                    {el.state === 'pending' &&
                                                        'Ожидает'}
                                                    {el.state === 'accepted' &&
                                                        'Одобрено'}
                                                    {(el.state === 'rejected' ||
                                                        el.state ===
                                                            'failed') &&
                                                        'Отклонена'}
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className={s.footer}>
                    <div className={s.footer_buttons}>
                        {selectedId.length >= 1 && (
                            <div
                                onClick={() => setIsOpened(true)}
                                className={clsx(
                                    s.footer_buttons_icon,
                                    s.remove
                                )}
                            >
                                <Icons.M.Cancelled />
                                Удалить
                            </div>
                        )}
                    </div>
                    <Pagination
                        page={page}
                        total={total}
                        limit={limit}
                        handleChangeLimit={handleChangeLimit}
                        onNext={onNext}
                        onPrev={onPrev}
                        setPage={setPage}
                    />
                </div>
            </SmartForm>
            <ModalWindow isOpened={isOpened} setIsOpened={setIsOpened}>
                <div className={s.modal}>
                    <p className={s.modal_title}>
                        Ты уверен, что хочешь удалить выбранные элементы?
                    </p>
                    <div className={s.modal_controls}>
                        <AppButton onClick={handleClickDelete}>
                            Да, удалить
                        </AppButton>
                        <AppButton
                            onClick={() => setIsOpened(false)}
                            variant="ghost"
                        >
                            Нет, оставить
                        </AppButton>
                    </div>
                </div>
            </ModalWindow>
        </>
    )
}
