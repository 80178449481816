import React from 'react'
import image from '../../shared/assets/404/background.png'
import error from '../../shared/assets/404/404.png'
import { AppButton } from '../../shared/ui/Button'
import { ROUTES } from '../../config/routes'
import { Link } from 'react-router-dom'

import s from './notFound.module.scss'

export const NotFoundPage = () => {
    return (
        <div className={s.wrapper}>
            <img src={image} alt="image" className={s.backgroundImage} />
            <div className={s.content}>
                <h1>Кажется, ты потерялся</h1>
                <p>
                    Вернись на главную и продолжи свой путь <br /> к
                    профессиональному росту
                </p>
                <Link to={ROUTES.root}>
                    <AppButton
                        variant={'white'}
                        size="lg"
                        onClick={() => {}}
                        className={s.button}
                    >
                        Вернуться на главную
                    </AppButton>
                </Link>
            </div>
            <img src={error} alt="error" className={s.error} />
        </div>
    )
}
