import React, { SyntheticEvent, useEffect, useState } from 'react'
import { useSmartForm } from '../../../../../services/formProcessing'
import { useList } from '../../../../../shared/hooks/useList/useList'
import { SmartForm } from '../../../../../services/formProcessing/ui/SmartForm'
import { Icons } from '../../../../../shared/icons'
import { TextField } from '../../../../../shared/ui/TextField'
import { clsx } from 'clsx'
import { AppButton } from '../../../../../shared/ui/Button'
import { Pagination } from '../../../../../widgets/pagination/ui/Pagination'
import { ModalWindow } from '../../../../../shared/ui/ModalWindow'
import { programsApi } from '../api'
import { IProgramPatch, IProgramsGet } from '../model/types'
import { StatusType } from '../../faq/model/types'
import { StatusOption } from '../../../../../entities/application/types'

import s from './programList.module.scss'

export const statusOptions: StatusOption[] = [
    {
        label: 'Ожидает',
        value: 'onModeration',
    },
    {
        label: 'Отклонено',
        value: 'inactive',
    },
    {
        label: 'Одобрено',
        value: 'active',
    },
]

interface IColumn {
    label: string
    order: 'asc' | 'desc' | 'default'
    sort: keyof IProgramsGet
}

interface IRow extends IProgramsGet {
    checked: boolean
}

const COLUMNS: IColumn[] = [
    { label: 'ID', order: 'default', sort: 'id' },
    { label: 'Активность', order: 'default', sort: 'isEnabled' },
    { label: 'Автор программы', order: 'default', sort: 'authorName' },
    { label: 'Название программы', order: 'default', sort: 'name' },
    { label: 'Статус', order: 'default', sort: 'state' },
]

type PropsType = {
    setProgramId: (value: number) => void
    setStatus: (value: StatusType | null) => void
}

export const ApplicationsList: React.FC<PropsType> = ({
    setProgramId,
    setStatus,
}) => {
    const [isOpened, setIsOpened] = useState(false)
    const [activeDropdown, setActiveDropdown] = useState(-1)
    const [limit, setLimit] = useState(10)
    const {
        items: programs,
        page,
        total,
        setFilters,
        nextPage,
        prevPage,
        setPage,
        reset,
    } = useList<IProgramsGet>({
        key: ['programs'],
        apiMethod: (params) => programsApi.getPrograms(params),
        defaultLimit: limit,
    })

    const form = useSmartForm({
        defaultValues: {
            limit: { value: limit, label: limit },
        },
    })

    const [columns, setColumns] = useState<IColumn[]>(COLUMNS)
    const [rows, setRows] = useState<IRow[]>([])
    const [selectedId, setSelectedId] = useState<{ id: number }[]>([])

    const isRowActive = (id: number) => selectedId.find((el) => el.id === id)
    const onSubmit = (data: any) => {
        setFilters({ ...data, limit })
        form.reset()
    }
    const onPrev = (evt: SyntheticEvent) => {
        evt.preventDefault()
        prevPage()
    }
    const onNext = (evt: SyntheticEvent) => {
        evt.preventDefault()
        nextPage()
    }
    const handleChangeLimit = ({ value }: any) => {
        setLimit(value)
        setFilters({ ...form.getValues(), limit: value })
    }
    const toggleCheckbox = (value: IRow) => {
        setRows((rows) =>
            rows.map((el) =>
                el.id === value.id ? { ...el, checked: !el.checked } : el
            )
        )
        if (selectedId.find((el) => el.id === value.id)) {
            setSelectedId((item) => item.filter(({ id }) => id !== value.id))
        } else {
            setSelectedId([...selectedId, { id: value.id }])
        }
    }
    const selectAllCheckbox = () => {
        if (selectedId.length === rows.length) {
            setRows((rows) => rows.map((el) => ({ ...el, checked: false })))
            setSelectedId([])
        } else {
            setRows((rows) => rows.map((el) => ({ ...el, checked: true })))
            setSelectedId(rows.map((el) => ({ id: el.id })))
        }
    }
    const handleClickDelete = async () => {
        const ids = selectedId.map((el) => el.id)
        try {
            await programsApi.deletePrograms({ ids })
            setIsOpened(false)
            setSelectedId([])
            reset()
        } catch (e) {
            console.log(e)
            alert('Something went wrong')
        }
    }

    const handleClickAdd = () => {
        setStatus('add')
    }

    const handleClickEdit = () => {
        const [{ id }] = selectedId
        setStatus('edit')
        setProgramId(id)
    }

    const sortTable = (column: IColumn) => {
        setFilters({
            ...form.getValues(),
            limit,
            sort: column.sort,
            order: column.order === 'asc' ? 'desc' : 'asc',
        })
        setColumns((columns) =>
            columns.map((el) =>
                el.sort === column.sort
                    ? { ...el, order: el.order === 'asc' ? 'desc' : 'asc' }
                    : { ...el, order: 'default' }
            )
        )
    }

    const changeProgramStatus = async (newValue: string, id: number) => {
        try {
            await programsApi.patchProgramById(id, {
                state: newValue,
            } as IProgramPatch)
        } catch (e) {
            console.log(e)
            alert('Something went wrong')
        }
        reset()
    }

    const toggleDropdown = (index: number) => {
        setActiveDropdown((prevIndex) => (prevIndex === index ? -1 : index))
    }

    useEffect(() => {
        setRows(programs.map((el) => ({ ...el, checked: false })))
    }, [programs])

    return (
        <>
            <SmartForm form={form} submit={form.handleSubmit(onSubmit)}>
                <div className={s.wrapper}>
                    <div className={s.controls}>
                        <div className={s.searchLine}>
                            <Icons.admin.search />
                            <TextField
                                className={s.search_field}
                                name="q"
                                placeholder="Поиск"
                            />
                            <div className={s.chevron}>
                                <Icons.M.ChevronUp />
                            </div>
                        </div>
                        <AppButton variant="ghost" className={s.search_button}>
                            Найти
                        </AppButton>
                        <AppButton
                            className={s.add_button}
                            onClick={handleClickAdd}
                        >
                            Добавить{'\u00A0'}элемент
                        </AppButton>
                    </div>
                    <div className={s.tableWrapper}>
                        <table className={s.table}>
                            <thead>
                                <tr>
                                    <th>
                                        <input
                                            type="checkbox"
                                            onChange={selectAllCheckbox}
                                        />
                                    </th>
                                    {columns.map((el) => (
                                        <th key={el.label}>
                                            <span
                                                onClick={() =>
                                                    el.label !==
                                                        'Автор программы' &&
                                                    sortTable(el)
                                                }
                                            >
                                                {el.label}
                                                {el.label !==
                                                    'Автор программы' && (
                                                    <Icons.M.ChevronUp
                                                        className={clsx(
                                                            s.sort,
                                                            {
                                                                [s.sort_asc]:
                                                                    el.order ===
                                                                    'asc',
                                                            }
                                                        )}
                                                    />
                                                )}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map((el) => (
                                    <tr
                                        key={el.id}
                                        className={clsx(s.row, {
                                            [s.row_active]: isRowActive(el.id),
                                        })}
                                    >
                                        <td>
                                            <input
                                                checked={el.checked}
                                                onChange={() =>
                                                    toggleCheckbox(el)
                                                }
                                                type="checkbox"
                                            />
                                        </td>
                                        <td>{el.id}</td>
                                        <td>{el.isEnabled ? 'Да' : 'Нет'}</td>
                                        <td>{el.authorName}</td>
                                        <td>{el.name}</td>
                                        <td>
                                            <div
                                                className={clsx([
                                                    s.dropdown,
                                                    {
                                                        [s.active]:
                                                            activeDropdown ===
                                                                el.id &&
                                                            el.state !==
                                                                'inactive',
                                                    },
                                                ])}
                                                onClick={() =>
                                                    toggleDropdown(el.id)
                                                }
                                            >
                                                <div className={s.statusInfo}>
                                                    <div
                                                        className={clsx([
                                                            s.statusCircle,
                                                            {
                                                                [s.red]:
                                                                    el.state ===
                                                                    'inactive',
                                                                [s.yellow]:
                                                                    el.state ===
                                                                    'active',
                                                            },
                                                        ])}
                                                    />
                                                    <p className={s.status}>
                                                        {el.state ===
                                                            'inactive' &&
                                                            'Отклонено'}
                                                        {el.state ===
                                                            'active' &&
                                                            'Одобрено'}
                                                        {el.state ===
                                                            'onModeration' &&
                                                            'Ожидает'}
                                                    </p>
                                                    <div className={s.chevron}>
                                                        <Icons.M.ChevronUp />
                                                    </div>

                                                    {activeDropdown === el.id &&
                                                        el.state !==
                                                            'inactive' && (
                                                            <div
                                                                className={
                                                                    s.dropdownContent
                                                                }
                                                            >
                                                                {statusOptions.map(
                                                                    (
                                                                        {
                                                                            label,
                                                                            value,
                                                                        },
                                                                        i
                                                                    ) => (
                                                                        <label
                                                                            className={
                                                                                s.dropdownItem
                                                                            }
                                                                            key={
                                                                                i
                                                                            }
                                                                            onClick={() => {
                                                                                changeProgramStatus(
                                                                                    value,
                                                                                    el.id
                                                                                )
                                                                            }}
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                className={
                                                                                    s.checkbox
                                                                                }
                                                                                checked={
                                                                                    el.state ===
                                                                                    value
                                                                                }
                                                                            />
                                                                            {
                                                                                label
                                                                            }
                                                                        </label>
                                                                    )
                                                                )}
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className={s.footer}>
                    <div className={s.footer_buttons}>
                        {selectedId.length === 1 && (
                            <div
                                className={clsx(s.footer_buttons_icon, s.edit)}
                                onClick={handleClickEdit}
                            >
                                <Icons.M.Edit />
                                Редактировать
                            </div>
                        )}
                        {selectedId.length >= 1 && (
                            <div
                                className={clsx(
                                    s.footer_buttons_icon,
                                    s.remove
                                )}
                                onClick={() => {
                                    setIsOpened(true)
                                }}
                            >
                                <Icons.M.Cancelled />
                                Удалить
                            </div>
                        )}
                    </div>
                    <Pagination
                        page={page}
                        total={total}
                        limit={limit}
                        handleChangeLimit={handleChangeLimit}
                        onNext={onNext}
                        onPrev={onPrev}
                        setPage={setPage}
                    />
                </div>
            </SmartForm>
            <ModalWindow isOpened={isOpened} setIsOpened={setIsOpened}>
                <div className={s.modal}>
                    <p className={s.modal_title}>
                        Ты уверен, что хочешь удалить выбранные элементы?
                    </p>
                    <div className={s.modal_controls}>
                        <AppButton onClick={handleClickDelete}>
                            Да, удалить
                        </AppButton>
                        <AppButton
                            onClick={() => setIsOpened(false)}
                            variant="ghost"
                        >
                            Нет, оставить
                        </AppButton>
                    </div>
                </div>
            </ModalWindow>
        </>
    )
}
