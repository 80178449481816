import { NumberItem } from '../../../../pages/landingMain/cta/temporaryData'
import styled from '@emotion/styled'
import { theme } from '../../../../shared/styles/theme'

type PropsType = {
    item: NumberItem
}

const CardWrapper = styled.article`
    padding: 24px;
    background-color: ${theme.color.white};
    box-shadow: 5px 5px 15px 0 #005bff1a;
    border-radius: 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 14px;
    line-height: 1.2;
    color: ${theme.color.darkSpace[700]};
    width: 100%;

    @media (max-width: 1920px) {
        max-width: 394px;
    }

    @media (max-width: 1279px) {
        max-width: 304px;
    }

    @media (max-width: 1099px) {
        padding: 16px;
        gap: 20px;
    }

    @media (max-width: 767px) {
        gap: 6px;
    }

    @media (max-width: 500px) {
        max-width: 100%;
    }
`

const NumberWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 106px;
    width: 100%;
`

const Number = styled.div`
    font-size: 36px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.01em;
    color: ${theme.color.ozonMain};

    @media (min-width: 768px) {
        font-size: 52px;
    }

    @media (min-width: 1280px) {
        font-size: 48px;
    }
`

const Description = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    color: ${theme.color.darkSpace[450]};
    max-width: 224px;

    @media (min-width: 768px) {
        line-height: 1.3;
        letter-spacing: -0.01em;
    }
`

export const NumberCard: React.FC<PropsType> = ({ item }) => {
    const { number, title, description } = item
    return (
        <CardWrapper>
            <NumberWrapper>
                <Number>{number}</Number>
                {title && <span>{title}</span>}
            </NumberWrapper>
            <Description>{description}</Description>
        </CardWrapper>
    )
}
