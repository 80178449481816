import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Playground } from './playground'
import { PersonalAccount } from './personalAccount'
import { Catalog } from './catalog'
import { LandingMain } from './landingMain'
import { LandingForExperts } from './landingForExperts'
import { NotFoundPage } from './notFoundPage'
import { MentorProfilePage } from './mentorProfile'
import { ROUTES } from '../config/routes'
import { ProgrammPage } from './programm'
import { CreateReviewPage } from './createReview'
import { AuthFinishPage } from '../features/auth'
import { AdminPanel } from './adminPanel'
import { PrivacyPolicy } from './privacy/privacyPolicy'
import { Agreement } from './agreement'

export const RootRouter = () => {
    return (
        <div>
            <Switch>
                <Route exact path={ROUTES.root}>
                    <LandingMain />
                </Route>
                <Route exact path={ROUTES.experts}>
                    <LandingForExperts />
                </Route>

                <Route path={ROUTES.personalCabinet.root}>
                    <PersonalAccount />
                </Route>
                <Route path={ROUTES.catalog}>
                    <Catalog />
                </Route>
                <Route path={`${ROUTES.program}/:id`}>
                    <ProgrammPage />
                </Route>
                <Route path={`${ROUTES.expert}/:id`}>
                    <MentorProfilePage />
                </Route>

                <Route path={`${ROUTES.feedback}/:key`}>
                    <CreateReviewPage />
                </Route>
                <Route path={ROUTES.authFinish}>
                    <AuthFinishPage />
                </Route>
                <Route path={ROUTES.playground}>
                    <Playground />
                </Route>
                <Route path={ROUTES.adminPanel.root}>
                    <AdminPanel />
                </Route>
                <Route path={ROUTES.privacyPolicy}>
                    <PrivacyPolicy />
                </Route>
                <Route path={ROUTES.agreement}>
                    <Agreement />
                </Route>

                <Route path="*">
                    <NotFoundPage />
                </Route>
            </Switch>
        </div>
    )
}
