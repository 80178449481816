import React from 'react'
import { useModalWindow } from '../../../shared/hooks/useModalWindow'
import { AppButton } from '../../../shared/ui/Button'
import { CreateConsultationApplicationModal } from './modals/CreateConsultationApplicationModal'
import { CreateConsultationApplicationSuccessModal } from './modals/CreateConsultationApplicationSuccessModal'

type PropsType = {
    roleType?: string
}
export const CreateConsultationApplicationButton: React.FC<PropsType> = ({
    roleType,
}) => {
    const createConsultationApplicationFormModal = useModalWindow()
    const createConsultationApplicationSuccessModal = useModalWindow()

    const title =
        roleType === 'mentor'
            ? 'Отправить заявку на менторинг'
            : 'Отправить заявку на консультацию'

    return (
        <>
            <AppButton
                onClick={createConsultationApplicationFormModal.toggleOpen}
            >
                {title}
            </AppButton>
            <CreateConsultationApplicationModal
                onSuccess={createConsultationApplicationSuccessModal.toggleOpen}
                roleType={roleType!}
                {...createConsultationApplicationFormModal}
            />
            <CreateConsultationApplicationSuccessModal
                roleType={roleType}
                {...createConsultationApplicationSuccessModal}
            />
        </>
    )
}
