import { ProgramCardType } from '../../types'
import { useQueryClient } from '@tanstack/react-query'
import { IProfileExpert } from '../../../profile'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../../config/routes'
import { Icons } from '../../../../shared/icons'
import { SmartSwitch } from '../../../../shared/ui/Switch'
import { SmartForm } from '../../../../services/formProcessing/ui/SmartForm'
import { useSmartForm } from '../../../../services/formProcessing'
import { CreateOrEditProgramModal } from '../../../../pages/personalAccount/subpages/myPrograms/ui/programsList/modals'
import { useModalWindow } from '../../../../shared/hooks/useModalWindow'
import clsx from 'clsx'
import { programApi } from '../../api'

import s from './programCard.module.scss'

type ProgramCardProps = {
    programList: ProgramCardType[]
    reset: () => void
}

export const ProgramCard = ({ programList, reset }: ProgramCardProps) => {
    const queryClient = useQueryClient()
    const profile = queryClient.getQueryData<IProfileExpert>(['profile'])
    const [selectProgram, setSelectProgram] = useState<ProgramCardType>()

    const form = useSmartForm<{ [key: number]: boolean }>({
        defaultValues: programList.reduce(
            (acc, program) => ({
                ...acc,
                [program.id]: program.isEnabled,
            }),
            {}
        ),
    })
    const formModalController = useModalWindow()

    const handleSwitchChange = async (id: number, programStatus: boolean) => {
        try {
            await programApi.patchProgramById(id, {
                isEnabled: !programStatus,
            } as ProgramCardType)
            reset()
        } catch (e) {
            console.log(e)
            alert('Something went wrong')
        }
    }

    return (
        <>
            {programList.map((program) => (
                <>
                    <div
                        className={clsx([s.programWrapper], {
                            [s.programWrapper__inactive]: !program.isEnabled,
                        })}
                        key={program.id}
                    >
                        <div className={s.program__imageBlock}>
                            <img
                                className={s.image}
                                src={program.image}
                                alt="image"
                            />
                            <div className={s.buttonWrapper}>
                                {program.state !== 'onModeration' && (
                                    <SmartForm
                                        form={form}
                                        submit={() => {}}
                                        onChange={() =>
                                            handleSwitchChange(
                                                program.id,
                                                program.isEnabled
                                            )
                                        }
                                        className={s.switcher}
                                    >
                                        <SmartSwitch
                                            color="white"
                                            variant="md"
                                            name={`${program.id}`}
                                        />

                                        {!program.isEnabled && (
                                            <div className={s.tooltip}>
                                                Программа скрыта и не
                                                отображается в каталоге программ
                                            </div>
                                        )}
                                    </SmartForm>
                                )}
                                <Icons.M.Edit
                                    className={s.edit}
                                    onClick={() => {
                                        setSelectProgram(program)
                                        formModalController.toggleOpen()
                                    }}
                                />
                            </div>
                            {program.state === 'onModeration' && (
                                <p className={s.status}>На модерации</p>
                            )}
                        </div>
                        <div className={s.program__infoBlock}>
                            <div className={s.program__info}>
                                <p className={s.program__info_title}>
                                    {program.name}
                                </p>
                                <p className={s.program__info_name}>
                                    {profile?.fullName}, <br />{' '}
                                    {profile?.position}
                                </p>
                            </div>
                            <p className={s.program__descriptio}>
                                {program.shortDescription}
                            </p>
                            <div className={s.program__bottom}>
                                <Link
                                    className={s.toApplications}
                                    to={`${ROUTES.personalCabinet.applications.programm}/${program.id}`}
                                >
                                    К заявкам
                                    <Icons.M.ArrowToRight />
                                </Link>
                                <p className={s.applicationsQuantity}>
                                    Количество заявок: {program.applications}
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            ))}

            <CreateOrEditProgramModal
                {...formModalController}
                program={selectProgram}
                isEdit={true}
                reset={reset}
            />
        </>
    )
}
