import {adminApiInstance, apiInstance} from "../../../../../shared/api";
import {FaqType, IFaqGet, IFaqPost, IFaqResponse} from "../model/types";


export const faqApi = {
    getFaqs: (params: IFaqGet, type: FaqType) => {
        return adminApiInstance.get<IFaqResponse>(`/faq/search/${type}`, {params})
    },
    getFaq: (id: number) => {
        return adminApiInstance.get<IFaqPost>(`faq/${id}`)
    },
    postFaq: (params: IFaqPost) => {
        return adminApiInstance.post<IFaqPost>(`/faq/${params.type}`, params)
    },
    deleteFaqs: (ids: number[]) => {
        return adminApiInstance.delete<{ids: number[]}>(`/faq`, {data: {ids}})
    },
    patchFaq: (params: Partial<IFaqPost>) => {
        return adminApiInstance.patch(`/faq/${params.id}`, params)
    },
    getFaqByPage: (type: FaqType) => {
        return apiInstance.get(`/promo/faq/${type}`)
    }
}
