import React from 'react'

import { servicesItems } from '../temporartData'

import s from '../aboutMarket.module.scss'

export const AnswerComponent = () => {
    return (
        <div className={s.aboutSalary}>

            <div className={s.salaryWrapper}>
                <p className={s.number}>01</p>
                <div className={s.salaryText}>
                    <p className={s.salaryTitle}>
                        Все эксперты получают приветственные баллы <br /> за
                        регистрацию своей анкеты
                    </p>
                </div>
            </div>

            <div className={s.servicesWrapper}>
                <div className={s.services}>
                    <div className={s.servicesSubTitle}>
                        Приветственные баллы
                    </div>
                    <div className={s.servicesPrice}>500 баллов</div>
                </div>
            </div>

            <div className={s.salaryWrapper}>
                <p className={s.number}>02</p>
                <div className={s.salaryText}>
                    <p className={s.salaryTitle}>
                        Эксперты получают баллы за оказанные услуги
                    </p>
                </div>
            </div>

            {servicesItems.map(({ title, services }, index) => (
                <div key={index}>
                    <p className={s.servicesTitle}>{title}</p>
                    <div className={s.servicesWrapper}>
                        {services.map(({ service, price }, index) => (
                            <div className={s.services} key={index}>
                                <div className={s.servicesSubTitle}>
                                    {service}
                                </div>
                                <div className={s.servicesPrice}>
                                    {price} баллов
                                </div>
                            </div>
                        ))}
                    </div>
                    {(index !== servicesItems.length - 1) && <hr />}
                </div>
            ))}

            <div className={s.salaryWrapper}>
                <p className={s.number}>03</p>
                <div className={s.salaryText}>
                    <p className={s.salaryTitle}>
                        Все эксперты автоматически принимают участие <br />в
                        программе лояльности
                    </p>
                    <p className={s.salaryDescription}>
                        Для получения баллов лояльности, необходимо закрыть N-ное количество заявок: Ментор и
                        консультант - за каждые 3 закрытые заявки
                        <br />
                        Тренер - за каждые 15 заявок
                    </p>
                </div>
            </div>

            <div className={s.servicesWrapper}>
                <div className={s.services} style={{marginBottom: 0}}>
                    <div className={s.servicesSubTitle}>
                        Фиксированная цена
                    </div>
                    <div className={s.servicesPrice}>1000 баллов</div>
                </div>
            </div>

        </div>
    )
}