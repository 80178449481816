import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data: {},
}

export const programSlice = createSlice({
    name: 'program',
    initialState,
    reducers: {
        getProgram: (state: any, { payload }) => {
            state.data = { ...state.data, ...payload }
        },
    },
})

export const { actions: programActions, reducer: programReducer } = programSlice
