import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'

type PropsType = {
    color?: string
} & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
>

const BoxStyled = styled.div<{ color: string }>`
    border-radius: 16px;
    background-color: ${(props) => props.color};
    padding: 40px;

    ${(props) => css`
        @media screen and (max-width: ${props.theme.media.tablet}) {
            padding: 40px 16px;
        }
    `};
`

export const Box: React.FC<PropsType> = ({ color = 'white', ...props }) => {
    return (
        <BoxStyled color={color} {...props}>
            {props.children}
        </BoxStyled>
    )
}
