import { SmartForm } from '../../../../services/formProcessing/ui/SmartForm'
import { TextField } from '../../../../shared/ui/TextField'
import { AppButton } from '../../../../shared/ui/Button'
import { css } from '@emotion/react'
import React from 'react'
import { useSmartForm } from '../../../../services/formProcessing'
import { z } from 'zod'
import { SmartCheckbox } from '../../../../shared/ui/Checkbox'
import s from './adminAuthForm.module.scss'
import { useAdminLogin } from '../../api/queries'

const formSchema = z.object({
    username: z.string().min(2, 'Имя пользователя слишком короткое'),
    password: z.string().min(8, 'Минимум 8'),
    shouldRemember: z.boolean(),
})

export const AdminAuthForm = () => {
    const { mutate } = useAdminLogin()

    const form = useSmartForm(
        {
            defaultValues: {
                username: '',
                password: '',
                shouldRemember: false,
            },
        },
        {
            schema: formSchema,
        }
    )

    const handleSubmit = (data: any) => {
        mutate(data)
    }

    return (
        <SmartForm form={form} submit={handleSubmit}>
            <TextField
                label="Логин"
                placeholder="username"
                name="username"
                type={'text'}
            />
            <TextField
                label="Пароль"
                placeholder="password"
                name="password"
                type="password"
            />
            <div className={s.CheckBoxWrapper}>
                <SmartCheckbox
                    name="shouldRemember"
                    label="Запомнить меня на этом компьютере"
                />
            </div>

            <AppButton
                css={css`
                    width: 100%;
                `}
            >
                Войти
            </AppButton>
        </SmartForm>
    )
}
