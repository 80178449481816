import s from '../../../entities/profile/ui/index.module.scss'
import React from 'react'
import { useSelector } from 'react-redux'
import { profileSelectors } from '../../../entities/profile'
import step_1 from '../assets/step_1.png'
import step_2 from '../assets/step_2.png'
import step_3 from '../assets/step_3.png'
import {
    useFetchProfile,
    useFetchUser,
} from '../../../entities/profile/api/queries'

interface IStepLayout {
    children: React.ReactNode
}

const stepHash: Record<number, string> = {
    1: step_1,
    2: step_2,
    3: step_3,
}

export const StepLayout = ({ children }: IStepLayout) => {
    const step = useSelector(profileSelectors.step)
    const { data } = useFetchProfile()
    const { data: user } = useFetchUser()

    return (
        <div className={s.wrapper}>
            {data?.isEnabled && (
                <div className={s.header}>
                    <div>
                        {user?.expertState !== 'onModeration' && (
                            <>
                                <p className={s.title}>Заполни свой профиль</p>
                                <p className={s.desc}>
                                    Заполни профиль максимально подробно. Так ты
                                    будешь получать больше заявок от коллег
                                </p>
                            </>
                        )}
                    </div>
                    {step && <img src={stepHash[step]} />}
                </div>
            )}
            <div className={s.children}>{children}</div>
        </div>
    )
}
