import React from 'react'
import { AppButton } from '../../../../../../shared/ui/Button'
import { useModalWindow } from '../../../../../../shared/hooks/useModalWindow'
import { CreateOrEditProgramModal } from './modals'
import { ProgramCard } from '../../../../../../entities/programm'
import { ProgramCardType } from '../../../../../../entities/programm/types'

import s from './programsList.module.scss'

type ProgramsListProps = {
    programList: ProgramCardType[]
    page: number
    total: number
    showMore: () => void
    reset: () => void
}

export const ProgramsListPage = ({
    programList,
    page,
    showMore,
    reset,
    total,
}: ProgramsListProps) => {
    const formModalController = useModalWindow()

    return (
        <div className={s.programListWrapper}>
            <div className={s.header}>
                <h3 className={s.header__title}>Созданные программы</h3>
                <AppButton onClick={formModalController.toggleOpen}>
                    Создать программу
                </AppButton>
            </div>

            <div className={s.programList}>
                <ProgramCard programList={programList} reset={reset} />
            </div>

            {page * 4 < total && (
                <AppButton
                    size="lg"
                    variant="ghost"
                    onClick={showMore}
                    className={s.moreButton}
                >
                    Показать ещё
                </AppButton>
            )}

            <CreateOrEditProgramModal reset={reset} {...formModalController} />
        </div>
    )
}
