import React from 'react'
import { HTMLAttributes, ReactNode, useRef } from 'react'
import { ModalWindowCustomPropsType } from './types'
import { useOutsideClick } from '../../hooks/useOutsideClick'
import { Icons } from '../../icons'

import s from './modalWindow.module.scss'

const CloseButton = (props: HTMLAttributes<HTMLDivElement>) => {
    return (
        <div className={s.closeButtonContainer} {...props}>
            <Icons.M.Close />
        </div>
    )
}

export type ModalWindowPropsType = {
    isOpened: boolean
    setIsOpened: (v: boolean) => void
    children?: ReactNode
    render?: React.FC<Required<ModalWindowCustomPropsType>>
} & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
>

export const ModalWindow = ({
    children,
    render,
    isOpened,
    setIsOpened,
    ...htmlProps
}: ModalWindowPropsType) => {
    const ref = useRef<any>()

    const closeModal = () => {
        setIsOpened(false)
    }

    useOutsideClick(ref, closeModal, isOpened)

    if (!isOpened) return null

    return (
        <div className={s.container}>
            <div className={s.modalWindow} ref={ref} {...htmlProps}>
                <CloseButton onClick={closeModal} />
                {children}
            </div>
        </div>
    )
}
