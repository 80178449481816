import React, {SyntheticEvent, useEffect} from 'react';
import s from './index.module.scss'
import {useModalWindow} from "../../../../shared/hooks/useModalWindow";
import {ModalWindow} from "../../../../shared/ui/ModalWindow";
import {DesktopCard} from "./card";
import {LinkPresets} from "../../../../shared/ui/Link";
import clsx from "clsx";
import {IAdminPanelPath, IAdminPanelPropsType} from "../../model/types";
import {AdminPageLayout} from "../../ui/adminPageLayout/layout";
import {useGetWorkspace, useGetWorkspaceStats} from "./api/queries";
import {adminPanelPaths} from "../../routes";

const hash: Record<string, {name: string, title: string}> = {
    requests: {name: "applications", title: "Заявок на обучение"},
    users: {name: "experts", title: "Человек присоединились к платформе"},
    faq: {name: "faqQuestions", title: "Вопросы и ответы"},
    programs: {name: "programs", title: "Программ создано Внутренними тренерами"},
    reviews: {name: "reviews", title: "Отзывов"},
    shop: {name: "shopItems", title: "Товаров загружено"},
    tags: {name: "tags", title: "Текущих областей"},
}

export const Workspace: React.FC<IAdminPanelPropsType> = ({title}) => {
    const paths: IAdminPanelPath[] = adminPanelPaths.filter(el => el.name !== 'workspace')
    const {data: workspace, isFetching: isFetchingWorkspace} = useGetWorkspace()
    const {data: stats, isFetching: isFetchingStats} = useGetWorkspaceStats()
    const modal = useModalWindow()
    const handleClickOpen = (evt: SyntheticEvent) => {
        evt.preventDefault()
        modal.toggleOpen()
    }

    const getWorkspaceCards = () => paths
        .filter(path => workspace?.data.find((el: string) => el === path.name))
        .map(el => ({ ...el, stats: `${hash[el.name].title}: ${stats?.[hash[el.name].name]}` }))

    const getModalCards = () => paths
        .filter(path => !workspace?.data.find((el: string) => el === path.name))
        .map(el => ({ ...el, stats: `${hash[el.name].title}: ${stats?.[hash[el.name].name]}` }))

    useEffect(() => {
        if (workspace?.data.length === paths.length) modal.setIsOpened(false)
    }, [JSON.stringify(workspace)])

    if (isFetchingWorkspace || isFetchingStats) return null

    return (
        <AdminPageLayout title={title}>
            <div className={s.card_wrapper}>
                {getWorkspaceCards()?.map((card: IAdminPanelPath) => (
                    <DesktopCard
                        key={card.name}
                        {...card}
                        desktop
                    />
                ))}
                {getWorkspaceCards().length < 7 &&
                  <div className={clsx(s.card, s.card_add)}>
                    <LinkPresets.AddBlock to="/" onClick={handleClickOpen} />
                  </div>}
            </div>
            <ModalWindow {...modal} className={s.modal}>
                <div className={s.card_wrapper}>
                    {getModalCards()?.map((card: IAdminPanelPath) => (
                        <DesktopCard
                            key={card.name}
                            {...card}
                        />
                    ))}
                </div>
            </ModalWindow>
        </AdminPageLayout>
    )
}
