import React from 'react'
import s from './Program.module.scss'
import { Avatar } from '../../../../../shared/ui/Avatar'
import { Icons } from '../../../../../shared/icons'
import { Tag } from '../../../../../shared/ui/Tag'
import { IProgramProfile } from '../../../types'
import { css } from '@emotion/react'

type PropsType = {
    variant: 'short' | 'detailed'
    programProfile: IProgramProfile
}
export const Program: React.FC<PropsType> = ({ variant, programProfile }) => {
    return (
        <div className={s.Container}>
            <div className={s.Program}>
                <div className={s.ProgramWrapper}>
                    <h4 className={`${s.TitleH4} ${s.ProgramMobileTitle}`}>
                        {programProfile.name}
                    </h4>
                    <div className={s.ProgramAvatarBlock}>
                        <Avatar
                            url={programProfile.expert.photo}
                            className={s.ProgramAvatarBlock__avatar}
                        />
                        <div className={s.ProgramAvatarBlock__info}>
                            <h4
                                className={`${s.TitleH4} ${s.ProgramAvatarBlock__title}`}
                            >
                                {programProfile.name}
                            </h4>
                            <span className={s.ProgramAvatarBlock__position}>
                                {programProfile.expert.fullName},
                            </span>
                            <span className={s.ProgramAvatarBlock__position}>
                                {programProfile.expert.position}
                            </span>
                        </div>
                    </div>
                    <div className={s.ProgramSocials}>
                        {programProfile.expert.telegram && (
                            <a
                                href={programProfile.expert.telegram}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={s.ProgramSocials__link}
                            >
                                <Icons.social.Telegram />
                            </a>
                        )}
                        {programProfile.expert.vkontakte && (
                            <a
                                href={programProfile.expert.vkontakte}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={s.ProgramSocials__link}
                            >
                                <Icons.social.Vkontakte />
                            </a>
                        )}
                        {programProfile.expert.staff && (
                            <a
                                href={programProfile.expert.staff}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={`${s.ProgramSocials__link} ${s.ProgramSocials__staff}`}
                            >
                                Staff
                            </a>
                        )}
                    </div>
                </div>
                <div className={s.ProgramTags}>
                    {programProfile.tags.length > 0 &&
                        programProfile.tags.map((tag, index) => {
                            return (
                                <Tag key={index} className={s.ProgramTags__tag}>
                                    {tag}
                                </Tag>
                            )
                        })}
                </div>
            </div>
            <div className={s.AboutProgram}>
                <div>
                    <p className={s.TitleP1}>О курсе:</p>
                    {
                        programProfile.about.split("\n").map((line, i) => (
                            <div key = {i}>
                                <span>{line}</span>
                                {i != programProfile.about.split("\n").length - 1 &&
                                    <br/>
                                }
                            </div>
                        ))
                    }
                </div>
                <div className={s.AboutProgram__interaction}>
                    <div>
                        <p className={s.TitleP1}>Формат:</p>
                        <span>{programProfile.interactionFormat}</span>
                    </div>
                    <div>
                        <p className={s.TitleP1}>Продолжительность:</p>
                        <span>{programProfile.duration}</span>
                    </div>
                    <div>
                        <p className={s.TitleP1}>Город:</p>
                        <span>{programProfile.city}</span>
                    </div>
                </div>
            </div>
            {variant === 'detailed' && (
                <>
                    <div className={s.ProgramExpertise}>
                        <h4 className={s.TitleP1}>Кому подойдет?</h4>
                        <ul className={s.ProgramExpertise__list}>
                            {programProfile.expertise.length > 0 &&
                                programProfile.expertise.map((item, index) => {
                                    return (
                                        <li
                                            key={index}
                                            className={s.ProgramExpertise__item}
                                        >
                                            <ul
                                                css={css`
                                                    margin-bottom: 24px;
                                                `}
                                            >
                                                {item.specialisations.length >
                                                    0 &&
                                                    item.specialisations.map(
                                                        (item, index) => {
                                                            return (
                                                                <li key={index}>
                                                                    {item}
                                                                </li>
                                                            )
                                                        }
                                                    )}
                                            </ul>
                                            <p className={s.TitleP1}>
                                                Чему научатся?
                                            </p>
                                            <ul>
                                                {
                                                    item.description.split("\n").map((line, i) => (
                                                        <div key = {i}>
                                                            <li>{line}</li>
                                                        </div>
                                                    ))
                                                }
                                            </ul>
                                        </li>
                                    )
                                })}
                        </ul>
                    </div>
                    <div className={s.Info}>
                        <Icons.M.MediumInfo></Icons.M.MediumInfo>
                        <span>
                            Как только группа будет набрана, тренер свяжется с
                            тобой и сообщит все детали и даты.
                        </span>
                    </div>
                </>
            )}
        </div>
    )
}
