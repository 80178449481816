import { ThemeType } from '../styles/theme'

const flatObjectWithFullNamesForCssVariables = (
    object: { [key: string]: any },
    currentKey = ''
) => {
    const result = {} as any
    Object.entries(object).forEach(([key, value]) => {
        const accumulatedKey = `${currentKey}-${key}`
        if (typeof value == 'string') {
            result[accumulatedKey] = value
        } else {
            Object.assign(
                result,
                flatObjectWithFullNamesForCssVariables(value, accumulatedKey)
            )
        }
    })

    return result
}

export const generateCssColorsFromTheme = (theme: ThemeType) => {
    const styleTag = document.createElement('style')

    const variables = flatObjectWithFullNamesForCssVariables(
        theme.color,
        '--color'
    )
    const css = `
        html {
            ${Object.entries(variables)
                .map(([key, value]) => `${key}: ${value}`)
                .join(';')}
        }
    `

    document.getElementsByTagName('head')[0].appendChild(styleTag)

    styleTag.appendChild(document.createTextNode(css))
}
