import React, {ReactNode, useState} from 'react';
import {NavLink} from "react-router-dom";
import {Icons} from "../../../../shared/icons";
import clsx from "clsx";
import s from './AdminPanelMenuItem.module.scss'
import {IAdminPanelSubpage} from "../../../../pages/adminPanel/model/types";

type PropsType = {
    name: string
    path: string
    icon: ReactNode
    title: string
    subpages?: IAdminPanelSubpage[]
}

export const AdminPanelMenuItem: React.FC<PropsType> = ({name, path, icon, title, subpages}) => {

    const [isOpened, setIsOpened] = useState(false)
    const [activeIndex, setActiveIndex] = useState<number | null>(null)

    const handleClickOpen = () => {
        setIsOpened(!isOpened)
        setActiveIndex(null)
    }

    const handleClickSubtitle = (index: number) => {
        if (subpages) setActiveIndex(index)
    }

    return (
        <>
            <NavLink
                key={name}
                to={path}
                className={s.list_item}
                activeClassName={s.list_item_active}
                onClick={handleClickOpen}
            >
                {icon}
                <p>{title}</p>
                {subpages && <Icons.M.ChevronUp  className={clsx(s.icon, s.chevron, {[s.chevron_active]: isOpened})}/>}
            </NavLink>
            {
                isOpened && subpages?.map((type, index) => (
                <NavLink
                    key={type.title}
                    to={`${type?.path}`}
                    onClick={() => handleClickSubtitle(index)}
                >
                    <div className={clsx(s.list_item, s.list_item_type)} key={title} >
                        {activeIndex === index && <Icons.M.ChevronUp className={clsx(s.icon, s.type_active)}/>}
                        {type.title}
                    </div>
                </NavLink>
                ))
            }
        </>
    )
}
