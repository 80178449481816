import s from './SwitcherExperts.module.scss'
import { Switcher } from '../../../shared/ui/Switcher'
import React, { useState } from 'react'
import { Tab } from './Tab'
import consultant from '../../../shared/assets/consultant.png'
import mentor from '../../../shared/assets/mentor.png'
import trainer from '../../../shared/assets/trainer.png'
import card1 from '../../../shared/assets/switcherExpertCards/card1.jpg'
import card2 from '../../../shared/assets/switcherExpertCards/card2.jpg'
import card3 from '../../../shared/assets/switcherExpertCards/card3.jpg'
import card4 from '../../../shared/assets/switcherExpertCards/card4.jpg'

export const options = [
    {
        value: '1',
        text: 'Консультант',
        question: 'Как будет проходить консультирование?',
        steps: [
            'После подтверждения анкеты, твой профиль появится в базе консультантов',
            'Коллега оставит запрос на личную консультацию',
            'Ты выбираешь, принять или отклонить заявку',
            'Проводишь установочную встречу с коллегой',
            'Взаимодействуете с коллегой по сформированным договоренностям',
        ],
        img: consultant,
        title: 'Обучение консультантов',
        desc: 'Чтобы консультация была максимально полезной для коллеги и проста для тебя, команда Ozon Универа подготовила для тебя обучение. После подтверждения заполненной анкеты ты получишь:',
        cards: [
            {
                text: 'Дистанционный курс с удобными форматами обучения',
                src: card1,
            },
            {
                text: 'Материалы и шаблоны, которые помогут в учебном процессе',
                src: card2,
            },
            { text: 'Деловые игры и «секретные» мероприятия', src: card3 },
        ],
    },
    {
        value: '2',
        text: 'Ментор',
        question: 'Как будет проходить менторинг?',
        steps: [
            'После подтверждения анкеты твой профиль появится в общей базе менторов',
            'Менти оставит запрос на менторинг',
            'Ты принимаешь или отклоняешь заявку',
            'Проводите установочную встречу с менти и определяется формат и периодичность работы',
            'Взаимодействуете с менти для достижения целей',
        ],
        img: mentor,
        title: 'Обучение менторов и менти',
        desc: 'Чтобы менторинг был максимально полезным для менти и прост для тебя, команда Ozon Универа подготовила для тебя обучение. После подтверждения заполненной анкеты ты получишь:',
        cards: [
            {
                text: 'Дистанционный курс с удобными форматами обучения',
                src: card1,
            },
            {
                text: 'Материалы и шаблоны, которые помогут в учебном процессе',
                src: card2,
            },
            { text: 'Деловые игры и «секретные» мероприятия', src: card3 },
        ],
    },
    {
        value: '3',
        text: 'Тренер',
        question: 'Как будет проходить обучение других сотрудников?',
        steps: [
            'После подтверждения анкеты тебя пригласят в «Школу внутреннего тренера»',
            'Пройдя обучение, ты сможешь создать свою небольшую программу',
            'Твоя программа появится в общем каталоге программ от экспертов',
            'Как только группа заполнится, вышлешь приглашения участникам на удобные даты',
            'Проведешь программу, получишь отзывы коллег и заработаешь баллы в магазине экспертов',
        ],
        img: trainer,
        title: 'Обучение внутренних тренеров',
        desc: 'Команда Ozon Универа поможет тебе получить все необходимые знания и умения для создания программы. Ты получишь помощь и поддержку, а также сможешь пройти полноценное обучение, которое будет состоять из:',
        cards: [
            {
                text: 'Дистанционный курс с удобными форматами обучения',
                src: card1,
            },
            {
                text: 'Материалы и шаблоны, которые помогут в учебном процессе',
                src: card2,
            },
            { text: 'Деловые игры и «секретные» мероприятия', src: card3 },
            {
                text: 'Вебинары и воркшопы с тренерами и другими экспертами',
                src: card4,
            },
        ],
    },
]

const hashMap: any = options.reduce(
    (prev, cur) => ({ ...prev, [cur.value]: cur }),
    {}
)

export const SwitcherExperts: React.FC = () => {
    const [value, setValue] = useState<string>('1')

    return (
        <div className={s.wrapper}>
            <p className={s.title}>
                {
                    'Выбери свою роль и\u00A0открой коллегам мир\u00A0новых знаний'
                }
            </p>
            <div className={s.switcher}>
                <Switcher options={options} setValue={setValue} value={value} />
            </div>
            <Tab {...hashMap[value]} />
        </div>
    )
}
