import React, { useState } from 'react'
import { MentorMarketList } from '../../../../widgets/mentorMarketList'

import s from './expertsMarket.module.scss'
import { Icons } from '../../../../shared/icons'

export const ExpertsMarket = () => {
    const [orderPlaced, setOrderPlaced] = useState(false)

    const handleOrderPlaced = () => {
        setOrderPlaced(true)
    }

    return (
        <div className={s.expertsMarket}>
            {orderPlaced && (
                <div className={s.thankYou}>
                    <p>Спасибо, заказ оформлен</p>
                    <p className={s.thankYou__desctiption}>
                        С тобой свяжется специалист Ozon Универ <br /> и уточнит
                        о передаче подарка
                    </p>

                    <div
                        className={s.thankYou__closeButton}
                        onClick={() => setOrderPlaced(false)}
                    >
                        <Icons.M.Close />
                    </div>
                </div>
            )}

            <h3 className={s.marketTitle}>Выбирай подарки</h3>
            <p className={s.marketSubtitle}>
                Выбирай подарок, если баллов достаточно, нажми «Подробнее»,{' '}
                <br />и мы свяжемся с тобой для передачи подарка
            </p>
            <MentorMarketList onOrderPlaced={handleOrderPlaced} />
        </div>
    )
}
