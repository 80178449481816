import React, { useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import ISwiper from 'swiper'
import { Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import { Icons } from '../../../shared/icons'
import { CatalogCard } from '../../../features/catalogCard'
import { AppButton } from '../../../shared/ui/Button'
import s from './PromoExpertplace.module.scss'
import { useFetchPromoCatalog } from '../../../entities/promoCatalog/api/queries'
import { IPromoCatalogItem } from '../../../entities/promoCatalog/model/types'
import { ROUTES } from '../../../config/routes'
import { useHistory } from 'react-router-dom'

export const PromoExpertplace: React.FC = () => {
    const history = useHistory()
    const { data, isPending } = useFetchPromoCatalog()
    const promoList = data?.data

    const mentorsSwiperRef = useRef<ISwiper>()

    const sliderSettings = {
        320: {
            slidesPerView: 1,
            spaceBetween: 16,
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 16,
        },
        1024: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        1440: {
            slidesPerView: 4,
            spaceBetween: 20,
        },
    }

    if (isPending) {
        return <div>Loading...</div>
    }
    if (!promoList?.length) return null

    return (
        <div className={s.Container}>
            <div className={s.Block}>
                <AppButton
                    className={s.OpportunityButton}
                    size="md"
                    variant="outlined"
                    onClick={() => history.push(ROUTES.catalog)}
                >
                    Узнать все возможности
                </AppButton>
                <div className={s.TitleWrapper}>
                    <h2 className={s.Title}>Уже в Expertplace</h2>
                    <div className={s.MentorsSliderButtonsWrapper}>
                        <button
                            className={`${s.MentorsSliderButtons} MentorsSliderButtons-prev`}
                            aria-label="Prev slide"
                        >
                            <Icons.S.ChevronLeft></Icons.S.ChevronLeft>
                        </button>
                        <button
                            className={`${s.MentorsSliderButtons} MentorsSliderButtons-next`}
                            aria-label="Next slide"
                        >
                            <Icons.S.ChevronRight></Icons.S.ChevronRight>
                        </button>
                    </div>
                </div>
            </div>
            <Swiper
                breakpoints={sliderSettings}
                modules={[Navigation]}
                navigation={{
                    nextEl: '.MentorsSliderButtons-next',
                    prevEl: '.MentorsSliderButtons-prev',
                    disabledClass: `${s.MentorsSliderButtons_disabled}`,
                }}
                className={s.MentorsSlider}
                onSwiper={(swiper: ISwiper) => {
                    mentorsSwiperRef.current = swiper
                }}
            >
                {promoList.map((item: IPromoCatalogItem, index: number) => (
                    <SwiperSlide key={index}>
                        <CatalogCard item={item}></CatalogCard>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}
