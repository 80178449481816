import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { theme } from '../../../../shared/styles/theme'
import { Icons } from '../../../../shared/icons'
import { css } from '@emotion/react'
import { IPromoReviewsGet } from '../../../../entities/review/model/types'

type PropsType = {
    item: IPromoReviewsGet
    addCallbackToObserver?: (cb: () => void) => void
}

const CardWrapper = styled.article`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 16px;
    border: 1px solid ${theme.color.darkSpace[100]};
    border-radius: 16px;
    background-color: ${theme.color.white};
    font-size: 14px;
    line-height: 1.2;
    color: ${theme.color.darkSpace[300]};
    max-width: 100%;
    height: 100%;

    @media (min-width: 1280px) {
        gap: 79px;
        padding: 24px;
    }
`

const ImageWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
    width: 272px;
    height: 272px;
    border-radius: 100%;
    z-index: 1;

    @media (max-width: 768px) {
        width: 284px;
        height: 284px;
    }
`

const ContentWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
`

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`

const H5Styles = styled.h5`
    font-size: 18px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: -0.01em;
    color: ${theme.color.darkSpace[700]};

    @media (min-width: 361px) {
        font-size: 20px;
    }
`

const PlayButton = styled.button<{ isActive: boolean }>`
    background: none;
    display: flex;
    cursor: pointer;
    align-items: center;
    stroke: ${({ isActive }) =>
        isActive ? theme.color.ozonMain : theme.color.darkSpace[700]};
`

export const ReviewCard: React.FC<PropsType> = ({
    item,
    addCallbackToObserver,
}) => {
    const { fullName, position, preview, video } = item
    const [isMuted, setIsMuted] = useState(true)
    const videoElem = useRef<HTMLVideoElement>(null)

    const togglePlay = () => {
        if (videoElem.current) {
            if (isMuted) {
                videoElem.current.currentTime = 0
            }
            setIsMuted((p) => !p)
        }
    }

    const handleVideoEnded = () => {
        if (videoElem.current) {
            videoElem.current.currentTime = 0
            videoElem.current.play()
        }
    }

    useEffect(() => {
        if (addCallbackToObserver) {
            addCallbackToObserver(() => {
                videoElem?.current?.play()
            })
        }
    }, [])

    return (
        <CardWrapper>
            <Icons.reviews.BackgroundCardStar
                css={css`
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    transition: opacity 0.3s;
                    opacity: ${isMuted ? 0 : 1};
                `}
            />
            <ImageWrapper>
                <video
                    muted={isMuted}
                    ref={videoElem}
                    src={video}
                    poster={preview}
                    onEnded={handleVideoEnded}
                />
            </ImageWrapper>
            <ContentWrapper>
                <InfoWrapper>
                    <H5Styles>{fullName}</H5Styles>
                    <p>{position}</p>
                </InfoWrapper>
                <PlayButton onClick={togglePlay} isActive={!isMuted}>
                    {isMuted ? <Icons.M.Sound /> : <Icons.M.Soundwave />}
                </PlayButton>
            </ContentWrapper>
        </CardWrapper>
    )
}
