import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

type ErrorType = {
    field: string
    message: string
}

type ErrorsStoreType = {
    errors: Array<ErrorType>
}

const initialState: ErrorsStoreType = {
    errors: [],
}

export const errorsSlice = createSlice({
    initialState,
    name: 'errors',
    reducers: {
        setErrors: (state, { payload }: PayloadAction<Array<ErrorType>>) => {
            state.errors = payload
        },
        clearErrors: (state) => {
            state.errors = []
        },
    },
})

export const selectStoreErrors = (state: RootState) => state.errorsSlice.errors
