import React from 'react'
import { AppButton } from '../../../../shared/ui/Button'
import { Typography } from '../../../../shared/ui/Typography'
import {
    ModalWindow,
    ModalWindowPropsType,
} from '../../../../shared/ui/ModalWindow'

export const ApplicationRejectedModal = (props: ModalWindowPropsType) => {
    return (
        <ModalWindow {...props}>
            <Typography as="h4">Заявка отклонена</Typography>
            <Typography as="t1">
                Форма успешно отправлена, заявка отклонена
                <br />
            </Typography>
        </ModalWindow>
    )
}
