import React from 'react'
import { AdminAuthForm } from './adminAuthForm/adminAuthForm'
import s from './adminAuth.module.scss'

export const AdminAuth = () => {
    return (
        <div className={s.Container}>
            <div className={s.Wrapper}>
                <h1 className={s.Title}>Авторизация</h1>
                <AdminAuthForm />
            </div>
        </div>
    )
}
