import React from 'react'
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export const DevNavigator = ({
    routes,
}: {
    routes: { [key: string]: any }
}) => {
    const [isOpened, setIsOpened] = useState(false)

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.openNavigator = () => setIsOpened(true)
    }, [])

    if (!isOpened) {
        return null
    }

    const renderNavItem = (object: { [key: string]: any }) => {
        return (
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                `}
            >
                {Object.entries(object).map(([key, value], index) => (
                    <div key={value + index}>
                        {typeof value === 'string' ? (
                            <Link to={value}>{value}</Link>
                        ) : (
                            <div
                                css={css`
                                    padding-left: 10px;
                                `}
                            >
                                <h3>{key}</h3>
                                {renderNavItem(value)}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div
            css={css`
                position: fixed;
                height: 400px;
                width: 300px;
                bottom: 0;
                right: 0;
                background-color: white;
                box-shadow: 0 0 5px gray;
                border-radius: 10px;
                padding: 16px;
                overflow: auto;
            `}
        >
            <div>{renderNavItem(routes)}</div>
        </div>
    )
}
