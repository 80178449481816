import { css } from '@emotion/react'
import React, { HTMLAttributes } from 'react'
import './styles.css'

type PropsType = {
    as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 't1' | 't2' | 't3' | 'p1'
    center?: boolean
} & HTMLAttributes<HTMLHeadingElement>

const possibleTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']

export const Typography: React.FC<PropsType> = ({
    as,
    center = false,
    className = '',
    ...props
}) => {
    return React.createElement(possibleTags.includes(as) ? as : 'p', {
        ...props,
        className: `typography ${className} ${as} ${center && 'center'}`,
        style: { color: props.color },
    })
}
