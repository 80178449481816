import React from 'react'
import { PersonalAccountRouter } from './router'
import { PersonalAccountMenu } from '../../widgets/personalAccountMenu'

import s from './personalAccount.module.scss'
import { Layout } from '../../shared/ui/Layout'
import { Container } from '../../shared/ui/Container'
import {
    useFetchProfile,
    useFetchUser,
} from '../../entities/profile/api/queries'
import { LinkPresets } from '../../shared/ui/Link'
import { useHistory } from 'react-router-dom'

export const PersonalAccount = () => {
    const {isFetched} = useFetchProfile()
    useFetchUser()
    const history = useHistory()

    if (!isFetched) return null

    return (
        <Layout>
            <Container>
                <div className={s.personalAccount}>
                    <div className={s.back}>
                        <LinkPresets.BackBlue
                            to={'#'}
                            onClick={() => history.goBack()}
                        />
                    </div>
                    <PersonalAccountMenu />
                    <PersonalAccountRouter />
                </div>
            </Container>
        </Layout>
    )

}
