import React from 'react'
import { AppButton } from '../../../../shared/ui/Button'
import { Typography } from '../../../../shared/ui/Typography'
import {
    ModalWindow,
    ModalWindowPropsType,
} from '../../../../shared/ui/ModalWindow'

type PropsType = {
    props: ModalWindowPropsType
    type: string | null
}

export const ApplicationApprovedModal = ({ props, type }: PropsType) => {
    let text

    switch (type) {
        case 'mentor':
            text = (
                <Typography as="t1">
                    Менти получит уведомление о том, что ты одобрил его заявку,
                    на почту. <br />
                    Теперь ты можешь связаться с ним в chatzone и запланировать
                    установочную встречу
                </Typography>
            )
            break
        case 'consultant':
            text = (
                <Typography as="t1">
                    Коллега получит уведомление о том, что ты одобрил его
                    заявку, на почту. <br />
                    Теперь ты можешь связаться с ним в chatzone и запланировать
                    установочную встречу
                </Typography>
            )
            break
        case 'program':
            text = (
                <Typography as="t1">
                    Коллега получит уведомление о том, что ты одобрил его
                    заявку, на почту.
                    <br /> Если принятых заявок уже достаточно, ты можешь
                    сформировать группу и направить коллегам первую коммуникацию
                    о предстоящем обучении.
                </Typography>
            )
            break
        default:
            text = (
                <Typography as="t1">
                    Коллега получит уведомление о том, что ты одобрил его
                    заявку, на почту. <br />
                    Теперь ты можешь связаться с ним в chatzone и запланировать
                    установочную встречу
                </Typography>
            )
    }

    return (
        <ModalWindow {...props}>
            <Typography as="h4">Заявка одобрена</Typography>
            {text}
            <AppButton
                onClick={() => props.setIsOpened(false)}
                variant="filled"
                size="md"
            >
                Хорошо
            </AppButton>
        </ModalWindow>
    )
}
