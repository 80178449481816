import { zodResolver } from '@hookform/resolvers/zod'
import {
    FieldValues,
    useForm,
    UseFormProps,
    UseFormReturn,
} from 'react-hook-form'

export const useSmartForm = <
    TFieldValues extends FieldValues = FieldValues,
    TContext = any,
>(
    props?: UseFormProps<TFieldValues, TContext>,
    options?: {
        schema?: any
    }
): UseFormReturn<TFieldValues, TContext> => {
    const form = useForm({
        reValidateMode: 'onSubmit',
        mode: 'onSubmit',
        ...props,
        resolver: options?.schema ? zodResolver(options.schema) : undefined,
    })

    return form
}

export type useSmartFormProps = Parameters<typeof useSmartForm>
