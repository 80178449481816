import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProfileSliceType } from './types'

const initialState: ProfileSliceType = {
    step: null,
    isMenuOpen: false,
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setStep: (
            state,
            { payload }: PayloadAction<ProfileSliceType['step']>
        ) => {
            state.step = payload
        },
        toggleMenu: (state) => {
            state.isMenuOpen = !state.isMenuOpen
        },
        closeMenu: (state) => {
            state.isMenuOpen = false
        },
    },
})

export const { actions: profileActions, reducer: profileReducer } = profileSlice
