import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../app/store";
import {useQuery} from "@tanstack/react-query";
import {promoCatalogApi} from "./index";
import {promoCatalogActions} from "../model/slice";

export const useFetchPromoCatalog = () => {
    const dispatch = useDispatch<AppDispatch>()
    return useQuery({
        queryKey: ['promoCatalog'],
        queryFn: async () => {
            const response = await promoCatalogApi.getPromoCatalog()
            dispatch(promoCatalogActions.setPromoCatalog(response.data))
            return response.data
        },
    })
}