import { SSO_URL } from '../model/constants'

export const useAuth = () => {
    const login = () => {
        window.location.href = SSO_URL
    }

    return {
        login,
    }
}
