import {createSlice} from "@reduxjs/toolkit";
import {PromoCatalogItemSliceType} from "./types";

const initialState: PromoCatalogItemSliceType = {
    data: {
        data: []
    }
}

export const promoCatalogSlice = createSlice({
    name: 'promoCatalog',
    initialState,
    reducers: {
        setPromoCatalog: (state, {payload}) => {
            state.data = {...state.data, ...payload}
        }
    }
})

export const {actions: promoCatalogActions, reducer: promoCatalogReducer} = promoCatalogSlice
