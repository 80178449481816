import React from 'react'
import { css } from '@emotion/react'
import { useSmartForm } from '../../../../services/formProcessing'
import { SmartForm } from '../../../../services/formProcessing/ui/SmartForm'
import {
    ModalWindow,
    ModalWindowPropsType,
} from '../../../../shared/ui/ModalWindow'
import { TextField } from '../../../../shared/ui/TextField'
import { Typography } from '../../../../shared/ui/Typography'
import { AppButton } from '../../../../shared/ui/Button'
import { useParams } from 'react-router-dom'
import { z } from 'zod'
import { applicationApi } from '../../../../entities/application'

type FormType = {
    fullName: string
    email: string
    staff: string
}

type PropsType = ModalWindowPropsType & {
    onSuccess: () => void
}

const formSchema = z.object({
    fullName: z.string().min(2, 'Имя пользователя слишком короткое'),
    email: z.string().email('Некорректный email'),
    staff: z
        .string()
        .refine(
            (value) => value.includes('staff.o3.ru'),
            'Некорректная ссылка на Staff'
        ),
})

export const CreateProgrammApplicationModal = ({
    onSuccess,
    ...props
}: PropsType) => {
    const { id } = useParams<{ id: string }>()
    const form = useSmartForm<FormType>(
        {
            defaultValues: {
                fullName: '',
                email: '',
                staff: '',
            },
        },
        {
            schema: formSchema,
        }
    )

    const handleSubmit = async (data: FormType) => {
        try {
            await applicationApi.postSubmitApplication({
                email: data.email,
                staff: data.staff,
                fullName: data.fullName,
                trainingId: Number(id),
                type: 'program',
            })
            props.setIsOpened(false)
            onSuccess()
        } catch (e) {
            console.log(e)
            alert('Something went wrong')
        }
    }

    return (
        <ModalWindow {...props}>
            <SmartForm
                css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 32px;
                    justify-content: flex-start;
                    width: 100%;
                `}
                submit={handleSubmit}
                form={form}
            >
                <Typography as="h4">Заявка на программу</Typography>
                <TextField
                    label="ФИО*"
                    placeholder="Фамилия Имя Отчество"
                    name="fullName"
                />
                <TextField
                    label="E-mail*"
                    placeholder="Введи Свой e-mail"
                    name="email"
                />
                <TextField
                    label="Ссылка на staff*"
                    placeholder="Ссылка на staff"
                    name="staff"
                />
                <AppButton
                    css={css`
                        width: fit-content;
                        margin: 0 auto;
                    `}
                >
                    Отправить заявку
                </AppButton>
            </SmartForm>
        </ModalWindow>
    )
}
