import React from 'react'
import s from './ExpertProfileTab.module.scss'
import clsx from 'clsx'
import moment from 'moment'
import 'moment/locale/ru'
import { IExperience, IExpertise } from '../../model/types'
moment.locale('ru')

type PropsType = {
    tabIndex: string
    expertise?: IExpertise[]
    experience?: IExperience[]
}

const Experience: React.FC<Partial<PropsType>> = ({ experience }) => {
    const getDateDiapason = () => {
        let m = ''
        let y = ''
        let startDate: any = ''
        let endDate: any = ''
        experience?.forEach((el) => {
            startDate = startDate
                ? startDate > el.since
                    ? el.since
                    : startDate
                : el.since
            endDate = el.isOngoing
                ? moment()
                : startDate
                  ? endDate < el.until
                      ? el.until
                      : startDate
                  : el.until
        })

        const years = Math.abs(moment(startDate).diff(moment(endDate), 'years'))
        const months = Math.abs(
            moment(startDate).diff(moment(endDate), 'months') % 12
        )

        if (years === 1) {
            y = `${years} Год`
        } else if (years > 1 && years < 5) {
            y = `${years} Года`
        } else if (years >= 5) {
            y = `${years} Лет`
        } else {
            y = ''
        }

        if (months === 1) {
            m = `${months} Месяц`
        } else if (months > 1 && months < 5) {
            m = `${months} Месяца`
        } else if (months >= 5) {
            m = `${months} Месяцев`
        }
        return `${y} ${m}`
    }

    return (
        <div>
            <p className={clsx(s.h4, s.black, s.experience_title)}></p>
            <p className={s.p2}>{getDateDiapason()}</p>
            <div className={s.experience_wrapper}>
                {experience?.map((el: any, i: number) => (
                    <div key={i} className={s.experience}>
                        <div className={s.experience_info}>
                            <p className={clsx(s.p2)}>{el.position}</p>
                            <p className={clsx(s.p3, s.gray)}>
                                {el.organization}
                            </p>
                            {el.isOngoing}
                            <p className={clsx(s.t2, s.gray, s.date)}>
                                {`${moment(el.since).format('MMMM YYYY')} - ${
                                    el.isOngoing
                                        ? 'по настоящее время'
                                        : moment(el.until).format('MMMM YYYY')
                                }`}
                            </p>
                        </div>
                        <div className={s.experience_results}>
                            {el.results
                                .split('\n')
                                .map((el: string, i: number) => (
                                    <p key={i} className={s.p3}>
                                        {el}
                                    </p>
                                ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

const Expertise: React.FC<Partial<PropsType>> = ({ expertise }) => (
    <div className={s.expertises_wrapper}>
        {expertise?.map((el: any, i: number) => (
            <div key={i} className={s.expertises}>
                <p className={clsx(s.h4)}>{el.description}</p>
                <p className={clsx(s.p1, s.subtitle)}>С чем могу помочь?</p>
                <ul className={s.list}>
                    {Array.isArray(el.specialisations) &&
                        el.specialisations.map((el: any, i: number) => (
                            <li key={i} className={clsx(s.p3)}>
                                {el}
                            </li>
                        ))}
                    {typeof el.specialisations === 'string' && (
                        <li className={clsx(s.p3)}>{el.specialisations}</li>
                    )}
                </ul>
            </div>
        ))}
    </div>
)

export const ExpertProfileTab = (props: PropsType) => {
    const { tabIndex, expertise, experience } = props
    switch (tabIndex) {
        case '1':
            return <Expertise expertise={expertise} />
        case '2':
            return <Experience experience={experience} />
        default:
            return null
    }
}
