import React from 'react'
import { Accordion } from '../../../shared/ui/Accordion'
import { AppButton } from '../../../shared/ui/Button'
import s from './Faq.module.scss'
import { FaqType } from '../../../pages/adminPanel/subpages/faq/model/types'
import { useGetFaqByPage } from '../../../pages/adminPanel/subpages/faq/api/queries'

type PropsType = {
    type: FaqType
}

export const Faq: React.FC<PropsType> = ({ type }) => {
    const { data, isFetching, isSuccess } = useGetFaqByPage(type)

    if (isFetching) return null
    if (isSuccess) {
        return (
            <div className={s.wrapper} id="faq">
                <p className={s.title}>Частые вопросы</p>
                <Accordion list={data?.data} />
                <div className={s.feedback}>
                    <p className={s.feedback_text}>
                        Не нашли ответы на свои вопросы? <br /> Не стейснятесь
                        их задать
                    </p>
                    <AppButton
                        id="contacts"
                        onClick={() =>
                            (window.location.href = 'mailto:mavramova@ozon.ru')
                        }
                    >
                        {'Связаться\u00A0с\u00A0нами'}
                    </AppButton>
                </div>
            </div>
        )
    }
    return null
}
