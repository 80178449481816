import { useEffect, useState } from 'react'
import { TokenStorage } from '../model'
import { setToken } from '../lib/setToken'
import { useLocation } from 'react-router-dom'
import { ROUTES } from '../../../config/routes'
import {
    handleRefreshUserRefreshToken,
    handleRefreshUserAccessToken,
} from '../../../entities/user/lib/auth'

export const useAutoAuth = () => {
    const [isReady, setIsReady] = useState(false)
    const { pathname } = useLocation()

    const handleAutoAuth = async () => {
        if (pathname.includes(ROUTES.authFinish)) {
            setIsReady(true)
            return
        }

        const token = TokenStorage.getTokenData('user')

        if (!token) {
            handleRefreshUserRefreshToken()
            return
        }

        const currentDate = new Date()

        if (new Date(token.accessTokenExpiresAt * 1000) < currentDate) {
            if (new Date(token.refreshTokenExpiresAt * 1000) < currentDate) {
                handleRefreshUserRefreshToken()
                return
            }

            await handleRefreshUserAccessToken(token.refreshToken)
        } else {
            setToken(token)
        }

        setIsReady(true)
    }

    useEffect(() => {
        try {
            handleAutoAuth()
        } catch (e) {
            console.error(e)
            alert('There was an error with auth')
        }
    }, [])

    return { isReady }
}
