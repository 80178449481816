import React, { SyntheticEvent } from 'react'
import { useModalWindow } from '../../../shared/hooks/useModalWindow'
import { AppButton } from '../../../shared/ui/Button'
import { UploadAvatarModal } from './modals/uploadAvatarModal'
import s from './FillingProfile.module.scss'
import { Avatar } from '../../../shared/ui/Avatar'
import { SmartForm } from '../../../services/formProcessing/ui/SmartForm'
import { useSmartForm } from '../../../services/formProcessing'
import { TextField } from '../../../shared/ui/TextField'
import { LinkPresets } from '../../../shared/ui/Link'
import clsx from 'clsx'
import { StepLayout } from './StepLayout'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../app/store'
import { profileActions } from '../../../entities/profile'
import { useFieldArray } from 'react-hook-form'
import { IProfileExpertFirstStep } from '../../../entities/profile/model/types'
import {
    useFetchProfile,
    usePatchProfile,
} from '../../../entities/profile/api/queries'

interface IExpertise {
    description?: string
    specialisations?: string
}
interface IForm extends IProfileExpertFirstStep {
    expertise: IExpertise[]
}

export const FillingProfileFirstStep: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>()
    const nextStep = () => dispatch(profileActions.setStep(2))
    const { data: dataProfile } = useFetchProfile()
    const form = useSmartForm<IForm>({
        defaultValues: {
            photo: dataProfile?.photo,
            about: dataProfile?.about,
            fullName: dataProfile?.fullName,
            position: dataProfile?.position,
            socialTelegram: dataProfile?.socialTelegram,
            socialVkontakte: dataProfile?.socialVkontakte,
            socialStaff: dataProfile?.socialStaff,
            expertise: dataProfile?.expertise?.map((el) => ({
                ...el,
                specialisations: el.specialisations.join('\n'),
            })),
        },
    })
    const { fields, append, remove } = useFieldArray({
        name: 'expertise',
        control: form.control,
    })
    const uploadAvatarModal = useModalWindow()
    const { mutate, isPending } = usePatchProfile(nextStep)

    const handleClickAvatar = (evt: SyntheticEvent) => {
        evt.preventDefault()
        uploadAvatarModal.toggleOpen()
    }
    const handleClickAddExpertise = (evt: SyntheticEvent) => {
        evt.preventDefault()
        append({})
    }
    const handleClickRemoveExpertise = (evt: SyntheticEvent, index: number) => {
        evt.preventDefault()
        remove(index)
    }
    const onSubmit = (data: IForm) => {
        const expertise = data?.expertise?.map((el: any) => ({
            ...el,
            specialisations: el.specialisations.split('\n'),
        }))
        const newData = {
            expertise,
            photo: dataProfile?.photo,
        }
        mutate({ ...data, ...newData })
    }

    return (
        <StepLayout>
            <SmartForm
                className={clsx(s.step, s.step_1)}
                form={form}
                submit={form.handleSubmit(onSubmit)}
            >
                <p className={s.subtitle}>Мои данные</p>
                <div className={s.section_1}>
                    <div className={clsx(s.avatar_wrapper)}>
                        <Avatar url={dataProfile?.photo} className={s.avatar} />
                        <AppButton
                            className={s.avatar_button}
                            variant="ghost"
                            onClick={handleClickAvatar}
                        >
                            Загрузи аватар
                        </AppButton>
                    </div>
                    <TextField
                        label="Ф.И.О."
                        placeholder="Иванов Иван Иванович"
                        name="fullName"
                    />
                    <TextField
                        label="Должность"
                        placeholder="Дизайнер"
                        name="position"
                    />
                    <TextField
                        label="О себе"
                        placeholder="Несколько предложений про себя"
                        name="about"
                        maxLength={200}
                    />
                    <TextField
                        label="Ссылка на Staff"
                        placeholder="https://"
                        name="socialStaff"
                    />
                    <div className={s.social}>
                        <TextField
                            label="Ссылка на Telegram"
                            placeholder="https://"
                            name="socialTelegram"
                        />
                        {/*<TextField*/}
                        {/*    label="Ссылка на Вконтакте"*/}
                        {/*    placeholder="https://"*/}
                        {/*    name="socialVkontakte"*/}
                        {/*/>*/}
                    </div>
                </div>
                <div className={s.section_1}>
                    <div>
                        <p className={s.subtitle}>
                            Чем ты можешь быть полезен?
                        </p>
                        <p className={s.desc}>
                            Расскажи подробнее, какие конкретные запросы от
                            коллег ты мог бы помочь решить, заполнив форму ниже:
                        </p>
                    </div>
                    {fields.map((field, index) => (
                        <div key={field.id} className={s.section_1}>
                            <TextField
                                label="Кому"
                                placeholder="Укажи, каким категориям сотрудников ты можешь быть полезен"
                                name={`expertise.${index}.description`}
                            />
                            <TextField
                                as="textarea"
                                label="Чем можешь быть полезен"
                                placeholder="Перечисли по пунктам, чем именно ты можешь помочь этой категории сотрудников"
                                name={`expertise.${index}.specialisations`}
                            />
                            <LinkPresets.Remove
                                onClick={(evt) =>
                                    handleClickRemoveExpertise(evt, index)
                                }
                                to=""
                            />
                        </div>
                    ))}
                    <LinkPresets.AddBlock
                        onClick={handleClickAddExpertise}
                        to=""
                    />
                </div>
                <div className={clsx(s.section_1, s.submit)}>
                    <AppButton disabled={isPending}>
                        Сохранить изменения
                    </AppButton>
                    <p onClick={nextStep} className={s.skip}>
                        Далее
                    </p>
                </div>
            </SmartForm>
            <UploadAvatarModal {...uploadAvatarModal} />
        </StepLayout>
    )
}
