import { css } from '@emotion/react'
import { useSmartForm } from '../../../services/formProcessing'
import { SmartForm } from '../../../services/formProcessing/ui/SmartForm'
import { theme } from '../../../shared/styles/theme'
import { AppButton } from '../../../shared/ui/Button'
import { TextField } from '../../../shared/ui/TextField'
import { Typography } from '../../../shared/ui/Typography'
import { ProgramCardType } from '../../../entities/programm/types'
import React, { SyntheticEvent, useEffect } from 'react'
import { LinkPresets } from '../../../shared/ui/Link'
import { useFieldArray } from 'react-hook-form'
import { useList } from '../../../shared/hooks/useList/useList'
import { tagsApi } from '../../../entities/tags/api'
import { transformTagsForFetch } from '../../fillingProfile/ui/helpers'
import { programApi } from '../../../entities/programm/api'
import { SmartRadio } from '../../../shared/ui/Radio'
import { SmartCreatableSelect } from '../../../shared/ui/CreatableSelect'

const SectionLabel = ({
    title,
    subtitle,
    ...divProps
}: {
    title: string
    subtitle?: string
} & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
>) => {
    return (
        <div
            {...divProps}
            css={css`
                margin-bottom: 32px;
            `}
        >
            <Typography as="h4">{title}</Typography>
            {subtitle && (
                <Typography
                    css={css`
                        margin-top: 12px;
                    `}
                    as="t1"
                    color={theme.color.darkSpace[450]}
                >
                    {subtitle}
                </Typography>
            )}
        </div>
    )
}

type PropsType = {
    program?: ProgramCardType
    reset?: () => void
    isEdit?: boolean
    setModalWindowIsVisible?: (v: boolean) => void
}

export const CreateOrEditProgramForm = ({
    program,
    reset,
    isEdit,
    setModalWindowIsVisible,
}: PropsType) => {
    const form = useSmartForm<ProgramCardType>({
        defaultValues: {
            city: program?.city,
            duration: program?.duration,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            expertise: program?.expertise
                ? program?.expertise.map((el) => ({
                      ...el,
                      specialisations: el.specialisations.join('\n'),
                  }))
                : [
                      {
                          description: '',
                          specialisations: [''],
                      },
                  ],
            format: program?.format,
            fullDescription: program?.fullDescription,
            name: program?.name,
            shortDescription: program?.shortDescription,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            tags: program?.tags?.map((el, i) => ({ value: i, label: el })),
        },
    })

    const { fields, append, remove } = useFieldArray({
        name: 'expertise',
        control: form.control,
    })

    const { items: fetchTags } = useList<string[]>({
        key: 'tags',
        apiMethod: tagsApi.getTags,
        defaultLimit: 100,
    })
    const handleClickAddExpertise = (evt: SyntheticEvent) => {
        evt.preventDefault()
        append({
            description: '',
            specialisations: [''],
        })
    }

    const handleClickRemoveExpertise = (evt: SyntheticEvent, index: number) => {
        evt.preventDefault()
        remove(index)
    }

    const handleSubmit = async (data: ProgramCardType) => {
        const tags = transformTagsForFetch(data?.tags)
        const expertise = data?.expertise?.map((el: any) => ({
            ...el,
            specialisations: el.specialisations.split('\n'),
        }))
        const preparationData = {
            ...data,
            format: data.format.toString(),
            tags: tags,
            expertise: expertise,
        }
        if (isEdit) {
            try {
                await programApi.patchProgramById(
                    program!.id!,
                    preparationData as ProgramCardType
                )

                if (setModalWindowIsVisible) {
                    setModalWindowIsVisible(false)
                }
                if (reset) await reset()
            } catch (e) {
                console.log(e)
                alert('Something went wrong')
            }
        } else {
            try {
                await programApi.postCreateProgram(
                    preparationData as ProgramCardType
                )

                if (setModalWindowIsVisible) {
                    setModalWindowIsVisible(false)
                }
                if (reset) reset()
            } catch (e) {
                console.log(e)
                alert('Something went wrong')
            }
        }
    }

    useEffect(() => {
        form.watch()
    }, [form.getValues('format')])

    return (
        <SmartForm
            css={css`
                overflow: auto;
                width: 100%;
                ::-webkit-scrollbar {
                    display: none;
                }
                max-height: 80vh;
            `}
            form={form}
            submit={() => {}}
        >
            <Typography
                css={css`
                    margin-bottom: 24px;
                `}
                center
                as="h4"
            >
                Редактирование карточки программы
            </Typography>
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                `}
            >
                <TextField
                    label="Название программы"
                    placeholder="Продуктовые исследования"
                    name="name"
                    required={true}
                />
                <TextField
                    as="textarea"
                    label="Краткое описание программы"
                    placeholder="Впиши краткое (до 200 символов), максимально емкое описание программы. Оно появится в превью карточки в каталоге программ."
                    name="shortDescription"
                    required={true}
                    maxLength={200}
                />
                <TextField
                    as="textarea"
                    label="Подробное описание программы"
                    placeholder="Впиши полное описание программы со структурой."
                    name="fullDescription"
                    required={true}
                />
                <TextField
                    label="Продолжительность"
                    placeholder="Например, 3 часа"
                    name="duration"
                    required={true}
                />
                {fields.map((field, index) => (
                    <div key={field.id}>
                        <TextField
                            label="Для кого"
                            placeholder="Например, начинающим дизайнерам"
                            name={`expertise.${index}.specialisations`}
                            required={true}
                        />
                        <TextField
                            as="textarea"
                            label="Чему научатся"
                            placeholder="Впиши образовательные результаты, которых достигнут участники твоего обучения. Например, узнают алгоритм выдачи эффективной обратной связи."
                            name={`expertise.${index}.description`}
                            required={true}
                        />
                        {fields.length > 1 && (
                            <LinkPresets.Remove
                                onClick={(evt) =>
                                    handleClickRemoveExpertise(evt, index)
                                }
                                to=""
                            />
                        )}
                    </div>
                ))}
                <LinkPresets.AddBlock onClick={handleClickAddExpertise} to="" />
            </div>
            <div
                css={css`
                    margin: 24px 0 32px;
                `}
            >
                <SectionLabel
                    title="Формат взаимодействия"
                    subtitle="Выбери, в каком формате ты бы хотел взаимодействовать с
                    учениками"
                />

                <div
                    css={css`
                        display: flex;
                        gap: 12px;
                    `}
                >
                    <SmartRadio
                        name="format"
                        label="Очный тренинг"
                        value="offline"
                    />
                    <SmartRadio
                        name="format"
                        label="Онлайн-вебинар"
                        value="online"
                    />
                </div>
            </div>

            {form.getValues('format') !== 'online' && (
                <div>
                    <Typography
                        css={css`
                            margin-bottom: 24px;
                        `}
                        as="h4"
                    >
                        Город
                    </Typography>

                    <TextField
                        placeholder="Ввести информацию"
                        name="city"
                        required={true}
                    />
                </div>
            )}

            <div>
                <SectionLabel
                    title="Теги"
                    subtitle="Добавь теги, по которым можно будет найти программу (до 10)"
                />

                <SmartCreatableSelect
                    label=""
                    name="tags"
                    placeholder="Теги"
                    isMulti
                    options={fetchTags.map((el: any, i) => ({
                        value: i,
                        label: el,
                    }))}
                />
            </div>

            <div
                css={css`
                    display: flex;
                    justify-content: center;
                    margin-top: 24px;
                `}
            >
                <AppButton onClick={form.handleSubmit(handleSubmit)}>
                    {isEdit ? 'Отправить на модерацию' : 'Создать программу'}
                </AppButton>
            </div>
        </SmartForm>
    )
}
