import React, { useState } from 'react'
import { StatusType } from '../../../faq/model/types'
import { ExpertRole, IExpertList } from '../../model'
import { useList } from '../../../../../../shared/hooks/useList/useList'
import { expertsApi } from '../../api'
import { ExpertsForm } from '../usersForm'
import { AdminPageLayout } from '../../../../ui/adminPageLayout/layout'
import { ExpertsList } from '../usersList'

type PropsType = {
    title: string
    type?: ExpertRole
}

const ExpertsLayout: React.FC<PropsType> = ({ title, type }) => {
    const [status, setStatus] = useState<StatusType | null>(null)
    const [id, setId] = useState<number>()

    const query = useList<IExpertList>({
        key: ['users', type || ''],
        apiMethod: (params) => expertsApi.getExperts({ ...params, type }),
    })

    if (status)
        return (
            <ExpertsForm
                id={id}
                role={type}
                status={status}
                setStatus={setStatus}
                reset={query.reset}
            />
        )

    return (
        <AdminPageLayout
            title={title === 'Пользователи' ? `Все пользователи` : title}
        >
            <ExpertsList
                role={type}
                setId={setId}
                setStatus={setStatus}
                query={query}
            />
        </AdminPageLayout>
    )
}

export default ExpertsLayout
