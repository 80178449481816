import { Container } from '../Container'
import { Icons } from '../../icons'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../config/routes'
import { AppButton } from '../Button'
import React from 'react'
import clsx from 'clsx'

import s from './header.module.scss'
import { useFetchProfile } from '../../../entities/profile/api/queries'
import { Avatar } from '../Avatar'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../app/store'
import { profileActions, profileSelectors } from '../../../entities/profile'
import { useWindowDimensions } from '../../hooks/useWindowDimensions'

// TODO: сделать корректную разлинковку
export const Header = () => {
    const dispatch = useDispatch<AppDispatch>()
    const { width } = useWindowDimensions()
    const { data: profile } = useFetchProfile()
    const isMenuOpen = useSelector(profileSelectors.isMenuOpen)
    const handleToggleMenu = () => {
        dispatch(profileActions.toggleMenu())
    }

    const formatFullName = (fullName: string) => {
        const parts = fullName.split(' ')
        const surname = parts[0]
        const patronymicInitials =
            parts.length > 2 ? parts[1].charAt(0) + '.' : ''
        return surname + ' ' + patronymicInitials
    }

    const isShowMenu =
        width >= 980 || !window.location.pathname.includes('personal-cabinet')

    return (
        <Container>
            <div className={s.header}>
                <Link
                    to={ROUTES.root}
                    onClick={() => dispatch(profileActions.closeMenu())}
                >
                    <Icons.logos.HeaderLogo className={s.logo} />
                </Link>
                {isShowMenu && (
                    <ul
                        className={clsx([
                            s.menu,
                            { [s.burgerMenu]: isMenuOpen },
                        ])}
                    >
                        <li>
                            <Link
                                to={ROUTES.root}
                                onClick={() =>
                                    dispatch(profileActions.closeMenu())
                                }
                            >
                                О платформе
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={`${ROUTES.catalog}?types=mentors`}
                                onClick={() =>
                                    dispatch(profileActions.closeMenu())
                                }
                            >
                                Менторинг
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={`${ROUTES.catalog}?types=consultants`}
                                onClick={() =>
                                    dispatch(profileActions.closeMenu())
                                }
                            >
                                Консультация эксперта
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={`${ROUTES.catalog}?types=programs`}
                                onClick={() =>
                                    dispatch(profileActions.closeMenu())
                                }
                            >
                                Программы от экспертов
                            </Link>
                        </li>
                    </ul>
                )}

                <div className={s.buttonsWrapper}>
                    {!profile?.id ? (
                        <Link to={ROUTES.experts}>
                            <AppButton
                                size="sm"
                                variant="ghost"
                                className={s.appButton}
                            >
                                Стать экспертом
                            </AppButton>
                        </Link>
                    ) : (
                        <div className={s.info}>
                            <Link
                                to={ROUTES.personalCabinet.me.root}
                                onClick={() =>
                                    dispatch(profileActions.closeMenu())
                                }
                            >
                                <p className={s.name}>
                                    {formatFullName(profile?.fullName!)}
                                </p>
                                <Avatar
                                    url={profile.photo}
                                    className={s.avatar}
                                />
                            </Link>
                        </div>
                    )}

                    <div
                        className={clsx([s.burgerIcon], {
                            [s.expertHamburger]: profile?.isEnabled,
                        })}
                        onClick={handleToggleMenu}
                    >
                        {isMenuOpen ? <Icons.M.Close /> : <Icons.M.Hamburger />}
                    </div>
                </div>
            </div>
        </Container>
    )
}
