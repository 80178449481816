import React, { SyntheticEvent, useEffect, useState } from 'react'
import { StatusType, FaqType, IFaqGet } from '../../model/types'
import { SmartForm } from '../../../../../../services/formProcessing/ui/SmartForm'
import s from './indes.module.scss'
import { TextField } from '../../../../../../shared/ui/TextField'
import { AppButton } from '../../../../../../shared/ui/Button'
import moment from 'moment/moment'
import { Pagination } from '../../../../../../widgets/pagination/ui/Pagination'
import { useSmartForm } from '../../../../../../services/formProcessing'
import { Icons } from '../../../../../../shared/icons'
import clsx from 'clsx'
import { useDeleteFaqs } from '../../api/queries'
import { ModalWindow } from '../../../../../../shared/ui/ModalWindow'

type PropsType = {
    type: FaqType
    setStatus: (status: StatusType) => void
    setId: (id: number) => void
    query: any
}

interface IColumn {
    label: string
    order: 'asc' | 'desc' | 'default'
    sort: keyof IFaqGet
}

interface IRow extends IFaqGet {
    checked: boolean
}

const COLUMNS: IColumn[] = [
    { label: 'ID', order: 'default', sort: 'id' },
    { label: 'Название', order: 'default', sort: 'question' },
    { label: 'Активность', order: 'default', sort: 'isEnabled' },
    { label: 'Сортировка', order: 'default', sort: 'priority' },
    { label: 'Дата Изменения', order: 'default', sort: 'updatedAt' },
]

export const FaqList: React.FC<PropsType> = ({ setId, setStatus, query }) => {
    const {
        items,
        page,
        total,
        isFetching,
        setFilters,
        nextPage,
        prevPage,
        setPage,
        isError,
        reset,
    } = query
    const [isOpened, setIsOpened] = useState(false)
    const [limit, setLimit] = useState(10)
    const form = useSmartForm({
        defaultValues: {
            q: '',
            limit: { value: limit, label: limit },
        },
    })
    const [columns, setColumns] = useState<IColumn[]>(COLUMNS)
    const [rows, setRows] = useState<IRow[]>([])
    const [selectedId, setSelectedId] = useState<{ id: number }[]>([])
    const { mutate: del } = useDeleteFaqs(reset)

    const isRowActive = (id: number) => selectedId.find((el) => el.id === id)
    const onSubmit = (data: any) => {
        setFilters({ ...data, limit })
    }
    const onPrev = (evt: SyntheticEvent) => {
        evt.preventDefault()
        prevPage()
    }
    const onNext = (evt: SyntheticEvent) => {
        evt.preventDefault()
        nextPage()
    }
    const handleChangeLimit = ({ value }: any) => {
        setLimit(value)
        setFilters({ ...form.getValues(), limit: value })
    }
    const handleClickAdd = (evt: SyntheticEvent) => {
        evt.preventDefault()
        setStatus('add')
    }
    const handleClickEdit = () => {
        const [{ id }] = selectedId
        setStatus('edit')
        setId(id)
    }
    const toggleCheckbox = (value: IRow) => {
        setRows((rows) =>
            rows.map((el) =>
                el.id === value.id ? { ...el, checked: !el.checked } : el
            )
        )
        if (selectedId.find((el) => el.id === value.id)) {
            setSelectedId((item) => item.filter(({ id }) => id !== value.id))
        } else {
            setSelectedId([...selectedId, { id: value.id }])
        }
    }
    const selectAllCheckbox = () => {
        if (selectedId.length === rows.length) {
            setRows((rows) => rows.map((el) => ({ ...el, checked: false })))
            setSelectedId([])
        } else {
            setRows((rows) => rows.map((el) => ({ ...el, checked: true })))
            setSelectedId(rows.map((el) => ({ id: el.id })))
        }
    }
    const handleClickDelete = () => {
        del(selectedId.map((el) => el.id))
        setIsOpened(false)
    }
    const sortTable = (column: IColumn) => {
        setFilters({
            ...form.getValues(),
            limit,
            sort: column.sort,
            order: column.order === 'asc' ? 'desc' : 'asc',
        })
        setColumns((columns) =>
            columns.map((el) =>
                el.sort === column.sort
                    ? { ...el, order: el.order === 'asc' ? 'desc' : 'asc' }
                    : { ...el, order: 'default' }
            )
        )
    }

    useEffect(() => {
        setRows(items.map((el: any) => ({ ...el, checked: false })))
    }, [items])

    if (isFetching) return null

    return (
        <>
            <SmartForm form={form} submit={form.handleSubmit(onSubmit)}>
                <div className={s.wrapper}>
                    <div className={s.controls}>
                        <TextField
                            className={s.search_field}
                            name="q"
                            placeholder="Поиск"
                        />
                        <AppButton variant="ghost" className={s.search_button}>
                            Найти
                        </AppButton>
                        <AppButton
                            onClick={handleClickAdd}
                            className={s.add_button}
                        >
                            +{'\u00A0'}Добавить{'\u00A0'}элемент
                        </AppButton>
                    </div>
                    <div className={s.table_wrapper}>
                        <table className={s.table}>
                            <thead>
                                <tr>
                                    <th>
                                        <input
                                            type="checkbox"
                                            onChange={selectAllCheckbox}
                                        />
                                    </th>
                                    {columns.map((el) => (
                                        <th key={el.label}>
                                            <span onClick={() => sortTable(el)}>
                                                {el.label}
                                                <Icons.M.ChevronUp
                                                    className={clsx(s.sort, {
                                                        [s.sort_asc]:
                                                            el.order === 'asc',
                                                    })}
                                                />
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map((el) => (
                                    <tr
                                        key={el.id}
                                        className={clsx(s.row, {
                                            [s.row_active]: isRowActive(el.id),
                                        })}
                                    >
                                        <td>
                                            <input
                                                checked={el.checked}
                                                onChange={() =>
                                                    toggleCheckbox(el)
                                                }
                                                type="checkbox"
                                            />
                                        </td>
                                        <td>{el.id}</td>
                                        <td>{el.question}</td>
                                        <td>{el.isEnabled ? 'Да' : 'Нет'}</td>
                                        <td>{el.priority}</td>
                                        <td>
                                            {moment(el.updatedAt).format(
                                                'DD.MM.YYYY'
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className={s.footer}>
                    <div className={s.footer_buttons}>
                        {selectedId.length >= 1 && (
                            <div
                                onClick={() => setIsOpened(true)}
                                className={clsx(
                                    s.footer_buttons_icon,
                                    s.remove
                                )}
                            >
                                <Icons.M.Cancelled />
                                Удалить
                            </div>
                        )}
                        {selectedId.length === 1 && (
                            <div
                                onClick={handleClickEdit}
                                className={clsx(s.footer_buttons_icon, s.edit)}
                            >
                                <Icons.M.Edit />
                                Редактировать
                            </div>
                        )}
                    </div>
                    <Pagination
                        page={page}
                        total={total}
                        limit={limit}
                        handleChangeLimit={handleChangeLimit}
                        onNext={onNext}
                        onPrev={onPrev}
                        setPage={setPage}
                    />
                </div>
            </SmartForm>
            <ModalWindow isOpened={isOpened} setIsOpened={setIsOpened}>
                <div className={s.modal}>
                    <p className={s.modal_title}>
                        Ты уверен, что хочешь удалить выбранные элементы?
                    </p>
                    <div className={s.modal_controls}>
                        <AppButton onClick={handleClickDelete}>
                            Да, удалить
                        </AppButton>
                        <AppButton
                            onClick={() => setIsOpened(false)}
                            variant="ghost"
                        >
                            Нет, оставить
                        </AppButton>
                    </div>
                </div>
            </ModalWindow>
        </>
    )
}
