import React from 'react'
import s from '../../../entities/profile/ui/index.module.scss'
import { StepLayout } from './StepLayout'
import clsx from 'clsx'

export const ProfileDisabled = () => {
    return (
        <StepLayout>
            <div className={clsx(s.step, s.step_2)}>
                <p className={s.subtitle}>Это профиль эксперта</p>
                <div css={{ height: '20px' }} />
                <p className={s.p3}>
                    Он будет доступен после того, как ты{' '}
                    <a href={'/experts'}>зарегистрируешься</a> в качестве
                    эксперта на платформе
                </p>
            </div>
        </StepLayout>
    )
}
