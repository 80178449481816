import React from 'react'
import {StepLayout} from "./StepLayout";
import s from './FillingProfile.module.scss'
import clsx from "clsx";

export const ProfileUnderReview = () => {
    return (
        <StepLayout>
            <div className={clsx(s.step, s.step_2)}>
                <p className={s.subtitle}>Проверка профиля</p>
                <p className={s.p3}>
                    Твой профиль находится на модерации. Как правило, проверка занимает не более
                    5 рабочих дней. Функции личного кабинета станут доступны после успешной модерации.
                    В случае отклонения заявки, ты сможешь подать её повторно.
                </p>
                <p className={s.p3}>По итогам проверки ты получишь соответствующее уведомление на e-mail.</p>
            </div>
        </StepLayout>
    )
}
