import s from './Advantages.module.scss'
import { Container } from '../../../shared/ui/Container'
import React, { useRef, useState } from 'react'
import { Icons } from '../../../shared/icons'
import { Swiper, SwiperSlide } from 'swiper/react'
import ISwiper from 'swiper'
import { clsx } from 'clsx'
import 'swiper/css'

export const data = [
    {
        icon: <Icons.advantages.Desktop />,
        title: 'Глубина знаний',
        description:
            'Если у тебя есть глубокие и обширные знания в определенной области, где ты чувствуешь себя уверенно и можешь разъяснить сложные концепции, это может свидетельствовать о твоей экспертизе.',
    },
    {
        icon: <Icons.advantages.Bulb />,
        title: 'Опыт',
        description:
            'Практический опыт играет ключевую роль. Если ты успешно решал задачи или работал в конкретной области в течение длительного времени, это может быть признаком твоей экспертизы.',
    },
    {
        icon: <Icons.advantages.World />,
        title: 'Признание от других',
        description:
            'Eсли другие признают тебя экспертом и обращаются за советом или помощью в связанных вопросах, это также может служить подтверждением твоей компетенции',
    },
]

const breakpoints = {
    320: {
        slidesPerView: 1.5,
        spaceBetween: 16,
    },
    768: {
        slidesPerView: 2,
        spaceBetween: 16,
    },
    1024: {
        slidesPerView: 3,
        spaceBetween: 20,
    },
}

export const AdvantagesForExperts = () => {
    const swiperRef = useRef<ISwiper>()
    const [hoverIndex, setHoverIndex] = useState<null | number>(0)

    return (
        <div className={s.wrapper}>
            <Container>
                <div className={s.title}>
                    <p className={s.h1}>Определи зоны своей экспертизы:</p>
                    <p className={s.p2}>
                        Следуй простому алгоритму, чтобы определить, в чем ты
                        уже являешься экспертом
                    </p>
                </div>
                <Swiper
                    className={s.swiper}
                    initialSlide={0}
                    breakpoints={breakpoints}
                    onSwiper={(swiper: ISwiper) => {
                        swiperRef.current = swiper
                    }}
                >
                    {data?.map((el, i) => {
                        return (
                            <SwiperSlide
                                key={el.title}
                                onMouseEnter={() => setHoverIndex(i)}
                                className={s.slide}
                            >
                                {({ isActive }) => {
                                    const isActiveIndex = i === hoverIndex
                                    if (isActive && isActiveIndex)
                                        setHoverIndex(i)
                                    return (
                                        <div
                                            className={clsx(s.item, {
                                                [s.item_active]: isActiveIndex,
                                            })}
                                        >
                                            <div className={s.item_title}>
                                                <div
                                                    className={clsx(s.icon, {
                                                        [s.icon_active]:
                                                            isActiveIndex,
                                                    })}
                                                >
                                                    {el.icon}
                                                </div>
                                                <p className={s.p1}>
                                                    {el.title}
                                                </p>
                                            </div>
                                            <div
                                                className={clsx(s.content, {
                                                    [s.content_active]:
                                                        isActiveIndex,
                                                })}
                                            >
                                                <p className={s.p3}>
                                                    {el.description}
                                                </p>
                                                <p className={s.h1}>
                                                    {String(i + 1).padStart(
                                                        2,
                                                        '0'
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    )
                                }}
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </Container>
        </div>
    )
}
