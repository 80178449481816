import React from 'react'
import {
    ModalWindow,
    ModalWindowPropsType,
} from '../../../../shared/ui/ModalWindow'
import { Typography } from '../../../../shared/ui/Typography'
import styled from '@emotion/styled'

type PropsType = {
    studentList: string[]
}

const Name = styled.li`
    margin-top: 10px;
    font: var(--font-p1);
`

export const StudentList = ({
    studentList,
    ...props
}: ModalWindowPropsType & PropsType) => {
    return (
        <ModalWindow {...props}>
            <Typography as="h3">Все участники</Typography>
            <ul css={{ listStyle: 'decimal' }}>
                {studentList.map((student, index) => (
                    <Name key={index}>{student}</Name>
                ))}
            </ul>
        </ModalWindow>
    )
}
