import React from 'react'
import { HTMLAttributes, ReactNode } from 'react'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { useSmartForm } from '../../lib/useSmartForm'

export const SmartForm: React.FC<
    {
        children: ReactNode
        form: UseFormReturn<any, any>
        submit: (data: any) => void
    } & HTMLAttributes<HTMLFormElement>
> & {
    Smarter: SmarterType
} = ({ children, form, submit, ...rest }) => {
    return (
        <FormProvider {...form}>
            <form {...rest} onSubmit={form.handleSubmit(submit)}>
                {children}
            </form>
        </FormProvider>
    )
}

type SmarterType = React.FC<
    {
        children: ReactNode
        formProps: Parameters<typeof useSmartForm>
        submit: (data: any) => void
    } & HTMLAttributes<HTMLFormElement>
>

// eslint-disable-next-line react/display-name
SmartForm.Smarter = ({ children, submit, formProps, ...rest }) => {
    const form = useSmartForm(...formProps)

    return (
        <FormProvider {...form}>
            <form {...rest} onSubmit={form.handleSubmit(submit)}>
                {children}
            </form>
        </FormProvider>
    )
}
