import React, { useState } from 'react'
import { useModalWindow } from '../../../../shared/hooks/useModalWindow'
import { UnifyApplicationsModal } from '../modals/unifyApplications'
import { Icons } from '../../../../shared/icons'
import { SmartSelect } from '../../../../shared/ui/Select'
import { SmartForm } from '../../../../services/formProcessing/ui/SmartForm'
import { useSmartForm } from '../../../../services/formProcessing'

import s from './programApplicationsWrapper.module.scss'
import {
    applicationApi,
    ApplicationListWithInfoRow,
    ProgramGroup,
} from '../../../../entities/application'
import { useList } from '../../../../shared/hooks/useList/useList'
import {
    ApplicationType,
    StatusOption,
} from '../../../../entities/application/types'
import { useGetProgramInfo } from '../../../../entities/programm/api/queries'
import { useParams } from 'react-router-dom'
import { useWatch } from 'react-hook-form'

const optionsForSmartSelect: StatusOption[] = [
    {
        value: 'pending',
        label: 'сначала необработанные',
    },
    {
        value: 'recent',
        label: 'по новизне',
    },
    {
        value: 'completed',
        label: 'сначала завершенные',
    },
]

type SelectApplicationFormType = {
    selectedApplications: string[]
}

export const ProgramApplicationsWrapper = () => {
    const unifyApplicationsModal = useModalWindow()
    const form = useSmartForm()
    const selectApplicationsForm = useSmartForm<SelectApplicationFormType>({
        defaultValues: {
            selectedApplications: [],
        },
    })
    const [selectedApplications, setSelectedApplications] = useState<
        ApplicationType[]
    >([])
    const [isFormingNewGroup, setIsFormingNewGroup] = useState(false)
    const { id } = useParams<{ id: string }>()
    const { data } = useGetProgramInfo(Number(id))

    const {
        items: applications,
        total,
        showMore,
        page,
        setFilters,
        reset,
        updateItem,
    } = useList<ApplicationType>({
        key: 'programmApplications',
        apiMethod: (params) =>
            applicationApi.getListProgramApplications(Number(id), params),
        defaultLimit: 6,
    })

    const { selectedApplications: selectedApplicationsIds } = useWatch({
        control: selectApplicationsForm.control,
    }) as SelectApplicationFormType

    const formNewGroupHandler = () => {
        setSelectedApplications(
            applications.filter((v) =>
                selectedApplicationsIds.includes(String(v.id))
            )
        )
        form.reset()
        setIsFormingNewGroup(false)
        unifyApplicationsModal.toggleOpen()
        selectApplicationsForm.reset()
    }

    const onFiltersApply = (data: any) => {
        setFilters({ sort: data.value })
    }

    return (
        <div className={s.programApplicationWrapper}>
            <h3 className={s.programTitle}>«{data?.name}»</h3>
            <h4 className={s.sortingGroup}>Сформированные группы:</h4>

            <ProgramGroup reset={reset} />

            <div
                className={s.newGroup}
                onClick={() => {
                    setIsFormingNewGroup((p) => !p)
                    selectApplicationsForm.reset()
                }}
            >
                <Icons.M.Plus />
                <p>Сформировать новую группу</p>
            </div>

            <h4 className={s.allApplications}>Все заявки</h4>

            <div className={s.sortingWrapper}>
                <SmartForm
                    form={form}
                    submit={() => {}}
                    className={s.smartSelect}
                >
                    <SmartSelect
                        placeholder="Сортировать"
                        name="sorting"
                        options={optionsForSmartSelect}
                        onChange={onFiltersApply}
                    />
                </SmartForm>

                {selectedApplicationsIds.length >= 2 && (
                    <div className={s.newGroup} onClick={formNewGroupHandler}>
                        <Icons.M.Plus />
                        <p>Объединить в группу</p>
                    </div>
                )}
            </div>

            <div className={s.applicationList}>
                <ApplicationListWithInfoRow
                    updateItem={updateItem}
                    applications={applications}
                    total={total}
                    page={page}
                    isFormingNewGroup={isFormingNewGroup}
                    showMore={showMore}
                    refetch={reset}
                    hookFormRegister={selectApplicationsForm.register}
                />
            </div>

            <UnifyApplicationsModal
                {...unifyApplicationsModal}
                selectedApplications={selectedApplications}
            />
        </div>
    )
}
