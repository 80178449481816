import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {workspaceApi} from "./index";

interface IResponse {
    data: string[]
}
const key = 'workspace'


export const useGetWorkspace = () => {
    return useQuery<IResponse>({
        queryKey: [key],
        queryFn: async () => {
            const response = await workspaceApi.getWorkspace()
            return response.data
        },
    })
}

export const usePutWorkspace = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: [key],
        mutationFn: async (data: string) => {
            const current = queryClient.getQueryData([key]) as IResponse
            return workspaceApi.putWorkspace([data, ...current.data])
        },
        onSuccess: (data) => {
            queryClient.setQueryData([key], {...data.data})
        },
        onError: (data: any) => {
            const error  = data.response.data.error
            alert(error)
        }
    })
}

export const useGetWorkspaceStats = () => {
    return useQuery<Record<string, number>>({
        queryKey: ['workspace stats'],
        queryFn: async () => {
            const response = await workspaceApi.getWorkspaceStats()
            return response.data
        }
    })
}


