import { css } from '@emotion/react'
import { Checkbox, SmartCheckbox } from '../../../../shared/ui/Checkbox'
import { ApplicationType } from '../../types'
import s from './styles.module.css'
import React from 'react'

type PropsType = {
    application: ApplicationType
    name: string
}

const STATUS_MAP: {
    [key in ApplicationType['state']]: {
        text: string
        color: string
    }
} = {
    accepted: {
        text: 'Принята',
        color: '#FFD540',
    },
    completed: {
        text: 'Завершена',
        color: '#46D074',
    },
    rejected: {
        text: 'Отклонена',
        color: '#FF3C75',
    },
    pending: {
        text: 'Ожидаает ответа',
        color: '#2E78FF',
    },
    failed: {
        text: 'Не успешно',
        color: '#FF3C75',
    },
}

export const ApplicationCardForUnify = ({ application, name }: PropsType) => {
    const statusData = STATUS_MAP[application.state]

    return (
        <label className={s.container}>
            <SmartCheckbox value={String(application.id)} name={name} />

            <div className={s.userInfo}>
                <span className={s.fullName}>{application.fullName}</span>
                <span className={s.email}>{application.email}</span>
                {/* <span className={s.email}>{application.id}</span> */}
            </div>

            <p className={s.text}>{application.description}</p>

            <div className={s.status}>
                <span
                    css={css`
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: ${statusData.color};
                    `}
                />
                <span>{statusData.text}</span>
            </div>
        </label>
    )
}
