import { IExperience } from '../../../../entities/profile/model/types'
import moment from 'moment'

export const transformRolesForFetch = (currentRoles: any[], roles: any) => {
    return Object.keys(roles).reduce(
        (prev, cur) => ({
            ...prev,
            [cur]: currentRoles.includes(cur) ? 'active' : 'inactive',
        }),
        {}
    )
}

export const transformTagsForFetch = (tags: any) =>
    tags?.map((el: any) => el.label)

export const transformCityForFetch = (city: any) => city.label

export const transformExperienceForFetch = (experience: any): IExperience[] =>
    experience.map((el: any) => ({
        isOngoing: el.isOngoing,
        organization: el.organization,
        position: el.position,
        results: el.results,
        since: moment()
            .month(el.sinceMonth.value)
            .year(el.sinceYear)
            .toISOString(),
        until: el.isOngoing ? null : moment()
            .month(el.untilMonth.value)
            .year(el.untilYear)
            .toISOString(),
    }))

export const getInteractionFormat = (format: string) => {
    const formatDict: Record<string, string> = {
        online: 'онлайн',
        offline: 'офлайн',
        any: 'онлайн, офлайн',
    }
    return formatDict[format]
}
