import classname from './index.module.scss'
import styled from '@emotion/styled'
import { theme } from '../../../shared/styles/theme'
import { css } from '@emotion/react'
import React from 'react'
import { NumberCard } from '../../../features/numberCard'
import { numberData } from './temporaryData'
import { AppButton } from '../../../shared/ui/Button'
import { Icons } from '../../../shared/icons'
import backgroundImage from '../../../public/image/landingMain/cta/background_cta.png'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../../config/routes'

const H2Styles = styled.h2`
    font-size: 36px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.01em;
    color: ${theme.color.darkSpace[700]};

    @media (min-width: 768px) {
        font-size: 52px;
    }

    @media (min-width: 1100px) {
    }

    @media (min-width: 1280px) {
        font-size: 48px;
        max-width: 45%;
    }
`

export const CTA = () => {
    const history = useHistory()

    return (
        <div className={classname.Container}>
            <div className={classname.Background}>
                <Icons.cta.CtaBackgroundStar
                    css={css`
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;

                        @media (max-width: 1279px) {
                            display: none;
                        }
                    `}
                ></Icons.cta.CtaBackgroundStar>
                <Icons.cta.CtaBackgroundStarSmall
                    css={css`
                        position: absolute;
                        bottom: -5%;
                        right: 0;
                        display: none;

                        @media (min-width: 501px) and (max-width: 1279px) {
                            display: block;
                        }
                    `}
                ></Icons.cta.CtaBackgroundStarSmall>
                <div className={classname.ContentContainer}>
                    <div className={classname.TitleContainer}>
                        <div
                            css={css`
                                max-width: 75%;
                                width: 100%;
                                @media (max-width: 768px) {
                                    max-width: 100%;
                                }
                            `}
                        >
                            <H2Styles>
                                Ozon Expertplace — точка притяжения талантов
                            </H2Styles>
                        </div>
                    </div>
                    <div className={classname.CardsWrapper}>
                        {numberData.map((item) => (
                            <NumberCard key={item.id} item={item}></NumberCard>
                        ))}
                    </div>
                </div>
                <div className={classname.ButtonsContainer}>
                    <div
                        css={css`
                            max-width: 75%;
                            width: 100%;
                            @media (max-width: 768px) {
                                max-width: 100%;
                            }
                        `}
                    >
                        <div className={classname.ButtonsWrapper}>
                            <AppButton
                                size="md"
                                variant="filled"
                                icon="CircleIconArrow"
                                style={{ textAlign: 'left' }}
                                onClick={() => history.push(ROUTES.catalog)}
                            >
                                Начать обучение
                            </AppButton>
                            <AppButton
                                size="md"
                                variant="outlined"
                                style={{ textAlign: 'left' }}
                                onClick={() => history.push(ROUTES.experts)}
                            >
                                Поделиться экспертизой
                            </AppButton>
                        </div>
                    </div>
                </div>
                <img
                    className={classname.BackgroundImage}
                    src={backgroundImage}
                    loading="lazy"
                    alt=""
                />
            </div>
        </div>
    )
}
