import React from 'react'
import clsx from 'clsx'
import s from './index.module.scss'

export enum ContainerVariants {
    Default = 'default',
    Wide = 'wide',
}

interface IContainerProps {
    children:  React.ReactNode,
    variant?: ContainerVariants
}

export const Container = (props: IContainerProps) => {
    const {children, variant = ContainerVariants.Default} = props
    return <div className={clsx(s.container, s[variant])}>{children}</div>
}
