import { GetTokenResponseType } from '../model/types'
import { authApiInstance } from '../../../shared/api'

export const authApi = {
    getToken: ({ code, refresh }: { code?: string; refresh?: string }) => {
        return authApiInstance.get<GetTokenResponseType>('/tokens', {
            params: {
                code,
                refresh,
            },
        })
    },
}
