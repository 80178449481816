import { useHistory, useParams } from 'react-router-dom'
import { MentorProfile } from '../../entities/mentor'
import { Layout } from '../../shared/ui/Layout'
import { Container } from '../../shared/ui/Container'
import { css } from '@emotion/react'
import { theme } from '../../shared/styles/theme'
import s from './index.module.scss'
import { LinkPresets } from '../../shared/ui/Link'
import React, { useEffect, useState } from 'react'
import { CreateConsultationApplicationButton } from '../../features/createConsultationApplication'
import { ProfileReviews } from '../../widgets/profileReviews'
import { useFetchExpertProfile } from '../../entities/mentor/api/queries'

export const MentorProfilePage = () => {
    const { id } = useParams<{ id: string }>()
    const history = useHistory()
    const [roleTypes, setRoleTypes] = useState<string[]>([])
    const { data: expertProfile, isFetching } = useFetchExpertProfile(id)

    type typesOptions = {
        [key: string]: boolean
    }

    const array: string[] = []
    if (expertProfile) {
        const expertTypes: typesOptions = expertProfile.types
        for (const key in expertTypes) {
            if (expertTypes[key]) {
                array.push(key)
            }
        }
    }

    useEffect(() => {
        setRoleTypes(array)
    }, [expertProfile])

    if (isFetching) {
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',
                }}
            >
                Loading...
            </div>
        )
    }
    if (!expertProfile) return null

    return (
        <Layout
            css={css`
                background-color: ${theme.color.white};
            `}
        >
            <Container>
                <div className={s.MentorContainer}>
                    {isFetching ? (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '600px',
                            }}
                        >
                            Loading...
                        </div>
                    ) : (
                        <>
                            <div className={s.GoBackLink}>
                                <LinkPresets.BackBlue
                                    to={'#'}
                                    onClick={() => history.goBack()}
                                />
                            </div>
                            <div className={s.MentorWrapper}>
                                <MentorProfile
                                    mentor={{ id }}
                                    expertProfile={expertProfile}
                                />
                                <div className={s.ButtonBlock}>
                                    {roleTypes.length > 0 &&
                                        roleTypes.map((roleType, index) => {
                                            return (
                                                <CreateConsultationApplicationButton
                                                    key={index}
                                                    roleType={roleType}
                                                />
                                            )
                                        })}
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <ProfileReviews />
            </Container>
        </Layout>
    )
}
