import { Icons } from '../../../shared/icons'
import React from 'react'

export const data = [
    {
        icon: <Icons.advantages.Desktop />,
        iconActive: <Icons.advantagesLight.DesktopActive />,
        title: 'Разнообразие форматов',
        description:
            'С помощью Expertplace, ты сможешь как сам стать экспертом в разных ролях, так и получить экспертизу от коллег в разных форматах – менторинг, консультация или обучение на программе',
    },
    {
        icon: <Icons.advantages.Bulb />,
        iconActive: <Icons.advantagesLight.BulbActive />,
        title: 'Уникальные темы',
        description:
            'В Expertplace ты сможешь найти уникальные темы – анализ социальных медиа, программирование на SQL, методы исследования, финансовый анализ, работа в Figma и многие другие.',
    },
    {
        icon: <Icons.advantages.World />,
        iconActive: <Icons.advantagesLight.WorldActive />,
        title: 'Весь опыт Ozon',
        description:
            'В Expertplace своими знаниями и опытом делятся коллеги, которые знают специфику нашей компании и смогут привести релевантные примеры и кейсы из практики жизни в Ozon',
    },
    {
        icon: <Icons.advantages.Together />,
        iconActive: <Icons.advantagesLight.TogetherActive />,
        title: 'Нетворкинг',
        description:
            'Здесь ты сможешь найти новые знакомства с людьми, которые разделяют твои увлечения, задачи, области экспертизы и развития. Знакомься, общайся, развивайся сам и помогай в развитии коллегам',
    },
    {
        icon: <Icons.advantages.Rocket />,
        iconActive: <Icons.advantagesLight.RocketActive />,
        title: 'Быстрая обратная связь',
        description:
            'В роли эксперта ты сможешь получать оперативный фидбэк на свою помощь коллеге. А если запросишь помощь эксперта, всегда сможешь оставить отзыв на ваше взаимодействие. Максимально быстро и прозрачно!',
    },
    {
        icon: <Icons.advantages.AdvantagesStar />,
        iconActive: <Icons.advantagesLight.StarActive />,
        title: 'Лучшие эксперты OZON',
        description:
            'Команда Универа уверена, что в Ozon работают лучшие эксперты в своих областях. А в Expertplace эти эксперты готовы делиться этими знаниями с коллегами. Становись экспертом на платформе и ты!',
    },
]
