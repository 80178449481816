const PERSONAL_CABINET_ROUTE = 'personal-cabinet'
const ADMIN_PANEL_ROUTE = 'admin-panel'

export const ROUTES = {
    root: '/',
    experts: '/experts',
    personalCabinet: {
        root: `/${PERSONAL_CABINET_ROUTE}`,
        aboutMarket: `/${PERSONAL_CABINET_ROUTE}/about-market`,
        me: {
            root: `/${PERSONAL_CABINET_ROUTE}/me`,
        },
        applications: {
            root: `/${PERSONAL_CABINET_ROUTE}/applications`,
            programm: `/${PERSONAL_CABINET_ROUTE}/applications/programm`,
        },
        reviews: `/${PERSONAL_CABINET_ROUTE}/reviews`,
        myPrograms: `/${PERSONAL_CABINET_ROUTE}/my-programs`,
        training: `/${PERSONAL_CABINET_ROUTE}/training`,
        market: `/${PERSONAL_CABINET_ROUTE}/market`,
    },
    catalog: '/skills',
    expert: '/expert',
    program: '/program',
    playground: '/playground',
    feedback: '/feedback',
    authFinish: '/auth-finish',
    adminPanel: {
        root: `/${ADMIN_PANEL_ROUTE}`,
        auth: `/${ADMIN_PANEL_ROUTE}/auth`,
        workspace: `/${ADMIN_PANEL_ROUTE}/workspace`,
        expertise: `/${ADMIN_PANEL_ROUTE}/expertise`,
        users: {
            root: `/${ADMIN_PANEL_ROUTE}/users`,
            coach: `/${ADMIN_PANEL_ROUTE}/users/coach`,
            consultant: `/${ADMIN_PANEL_ROUTE}/users/consultant`,
            mentor: `/${ADMIN_PANEL_ROUTE}/users/mentor`,
        },
        programs: `/${ADMIN_PANEL_ROUTE}/programs`,
        faq: {
            root: `/${ADMIN_PANEL_ROUTE}/faq`,
            mainPage: `/${ADMIN_PANEL_ROUTE}/faq/mainPage`,
            expert: `/${ADMIN_PANEL_ROUTE}/faq/expert`,
            student: `/${ADMIN_PANEL_ROUTE}/faq/student`,
        },
        shop: {
            root: `/${ADMIN_PANEL_ROUTE}/shop`,
            request: `/${ADMIN_PANEL_ROUTE}/shop/request`,
            catalog: `/${ADMIN_PANEL_ROUTE}/shop/catalog`,
        },
        reviews: {
            root: `/${ADMIN_PANEL_ROUTE}/reviews`,
            mainPage: `/${ADMIN_PANEL_ROUTE}/reviews/mainPage`,
            expert: `/${ADMIN_PANEL_ROUTE}/reviews/expert`,
            program: `/${ADMIN_PANEL_ROUTE}/reviews/program`,
        },
        applications: {
            root: `/${ADMIN_PANEL_ROUTE}/applications`,
            coach: `/${ADMIN_PANEL_ROUTE}/applications/coach`,
            consultant: `/${ADMIN_PANEL_ROUTE}/applications/consultant`,
            mentor: `/${ADMIN_PANEL_ROUTE}/applications/mentor`,
        },
    },
    privacyPolicy: '/privacy',
    agreement: '/agreement',
}
