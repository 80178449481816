import className from './index.module.scss'
import { CatalogListWithFilters } from '../../../widgets/catalogList'
import { useWindowDimensions } from '../../../shared/hooks/useWindowDimensions'
import { Dropdown } from '../../../shared/ui/Dropdown'
import React, { SyntheticEvent, useEffect, useState } from 'react'
import { useList } from '../../../shared/hooks/useList/useList'
import { catalogApi } from '../../../entities/catalog'
import { ICatalogCard } from '../../../entities/catalog/model/types'
import { CatalogSearchBar } from '../../../features/catalogSearchBar'
import { useSmartForm } from '../../../services/formProcessing'
import { FormProvider } from 'react-hook-form'
import { AppliedFiltersList } from './components/appliedFiltersList'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

type PropsType = {
    isOpenMobileFilter: () => void
    isFilterPopupOpen: boolean
}

export const initialTitle = 'Получи экспертизу'

export const CatalogBlock: React.FC<PropsType> = ({
    isOpenMobileFilter,
    isFilterPopupOpen,
}) => {
    const { search } = useLocation()
    const { width } = useWindowDimensions()

    let limit
    if (width < 768) {
        limit = 4
    } else if (width < 1024) {
        limit = 8
    } else {
        limit = 9
    }

    const { items, isFetching, filters, setFilters, showMore, page, total } =
        useList<ICatalogCard>({
            apiMethod: catalogApi.getCatalogCards,
            key: 'catalog',
            defaultLimit: limit,
        })

    const filterForm = useSmartForm({
        defaultValues: {},
    })

    // парсим урл при рендере, выставляем фильтры
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        const qs = queryString.parseUrl(search, {
            arrayFormat: 'comma',
        }).query

        const result = {} as {
            [key: string]: string[]
        }
        for (const key in qs) {
            result[key] =
                typeof qs[key] === 'string'
                    ? [qs[key] as string]
                    : (qs[key] as string[])
        }

        setFilters(result)
        filterForm.reset(result)
    }, [search])

    // добавляем параметры в урл
    useEffect(() => {
        const qs = queryString.stringify(filters, {
            arrayFormat: 'comma',
        })

        window.history.pushState(
            {},
            '',
            Object.keys(qs).length === 0 ? '/skills' : `?${qs}`
        )
    }, [filters])

    const [searchQuery, setSearchQuery] = useState<{ q: string }>({ q: '' })
    const [title, setTitle] = useState<string>(initialTitle)
    const handleSetFilter = () => {
        const filtersSearch: { [string: string]: any } = {
            ...filterForm.getValues(),
        }

        if (filtersSearch.city === null) {
            delete filtersSearch.city
        }

        if (filtersSearch.format === null) {
            delete filtersSearch.format
        }

        if (filtersSearch.types === false) {
            delete filtersSearch.types
        }

        if (filtersSearch.tags === false) {
            delete filtersSearch.tags
        }
        
        const search = { ...searchQuery }

        for (const key in filtersSearch) {
            if (typeof filtersSearch[key] === 'boolean') {
                delete filtersSearch[key]

                if (key === 'periodicity') {
                    if (filtersSearch[key].length > 1) {
                        delete filtersSearch.periodicity
                    }
                }

                if (key === 'format') {
                    if (filtersSearch[key] === '') {
                        delete filtersSearch.format
                    }
                }

                if (!search.q) {
                    if (filtersSearch.types) {
                        if (filtersSearch.types.length) {
                            if (filtersSearch.types[0] === 'mentors') {
                                setTitle('Менторинг')
                            }
                            if (filtersSearch.types[0] === 'consultants') {
                                setTitle('Консультация от эксперта')
                            }
                            if (filtersSearch.types[0] === 'programs') {
                                setTitle('Программы от экспертов')
                            }
                        } else {
                            setTitle(initialTitle)
                        }
                    }
                }
            }
        }

        for (const key in Object.values(search)) {
            if (Object.values(search)[key]) {
                setFilters({ ...search, ...filtersSearch })
            } else {
                setFilters({ ...filtersSearch })
            }
        }
    }

    useEffect(() => {
        handleSetFilter()
    }, [searchQuery])

    const [isResetSearchbar, setIsResetSearchbar] = useState<boolean>(false)
    const resetFilters = (e: SyntheticEvent) => {
        e.preventDefault()
        filterForm.reset()
        handleSetFilter()
        setIsResetSearchbar(true)
    }

    return (
        <div className={className.Container}>
            <CatalogSearchBar
                setSearchQuery={setSearchQuery}
                isFilterPopupOpen={isFilterPopupOpen}
                isResetSearchbar={isResetSearchbar}
                setIsResetSearchbar={setIsResetSearchbar}
                setTitle={setTitle}
            />
            <div className={className.TitleWrapper}>
                <div className={className.Title}>
                    {searchQuery.q ? (
                        <>
                            <span>Результаты поиска по запросу</span>
                            <h3>{`"${title}"`}</h3>
                        </>
                    ) : (
                        <h3>{title}</h3>
                    )}
                </div>
                <div className={className.Filters}>
                    {width > 1023 ? (
                        <span className={className.Filters__name}>Фильтры</span>
                    ) : (
                        <Dropdown
                            title="Фильтры"
                            className={className.DropdownFilters}
                            onClick={isOpenMobileFilter}
                            isFilterPopupOpen={isFilterPopupOpen}
                        />
                    )}
                    <FormProvider {...filterForm}>
                        <AppliedFiltersList
                            resetFilters={resetFilters}
                            items={items}
                            handleSetFilter={handleSetFilter}
                            totalItems={total}
                        />
                    </FormProvider>
                </div>
            </div>
            <CatalogListWithFilters
                limit={limit}
                showMore={showMore}
                page={page}
                total={total}
                handleSetFilter={handleSetFilter}
                isFetching={isFetching}
                filterForm={filterForm}
                items={items}
                isFilterPopupOpen={isFilterPopupOpen}
                resetFilters={resetFilters}
                isOpenMobileFilter={isOpenMobileFilter}
            />
        </div>
    )
}
