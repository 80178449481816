

export const getCurrentRole = (roles: any) => {
        const rolesDict = {coach: 'тренер', consultant: 'консультант', mentor: 'ментор',}
        const result = []
        if (roles) {
            if (roles.mentor === 'active') result.push(rolesDict.mentor)
            if (roles.coach === 'active') result.push(rolesDict.coach)
            if (roles.consultant === 'active') result.push(rolesDict.consultant)
        }
        return result.length ? result.join(', ') : 'роль не установлена'
}

