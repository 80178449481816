import React from 'react'
import s from './index.module.scss'
import { useSmartForm } from '../../../../../../services/formProcessing'
import { SmartForm } from '../../../../../../services/formProcessing/ui/SmartForm'
import { TextField } from '../../../../../../shared/ui/TextField'
import { SmartCheckbox } from '../../../../../../shared/ui/Checkbox'
import { AppButton } from '../../../../../../shared/ui/Button'
import { IMerchandisePatch, IMerchandisePost } from '../../model/types'
import image from '../../../../../../shared/assets/adminPanel/image.png'
import { Icons } from '../../../../../../shared/icons'
import moment from 'moment/moment'
import { getISOString } from '../../../../../../shared/helpers/getISOString'
import { shopApi } from '../../api'

type MerchandiseType = {
    merchandise?: IMerchandisePatch
    status: 'edit' | 'add'
    setIsCreateMerchandise: (state: boolean) => void
    setSelectedId?: (state: []) => void
    reset: () => void
}

const defaultValues: IMerchandisePatch & { isDelete: boolean } = {
    id: undefined,
    isEnabled: true,
    priority: 0,
    enabledUntil: '',
    enabledSince: '',
    name: '',
    description: '',
    cost: 0,
    variants: [],
    photos: [''],
    preview: '',
    isDelete: false,
}

export const MerchandiseForm: React.FC<MerchandiseType> = ({
    merchandise,
    status,
    setIsCreateMerchandise,
    setSelectedId,
    reset,
}) => {
    const form = useSmartForm<IMerchandisePatch & { isDelete: boolean }>({
        defaultValues: merchandise
            ? {
                  ...defaultValues,
                  id: merchandise.id,
                  isEnabled: merchandise.isEnabled,
                  priority: merchandise.priority,
                  enabledUntil: moment(merchandise.enabledUntil).format(
                      'YYYY-MM-DD'
                  ),
                  enabledSince: moment(merchandise.enabledSince).format(
                      'YYYY-MM-DD'
                  ),
                  name: merchandise.name,
                  description: merchandise.description,
                  cost: merchandise.cost,
                  variants: merchandise.variants,
                  photos: merchandise.photos,
                  preview: merchandise.preview,
              }
            : {
                  isEnabled: true,
                  variants: [
                      { quantity: 1, size: 'S' },
                      { quantity: 1, size: '36-38' },
                  ],
              },
    })
    const onSubmit = async (
        data: IMerchandisePatch & IMerchandisePost & { isDelete: boolean }
    ) => {
        const value: IMerchandisePatch = {
            cost: Number(data.cost),
            enabledSince: getISOString(data.enabledSince),
            enabledUntil: getISOString(data.enabledUntil),
            isEnabled: data.isEnabled,
            name: data.name,
            description: data.description,
            photos: data.photos || [],
            preview: data.preview || '',
            priority: Number(data.priority),
            variants: data.variants.map(({ quantity, size }) => {
                return { quantity: Number(quantity), size }
            }),
        }

        if (status === 'add') {
            try {
                await shopApi.postCreateMerchandise(value)
                setIsCreateMerchandise(false)
                setSelectedId!([])
                reset()
            } catch (e) {
                console.log(e)
                alert('Something went wrong')
            }
        } else {
            if (data.isDelete) {
                await deleteMerchandise()
            } else {
                try {
                    await shopApi.patchMerchandiseById(data.id!, value)
                    setIsCreateMerchandise(false)
                    setSelectedId!([])
                    reset()
                } catch (e) {
                    console.log(e)
                    alert('Something went wrong')
                }
            }
        }
    }
    const handleClickCancel = () => {
        setIsCreateMerchandise(false)
        setSelectedId!([])
    }

    const uploadPreview = async () => {
        try {
            const fileInput = document.createElement('input')
            fileInput.type = 'file'
            fileInput.accept = 'image/*'
            fileInput.onchange = async (event) => {
                const file = (event.target as HTMLInputElement).files?.[0]
                if (!file) return

                const formData = new FormData()
                formData.append('photo', file)

                const response =
                    await shopApi.postUploadMerchandisePhoto(formData)

                const imageUrl = response.data.url

                form.setValue('preview', imageUrl)
                form.watch()
            }
            fileInput.click()
        } catch (e) {
            console.log(e)
            alert('Something went wrong')
        }
        form.watch()
    }

    const uploadImages = async () => {
        try {
            const fileInput = document.createElement('input')
            fileInput.type = 'file'
            fileInput.accept = 'image/*'
            fileInput.multiple = true // Позволяет выбрать несколько файлов
            fileInput.onchange = async (event) => {
                const files = (event.target as HTMLInputElement).files
                if (!files || files.length === 0) return

                const uploadedImageUrls: string[] = []
                for (let i = 0; i < files.length; i++) {
                    const file = files[i]
                    const formData = new FormData()
                    formData.append('photo', file)

                    const response =
                        await shopApi.postUploadMerchandisePhoto(formData)

                    const imageUrl = response.data.url
                    uploadedImageUrls.push(imageUrl)
                }

                form.setValue('photos', uploadedImageUrls)
                form.watch()
            }
            fileInput.click()
        } catch (e) {
            console.log(e)
            alert('Something went wrong')
        }

        form.watch()
    }

    const addVariant = () => {
        const currentVariants = form.getValues('variants')
        const newVariant = { quantity: 5, size: 'M' }
        const updatedVariants = [...currentVariants, newVariant]
        form.setValue('variants', updatedVariants)
        form.watch()
    }

    const removeVariant = () => {
        const currentVariants = form.getValues('variants')
        const updatedVariants = [...currentVariants]
        updatedVariants.pop()
        form.watch()
        form.setValue('variants', updatedVariants)
    }

    const deleteMerchandise = async () => {
        const ids = [merchandise?.id!]
        try {
            await shopApi.deleteMerchandise({ ids })
            setIsCreateMerchandise(false)
            setSelectedId!([])
            reset()
        } catch (e) {
            console.log(e)
            alert('Something went wrong')
        }
    }

    return (
        <div className={s.wrapper}>
            <SmartForm form={form} submit={() => {}}>
                {merchandise && <p className={s.id}>ID: {merchandise?.id}</p>}
                <div className={s.checkbox}>
                    <SmartCheckbox name="isEnabled" label={'Активность'} />
                </div>
                <TextField
                    label="Сортировка"
                    name="priority"
                    type="number"
                    placeholder="500"
                    required={true}
                />
                <div className={s.date_wrapper}>
                    <TextField
                        className={s.date}
                        label="Начало активности"
                        name="enabledSince"
                        type="date"
                        required={true}
                    />
                    <TextField
                        className={s.date}
                        label="Окончание активности"
                        name="enabledUntil"
                        type="date"
                    />
                </div>
                <TextField
                    label="Название"
                    name="name"
                    placeholder="Футболка"
                    required={true}
                />
                <TextField
                    label="Описание"
                    name="description"
                    placeholder="Брендированный пакет с логотипом Ozon"
                    required={true}
                />
                <TextField
                    label="Стоимость (в баллах)"
                    name="cost"
                    placeholder="3000"
                    required={true}
                />

                <div className={s.variantsWrapper}>
                    <p>
                        Размер
                        <p className={s.size}>
                            Оставьте поле пустым, если товар без размера
                        </p>
                    </p>
                    <p>Наличие (шт)</p>
                    {form
                        .getValues('variants')
                        .map(({ quantity, size }, index) => (
                            <>
                                <TextField
                                    name={`variants[${index}].size`}
                                    className={s.variant}
                                    defaultValue={size}
                                />
                                <TextField
                                    name={`variants[${index}].quantity`}
                                    className={s.variant}
                                    defaultValue={quantity}
                                />
                            </>
                        ))}
                    <div className={s.add} onClick={addVariant}>
                        <Icons.M.Plus />
                        Добавить ещё
                    </div>
                    <div className={s.delete} onClick={removeVariant}>
                        <Icons.admin.trash />
                        Удалить
                    </div>
                </div>

                <h4 className={s.photoTitle}>Фотографии</h4>
                <p className={s.photoDescription}>Добавьте фото превью</p>
                <div className={s.photoPreview}>
                    {(form.getValues('preview') && (
                        <img
                            src={form.getValues('preview')}
                            alt="image"
                            className={s.image}
                        />
                    )) || <img src={image} alt="image" />}
                    <button className={s.uploadImage} onClick={uploadPreview}>
                        Загрузить фото
                        <Icons.admin.uploadImage></Icons.admin.uploadImage>
                    </button>
                </div>

                <p className={s.photoDescription}>Добавьте фотографии товара</p>
                <div className={s.photoPreview}>
                    {form.getValues('photos') &&
                    form.getValues('photos').length > 0 ? (
                        form
                            .getValues('photos')
                            .map((photo, index) => (
                                <img
                                    src={photo}
                                    alt="image"
                                    key={index}
                                    className={s.image}
                                />
                            ))
                    ) : (
                        <img src={image} alt="image" />
                    )}
                    <button className={s.uploadImage} onClick={uploadImages}>
                        Загрузить фото
                        <Icons.admin.uploadImage></Icons.admin.uploadImage>
                    </button>
                </div>

                {status === 'edit' && (
                    <div className={s.deleteCheckbox}>
                        <SmartCheckbox
                            name="isDelete"
                            label={
                                'Удалить товар без возможности восстановления'
                            }
                        />
                    </div>
                )}
                <div className={s.buttons}>
                    <AppButton onClick={form.handleSubmit(onSubmit)}>
                        {status === 'edit' ? 'Сохранить изменения' : 'Создать'}
                    </AppButton>
                    <p onClick={handleClickCancel} className={s.cancel}>
                        Отменить
                    </p>
                </div>
            </SmartForm>
        </div>
    )
}
