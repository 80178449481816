import clsx from 'clsx'
import s from './Accordion.module.scss'
import {AccordionItem} from "./AccordionItem";
import {useState} from "react";
import {IFaqItem} from "../../../../pages/adminPanel/subpages/faq/model/types";


interface IAccordionProps {
    defaultOpen?: number | null
    className?: string
    list: IFaqItem[]
}

export const Accordion =(props: IAccordionProps) => {
    const {className, list} = props

    const [selectedIndex, setSelectedIndex] = useState<number | null | undefined>(props.defaultOpen)

    const clickHandler = (index: number) => {
        index === selectedIndex ? setSelectedIndex(null) : setSelectedIndex(index)
    }

    return (
        <ul className={clsx([s.accordion, className])}>
            {
                list.map((item, index) => (
                    <AccordionItem
                        key={index}
                        value={item}
                        isOpen={index === selectedIndex}
                        onClick={() => clickHandler(index)}
                    />
                ))
            }
        </ul>
    )
}
