import { apiInstance } from '../../../shared/api'
import { IProfileExpert } from '../model/types'

export const profileApi = {
    getExpertProfile: () => apiInstance.get<IProfileExpert>(`/expert`),
    patchExpertProfile: (data: IProfileExpert) =>
        apiInstance.patch<IProfileExpert>(`/expert`, data),
    getCities: (params: { limit: number }) =>
        apiInstance.get('/search/city', { params }),
    getSelf: () => apiInstance.get('/user'),
}
