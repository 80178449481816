import { AppButton } from '../../../../../../shared/ui/Button'
import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../../../../config/routes'

import s from './onlyForTrainersAlertPage.module.scss'
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../../../../app/store";
import {profileActions} from "../../../../../../entities/profile";

export const OnlyForTrainersAlertPage = () => {
    const dispatch = useDispatch<AppDispatch>()
    return (
        <div className={s.forTrainers}>
            <h4 className={s.title}>
                Создание обучающих программ доступно только тренерам
            </h4>
            <p className={s.description}>
                У тебя еще не выбрана роль внутреннего тренера.
                Если ты тоже хочешь создавать свои программы и проводить их для коллег,
                добавь роль «Тренер» в настройках твоего профиля, нажав на кнопку ниже.
                После согласования роли тренера тебе предстоит дождаться старта потока Школы тренеров,
                в котором ты научишься создавать и проводить обучение.
                Информация о старте потока Школы придет тебе на почту в течение полугода.
                Вопросы по школе пиши на <a href="mailto:univer@ozon.ru">univer@ozon.ru</a>
            </p>

            <AppButton onClick={() => {
                dispatch(profileActions.setStep(2))
                dispatch(profileActions.closeMenu())
            }} className={s.createProgram}>
                <Link to={ROUTES.personalCabinet.me.root}>
                    Добавить роль тренера
                </Link>
            </AppButton>
        </div>
    )
}
