import React, { useEffect, useState } from 'react'

import { Avatar } from '../../shared/ui/Avatar'
import { Icons } from '../../shared/icons'
import { Link, NavLink } from 'react-router-dom'
import { ROUTES } from '../../config/routes'
import { SmartSwitch } from '../../shared/ui/Switch'
import { SmartForm } from '../../services/formProcessing/ui/SmartForm'
import { useSmartForm } from '../../services/formProcessing'
import clsx from 'clsx'
import s from './personalAccountMenu.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../app/store'
import { profileActions, profileSelectors } from '../../entities/profile'
import {
    useFetchProfile,
    useFetchUser,
    usePatchProfile,
} from '../../entities/profile/api/queries'
import { getCurrentRole } from '../../entities/profile/helpers'
import { css } from '@emotion/react'

const profileStructure = {
    about: '',
    city: '',
    email: '',
    experience: '',
    expertise: '',
    fullName: '',
    photo: '',
    position: '',
    socialStaff: '',
    socialTelegram: '',
    tags: '',
}

export const PersonalAccountMenu = () => {
    const dispatch = useDispatch<AppDispatch>()
    const { data: profile } = useFetchProfile()
    const { data: user } = useFetchUser()
    const { mutate } = usePatchProfile()
    const form = useSmartForm<{ profileStatus: boolean }>({
        defaultValues: { profileStatus: profile?.isEnabled },
    })
    const isMenuOpen = useSelector(profileSelectors.isMenuOpen)
    const [profileCompleteness, setProfileCompleteness] = useState<number>(0)

    const calculateProfileCompletion = (
        profile: any,
        profileStructure: any
    ) => {
        const profileKeys = Object.keys(profile)
        const requiredFields = Object.keys(profileStructure)
        const commonFields = profileKeys.filter((field) =>
            requiredFields.includes(field)
        )
        const filledFields = commonFields.filter((field) => {
            const value = profile[field]
            return (
                value !== null &&
                value !== '' &&
                (!Array.isArray(value) || value.length > 0)
            )
        })

        const completeness = (filledFields.length / requiredFields.length) * 100
        return Number(completeness.toFixed(0))
    }

    useEffect(() => {
        if (profile) {
            const completeness = calculateProfileCompletion(
                profile,
                profileStructure
            )
            setProfileCompleteness(completeness)
        }
    }, [profile, profileStructure])

    return (
        <div
            className={clsx([
                s.personalAccountMenu,
                { [s.burgerMenu]: isMenuOpen },
            ])}
        >
            <Avatar
                onClick={() => dispatch(profileActions.setStep(null))}
                url={profile?.photo}
            />
            <p className={s.name}>{profile?.fullName}</p>
            <p className={s.post}>{getCurrentRole(profile?.roles)}</p>
            {user?.expertState === 'active' && (
                <>
                    <Link to={ROUTES.personalCabinet.me.root}>
                        <button
                            className={s.editButton}
                            onClick={() => {
                                dispatch(profileActions.setStep(1))
                                dispatch(profileActions.closeMenu())
                            }}
                        >
                            Редактировать профиль
                        </button>
                    </Link>

                    <div className={s.statusWrapper}>
                        <div className={s.status}>
                            <p>Статус профиля</p>
                            <Icons.S.SmallInfo className={s.info} />
                            <p className={s.descr}>
                                Ты можешь регулировать видимость своего профиля
                                на платформе с помощью переключателя справа.
                            </p>
                        </div>
                        <SmartForm
                            form={form}
                            submit={() => {}}
                            onChange={() =>
                                mutate({
                                    isEnabled: form.watch('profileStatus'),
                                })
                            }
                        >
                            <SmartSwitch
                                color="blue"
                                variant="md"
                                name="profileStatus"
                            />
                        </SmartForm>
                    </div>
                </>
            )}

            <div className={s.menuListWrapper}>
                <NavLink
                    to={ROUTES.personalCabinet.me.root}
                    className={s.menuList}
                    activeClassName={s.activeMenuList}
                    onClick={() => {
                        dispatch(profileActions.closeMenu())
                        dispatch(profileActions.setStep(null))
                    }}
                >
                    <Icons.S.Account />
                    <p className={s.menuListTitile}>Мой профиль</p>
                </NavLink>

                <NavLink
                    to={ROUTES.personalCabinet.applications.root}
                    className={clsx([s.menuList, s.applicationsItem])}
                    activeClassName={s.activeMenuList}
                    onClick={() => dispatch(profileActions.closeMenu())}
                >
                    <div className={s.applications}>
                        <Icons.S.Applications />
                        <p className={s.menuListTitile}>Заявки</p>
                    </div>
                    {profile?.pendingApplications! >= 1 && (
                        <div className={s.counter}>
                            {profile?.pendingApplications}
                        </div>
                    )}
                </NavLink>

                <NavLink
                    to={ROUTES.personalCabinet.reviews}
                    className={s.menuList}
                    activeClassName={s.activeMenuList}
                    onClick={() => dispatch(profileActions.closeMenu())}
                >
                    <Icons.S.Reviews />
                    <p className={s.menuListTitile}>Отзывы</p>
                </NavLink>

                <NavLink
                    to={ROUTES.personalCabinet.myPrograms}
                    className={s.menuList}
                    activeClassName={s.activeMenuList}
                    onClick={() => dispatch(profileActions.closeMenu())}
                >
                    <Icons.S.Programs />
                    <p className={s.menuListTitile}>Мои программы</p>
                </NavLink>

                <NavLink
                    to={ROUTES.personalCabinet.training}
                    className={s.menuList}
                    activeClassName={s.activeMenuList}
                    onClick={() => dispatch(profileActions.closeMenu())}
                >
                    <Icons.S.Trainings css={css`
                        width: 16px;
                        height: 17px;
                    `} />
                    <p className={s.menuListTitile}>Обучение для эксперта</p>
                </NavLink>

                <NavLink
                    to={ROUTES.personalCabinet.market}
                    className={s.menuList}
                    activeClassName={s.activeMenuList}
                    onClick={() => dispatch(profileActions.closeMenu())}
                >
                    <Icons.S.Shop />
                    <p className={s.menuListTitile}>Магазин эксперта</p>
                </NavLink>
            </div>

            <div className={s.scoresWrapper}>
                <div>
                    <p className={s.score}>{profile?.points} баллов</p>
                    <div className={s.balance}>
                        <p>Баланс</p>
                        <Link
                            to={ROUTES.personalCabinet.aboutMarket}
                            onClick={() => dispatch(profileActions.closeMenu())}
                        >
                            О магазине
                        </Link>
                    </div>
                </div>
            </div>

            <div className={s.progressBarWrapper}>
                <p>
                    Профиль заполнен на{' '}
                    {user?.expertState === 'active' ? profileCompleteness : 0}%
                </p>
                <div className={s.progressBar}>
                    {user?.expertState === 'active' && (
                        <div
                            className={s.filledProgressBar}
                            style={{ width: `${profileCompleteness}%` }}
                        ></div>
                    )}
                </div>
                <p className={s.progressBarDescription}>
                    Заполни свой профиль, чтобы поднять его <br /> в топе и
                    получать больше заявок на <br />
                    обучение
                </p>
            </div>
        </div>
    )
}
