import {
    ModalWindow,
    ModalWindowPropsType,
} from '../../../../../../../shared/ui/ModalWindow'
import { CreateOrEditProgramForm } from '../../../../../../../widgets/createOrEditProgramForm'
import { ProgramCardType } from '../../../../../../../entities/programm/types'

type PropsType = ModalWindowPropsType & {
    program?: ProgramCardType
    reset?: () => void
    isEdit?: boolean
}

export const CreateOrEditProgramModal = ({
    program,
    reset,
    isEdit,
    ...modalWindowProps
}: PropsType) => {
    return (
        <ModalWindow {...modalWindowProps}>
            <CreateOrEditProgramForm
                reset={reset}
                program={program}
                isEdit={isEdit}
                setModalWindowIsVisible={modalWindowProps.setIsOpened}
            />
        </ModalWindow>
    )
}
