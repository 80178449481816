import {
    LOCAL_STORAGE_ADMIN_TOKEN_DATA_KEY,
    LOCAL_STORAGE_TOKEN_DATA_KEY,
} from './constants'
import { GetTokenResponseType } from './types'

const TokenStorageKeyMap = {
    user: LOCAL_STORAGE_TOKEN_DATA_KEY,
    admin: LOCAL_STORAGE_ADMIN_TOKEN_DATA_KEY,
}

export const TokenStorage = {
    getTokenData: (type: 'admin' | 'user'): GetTokenResponseType | null => {
        const data = localStorage.getItem(TokenStorageKeyMap[type])
        return data ? JSON.parse(data) : null
    },
    setTokenData: (
        data: GetTokenResponseType | null,
        type: 'admin' | 'user'
    ) => {
        localStorage.setItem(TokenStorageKeyMap[type], JSON.stringify(data))
    },
}
