export const theme = {
    color: {
        darkBlue: '#00215C',
        ozonBlue: {
            50: '#E5EFFF',
            200: '#99BDFF',
            300: '#5C96FF',
            400: '#2E78FF',
            500: '#005BFF',
            800: '#002F85',
            600: '#004ED6',
            700: '#003EAD',
        },
        ozonMain: '#005BFF',
        lightBlue: '#E5EFFF',
        ozonMagenta: {
            500: '#F91155',
        },
        white: '#FFFFFF',
        darkSpace: {
            50: '#EFF3F6',
            100: '#E9EEF1',
            150: '#E3E9EC',
            200: '#CCD6DE',
            300: '#96A3AE',
            400: '#728290',
            450: '#5A6C7D',
            700: '#001A34',
            900: '#000B14',
        },
        cloud: '#F4F8FF',
        gradient: {
            blue: 'linear-gradient(268.31deg, #005BFF -138.9%, #CBDEFF 138.75%)',
            green: 'linear-gradient(334.15deg, #C8F8D6 -20.42%, #E5EFFF 94.45%)',
        },
    },
    media: {
        laptop: '1440px',
        tablet: '1100px',
        phone: '500px',
    },
}

export type ThemeType = typeof theme
