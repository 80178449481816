import opportunities_1 from '../../../public/image/landingMain/possibilities/opportunities_1.png'
import empty_1 from '../../../public/image/landingMain/possibilities/empty_1.jpg'
import opportunities_2 from '../../../public/image/landingMain/possibilities/opportunities_2.png'
import empty_2 from '../../../public/image/landingMain/possibilities/empty_2.jpg'
import opportunities_3 from '../../../public/image/landingMain/possibilities/opportunities_3.png'
import empty_3 from '../../../public/image/landingMain/possibilities/empty_3.jpg'
import opportunities_4 from '../../../public/image/landingMain/possibilities/opportunities_4.png'
import empty_4 from '../../../public/image/landingMain/possibilities/empty_4.jpg'
import opportunities_5 from '../../../public/image/landingMain/possibilities/opportunities_5.png'
import empty_5 from '../../../public/image/landingMain/possibilities/empty_5.jpg'
import opportunities_6 from '../../../public/image/landingMain/possibilities/opportunities_6.png'
import empty_6 from '../../../public/image/landingMain/possibilities/empty_6.jpg'
import { ROUTES } from '../../../config/routes'

export const options = [
    {
        value: '1',
        text: 'Обучаться',
    },
    {
        value: '2',
        text: 'Стать экспертом',
    },
]

export const studentData = [
    {
        title: 'Найти ментора',
        text: 'Найти ментора — это возможность получать уникальные знания и опыт коллег, которые помогут тебе в карьерном росте.',
        buttonText: 'Найти ментора',
        backgroundImage: opportunities_1,
        backgroundImageCard: empty_1,
        navigate: `${ROUTES.catalog}?types=mentors`,
    },
    {
        title: 'Консультация эксперта',
        text: 'Найти консультанта — это возможность задать волнующие вопросы и решить свои проблемы.',
        buttonText: 'Найти консультанта',
        backgroundImage: opportunities_2,
        backgroundImageCard: empty_2,
        navigate: `${ROUTES.catalog}?types=consultants`,
    },
    {
        title: 'Программы от экспертов',
        text: 'Найти программу — это возможность подобрать для себя обучающую программу от наших экспертов.',
        buttonText: 'Найти программу',
        backgroundImage: opportunities_3,
        backgroundImageCard: empty_3,
        navigate: `${ROUTES.catalog}?types=programs`,
    },
]

export const expertData = [
    {
        title: 'Стать ментором',
        text: 'Стать ментором – это возможность поделиться своими знаниями и опытом с коллегами, помогая им достигать целей по развитию. От тебя только желание – остальному научим!',
        buttonText: 'Стать ментором',
        backgroundImage: opportunities_4,
        backgroundImageCard: empty_4,
        navigate: ROUTES.experts,
    },
    {
        title: 'Стать консультантом',
        text: 'Помогай коллегам по сложным вопросам в формате долгосрочной работы или разовой консультации',
        buttonText: 'Стать консультантом',
        backgroundImage: opportunities_5,
        backgroundImageCard: empty_5,
        navigate: ROUTES.experts,
    },
    {
        title: 'Стать тренером',
        text: 'Создавай собственные обучающие программе на базе своих знаний и опыта. А Школа внутреннего тренера тебе в этом поможет',
        buttonText: 'Стать тренером',
        backgroundImage: opportunities_6,
        backgroundImageCard: empty_6,
        navigate: ROUTES.experts,
    },
]
