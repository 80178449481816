import React from 'react'
import { OnlyForTrainersAlertPage } from './ui/forTrainers'
import { NoProgramsPage } from './ui/noPrograms'
import { ProgramsListPage } from './ui/programsList'
import { useQueryClient } from '@tanstack/react-query'
import { IProfileExpert } from '../../../../entities/profile'
import { useList } from '../../../../shared/hooks/useList/useList'
import { ProgramCardType } from '../../../../entities/programm/types'
import { programApi } from '../../../../entities/programm/api'

export const MyPrograms = () => {
    const queryClient = useQueryClient()
    const profile = queryClient.getQueryData<IProfileExpert>(['profile'])

    const {
        items: programList,
        isFetching,
        reset,
        page,
        total,
        showMore,
    } = useList<ProgramCardType>({
        key: ['programsList', profile?.roles],
        apiMethod: (params) => programApi.getProgramsList(params),
        defaultLimit: 4,
    })

    if (isFetching && programList.length === 0) {
        return <div>Loading...</div>
    }

    if (profile?.roles.coach !== 'active') {
        return <OnlyForTrainersAlertPage />
    }

    if (programList.length === 0) {
        return <NoProgramsPage />
    }

    return (
        <ProgramsListPage
            programList={programList}
            page={page}
            reset={reset}
            showMore={showMore}
            total={total}
        />
    )
}
