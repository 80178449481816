import s from './Tags.module.scss'
import { AppButton } from '../../../shared/ui/Button'
import React from 'react'

const buttons = [
    'Дизайн',
    'Аналитика',
    'Маркетинг',
    'HR',
    'IT',
    'Управление',
    'Продажи',
    'Программы и ПО',
    'Финансы',
]

const downloadFile = () => {
    const fileUrl = 'https://storage.yandexcloud.net/expert-storage/tags.pdf'
    window.open(fileUrl, '_blank')
}

export const Tags = () => {
    return (
        <div className={s.wrapper}>
            <div className={s.content}>
                <p className={s.title}>
                    Темы, которые наиболее всего востребованы у наших
                    сотрудников
                </p>
                <p className={s.desc}>
                    Скачай полный список и узнай, знания по каким темам наиболее
                    востребованы у сотрудников Ozon
                </p>
                <div className={s.buttons}>
                    {buttons.map((btn) => (
                        <AppButton
                            key={btn}
                            className={s.button}
                            variant="white"
                            size="sm"
                        >
                            {btn}
                        </AppButton>
                    ))}
                    <AppButton
                        className={s.button_outline}
                        variant="outlined"
                        size="sm"
                    >
                        50+ актуальных тем
                    </AppButton>
                </div>
                <AppButton
                    className={s.button_download}
                    variant="filled_circle"
                    icon="CircleIconSave"
                    onClick={downloadFile}
                >
                    Скачать полный текст
                </AppButton>
            </div>
        </div>
    )
}
