import { SyntheticEvent, useState, useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { Icons } from '../../../../shared/icons'
import { Tag } from '../../../../shared/ui/Tag'
import { filtersDataList } from '../../../../widgets/catalogList/ui/constants'
import s from '../index.module.scss'
import { ICatalogCard } from '../../../../entities/catalog/model/types'

export const AppliedFiltersList = ({
    handleSetFilter,
    resetFilters,
    totalItems,
}: {
    handleSetFilter: () => void
    items: ICatalogCard[]
    resetFilters: (e: SyntheticEvent) => void
    totalItems: number
}) => {
    const [tags, setTags] = useState<
        Array<{
            value: string
            label: string
            fieldName: string
        }>
    >([])
    const form = useFormContext()
    const formValues = useWatch({
        control: form.control,
    })


    useEffect(() => {
        const tagsResult = [] as Array<{
            label: string
            value: string
            fieldName: string
        }>

        if (formValues.city === null) {
            delete formValues.city
        }

        if (formValues.format === null) {
            delete formValues.format
        }

        if (formValues.types === false) {
            delete formValues.types
        }

        if (formValues.tags === false) {
            delete formValues.tags
        }

        for (const key in formValues) {
            if (formValues[key].length) {
                if (key === 'city' || key === 'tags') {
                    formValues.city =
                        typeof formValues.city === 'string'
                            ? [formValues.city]
                            : formValues.city
                    formValues[key].forEach((item: string) => {
                        tagsResult.push({
                            label: item,
                            value: item,
                            fieldName: key,
                        })
                    })
                }

                formValues[key] =
                    typeof formValues[key] === 'string'
                        ? [formValues[key]]
                        : formValues[key]

                const filtersSource = filtersDataList.find((i) => i.name == key)

                formValues[key].forEach((item: string) => {
                    const filterData = filtersSource?.items.find(
                        (filterItem) => filterItem.value === item
                    )

                    if (filterData) {
                        tagsResult.push({
                            label: filterData.label,
                            value: filterData.value,
                            fieldName: key,
                        })
                    }
                })
            }
        }

        setTags(tagsResult)
    }, [formValues])

    const removeFilterHandler = (name: string, value: string) => {
        let oldValue = form.getValues()[name]

        if (typeof oldValue === 'string') {
            oldValue = [oldValue]
        }
        if (oldValue) {
            const newValue = oldValue.filter((i: string) => i !== value)
            form.setValue(name, newValue)
        }
        handleSetFilter()
    }

    return (
        <div className={s.TagsWrapper}>
            <div className={s.Tags}>
                {tags.length > 0 &&
                    tags.map((tag, index) => (
                        <Tag className={s.Tag} key={index}>
                            <span>{tag.label}</span>
                            <Icons.M.Close
                                onClick={() =>
                                    removeFilterHandler(
                                        tag.fieldName,
                                        tag.value
                                    )
                                }
                                className={s.Tag__icon}
                            ></Icons.M.Close>
                        </Tag>
                    ))}
                {tags.length >= 2 && (
                    <Tag className={`${s.Tag} ${s.Tag__clear}`}>
                        <span>Очистить всё</span>
                        <Icons.M.Close
                            className={s.Tag__icon}
                            onClick={resetFilters}
                        ></Icons.M.Close>
                    </Tag>
                )}
            </div>
            <span className={s.Count}>Всего: {totalItems}</span>
        </div>
    )
}
