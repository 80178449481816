import React from 'react'
import {
    ModalWindow,
    ModalWindowPropsType,
} from '../../../../shared/ui/ModalWindow'
import { Typography } from '../../../../shared/ui/Typography'
import { CropImage } from '../../../../features/uploadAvatar'

type PropsType = ModalWindowPropsType

export const UploadAvatarModal = ({ ...props }: PropsType) => {
    return (
        <ModalWindow {...props}>
            <Typography as="h4">Загрузи аватар</Typography>
            <div style={{width: "500px"}}>
                <Typography as="t1" center={true}>
                Выбери фотографию, на которой голова и плечи будут находиться в верхней трети фото. После загрузки фотографии, постарайся минимально обрезать ее.
                Пример правильной фотографии <a target="_blank" rel="noreferrer" href="https://storage.yandexcloud.net/expertplace-storage/example-avatar.jpeg">тут</a>.
                </Typography>
            </div>
            <div style={{marginTop: "-20px"}}>
                <Typography as="t1">
                    Максимальный размер изображения 2 мб
                </Typography>
            </div>
            <CropImage {...props} />
        </ModalWindow>
    )
}
