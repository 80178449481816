import axios, { AxiosError } from 'axios'
import queryString from 'query-string'
import {
    LOCAL_STORAGE_ADMIN_TOKEN_DATA_KEY,
    SSO_URL,
} from '../../features/auth/model/constants'
import { ROUTES } from '../../config/routes'
import { handleRefreshUserAccessToken } from '../../entities/user/lib/auth'
import { TokenStorage } from '../../features/auth/model'
import { handleRefreshAdminAccessToken } from '../../entities/admin/lib/auth'

axios.defaults.paramsSerializer = (params) =>
    queryString.stringify(params, {
        arrayFormat: 'comma',
    })

export const apiInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_API_URL}/v1`,
})
export const adminApiInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_ADMIN_API_URL}/v1`,
})
export const authApiInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_API_URL}/auth`,
})
export const authAdminApiInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_ADMIN_API_URL}/auth`,
})

const ACCESS_EXPIRED_MESSAGE = 'Access token expired'
const REFRESH_EXPIRED_MESSAGE = 'Refresh token expired'
const NO_TOKEN_MESSAGE = 'Bearer token required'
const INVALID_TOKEN_MESSAGE = 'Invalid token'

apiInstance.interceptors.response.use(
    (res) => res,
    async (error: AxiosError<{ error: string }>) => {
        if (error?.response && error.response?.status == 403) {
            const errorMessage = error.response?.data?.error

            switch (errorMessage) {
                case ACCESS_EXPIRED_MESSAGE:
                    await handleRefreshUserAccessToken(
                        TokenStorage.getTokenData('user')!.refreshToken
                    )
                    return
                case NO_TOKEN_MESSAGE:
                case INVALID_TOKEN_MESSAGE:
                case REFRESH_EXPIRED_MESSAGE:
                    window.location.href = SSO_URL
                    return
                default:
                    return
            }
        }
    }
)

adminApiInstance.interceptors.response.use(
    (res) => res,
    async (error: AxiosError<{ error: string }>) => {
        const errorMessage = error.response?.data?.error

        if (error?.response && error.response?.status == 403) {
            switch (errorMessage) {
                case ACCESS_EXPIRED_MESSAGE:
                    await handleRefreshAdminAccessToken(
                        TokenStorage.getTokenData('admin')!.refreshToken
                    )
                    return
                case NO_TOKEN_MESSAGE:
                case INVALID_TOKEN_MESSAGE:
                case REFRESH_EXPIRED_MESSAGE:
                    localStorage.removeItem(LOCAL_STORAGE_ADMIN_TOKEN_DATA_KEY)
                    window.location.href = ROUTES.adminPanel.auth
                    return
                default:
                    return
            }
        }
    }
)
