import React from 'react'

import s from './aboutMarket.module.scss'
import { Accordion } from '../../../../shared/ui/Accordion'
import { list } from './temporartData'

export const AboutMarketPage = () => {
    return (
        <div className={s.aboutMarket}>
            <div>
                <h3>Правила начисления баллов</h3>
                <div className={s.questionWrapper}>
                    <Accordion list={list} className={s.accordion} defaultOpen={0} />
                </div>
            </div>
        </div>
    )
}
