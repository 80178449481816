import React, { useState } from 'react'
import { StatusType } from '../../../faq/model/types'
import { AdminPageLayout } from '../../../../ui/adminPageLayout/layout'
import { ProgramsReviews } from './ui/programsReviews/programsReviews'
import { useGetReviewsById } from '../../api/queries'
import { EditForm } from './ui/programsReviewsForm/editForm/editForm'
import { NewForm } from './ui/programsReviewsForm/newForm/newForm'

export const ReviewsProgram = () => {
    const [status, setStatus] = useState<StatusType | null>(null)
    const [reviewId, setReviewId] = useState<number>()

    const ChangeForm = () => {
        const { data: review, isFetching } = useGetReviewsById(reviewId!)

        if (isFetching) return null

        return (
            <EditForm status={status!} setStatus={setStatus} review={review} />
        )
    }

    if (status === 'edit') {
        return <ChangeForm />
    }

    if (status === 'add') {
        return <NewForm status={status!} setStatus={setStatus} />
    }

    return (
        <AdminPageLayout title="Отзывы об экспертах">
            <ProgramsReviews
                setStatus={setStatus}
                status={status}
                setReviewId={setReviewId}
            />
        </AdminPageLayout>
    )
}
