import React, { ReactElement, RefAttributes } from 'react'
import CreatableSelect from 'react-select/creatable'
import { GroupBase } from 'react-select'
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager'
import SelectType from 'react-select/dist/declarations/src/Select'
import { Controller, useFormContext } from 'react-hook-form'
import { ClearIndicator, DropdownIndicator, MultiValueRemove } from '../Select'
import { css } from '@emotion/react'
import { theme } from '../../styles/theme'
import './index.css'

declare type StateManagedSelectCustom = <
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
>(
    props: StateManagerProps<Option, IsMulti, Group> &
        RefAttributes<SelectType<Option, IsMulti, Group>> & {
            label?: string
        }
) => ReactElement

export const AppCreatableSelect: StateManagedSelectCustom = ({
    className,
    ...props
}) => {
    return (
        <div
            css={css`
                padding-bottom: 21px;
            `}
            className={className}
        >
            {props.label && (
                <label
                    css={css`
                        color: ${theme.color.darkSpace['700']};
                        margin-bottom: 8px;
                        display: block;
                    `}
                >
                    {props.label}
                </label>
            )}
            <CreatableSelect
                {...props}
                components={{
                    MultiValueRemove,
                    DropdownIndicator,
                    ClearIndicator,
                }}
                blurInputOnSelect
                classNamePrefix="react-select"
                formatCreateLabel={(inputText) => `Создать "${inputText}"`}
            />
        </div>
    )
}

export const SmartCreatableSelect: StateManagedSelectCustom = (props) => {
    const form = useFormContext()

    return (
        <Controller
            render={({ field }) => {
                return (
                    <AppCreatableSelect
                        {...props}
                        value={field.value}
                        classNamePrefix="react-select"
                        onChange={(value, actionMeta) => {
                            props.onChange && props.onChange(value, actionMeta)

                            form.setValue(props.name!, value)
                        }}
                    />
                )
            }}
            name={props.name!}
            control={form.control}
        />
    )
}
