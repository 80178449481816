import { apiInstance } from '../../../shared/api'
import { IPromoReviewsGet } from '../model/types'

export const reviewsApi = {
    getReviewsList: (params: { expertId?: number; programId?: number; type?: string }) =>
        apiInstance.get(`/reviews`, { params }),
    getReviewProfile: (key: string) => apiInstance.get(`review/${key}`),
    postReview: (
        key: string,
        data: {
            fullName: string
            position: string
            rating: number
            text: string
        }
    ) => apiInstance.post(`review/${key}`, data),
    getPromoReviews: () => apiInstance.get<IPromoReviewsGet>('promo/reviews'),
}
