import React, { useState } from 'react'
import { AppButton } from '../../../../shared/ui/Button'
import clsx from 'clsx'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import { Icons } from '../../../../shared/icons'
import { useModalWindow } from '../../../../shared/hooks/useModalWindow'
import { MarketItem } from '../modal/marketItem'
import { useList } from '../../../../shared/hooks/useList/useList'
import { marketApi } from '../../../../entities/market/api'
import { IProfileExpert } from '../../../../entities/profile'
import { useQueryClient } from '@tanstack/react-query'
import { marketItemType } from '../../../../entities/market/model/types'

import s from './mentorMarketList.module.scss'

type MentorMarketListPost = {
    onOrderPlaced: () => void
}

export const MentorMarketList: React.FC<MentorMarketListPost> = ({
    onOrderPlaced,
}) => {
    const queryClient = useQueryClient()
    const profile = queryClient.getQueryData<IProfileExpert>(['profile'])

    const [selectedItem, setSelectedItem] = useState<marketItemType | null>(
        null
    )
    const [purchasedId, setPurchasedId] = useState<number[]>([])

    const {
        items: merchandiseList,
        showMore,
        page,
        total,
    } = useList<marketItemType>({
        key: 'merchandiseList',
        apiMethod: (params) => marketApi.getMerchandiseList(params),
        defaultLimit: 9,
    })

    const marketItemModal = useModalWindow()

    const handleOpenModal = (item: marketItemType) => {
        setSelectedItem(item)
        marketItemModal.toggleOpen()
    }

    const updatePurchasedStatus = (itemId: number) => {
        setPurchasedId([...purchasedId, itemId])
    }

    return (
        <>
            <div className={s.marketWrapper}>
                {merchandiseList.map(
                    (
                        {
                            id,
                            name,
                            description,
                            cost,
                            preview,
                            photos,
                            variants,
                        },
                        index
                    ) => (
                        <div className={s.marketItem} key={index}>
                            <div className={s.itemImg}>
                                <Swiper
                                    slidesPerView={1}
                                    pagination={{ clickable: true }}
                                    modules={[Pagination, Navigation]}
                                    className={s.swiperContainer}
                                    navigation={{ enabled: true }}
                                >
                                    <SwiperSlide className={s.swiperSlide}>
                                        <img
                                            src={preview}
                                            alt=""
                                            className={s.merchandiseImg}
                                        />
                                    </SwiperSlide>
                                    {photos.map((img, index) => (
                                        <SwiperSlide
                                            className={s.swiperSlide}
                                            key={index}
                                        >
                                            <img
                                                src={img}
                                                alt=""
                                                className={s.merchandiseImg}
                                            />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>

                            <div className={s.itemInfo}>
                                <p className={s.itemInfo__price}>
                                    {cost} баллов
                                </p>
                                <p className={s.itemInfo__name}>{name}</p>

                                <AppButton
                                    size="lg"
                                    variant="filled"
                                    onClick={() =>
                                        handleOpenModal({
                                            photos,
                                            cost,
                                            name,
                                            description,
                                            id,
                                            variants,
                                            preview,
                                        })
                                    }
                                    className={clsx([s.itemInfo__order], {
                                        [s.purchased]: purchasedId.includes(id),
                                        [s.disabled]: profile?.points! < cost,
                                    })}
                                >
                                    {purchasedId.includes(id) ? (
                                        <div className={s.orderedButton}>
                                            <Icons.S.GreenCheck />
                                            Заказ оформлен
                                        </div>
                                    ) : (
                                        'Подробнее'
                                    )}
                                </AppButton>
                            </div>
                        </div>
                    )
                )}
            </div>
            {page * 9 < total && (
                <AppButton
                    size="lg"
                    variant="ghost"
                    onClick={showMore}
                    className={s.moreButton}
                >
                    Показать ещё
                </AppButton>
            )}

            {selectedItem && (
                <MarketItem
                    {...marketItemModal}
                    selectedItem={selectedItem}
                    onOrderPlaced={() => {
                        updatePurchasedStatus(selectedItem.id)
                        onOrderPlaced()
                    }}
                    points={profile?.points}
                />
            )}
        </>
    )
}
