import React, { useRef, useState } from 'react'
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { centerAspectCrop } from '../../lib/centerAspectRatio'
import { canvasPreview } from '../../lib/canvasPreview'
import './style.css'
import { css } from '@emotion/react'
import { useDebounceEffect } from '../../../../shared/hooks/useDebounceEffect'
import { AppButton } from '../../../../shared/ui/Button'
import { mentorApi } from '../../../../entities/mentor'
import { ModalWindowPropsType } from '../../../../shared/ui/ModalWindow'
import { useFetchProfile } from '../../../../entities/profile/api/queries'

const ASPECT = 1
type PropsType = ModalWindowPropsType

export const CropImage = ({ ...props }: PropsType) => {
    const [imgSrc, setImgSrc] = useState('')
    const previewCanvasRef = useRef<HTMLCanvasElement>(null)
    const imgRef = useRef<HTMLImageElement>(null)
    const blobUrlRef = useRef('')
    const [crop, setCrop] = useState<Crop>()
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const { refetch } = useFetchProfile()

    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined)
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setImgSrc(reader.result?.toString() || '')
            )
            reader.readAsDataURL(e.target.files[0])
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        const { width, height } = e.currentTarget
        setCrop(centerAspectCrop(width, height, ASPECT))
    }

    async function onDownloadCropClick() {
        const image = imgRef.current
        const previewCanvas = previewCanvasRef.current
        if (!image || !previewCanvas || !completedCrop) {
            throw new Error('Crop canvas does not exist')
        }

        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height

        const offscreen = new OffscreenCanvas(
            completedCrop.width * scaleX,
            completedCrop.height * scaleY
        )
        const ctx = offscreen.getContext('2d')
        if (!ctx) {
            throw new Error('No 2d context')
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ctx.drawImage(
            previewCanvas,
            0,
            0,
            previewCanvas.width,
            previewCanvas.height,
            0,
            0,
            offscreen.width,
            offscreen.height
        )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const blob = await offscreen.convertToBlob({
            type: 'image/jpeg',
            quality: 0.5
        })

        if (blobUrlRef.current) {
            URL.revokeObjectURL(blobUrlRef.current)
        }
        blobUrlRef.current = URL.createObjectURL(blob)

        const form = new FormData()
        form.append('photo', blob)
        const response = await mentorApi.uploadPhoto(form)
        await mentorApi.patchUploadPhoto({ photo: response.data.url })
        props.setIsOpened(false)
        await refetch()
    }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop
                )
            }
        },
        100,
        [completedCrop]
    )

    return (
        <div>
            <div>
                <label
                    css={css`
                        cursor: pointer;
                        margin-bottom: 20px;
                        display: block;
                        text-align: center;
                        text-decoration: underline;
                    `}
                    htmlFor="avatarImage"
                >
                    Загрузить изображение
                </label>
                <input
                    css={css`
                        display: none;
                    `}
                    id="avatarImage"
                    type="file"
                    accept="image/*"
                    onChange={onSelectFile}
                />
            </div>
            {!!imgSrc && (
                <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    aspect={ASPECT}
                    minHeight={100}
                >
                    <img
                        ref={imgRef}
                        alt="Crop me"
                        src={imgSrc}
                        onLoad={onImageLoad}
                    />
                </ReactCrop>
            )}

            {!!completedCrop && (
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-top: 24px;
                    `}
                >
                    <canvas
                        ref={previewCanvasRef}
                        css={css`
                            display: none;
                        `}
                    />
                    <AppButton onClick={onDownloadCropClick}>
                        Сохранить изменения
                    </AppButton>
                </div>
            )}
        </div>
    )
}
