import React, {ReactNode} from 'react';
import s from './index.module.scss'

type PropsType = {
    title: string
    children?: ReactNode
}

export const AdminPageLayout: React.FC<PropsType> = (props) => {
    return (
        <>
            <p className={s.h3}>{props.title}</p>
            <div className={s.wrapper}>{props.children}</div>
        </>
    )
}
