import React, { useState } from 'react'
import s from './ExpertProfile.module.scss'
import { Avatar } from '../../../../shared/ui/Avatar'
import { Icons } from '../../../../shared/icons'
import clsx from 'clsx'
import { Switcher } from '../../../../shared/ui/Switcher'
import { ExpertProfileTab } from '../expertProfileTab/ExpertProfileTab'
import { getCurrentRole } from '../../helpers'
import { getInteractionFormat } from '../../../../widgets/fillingProfile/ui/helpers'
import { useFetchProfile } from '../../api/queries'
import { Tag } from '../../../../shared/ui/Tag'

export const ExpertProfile: React.FC = () => {
    const { data } = useFetchProfile()
    const [tabIndex, setTabIndex] = useState<string>('1')
    const options = [
        { value: '1', text: 'Направления экспертизы' },
        { value: '2', text: 'Опыт работы' },
    ]

    return (
        <div className={s.wrapper}>
            <div className={s.personal}>
                <Avatar className={s.avatar} url={data?.photo} />
                <div className={s.personal_info}>
                    <p className={clsx(s.h4, s.black)}>{data?.fullName}</p>
                    <p className={clsx(s.p3, s.blue)}>
                        {getCurrentRole(data?.roles)}
                    </p>
                    <p className={clsx(s.p3, s.light_gray)}>{data?.position}</p>
                </div>
                <div className={s.social}>
                    <a target="blank" href={data?.socialTelegram as string}>
                        <Icons.social.Telegram />
                    </a>
                    <a target="blank" href={data?.socialStaff as string}>
                        Staff
                    </a>
                </div>
            </div>
            <div className={s.tags}>
                {data?.tags?.map((el: any, i: number) => (
                    <Tag key={i}>{el}</Tag>
                ))}
            </div>

            <div className={clsx(s.about, s.black)}>
                <p className={s.p1}>О себе:</p>
                <p className={s.p3}>{data?.about}</p>
                <div className={s.about_columns}>
                    <div>
                        <p className={s.p1}>Формат взаимодействия:</p>
                        <p className={clsx(s.p3, s.gray)}>
                            {getInteractionFormat(data?.interactionFormat!)}
                        </p>
                    </div>
                    <div>
                        <p className={s.p1}>Город:</p>
                        <p className={clsx(s.p3, s.gray)}>{data?.city}</p>
                    </div>
                </div>
            </div>

            <Switcher
                options={options}
                setValue={setTabIndex}
                value={tabIndex}
                className={s.switcher}
            />
            <ExpertProfileTab
                tabIndex={tabIndex}
                expertise={data?.expertise}
                experience={data?.experience}
            />
        </div>
    )
}
