import React, { useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { data } from './temporaryData'
import clsx from 'clsx'

import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/effect-coverflow'
import { Autoplay } from 'swiper/modules'

import s from './advantages.module.scss'
import ISwiper from 'swiper'

export const Advantages = () => {
    const advantagesSwiperRef = useRef<ISwiper>()
    const [hoveredIndexes, setHoveredIndexes] = useState<number[]>([])

    return (
        <Swiper
            loop={true}
            autoplay={{
                delay: 2000,
                pauseOnMouseEnter: true,
                disableOnInteraction: true,
            }}
            modules={[Autoplay]}
            className={s.advantagesSlider}
            breakpoints={{
                320: {
                    slidesPerView: 1.7,
                    spaceBetween: 16,
                },
                550: {
                    slidesPerView: 2.6,
                    spaceBetween: 16,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 16,
                },
                1440: {
                    slidesPerView: 4.2,
                    spaceBetween: 20,
                },
            }}
            onSwiper={(swiper: ISwiper) => {
                advantagesSwiperRef.current = swiper
            }}
            onSlideNextTransitionStart={() => {
                let index = 0
                index = advantagesSwiperRef.current?.realIndex + 1

                if (index === 6) {
                    index = 0
                }

                setHoveredIndexes([index])
            }}
        >
            {data.map((item, index) => {
                const isHovered = hoveredIndexes.includes(index)

                //TODO: Добавить свечение иконке
                return (
                    <SwiperSlide
                        key={index}
                        className={clsx([
                            s.swiperSlide,
                            { [s.customHover]: isHovered },
                        ])}
                    >
                        <div
                            className={s.card}
                            onMouseEnter={() =>
                                setHoveredIndexes(() => [index])
                            }
                            onMouseLeave={() => {
                                let index = 0
                                index =
                                    advantagesSwiperRef.current?.realIndex + 1

                                if (index === 6) {
                                    index = 0
                                }
                                setHoveredIndexes([index])
                            }}
                        >
                            <div className={s.icon}>
                                {isHovered ? item.iconActive : item.icon}
                            </div>
                            <p className={s.title}>{item.title}</p>
                            <div className={s.description}>
                                <span className={s.descriptionTitle}>
                                    {item.title}
                                </span>
                                <span className={s.descriptionText}>
                                    {item.description}
                                </span>
                            </div>
                        </div>
                    </SwiperSlide>
                )
            })}
        </Swiper>
    )
}
