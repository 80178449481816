import React, { useState } from 'react'
import { Icons } from '../../../shared/icons'
import { expertData, options, studentData } from './temporartData'
import star from '../../../public/image/landingMain/possibilities/star.svg'

import s from './possibilities.module.scss'
import clsx from 'clsx'
import { useScreenType } from '../../../shared/hooks/useScreenType'
import { useHistory } from 'react-router-dom'

export const Possibilities = () => {
    const [value, setValue] = useState<string>('1')
    const [active, setActive] = useState<number>(0)
    const screenType = useScreenType()
    const history = useHistory()

    const isStudentData = value === '1'
    const currentData = isStudentData ? studentData : expertData

    const chooseCard = (index: number) => {
        setActive(index)
    }

    const changeCategory = (value: string) => {
        setValue(value)
    }

    return (
        <div className={s.wrapper}>
            <div className={s.info}>
                <h2 className={s.title}>
                    Твои возможности <br /> на платформе
                </h2>
                <div className={s.whoAreYou}>
                    <div className={s.switcher}>
                        {options.map((item) => (
                            <button
                                key={item.value}
                                onClick={() => changeCategory(item.value)}
                                className={clsx([
                                    s.findButton,
                                    { [s.active]: value === item.value },
                                ])}
                            >
                                {item.text}
                            </button>
                        ))}
                    </div>
                    <div className={s.description}>
                        Ты можешь начать делиться экспертизой, выбрав одну или
                        несколько ролей. Либо начать обучение с помощью коллеги
                        из Ozon
                    </div>
                </div>
            </div>
            <div className={clsx([s.gridWrapper])}>
                {currentData.map(
                    (
                        {
                            backgroundImage,
                            title,
                            text,
                            buttonText,
                            backgroundImageCard,
                            navigate,
                        },
                        index
                    ) => (
                        <div
                            key={index}
                            className={clsx([
                                s.block,
                                {
                                    [s.mainBlock]: active === index,
                                    [s[`block${index}`]]: true,
                                },
                            ])}
                        >
                            {active === index ? (
                                <div
                                    key={index}
                                    className={clsx([
                                        s.mentorWrapper,
                                        { [s.studentData]: isStudentData },
                                    ])}
                                >
                                    <img
                                        className={s.starImg}
                                        src={star}
                                        alt={'star'}
                                    />
                                    <div className={s.mentorImgWrapper}>
                                        <img
                                            className={s.mentorImg}
                                            src={backgroundImage}
                                            alt={title}
                                        />
                                    </div>
                                    <div className={s.mentorInfo}>
                                        <div className={s.mentorText}>
                                            <span className={s.mentorTitle}>
                                                {title}
                                            </span>
                                            {text}
                                        </div>
                                        <button
                                            className={clsx([
                                                s.mentorButton,
                                                {
                                                    [s.studentData]:
                                                        isStudentData,
                                                },
                                            ])}
                                            onClick={() =>
                                                history.push(navigate)
                                            }
                                        >
                                            {buttonText}
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className={s.emptyCard}
                                    onClick={() => chooseCard(index)}
                                    onMouseEnter={() => {
                                        if (screenType === 'desktop')
                                            setActive(index)
                                    }}
                                    onMouseLeave={() => {
                                        if (screenType === 'desktop')
                                            setActive(index)
                                    }}
                                >
                                    <img
                                        className={s.emptyCardImg}
                                        src={backgroundImageCard}
                                        alt={'image'}
                                    />
                                    <span className={s.mentorTitle}>
                                        {title}
                                    </span>
                                    <div className={s.circle}>
                                        <Icons.circleIcons.CircleIconArrowDark />
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                )}
            </div>
        </div>
    )
}
