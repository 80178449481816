import React from 'react'
import s from './profileTab.module.scss'
import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

type ExperienceType = {
    isOngoing: boolean
    organization: string
    position: string
    results: string
    since: string
    until: string
}

type ExpertiseType = {
    description: string
    specialisations: string[]
}

type PropsType = {
    tabIndex: string
    experience: ExperienceType[]
    expertise: ExpertiseType[]
}

export const ProfileTab: React.FC<PropsType> = ({
    tabIndex,
    experience,
    expertise,
}) => {
    const Expertise = () => {
        return (
            <ul className={s.ExpertiseList}>
                {expertise.length > 0 &&
                    expertise.map((item, index) => {
                        return (
                            <li key={index} className={s.ExpertiseList__item}>
                                <h4 className={s.TitleH4}>
                                    {item.description}
                                </h4>
                                <p className={s.TitleP1}>С чем могу помочь?</p>
                                <ul style={{listStyleType: "none"}}>
                                    {item.specialisations.length > 0 &&
                                        item.specialisations.map(
                                            (item, index) => {
                                                return (
                                                    <li key={index}>{item || '⠀'}</li>
                                                )
                                            }
                                        )}
                                </ul>
                            </li>
                        )
                    })}
            </ul>
        )
    }

    const Experience = () => {
        const getDateDiapason = () => {
            let m = ''
            let y = ''
            let startDate: any = ''
            let endDate: any = ''
            experience?.forEach((el) => {
                startDate = startDate
                    ? startDate > el.since
                        ? el.since
                        : startDate
                    : el.since
                endDate = el.isOngoing
                    ? moment()
                    : startDate
                      ? endDate < el.until
                          ? el.until
                          : startDate
                      : el.until
            })

            const years = Math.abs(
                moment(startDate).diff(moment(endDate), 'years')
            )
            const months = Math.abs(
                moment(startDate).diff(moment(endDate), 'months') % 12
            )

            if (years === 1) {
                y = `${years} Год`
            } else if (years > 1 && years < 5) {
                y = `${years} Года`
            } else if (years >= 5) {
                y = `${years} Лет`
            } else {
                y = ''
            }

            if (months === 1) {
                m = `${months} Месяц`
            } else if (months > 1 && months < 5) {
                m = `${months} Месяца`
            } else if (months >= 5) {
                m = `${months} Месяцев`
            }
            return `${y} ${m}`
        }

        return (
            <div className={s.Experience}>
                <div className={s.TitleH4}>{getDateDiapason()}</div>
                <ul className={s.Experience__list}>
                    {experience.length > 0 &&
                        experience.map((item, index) => {
                            return (
                                <li key={index} className={s.Experience__item}>
                                    <div className={s.Experience__info}>
                                        <p className={s.TitleP2}>
                                            {item.position}
                                        </p>
                                        <span
                                            className={
                                                s.Experience__organization
                                            }
                                        >
                                            {item.organization}
                                        </span>
                                        <span
                                            className={s.Experience__timeLimit}
                                        >
                                            {`${moment(item.since).format('MMMM YYYY')} - ${item.isOngoing || item.until === null ? 'по настоящее время' : moment(item.until).format('MMMM YYYY')}`}
                                        </span>
                                    </div>
                                    <div className={s.Experience__results}>
                                        {item.results
                                            .split('\n')
                                            .map((el: string, i: number) => (
                                                <p key={i} className={s.p3}>
                                                    {el}
                                                </p>
                                            ))}
                                    </div>
                                </li>
                            )
                        })}
                </ul>
            </div>
        )
    }

    switch (tabIndex) {
        case '1':
            return <Expertise />
        case '2':
            return <Experience />
        default:
            return null
    }
}
