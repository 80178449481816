import React, { useState } from 'react'
import { IAdminPanelPropsType } from '../../model/types'
import { AdminPageLayout } from '../../ui/adminPageLayout/layout'
import { StatusType } from '../faq/model/types'
import { ApplicationsList } from './programList'
import { ProgramForm } from './programForm'
import { useGetProgramById } from './api/queries'

export const Programs: React.FC<IAdminPanelPropsType> = ({ title }) => {
    const [status, setStatus] = useState<StatusType | null>(null)
    const [programId, setProgramId] = useState<number>()

    const ProgramChangeForm = () => {
        const { data: program, isFetching } = useGetProgramById(programId!)
        if (isFetching) return null
        return (
            <ProgramForm
                status={status!}
                setStatus={setStatus}
                program={program}
            />
        )
    }

    if (status === 'edit') {
        return <ProgramChangeForm />
    }

    if (status === 'add') {
        return <ProgramForm status={status} setStatus={setStatus} />
    }

    return (
        <AdminPageLayout title={title}>
            <ApplicationsList
                setProgramId={setProgramId}
                setStatus={setStatus}
            />
        </AdminPageLayout>
    )
}
