import { adminApiInstance } from '../../../../../shared/api'
import {
    IMerchandiseParams,
    IMerchandisePatch,
    IMerchandisePost,
} from '../model/types'

export const shopApi = {
    getMerchandise: (params: { query?: string; sort?: string }) =>
        adminApiInstance.get(`/merchandise`, { params }),
    getMerchandiseById: (id: number) =>
        adminApiInstance.get(`/merchandise/${id}`),
    deleteMerchandise: (data: { ids: number[] }) =>
        adminApiInstance.delete(`/merchandise`, { data }),
    patchMerchandiseById: (id: number, data: IMerchandisePatch) =>
        adminApiInstance.patch(`/merchandise/${id}`, data),
    postCreateMerchandise: (data: IMerchandisePost) =>
        adminApiInstance.post(`/merchandise`, data),
    postUploadMerchandisePhoto: (photo: FormData) =>
        adminApiInstance.post(`/merchandise/photo`, photo),
    getMerchandiseRequests: (params: IMerchandiseParams) =>
        adminApiInstance.get(`/merchandise/requests`, { params }),
    putMerchandiseRequestById: (id: number, data: { state: string }) =>
        adminApiInstance.put(`/merchandise/requests/${id}`, data),
}
