import { useQuery } from '@tanstack/react-query'
import { programApi } from './index'
import { IProgramProfile, ProgramCardType } from '../types'

export const useGetProgramInfo = (id: number) => {
    return useQuery({
        queryKey: ['programInfo'],
        queryFn: async () => {
            const response = await programApi.getProgram(id)
            return response.data
        },
    })
}

export const useGetProgramById = (id: number) => {
    return useQuery<ProgramCardType>({
        queryKey: ['program'],
        queryFn: async () => {
            const response = await programApi.getProgram(id)
            return response.data
        },
    })
}

export const useGetProgramProfile = (id: number) => {
    return useQuery<IProgramProfile>({
        queryKey: ['programProfile'],
        queryFn: async () => {
            const response = await programApi.getProgramProfile(id)
            return response.data
        },
    })
}
