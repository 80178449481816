import React, { ReactElement, ReactNode } from 'react'
import { useAutoAuth } from '../../hooks/useAutoAuth'

export const AuthAppWrapper: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const { isReady } = useAutoAuth()

    if (!isReady) return null
    return children as ReactElement<any, any>
}
