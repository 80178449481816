import React, { SyntheticEvent, useEffect, useState } from 'react'
import { SmartForm } from '../../../../../../../../services/formProcessing/ui/SmartForm'
import s from './reviewsPromoList.module.scss'
import { TextField } from '../../../../../../../../shared/ui/TextField'
import { AppButton } from '../../../../../../../../shared/ui/Button'
import { Icons } from '../../../../../../../../shared/icons'
import clsx from 'clsx'
import { Pagination } from '../../../../../../../../widgets/pagination/ui/Pagination'
import { ModalWindow } from '../../../../../../../../shared/ui/ModalWindow'
import { useSmartForm } from '../../../../../../../../services/formProcessing'
import { useList } from '../../../../../../../../shared/hooks/useList/useList'
import { IReviewGet } from '../../model/types'
import { reviewsApi } from '../../api'
import moment from 'moment'
import { StatusType } from '../../../../../faq/model/types'

interface IColumn {
    label: string
    order: 'asc' | 'desc' | 'default'
    sort: keyof IReviewGet
}

interface IRow extends IReviewGet {
    checked: boolean
}

const COLUMNS: IColumn[] = [
    { label: 'Превью', order: 'default', sort: 'preview' },
    { label: 'ID', order: 'default', sort: 'id' },
    { label: 'Сортировка', order: 'default', sort: 'priority' },
    {
        label: 'Дата создания',
        order: 'default',
        sort: 'enabledSince',
    },
    { label: 'ФИО', order: 'default', sort: 'fullName' },
    { label: 'Должность', order: 'default', sort: 'position' },
]

type PropsType = {
    setStatus: (value: StatusType | null) => void
    status: StatusType | null
    setReviewId: (id: number) => void
}

export const ReviewsPromoList: React.FC<PropsType> = ({
    setStatus,
    status,
    setReviewId,
}) => {
    const [columns, setColumns] = useState<IColumn[]>(COLUMNS)
    const [rows, setRows] = useState<IRow[]>([])
    const [limit, setLimit] = useState(10)
    const [isOpened, setIsOpened] = useState(false)
    const [selectedId, setSelectedId] = useState<{ id: number }[]>([])
    const form = useSmartForm({
        defaultValues: {
            q: '',
            limit: { value: limit, label: limit },
        },
    })

    const {
        items: reviewsList,
        setFilters,
        nextPage,
        prevPage,
        page,
        total,
        setPage,
        reset,
    } = useList<IReviewGet>({
        key: 'reviewsList',
        apiMethod: (params) => reviewsApi.getPromoReviews(params),
        defaultLimit: limit,
    })

    const isRowActive = (id: number) => selectedId.find((el) => el.id === id)

    const onFiltersApply = (data: any) => {
        setFilters({ ...data, limit, q: data.q })
    }

    const onPrev = (evt: SyntheticEvent) => {
        evt.preventDefault()
        prevPage()
    }
    const onNext = (evt: SyntheticEvent) => {
        evt.preventDefault()
        nextPage()
    }
    const handleChangeLimit = ({ value }: any) => {
        setLimit(value)
        setFilters({ limit: value })
        form.setValue('q', '')
    }
    const handleClickEdit = () => {
        const [{ id }] = selectedId
        setStatus('edit')
        setReviewId(id)
    }

    const removeReviews = async () => {
        const ids = selectedId.map((el) => el.id)
        try {
            await reviewsApi.deletePromoReviews({ ids })
            setIsOpened(false)
            setSelectedId([])
            reset()
        } catch (e) {
            console.log(e)
            alert('Something went wrong')
        }
    }

    const toggleCheckbox = (value: IRow) => {
        setRows((rows) =>
            rows.map((el) =>
                el.id === value.id ? { ...el, checked: !el.checked } : el
            )
        )
        if (selectedId.find((el) => el.id === value.id)) {
            setSelectedId((item) => item.filter(({ id }) => id !== value.id))
        } else {
            setSelectedId([...selectedId, { id: value.id }])
        }
    }

    const selectAllCheckbox = () => {
        if (selectedId.length === rows.length) {
            setRows((rows) => rows.map((el) => ({ ...el, checked: false })))
            setSelectedId([])
        } else {
            setRows((rows) => rows.map((el) => ({ ...el, checked: true })))
            setSelectedId(rows.map((el) => ({ id: el.id })))
        }
    }

    const sortTable = (column: IColumn) => {
        setFilters({
            ...form.getValues(),
            limit,
            sort: column.sort === 'enabledSince' ? 'createdAt' : column.sort,
            order: column.order === 'asc' ? 'desc' : 'asc',
        })
        setColumns((columns) =>
            columns.map((el) =>
                el.sort === column.sort
                    ? { ...el, order: el.order === 'asc' ? 'desc' : 'asc' }
                    : { ...el, order: 'default' }
            )
        )
    }

    useEffect(() => {
        setRows(reviewsList.map((el) => ({ ...el, checked: false })))
    }, [reviewsList, status])

    return (
        <>
            <SmartForm form={form} submit={onFiltersApply}>
                <div className={s.reviewsWrapper}>
                    <div className={s.controls}>
                        <div className={s.searchLine}>
                            <Icons.admin.search />
                            <TextField
                                className={s.search_field}
                                name="q"
                                placeholder="Поиск"
                            />
                        </div>
                        <AppButton variant="ghost" className={s.search_button}>
                            Найти
                        </AppButton>
                        <AppButton
                            className={s.add_button}
                            onClick={() => {
                                setStatus('add')
                            }}
                        >
                            Добавить{'\u00A0'}элемент
                        </AppButton>
                    </div>
                    <div className={s.tableWrapper}>
                        <table className={s.table}>
                            <thead>
                                <tr>
                                    <th>
                                        <input
                                            type="checkbox"
                                            onChange={selectAllCheckbox}
                                        />
                                    </th>
                                    {columns.map((el) => (
                                        <th key={el.label}>
                                            <span
                                                onClick={() =>
                                                    el.label !== 'Превью' &&
                                                    sortTable(el)
                                                }
                                            >
                                                {el.label}
                                                {el.label !== 'Превью' && (
                                                    <Icons.M.ChevronUp
                                                        className={clsx(
                                                            s.sort,
                                                            {
                                                                [s.sort_asc]:
                                                                    el.order ===
                                                                    'asc',
                                                            }
                                                        )}
                                                    />
                                                )}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map((el, index) => (
                                    <tr
                                        key={index}
                                        className={clsx(s.row, {
                                            [s.row_active]: isRowActive(el.id),
                                        })}
                                    >
                                        <td>
                                            <input
                                                type="checkbox"
                                                onChange={() =>
                                                    toggleCheckbox(el)
                                                }
                                                checked={el.checked}
                                            />
                                        </td>
                                        <td>
                                            <img
                                                className={s.previewPhoto}
                                                src={el.preview}
                                                alt="previewPhoto"
                                            />
                                        </td>
                                        <td>{el.id}</td>
                                        <td>{el.priority}</td>
                                        <td>
                                            {moment(el.enabledSince).format(
                                                'YYYY-MM-DD'
                                            )}
                                        </td>
                                        <td>{el.fullName}</td>
                                        <td>{el.position}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className={s.footer}>
                    <div className={s.footer_buttons}>
                        {selectedId.length === 1 && (
                            <div
                                className={clsx(s.footer_buttons_icon, s.edit)}
                                onClick={handleClickEdit}
                            >
                                <Icons.M.Edit />
                                Редактировать
                            </div>
                        )}
                        {selectedId.length >= 1 && (
                            <div
                                className={clsx(
                                    s.footer_buttons_icon,
                                    s.remove
                                )}
                                onClick={() => {
                                    setIsOpened(true)
                                }}
                            >
                                <Icons.M.Cancelled />
                                Удалить
                            </div>
                        )}
                    </div>
                    <Pagination
                        page={page}
                        total={total}
                        limit={limit}
                        handleChangeLimit={handleChangeLimit}
                        onNext={onNext}
                        onPrev={onPrev}
                        setPage={setPage}
                    />
                </div>
            </SmartForm>

            <ModalWindow isOpened={isOpened} setIsOpened={setIsOpened}>
                <div className={s.modal}>
                    <p className={s.modal_title}>
                        Ты уверен, что хочешь удалить выбранные элементы?
                    </p>
                    <div className={s.modal_controls}>
                        <AppButton onClick={removeReviews}>
                            Да, удалить
                        </AppButton>
                        <AppButton
                            onClick={() => {
                                setIsOpened(false)
                                setSelectedId([])
                                setRows(
                                    reviewsList.map((el) => ({
                                        ...el,
                                        checked: false,
                                    }))
                                )
                            }}
                            variant="ghost"
                        >
                            Нет, оставить
                        </AppButton>
                    </div>
                </div>
            </ModalWindow>
        </>
    )
}
