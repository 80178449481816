import React from 'react'

import { Layout } from '../../../shared/ui/Layout'
import { Container } from '../../../shared/ui/Container'

import s from './privacyPolicy.module.scss'

export const PrivacyPolicy = () => {
    return (
        <Layout>
            <Container>
                <div className={s.privacyPolicy}>
                    <h1>
                        Политика конфиденциальности <br /> интернет-сайта
                    </h1>
                    <p>
                        Настоящая Политика конфиденциальности персональных
                        данных (далее - Политика конфиденциальности) является
                        неотъемлемой частью Публичной оферты, размещенной на
                        сайте в сети Интернет по адресу:
                        https://expertplace.o3t.ru/privacy (далее - Сайт).
                    </p>
                    <p>
                        Использование сервисов Сайта означает безоговорочное
                        согласие Пользователя с настоящей Политикой и указанными
                        в ней условиями обработки его персональной информации; в
                        случае несогласия с этими условиями Пользователь должен
                        воздержаться от использования сервисов.
                    </p>
                    <h2>1. ОБЩИЕ ПОЛОЖЕНИЯ</h2>
                    <p>
                        1.1 В рамках настоящей Политики под персональной
                        информацией Пользователя понимаются:
                    </p>
                    <p>
                        1.1.1. Персональная информация, которую Пользователь
                        предоставляет о себе самостоятельно при регистрации
                        (создании учетной записи) или в процессе использования
                        Сервисов, включая персональные данные Пользователя.
                        Обязательная для предоставления Сервисов информация
                        помечена специальным образом.
                    </p>
                    <p>
                        1.1.2. Данные, которые автоматически передаются сервисам
                        Сайта в процессе их использования с помощью
                        установленного на устройстве Пользователя программного
                        обеспечения, в том числе IP-адрес, данные файлов cookie,
                        информация о браузере Пользователя (или иной программе,
                        с помощью которой осуществляется доступ к сервисам),
                        технические характеристики оборудования и программного
                        обеспечения, используемых Пользователем, дата и время
                        доступа к сервисам, адреса запрашиваемых страниц и иная
                        подобная информация.
                    </p>
                    <p>
                        1.1.3. Настоящая Политика конфиденциальности применяется
                        только к Сайту https://expertplace.o3team.ru. Сайт
                        https://expertplace.o3team.ru не контролирует и не несет
                        ответственности за сайты третьих лиц, на которые
                        Пользователь может перейти по ссылкам, доступным на
                        Сайте{' '}
                        <a href="https://expertplace.o3t.ru/.">
                            https://expertplace.o3t.ru/.
                        </a>
                    </p>
                    <p>
                        1.1.4. Оператором персональных данных является ООО «Си
                        Четыре Групп Криэйт».
                    </p>
                    <h2>
                        2. ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЕЙ
                    </h2>
                    <p>
                        2.1. Сайт собирает и хранит только ту персональную
                        информацию, которая необходима для предоставления
                        сервисов или исполнения соглашений и договоров с
                        Пользователем, за исключением случаев, когда
                        законодательством предусмотрено обязательное хранение
                        персональной информации в течение определенного законом
                        срока.
                    </p>
                    <p>
                        В случае получения уведомления от Пользователя об отзыве
                        согласия на обработку персональных данных Сайт
                        прекращает обработку персональных данных Пользователя в
                        срок, не превышающий 10 рабочих дней с момента
                        получения.
                    </p>
                    <p>
                        Уведомление об отзыве согласия на обработку персональных
                        данных направляется на адрес электронной почты:
                        c4@c4group.ru, а также путем письменного обращения по
                        юридическому адресу: 105066, г. Москва, ул.
                        Новорязанская, д. 26, стр.1, этаж 1 пом. I, ком.
                    </p>
                    <p>
                        2.2. Персональную информацию Пользователя Сайт
                        обрабатывает в следующих целях:
                    </p>
                    <p>
                        2.2.1. Идентификации Пользователя, зарегистрированного
                        на Сайте, для обучения/предоставления услуг.
                    </p>
                    <p>
                        2.2.2. Предоставления Пользователю доступа к
                        персонализированным ресурсам Сайта.
                    </p>
                    <p>
                        2.2.3. Установления с Пользователем обратной связи,
                        включая направление уведомлений, запросов, касающихся
                        использования Сайта, оказания услуг, обработку запросов
                        и заявок от Пользователя.
                    </p>
                    <p>
                        2.2.4. Определения места нахождения Пользователя для
                        обеспечения безопасности, предотвращения мошенничества.
                    </p>
                    <p>
                        2.2.5. Подтверждения достоверности и полноты
                        персональных данных, предоставленных Пользователем.
                    </p>
                    <p>
                        2.2.6. Создания учетной записи для
                        обучения/предоставления услуг, если Пользователь дал
                        согласие на создание учетной записи.
                    </p>
                    <p>
                        2.2.7. Уведомления Пользователя Сайта об изменениях,
                        рекламных продуктов.
                    </p>
                    <p>
                        2.2.8. Предоставления Пользователю эффективной
                        клиентской и технической поддержки при возникновении
                        проблем, связанных с использованием Сайта.
                    </p>
                    <p>
                        2.2.9. Осуществления рекламной деятельности с согласия
                        Пользователя.
                    </p>
                    <h2>
                        3. УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ
                        ПОЛЬЗОВАТЕЛЕЙ И ЕЕ ПЕРЕДАЧИ ТРЕТЬИМ ЛИЦАМ
                    </h2>
                    <p>
                        3.1. Сайт хранит персональную информацию Пользователей в
                        соответствии с внутренними регламентами конкретных
                        сервисов.
                    </p>
                    <p>
                        3.2. В отношении персональной информации Пользователя
                        сохраняется ее конфиденциальность, кроме случаев
                        добровольного предоставления Пользователем информации о
                        себе для общего доступа неограниченному кругу лиц. При
                        использовании отдельных сервисов Пользователь
                        соглашается с тем, что определенная часть его
                        персональной информации становится общедоступной.
                    </p>
                    <p>
                        3.3. Сайт вправе передать персональную информацию
                        Пользователя третьим лицам в следующих случаях:
                    </p>
                    <p>
                        3.3.1. Пользователь выразил согласие на такие действия.
                    </p>
                    <p>
                        3.3.2. Передача необходима для использования
                        Пользователем определенного сервиса либо для исполнения
                        определенного соглашения или договора с Пользователем.
                    </p>
                    <p>
                        3.3.3. Передача предусмотрена российским или иным
                        применимым законодательством в рамках установленной
                        законодательством процедуры.
                    </p>
                    <p>
                        3.3.4. В случае продажи Сайта к приобретателю переходят
                        все обязательства по соблюдению условий настоящей
                        Политики применительно к полученной им персональной
                        информации.
                    </p>
                    <p>
                        3.4. Обработка персональных данных Пользователя
                        осуществляется без ограничения срока следующими
                        способами: сбор, запись, систематизация, накопление,
                        хранение, уточнение (обновление, изменение), извлечение,
                        использование, передача (распространение,
                        предоставление, доступ), обезличивание, блокирование,
                        удаление, уничтожение персональных данных, в том числе в
                        информационных системах персональных данных с
                        использованием средств автоматизации или без
                        использования таких средств. Обработка персональных
                        данных Пользователей осуществляется в соответствии с
                        Федеральным{' '}
                        <a
                            href="https://login.consultant.ru/link/?req=doc&base=LAW&n=439201&date=29.05.2024"
                            target="_blank"
                            rel="noreferrer"
                        >
                            законом
                        </a>{' '}
                        от 27.07.2006 N 152-ФЗ &quot;О персональных
                        данных&quot;.
                    </p>
                    <p>
                        3.5. При утрате или разглашении персональных данных
                        Администрация Сайта информирует Пользователя об утрате
                        или разглашении персональных данных.
                    </p>
                    <p>
                        3.6. Администрация Сайта принимает необходимые
                        организационные и технические меры для защиты
                        персональной информации Пользователя от неправомерного
                        или случайного доступа, уничтожения, изменения,
                        блокирования, копирования, распространения, а также от
                        иных неправомерных действий третьих лиц.
                    </p>
                    <p>
                        3.7. Администрация Сайта совместно с Пользователем
                        принимает все необходимые меры по предотвращению убытков
                        или иных отрицательных последствий, вызванных утратой
                        или разглашением персональных данных Пользователя.
                    </p>
                    <h2>4. ОБЯЗАТЕЛЬСТВА СТОРОН</h2>
                    <p>4.1. Пользователь обязан:</p>
                    <p>
                        4.1.1. Предоставить информацию о персональных данных,
                        необходимую для пользования Сайтом.
                    </p>
                    <p>
                        4.1.2. Обновлять, дополнять предоставленную информацию о
                        персональных данных в случае изменения данной
                        информации.
                    </p>
                    <p>4.2. Администрация Сайта обязана:</p>
                    <p>
                        4.2.1. Использовать полученную информацию исключительно
                        для целей, указанных в настоящей Политике
                        конфиденциальности.
                    </p>
                    <p>
                        4.2.2. Обеспечить хранение конфиденциальной информации в
                        тайне, не разглашать без предварительного письменного
                        разрешения Пользователя, а также не осуществлять
                        продажу, обмен, опубликование либо разглашение иными
                        возможными способами переданных персональных данных
                        Пользователя, за исключением предусмотренных настоящей
                        Политикой конфиденциальности.
                    </p>
                    <p>
                        4.2.3. Для обеспечения защиты персональных данных
                        Пользователя при их обработке приняты следующие
                        правовые, организационные и технические меры от
                        несанкционированного, неправомерного или случайного
                        доступа к персональным данным, уничтожения, изменения,
                        блокирования, копирования, предоставления,
                        распространения персональных данных, а также от иных
                        неправомерных действий в отношении персональных данных.
                    </p>
                    <p>
                        4.2.4. Осуществить блокирование персональных данных,
                        относящихся к соответствующему Пользователю, с момента
                        обращения или запроса Пользователя или его законного
                        представителя либо уполномоченного органа по защите прав
                        субъектов персональных данных на период проверки в
                        случае выявления недостоверных персональных данных или
                        неправомерных действий.
                    </p>
                    <h2>5. ОТВЕТСТВЕННОСТЬ СТОРОН</h2>
                    <p>
                        5.1. Администрация Сайта, не исполнившая свои
                        обязательства, несет ответственность за убытки,
                        понесенные Пользователем в связи с неправомерным
                        использованием персональных данных, в соответствии с
                        законодательством Российской Федерации.
                    </p>
                    <p>
                        5.2. В случае утраты или разглашения конфиденциальной
                        информации Администрация Сайта не несет ответственности,
                        если данная конфиденциальная информация:
                    </p>
                    <p>
                        5.2.1. Стала публичным достоянием до ее утраты или
                        разглашения.
                    </p>
                    <p>
                        5.2.2. Была получена от третьей стороны до момента ее
                        получения Администрацией Сайта.
                    </p>
                    <p>5.2.3. Была разглашена с согласия Пользователя.</p>
                    <h2>6. РАЗРЕШЕНИЕ СПОРОВ</h2>
                    <p>
                        6.1. До обращения в суд с иском по спорам, возникающим
                        из отношений между Пользователем Сайта и Администрацией
                        Сайта, обязательным является предъявление претензии
                        (письменного предложения о добровольном урегулировании
                        спора).
                    </p>
                    <p>
                        6.2. Получатель претензии в течение 30 календарных дней
                        со дня получения претензии письменно уведомляет
                        заявителя претензии о результатах рассмотрения
                        претензии.
                    </p>
                    <p>
                        6.3. При недостижении соглашения спор будет передан на
                        рассмотрение в суд в соответствии с действующим
                        законодательством Российской Федерации.
                    </p>
                    <p>
                        6.4. К настоящей Политике конфиденциальности и
                        отношениям между Пользователем и Администрацией Сайта
                        применяется действующее законодательство Российской
                        Федерации.
                    </p>
                    <h2>7. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</h2>
                    <p>
                        7.1. Администрация Сайта вправе вносить изменения в
                        настоящую Политику конфиденциальности без согласия
                        Пользователя.
                    </p>
                    <p>
                        7.2. Новая Политика конфиденциальности вступает в силу с
                        момента ее размещения на Сайте, если иное не
                        предусмотрено новой редакцией Политики
                        конфиденциальности.
                    </p>
                    <p>
                        7.3. Все предложения или вопросы по настоящей Политике
                        конфиденциальности следует сообщать c4@c4group.ru.
                    </p>
                    <p>
                        7.4. Действующая Политика конфиденциальности размещена
                        на странице по адресу:{' '}
                        <a
                            href="https://expertplace.o3t.ru/privacy"
                            target="_blank"
                            rel="noreferrer"
                        >
                            https://expertplace.o3t.ru/privacy
                        </a>
                        .
                    </p>
                    <p>
                        7.5. Настоящая Политика конфиденциальности является
                        неотъемлемой частью Публичной оферты и Соглашения об
                        использовании Сайта, размещенных на странице по адресу:
                        <a
                            href="https://expertplace.o3t.ru/agreement"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {' '}
                            https://expertplace.o3t.ru/agreement
                        </a>
                        .
                    </p>
                </div>
            </Container>
        </Layout>
    )
}
