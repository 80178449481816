import React, {useState} from 'react';
import {StatusType, FaqType, IFaqGet} from "../../model/types";
import {FaqForm} from "../faqForm";
import {AdminPageLayout} from "../../../../ui/adminPageLayout/layout";
import {FaqList} from "../faqList";
import {useList} from "../../../../../../shared/hooks/useList/useList";
import {faqApi} from "../../api";

type PropsType = {
    type: FaqType
    title: string
}

export const FaqLayout: React.FC<PropsType> = ({type, title}) => {
    const [status, setStatus] = useState<StatusType | null>(null)
    const [id, setId] = useState<number>()

    const query = useList<IFaqGet>({
        key: ['faq', type],
        apiMethod: (params) => faqApi.getFaqs(params, type),
    })


    if (status) return (
        <FaqForm
            id={id}
            type={type}
            status={status}
            setStatus={setStatus}
            reset={query.reset}
        />
    )

    return (
        <AdminPageLayout title={title}>
            <FaqList
                type={type}
                setId={setId}
                setStatus={setStatus}
                query={query}
            />
        </AdminPageLayout>
    )
}
