import className from './index.module.scss'
import { PromoCard } from './promoCard/promoCard'
import { ROUTES } from '../../../config/routes'

const content = [
    {
        id: 1,
        variant: 'v1' as const,
        title: 'Хочешь сам стать экспертом?',
        description:
            'Ознакомься с возможностями для экспертов и выбери свою роль на платформе',
        buttonName: 'Стать экспертом',
        navigation: ROUTES.experts,
    },
    {
        id: 2,
        variant: 'v2' as const,
        title: 'Нужна помощь в подборе экспертного обучения?',
        description: 'Напиши напрямую Маше',
        buttonName: 'Написать',
        navigation: `mailto:mavramova@ozon.ru `,
        expert: {
            name: 'Мария Аврамова',
            role: 'Менеджер по обучению',
            description:
                'Более двух лет помогаю развиваться сотрудникам в Ozon и смогу ответить на любой твой вопрос',
        },
    },
]

export const Promo = () => {
    return (
        <div className={className.Container}>
            {content.length > 0 &&
                content.map((item) => (
                    <PromoCard
                        key={item.id}
                        variant={item.variant}
                        title={item.title}
                        description={item.description}
                        buttonName={item.buttonName}
                        navigation={item.navigation}
                        expert={item.expert}
                    />
                ))}
        </div>
    )
}
