import React, { useState } from 'react'
import { AppButton } from '../../../../shared/ui/Button'
import { Typography } from '../../../../shared/ui/Typography'
import {
    ModalWindow,
    ModalWindowPropsType,
} from '../../../../shared/ui/ModalWindow'
import { useSmartForm } from '../../../../services/formProcessing'
import { SmartForm } from '../../../../services/formProcessing/ui/SmartForm'
import { TextField } from '../../../../shared/ui/TextField'
import { css } from '@emotion/react'
import { ApplicationRejectedModal } from './applicationRejected'
import { useModalWindow } from '../../../../shared/hooks/useModalWindow'
import { applicationApi } from '../../api'

const FORM_ID = 'applicationRejectedForm'

type ReasonForRejectionModalType = {
    id: number
    props: ModalWindowPropsType
    updateItem: (id: string | number, value: any) => void
}

type FormType = {
    reason: string
}

export const ReasonForRejectionModal = ({
    id,
    props,
    updateItem,
}: ReasonForRejectionModalType) => {
    const applicationRejectedModal = useModalWindow()
    const [reason, setReason] = useState('')

    const form = useSmartForm<FormType>({
        defaultValues: {
            reason: '',
        },
    })

    const handleSubmit = async (id: number) => {
        if (reason !== '') {
            try {
                const { data } = await applicationApi.putChangeApplicationState(
                    id,
                    {
                        reason: reason,
                        state: 'rejected',
                    }
                )
                updateItem(data.application.id, data.application)
                applicationRejectedModal.setIsOpened(true)
                props.setIsOpened(false)
            } catch (e) {
                console.log(e)
                alert('Something went wrong')
            }
        }
    }

    return (
        <>
            <ModalWindow {...props}>
                <Typography as="h4">Причина отклонения заявки</Typography>
                <Typography as="t1">Ученик получит уведомление</Typography>
                <SmartForm
                    css={css`
                        width: 100%;
                    `}
                    id={FORM_ID}
                    form={form}
                    submit={() => {}}
                >
                    <TextField
                        placeholder="Причина отклонения заявки"
                        name="reason"
                        onInput={(event) => {
                            setReason(event.currentTarget.value)
                        }}
                        required={true}
                    />
                </SmartForm>
                <AppButton
                    form={FORM_ID}
                    onClick={() => {
                        handleSubmit(id)
                    }}
                    variant="filled"
                    size="md"
                >
                    Отправить
                </AppButton>
            </ModalWindow>
            <ApplicationRejectedModal {...applicationRejectedModal} />
        </>
    )
}
