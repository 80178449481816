import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

type PropsType = {
    apiMethod: (params?: any) => Promise<any>
    defaultLimit?: number
    defaultParams?: { [key: string]: any }
    key: string | string[]
}

export const useList = <T>({
    apiMethod,
    defaultLimit = 10,
    defaultParams = {},
    key,
}: PropsType) => {
    const [filters, setFilters] = useState(defaultParams)
    const [items, setItems] = useState<T[]>([])
    const [mode, setMode] = useState<'resetAfterRequest' | 'addAfterRequest'>(
        'addAfterRequest'
    )
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(defaultLimit)
    const [page, setPage] = useState(1)

    const { data, error, isFetching, refetch, isError } = useQuery<{
        data: T[]
        total: number
    }>({
        staleTime: 0,
        gcTime: 0,
        retry: 1,
        queryKey: [key, limit, filters, page],
        queryFn: async () => {
            const response = await apiMethod({
                ...filters,
                limit,
                page,
            })

            return response.data
        },
    })

    useEffect(() => {
        if (data) {
            setTotal(data.total)

            if (mode == 'addAfterRequest') {
                setItems((prev) => [...prev, ...data.data])
                return
            }
            if (mode == 'resetAfterRequest') {
                setItems(data.data)
                return
            }
        }
    }, [data])

    const showAll = () => {
        if (data?.total) {
            setPage(1)
            setLimit(data.total)
            setMode('resetAfterRequest')
        }
    }

    const showMore = () => {
        setMode('addAfterRequest')
        setLimit(defaultLimit)
        setPage((prev) => prev + 1)
    }

    const onPageClickHandler = (page: number) => {
        setMode('resetAfterRequest')
        setLimit(defaultLimit)
        setPage(page)
    }

    const nextPage = () => {
        setMode('resetAfterRequest')
        setLimit(defaultLimit)
        setPage((p) => p + 1)
    }
    const prevPage = () => {
        setMode('resetAfterRequest')
        setLimit(defaultLimit)
        setPage((p) => p - 1)
    }

    const setFiltersHandler = (data: any) => {
        setMode('resetAfterRequest')
        setLimit(data.limit ?? defaultLimit)
        setPage(1)
        setFilters(data)
    }

    const reset = () => {
        setMode('resetAfterRequest')

        if (page == 1 && defaultLimit == limit) {
            refetch()
        } else {
            setPage(1)
            setLimit(defaultLimit)
        }
    }

    const updateItem = (id: string | number, newValue: T) => {
        setItems((prev) =>
            prev.map((item) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (item.id == id) {
                    return newValue
                }
                return item
            })
        )
    }

    return {
        items,
        showAll,
        showMore,
        error,
        isFetching,
        total,
        onPageClickHandler,
        nextPage,
        prevPage,
        page,
        filters,
        setFilters: setFiltersHandler,
        reset,
        refetch,
        setItems,
        updateItem,
        setPage,
        isError,
    }
}
