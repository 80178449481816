import { IProgramProfile, ProgrammType } from '../../types'
import { Program } from './Program/Program'
import React, { useEffect } from 'react'

export const ProgrammInfo: React.FC<{
    programm: ProgrammType
    // detailed one is for the programm page, shor is for the review page
    variant: 'short' | 'detailed'
    programProfile: IProgramProfile
}> = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Program
            variant={props.variant}
            programProfile={props.programProfile}
        />
    )
}
