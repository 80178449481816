import { TokenStorage } from '../model'
import { useEffect, useState } from 'react'
import { setAdminToken } from '../lib/setToken'
import { handleRefreshAdminAccessToken } from '../../../entities/admin/lib/auth'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../../config/routes'
import { LOCAL_STORAGE_ADMIN_TOKEN_DATA_KEY } from '../model/constants'

export const useAdminAutoAuth = () => {
    const [isReady, setIsReady] = useState(false)
    const { push } = useHistory()

    const handleAutoAuth = async () => {
        const token = TokenStorage.getTokenData('admin')

        if (!token) {
            push(ROUTES.adminPanel.auth)
            setIsReady(true)
            return
        }

        const currentDate = new Date()

        if (new Date(token.accessTokenExpiresAt * 1000) < currentDate) {
            if (new Date(token.refreshTokenExpiresAt * 1000) < currentDate) {
                localStorage.removeItem(LOCAL_STORAGE_ADMIN_TOKEN_DATA_KEY)
                push(ROUTES.adminPanel.auth)
                return
            }

            await handleRefreshAdminAccessToken(token.refreshToken)
        } else {
            setAdminToken(token)
        }

        setIsReady(true)
    }

    useEffect(() => {
        try {
            handleAutoAuth()
        } catch (e) {
            console.error(e)
            alert('There was an error with auth')
        }
    }, [])

    return {
        isReady,
    }
}
