import { apiInstance, adminApiInstance } from '../../../shared/api'
import { TokenStorage } from '../model'
import { GetTokenResponseType } from '../model/types'

export const setToken = (tokenData: GetTokenResponseType) => {
    apiInstance.defaults.headers.Authorization = `Bearer ${tokenData.accessToken}`
    TokenStorage.setTokenData(tokenData, 'user')
}

export const setAdminToken = (
    tokenData: GetTokenResponseType,
    remember: boolean = true
) => {
    adminApiInstance.defaults.headers.Authorization = `Bearer ${tokenData.accessToken}`

    if (remember) {
        TokenStorage.setTokenData(tokenData, 'admin')
    }
}
