import { Icons } from '../../../shared/icons'
import className from './index.module.scss'
import { AppButton } from '../../../shared/ui/Button'
import React, { useEffect, useRef, useState } from 'react'
import { useWindowDimensions } from '../../../shared/hooks/useWindowDimensions'
import { initialTitle } from '../../../pages/catalog/catalogBlock/catalogBlock'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

const initialValues = {
    q: '',
}

type PropsType = {
    setSearchQuery: (filters: any) => void
    isFilterPopupOpen: boolean
    isResetSearchbar: boolean
    setIsResetSearchbar: React.Dispatch<React.SetStateAction<boolean>>
    setTitle: React.Dispatch<React.SetStateAction<string>>
}

export const CatalogSearchBar: React.FC<PropsType> = ({
    setSearchQuery,
    isFilterPopupOpen,
    isResetSearchbar,
    setIsResetSearchbar,
    setTitle,
}) => {
    const [formValues, setFormValues] = useState(initialValues)
    const inputRef = useRef<HTMLInputElement>(null)
    const { width } = useWindowDimensions()
    const { search } = useLocation()
    const { push } = useHistory()

    const reset = () => {
        if (inputRef.current !== null) {
            inputRef.current.value = ''
        }
        push('/skills')
        setSearchQuery(initialValues)
        setTitle(initialTitle)
    }

    useEffect(() => {
        reset()
        setIsResetSearchbar(false)
    }, [isResetSearchbar])

    const handleChange = () => {
        if (inputRef.current !== null) {
            const value = inputRef.current.value
            setFormValues({ q: value })
        }
    }

    function handleSubmit(e: React.SyntheticEvent) {
        e.preventDefault()
        if (inputRef.current!.value) {
            setSearchQuery(formValues)
            setTitle(formValues.q)
        }
    }

    useEffect(() => {
        const qs = queryString.parseUrl(search, {
            arrayFormat: 'comma',
        }).query

        if (qs.q) {
            if (inputRef.current !== null) {
                inputRef.current.value = qs.q as string
            }
        }
    }, [])

    const cls = isFilterPopupOpen
        ? className.Form + ' ' + className.Form__open
        : className.Form

    return (
        <form onSubmit={handleSubmit} className={cls}>
            <label className={className.Label}>
                <Icons.M.Search
                    className={className.IconSearch}
                ></Icons.M.Search>
                <input
                    type="search"
                    placeholder={
                        width > 767
                            ? 'В чём нужна экспертиза? Например, Power Point'
                            : 'В чём нужна экспертиза?'
                    }
                    name="q"
                    ref={inputRef}
                    onChange={handleChange}
                    className={className.Input}
                />
                {inputRef.current?.value && (
                    <Icons.S.Cross
                        className={className.IconCross}
                        onClick={() => reset()}
                    ></Icons.S.Cross>
                )}
            </label>
            {width > 767 ? (
                <AppButton
                    variant="filled"
                    size="lg"
                    className={className.SearchButton}
                >
                    Найти
                </AppButton>
            ) : (
                <button className={className.IconSearchButton}>
                    <Icons.M.Search
                        className={className.IconSearchButton__icon}
                    />
                </button>
            )}
        </form>
    )
}
